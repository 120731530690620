import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  nama: ""
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      foto_desa: "",
      foto_info: "",
      input: "none",
      view: "inherit",
      judul: "Tambah Profile",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      sidebar: false,
      response_pengajuan_layanan: "",
      riwayat_disposisi: ""
    };
  }

  openCloseSidebar = () => {
    if (this.state.sidebar) {
      this.setState({ sidebar: false });
    } else {
      this.setState({ sidebar: true });
    }
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = () => {
    Get("pengaturan-aplikasi", null, dtkat => {
      console.log(dtkat);
      if (dtkat.results != null) {
        this.setState({
          data: dtkat.results,
          response_pengajuan_layanan: dtkat.results.response_pengajuan_layanan,
          sidebar: dtkat.results.riwayat_disposisi == 0 ? true : false
        });
      }
    });
  };

  // ========================================================================

  tutupForm = e => {
    this.setState({ input: "none", view: "inherit" });
  };

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.state.status.btnForm = true;
    this.forceUpdate();
    let uuid = null;
    let addProfile = {
    //   foto_desa: this.state.foto_desa,
      response_pengajuan_layanan: document.getElementById("response_pengajuan_layanan").value,
      riwayat_disposisi: this.state.sidebar==true?0:1,
    };
    let psn = "Tambah";
    let resstat = 200;
    let metode = "create";
    if(this.state.data!=null){
        uuid = this.state.data.uuid;
         psn = "Ubah";
         resstat = 200;
         metode = "update";
    }
    Post("pengaturan-aplikasi/" + metode, uuid, addProfile, res => {
      if (res.status === resstat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Profile",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Profile",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.setState({ input: "none", view: "inherit" });
      this.fetch();
    });
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = e => {
    e.preventDefault();
    this.setState({ view: "none", input: "inherit" });
  };
  // ========================================================================

  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

 

  render() {
    return (
      <div>
        <Breadcrumb title="Pengaturan Aplikasi Desa" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    {/* <div className="col-sm-1"> */}
                    {/* <h5></h5> */}
                    {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    {/* </div> */}
                    <div className="col-sm-12 text-right">
                      <a href="" onClick={this.ubahData}>
                        <i
                          className="fa fa-edit text-success"
                          style={{ fontSize: "24px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  className="card-body"
                  style={{ display: this.state.input }}
                >
                    <div className="col-lg-12">
                      <FormGroup className="row">
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Reponse Pengajuan Layanan
                        </Label>
                        <input
                          className="form-control"
                          id="response_pengajuan_layanan"
                          type="text"
                          placeholder="response"
                          defaultValue={this.state.response_pengajuan_layanan}
                        />
                      </FormGroup>
                      <FormGroup className="row ">
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Riwayat disposisi perangkat
                        </Label>

                        <div className="media-body text-right switch-sm">
                          {this.state.sidebar == false
                            ? "Tidak aktif"
                            : "Aktif"}

                          <label className="switch">
                            <input
                              type="checkbox"
                              id="sidebar-toggle"
                              defaultChecked={this.state.sidebar}
                              onClick={this.openCloseSidebar}
                            />
                            <span className="switch-state"></span>
                          </label>
                        </div>
                      </FormGroup>
                      <div className="col-lg-12 text-right">
                      <button
                        type="button"
                        className="btn btn-warning mr-3"
                        onClick={this.tutupForm}
                      >
                        Batal
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success ml-3"
                        onClick={this.simpan}
                      >
                        Simpan
                      </button>
                    </div>
                    </div>
                    
                </div>
                <div
                  className="card-body"
                  id="viewDesa"
                  style={{ display: this.state.view, fontSize: "20px" }}
                >
                  {this.state.data != null ? (
                    <>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-5">
                            Response pengajuan layanan
                          </div>
                          <div className="col-lg-2">:</div>
                          <div className="col-lg-5">
                            {this.state.response_pengajuan_layanan}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5">
                            Status Riwayat Disposisi
                          </div>
                          <div className="col-lg-2">:</div>
                          <div className="col-lg-5">
                            {this.state.sidebar == false
                              ? "Tidak Aktif"
                              : "Aktif"}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-12 ">
                        <div className="row">
                          <h6>Pengaturan aplikasi Belum ada</h6>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasicTable;
