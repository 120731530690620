import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../function/Format";
import { logo } from "./asset/base64img";
// Import the library

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  nomor: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",

  nik_pasangan: "",
  nama_pasangan: "",
  tempat_lahir_pasangan: "",
  tanggal_lahir_pasangan: "",
  jk_pasangan: "",
  pekerjaan_pasangan: "",
  agama_pasangan: "",
  alamat_pasangan: "",
  status_perkawinan_pasangan: "",

  tanggal_menikah: new Date(),
  tempat_menikah: "",
  wali_nikah: "",
  petugas_nikah: "",
  mas_kawin: "",
  nik_pelapor: "",
  nama_pelapor: "",

  tempat_lahir_pelapor: "",
  tanggal_lahir_pelapor: "",
  jk_pelapor: "",
  pekerjaan_pelapor: "",
  agama_pelapor: "",
  alamat_pelapor: "",
  status_perkawinan_pelapor: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const cetak = {
  nama_desa: "",
  nomor_surat: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  mas_kawin: 0,
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Pernah Menikah",
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: [],
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      tanggal_menikah: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: [],
      formPasangan: "none"
    };
  }

  // ================== Terbilang ===================

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan",
      "Sepuluh",
      "Sebelas"
    ];

    // pencocokan dimulai dari satuan angka terkecil
    if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka];
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " Belas";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " Puluh " + bilangan[hasil_mod];
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod);
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return this.terbilang(hasil_bagi) + " Ribu " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return this.terbilang(hasil_bagi) + " Juta " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) + " Milyar " + this.terbilang(hasil_mod)
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) + " Triliun " + this.terbilang(hasil_mod)
      );
    } else {
      return "Wow...";
    }
  };
  // ===============================================

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_menikah":
        console.log(date.format("YYYY-MM-DD"));
        this.setState({ tanggal_menikah: date.format("YYYY-MM-DD") });
        break;
      // case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("surat-keterangan/pernah-menikah" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let addSuratKeteranganPernahMenikah = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/pernah-menikah",
        null,
        addSuratKeteranganPernahMenikah,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================
  // ================== change input text ==================
  changeInputText = (e, fld) => {
    switch (fld) {
      case "nik_pasangan":
        console.log(e.target.value);
        this.state.dt.nik_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "alamat_pasangan":
        console.log(e.target);
        this.state.dt.alamat_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "nama_pasangan":
        console.log(e.target.value);
        this.state.dt.nama_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir_pasangan":
        console.log(e.target.value);
        this.state.dt.tempat_lahir_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan_pasangan":
        console.log(e.target.value);

        this.state.dt.pekerjaan_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "agama_pasangan":
        console.log(e.target.value);
        this.state.dt.agama_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "status_perkawinan_pasangan":
        console.log(e.target.value);
        this.state.dt.status_perkawinan_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "tanggal_lahir_pasangan":
        console.log(e.target.value);
        this.state.dt.tanggal_lahir_pasangan = e.target.value;
        this.forceUpdate();
        break;
    }
  };
  // data ayah
  dataPasangan = () => {
    console.log(this.props.location.state);
    if (this.state.nik3Stat != null) {
      this.state.showBio2 = true;
    } else {
      this.state.showBio2 = this.state.showBio2 == false ? true : false;
    }

    this.state.status.dataPenduduk3 =
      this.state.status.dataPenduduk3 == "" ? "none" : "";
    this.state.nik3Stat = null;
    this.state.formPasangan = this.state.formPasangan == "" ? "none" : "";
    this.state.dt.nama_nasab_p2 = "";
    this.state.dt.nama_pasangan = "";
    this.state.dt.jenis_kelamin_pasangan = "";
    this.state.dt.tempat_lahir_pasangan = "";
    this.state.dt.tanggal_lahir_pasangan = "";
    this.state.dt.pekerjaan_pasangan = "";
    this.state.dt.agama_pasangan = "";
    this.state.dt.nik_pasangan = "";
    this.state.dt.alamat_pasangan = "";
    this.forceUpdate();
  };
  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    if (
      this.state.nikStat &&
      this.state.nik2Stat &&
      this.state.dt.nik_pasangan != ""
    ) {
      this.state.status.btnForm = true;
      this.forceUpdate();
      let tgl_lhr = this.state.dt.tanggal_lahir.split("/");
      let tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
      let tgl_lhr_ps = this.state.dt.tanggal_lahir_pasangan.split("/");
      let tanggal_lahir_pasangan;
      if (this.state.formPasangan == "none") {
        tanggal_lahir_pasangan = this.state.dt.tanggal_lahir_pasangan;
      } else {
        tanggal_lahir_pasangan = this.state.dt.tanggal_lahir_pasangan;
      }

      let tgl_lhr_pl = this.state.dt.tanggal_lahir_pelapor.split("/");
      let tanggal_lahir_pelapor =
        tgl_lhr_pl[2] + "-" + tgl_lhr_pl[1] + "-" + tgl_lhr_pl[0];
      let addSuratKeteranganPernahMenikah = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        // nomor : document.getElementById("nomor").value,
        nik: this.state.nikStat.value,
        nama_pemohon: this.state.dt.nama_pemohon,
        tempat_lahir: this.state.dt.tempat_lahir,
        tanggal_lahir: tanggal_lahir,
        jenis_kelamin: this.state.dt.jk,
        status_perkawinan: this.state.dt.status_perkawinan,
        pekerjaan: this.state.dt.pekerjaan,
        agama: this.state.dt.agama,
        alamat: this.state.dt.alamat,

        nik_pasangan: this.state.dt.nik_pasangan,
        nama_pasangan: this.state.dt.nama_pasangan,
        tempat_lahir_pasangan: this.state.dt.tempat_lahir_pasangan,
        tanggal_lahir_pasangan: tanggal_lahir_pasangan,
        jenis_kelamin_pasangan: this.state.dt.jk_pasangan,
        status_perkawinan_pasangan: this.state.dt.status_perkawinan_pasangan,
        pekerjaan_pasangan: this.state.dt.pekerjaan_pasangan,
        agama_pasangan: this.state.dt.agama_pasangan,
        alamat_pasangan: this.state.dt.alamat_pasangan,

        tanggal_menikah: this.state.tanggal_menikah,
        tempat_menikah: document.getElementById("tempat_menikah").value,
        petugas_nikah: document.getElementById("petugas_nikah").value,
        wali_nikah: document.getElementById("wali_nikah").value,
        mas_kawin: document.getElementById("mas_kawin").value,
        saksi_nikah: document.getElementById("saksi_nikah").value,

        nik_pelapor: this.state.nik2Stat.value,
        nama_pelapor: this.state.dt.nama_pelapor,
        tempat_lahir_pelapor: this.state.dt.tempat_lahir_pelapor,
        tanggal_lahir_pelapor: tanggal_lahir_pelapor,
        jenis_kelamin_pelapor: this.state.dt.jk_pelapor,
        status_perkawinan_pelapor: this.state.dt.status_perkawinan_pelapor,
        pekerjaan_pelapor: this.state.dt.pekerjaan_pelapor,
        agama_pelapor: this.state.dt.agama_pelapor,
        alamat_pelapor: this.state.dt.alamat_pelapor,
        // hubungan_pelapor : document.getElementById("hubungan_pelapor").value,
        status_ttd: null,
        jabatan_ttd: "Kepala Desa Sungai Petai",
        nama_ttd: "Rian Adli, S.I.Kom",
        nip_ttd: "11474242142"
      };
      console.log(addSuratKeteranganPernahMenikah);
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeteranganPernahMenikah.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeteranganPernahMenikah.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/pernah-menikah/" + metode,
        addSuratKeteranganPernahMenikah.uuid,
        addSuratKeteranganPernahMenikah,
        res => {
          this.tutupForm();
          this.state.status.btnForm = false;
          this.state.status.form = false;
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Pernah Menikah",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Pernah Menikah",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon pilih data NIK Pembuat, Pasangan dan Pelapor"
      });
      document.getElementsByName("NIK3")[0].focus();
    }
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      console.log(dtkat);
      if (dtkat) {
        console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    console.log(id);
  };
  // ============================== Ubah Data ===============================
  tambahData = e => {
    this.state.status.dataPenduduk = "none";
    this.state.status.dataPenduduk2 = "none";
    this.state.status.dataPenduduk3 = "none";

    this.forceUpdate();
    this.setState({
      judul: "Tambah Surat Pernah Menikah",
      dt: frmDef,
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
      nik3Stat: [],
      // showBio2: true,
      formPasangan: "none"
    });
    this.bukaForm();
  };

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.state.judul = "Ubah Surat Pernah Menikah";
    this.forceUpdate();
    this.componentDidMount();
    Get("surat-keterangan/pernah-menikah/find", id, data => {
      Get("penduduk/find", data.results.nik_pasangan, data1 => {
        if (data1.results == null) {
          this.state.formPasangan = "";
          this.state.showBio2 = false;
          this.state.nik3Stat = null;
          this.state.dt.tanggal_lahir_pasangan =
            data.results.tanggal_lahir_pasangan;
        } else {
          this.state.nik3Stat = {
            value: data.results.nik_pasangan,
            label: data.results.nik_pasangan
          };
          this.state.formPasangan = "none";
          this.state.showBio2 = true;

          this.state.dt.tanggal_lahir_pasangan =
            data.results.tanggal_lahir_pasangan;
        }
        this.state.dt.agama = data.results.agama;
        this.state.dt.alamat = data.results.alamat;
        this.state.dt.id_jenis = data.results.id_jenis;
        this.state.dt.jk = data.results.jenis_kelamin;
        this.state.dt.nama_pemohon = data.results.nama_pemohon;
        this.state.dt.nik = data.results.nik;
        this.state.dt.nomor = data.results.nomor;
        this.state.dt.pekerjaan = data.results.pekerjaan;
        this.state.dt.status_perkawinan = data.results.status_perkawinan;
        // this.state.dt.status_perkawinan = data.results.status_perkawinan;
        this.state.dt.tanggal_lahir = new Date(
          data.results.tanggal_lahir
        ).toLocaleDateString("id-ID");
        this.state.dt.tanggal_surat = data.results.tanggal_surat;
        this.state.dt.tempat_lahir = data.results.tempat_lahir;
        this.state.dt.uuid = data.results.uuid;
        this.state.tanggal_surat = new Date(data.results.tanggal_surat);

        this.state.dt.nik_pasangan = data.results.nik_pasangan;
        this.state.dt.nama_pasangan = data.results.nama_pasangan;

        this.state.dt.tempat_lahir_pasangan =
          data.results.tempat_lahir_pasangan;
        this.state.dt.jk_pasangan = data.results.jenis_kelamin_pasangan;
        this.state.dt.status_perkawinan_pasangan =
          data.results.status_perkawinan_pasangan;
        this.state.dt.agama_pasangan = data.results.agama_pasangan;
        this.state.dt.alamat_pasangan = data.results.alamat_pasangan;
        this.state.dt.pekerjaan_pasangan = data.results.pekerjaan_pasangan;
        this.state.dt.status_perkawinan_pasangan =
          data.results.status_perkawinan_pasangan;

        this.state.dt.tanggal_menikah = data.results.tanggal_menikah;
        this.state.tanggal_menikah = new Date(data.results.tanggal_menikah);
        this.state.dt.tempat_menikah = data.results.tempat_menikah;
        this.state.dt.wali_nikah = data.results.wali_nikah;
        this.state.dt.petugas_nikah = data.results.petugas_nikah;
        this.state.dt.mas_kawin = data.results.mas_kawin;
        this.state.dt.saksi_nikah = data.results.saksi_nikah;

        this.state.dt.nik_pelapor = data.results.nik_pelapor;
        this.state.dt.nama_pelapor = data.results.nama_pelapor;
        this.state.dt.tanggal_lahir_pelapor = new Date(
          data.results.tanggal_lahir_pelapor
        ).toLocaleDateString("id-ID");
        this.state.dt.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor;
        this.state.dt.jk_pelapor = data.results.jenis_kelamin_pelapor;
        this.state.dt.status_perkawinan_pelapor =
          data.results.status_perkawinan_pelapor;
        this.state.dt.agama_pelapor = data.results.agama_pelapor;
        this.state.dt.alamat_pelapor = data.results.alamat_pelapor;
        this.state.dt.pekerjaan_pelapor = data.results.pekerjaan_pelapor;
        this.state.dt.status_perkawinan_pelapor =
          data.results.status_perkawinan_pelapor;
        // this.state.dt.hubungan_pelapor = data.results.hubungan_pelapor;

        this.state.dt.status_ttd = data.results.status_ttd;
        this.state.dt.jabatan_ttd = data.results.jabatan_ttd;
        this.state.dt.jabatan_pengganti_ttd =
          data.results.jabatan_pengganti_ttd;
        this.state.dt.nama_ttd = data.results.nama_ttd;
        this.state.dt.nip_ttd = data.results.nip_ttd;

        this.state.dt.status_ttd2 = data.results.status_ttd2;
        this.state.dt.jabatan_ttd2 = data.results.jabatan_ttd2;
        this.state.dt.jabatan_pengganti_ttd2 =
          data.results.jabatan_pengganti_ttd2;
        this.state.dt.nama_ttd2 = data.results.nama_ttd2;
        this.state.dt.nip_ttd2 = data.results.nip_ttd2;

        this.state.nikStat = {
          value: data.results.nik,
          label: data.results.nik
        };
        this.state.status.dataPenduduk = "block";
        this.state.nik2Stat = {
          value: data.results.nik_pelapor,
          label: data.results.nik_pelapor
        };
        this.state.status.dataPenduduk2 = "block";

        this.state.status.dataPenduduk3 = "block";
        // this.forceUpdate();
        this.bukaForm();
      });
    });
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/pernah-menikah/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Pernah Menikah",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Pernah Menikah",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.nikStat = null;
    this.state.dt.nama_pemohon = "";
    this.state.dt.tanggal_lahir = "";
    this.state.dt.tempat_lahir = "";
    this.state.dt.agama = "";
    this.state.dt.pekerjaan = "";
    this.state.dt.alamat = "";
    this.state.dt.jk = "";
    this.state.dt.status_perkawinan = "";
    this.state.status.dataPenduduk = "none";
    this.state.nik3Stat = null;
    this.state.dt.nama_pasangan = "";
    this.state.dt.tanggal_lahir_pasangan = "";
    this.state.dt.tempat_lahir_pasangan = "";
    this.state.dt.agama_pasangan = "";
    this.state.dt.pekerjaan_pasangan = "";
    this.state.dt.alamat_pasangan = "";
    this.state.dt.jk_pasangan = "";
    this.state.dt.status_perkawinan_pasangan = "";
    this.state.status.dataPenduduk3 = "none";
    this.state.nik2Stat = null;
    this.state.dt.nama_pelapor = "";
    this.state.dt.tanggal_lahir_pelapor = "";
    this.state.dt.tempat_lahir_pelapor = "";
    this.state.dt.agama_pelapor = "";
    this.state.dt.pekerjaan_pelapor = "";
    this.state.dt.alamat_pelapor = "";
    this.state.dt.jk_pelapor = "";
    this.state.dt.status_perkawinan_pelapor = "";
    this.state.status.dataPenduduk2 = "none";

    this.state.dt.nik_pasangan = "";
    this.state.tanggal_menikah = new Date();
    this.state.formPasangan = "none";
    this.state.status.dataPenduduk3 = "none";

    const frmDef = {
      uuid: "",
      id_jenis: "",
      nomor: "",
      tanggal_surat: new Date(),
      nik: "",
      nama_pemohon: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jk: "",
      pekerjaan: "",
      agama: "",
      alamat: "",
      status_perkawinan: "",

      nik_pasangan: "",
      nama_pasangan: "",
      tempat_lahir_pasangan: "",
      tanggal_lahir_pasangan: "",
      jk_pasangan: "",
      pekerjaan_pasangan: "",
      agama_pasangan: "",
      alamat_pasangan: "",
      status_perkawinan_pasangan: "",

      tanggal_menikah: new Date(),
      tempat_menikah: "",
      wali_nikah: "",
      petugas_nikah: "",
      mas_kawin: "",
      nik_pelapor: "",
      nama_pelapor: "",

      tempat_lahir_pelapor: "",
      tanggal_lahir_pelapor: "",
      jk_pelapor: "",
      pekerjaan_pelapor: "",
      agama_pelapor: "",
      alamat_pelapor: "",
      status_perkawinan_pelapor: "",

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: ""
    };
    this.state.accStat = [];
    this.state.tableAcc = "none";
    this.state.formDisposisi = false;
    this.state.formAcc = false;
    this.state.status.form = false;
    this.state.status.btnForm = true;
    this.state.dt = frmDef;
    this.state.nikStat = null;
    this.state.nik2Stat = null;
    this.state.nik3Stat = null;

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/pernah-menikah/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    let btnApproval = false;
    let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.map(dt => {
      status = dt.status;
    });
    if (status == "Diajukan") {
      btnCetak = true;
      btnAksi = true;
      btnApproval = true;
    } else {
      btnAksi = true;
      btnApproval = true;
      if (status == "Ditolak") {
        btnCetak = true;
      } else if (status == "Disetujui") {
        btnAksi = false;
        btnCetak = true;
        btnApproval = false;
      } else if ((status == "Menunggu Penerbitan") | (status == "Selesai")) {
        // btnAksi = false;
        btnCetak = false;
        // btnApproval = false;
      } else {
        btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="primary"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>

        &nbsp;&nbsp; */}
        {/* //list */}
        {/*         
        <Button
          size="xs"
          color="primary"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get("pekerjaan/list", null, data => {
        let frmPkr = [];
        data.results.map(dt => {
          frmPkr.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmPkr });
      });
      Get("agama/list", null, data => {
        let frmAgm = [];
        data.results.map(dt => {
          frmAgm.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmAgm });
      });
      Get("penduduk", null, data => {
        let frmNik = [];
        let frmNik2 = [];
        let frmNik3 = [];
        data.results.data.map(dt => {
          frmNik.push({ value: dt.id, label: dt.id });
          frmNik2.push({ value: dt.id, label: dt.id });
          frmNik3.push({ value: dt.id, label: dt.id });
        });
        this.setState({ frmNik, frmNik2, frmNik3 });
      });
      Get("tanda-tangan/list", null, data => {
        data.results.map((dt, i) => {
          if (dt.aktif == 1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.state.accButton = temp;
            this.state.accuuid = dt.uuid;
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld == "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.map(dt => {
            frmNik.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        if (fld == "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.map(dt => {
              frmNik2.push({ value: dt.id, label: dt.id });
            });
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik3 = [];
            datanik.data.results.data.map(dt => {
              frmNik3.push({ value: dt.id, label: dt.id });
            });
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };

  getPenduduk = (e, sel, jns) => {
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        const data_rt = data.results.data_kk.data_rt;
        const data_rw = data_rt.data_rw;
        const data_dusun = data_rw.data_dusun;
        const data_desa = data_dusun.data_keldes;
        const data_kecamatan = data_desa.data_kecamatan;
        const data_kabkota = data_kecamatan.data_kabkota;
        let alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", Dusun " +
          data.results.data_kk.data_rt.data_rw.data_dusun.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.data_kabkota.nama;

        if (jns == "pelapor") {
          this.state.tanggal_lahir_pelapor = data.results.tanggal_lahir;
          this.state.dt.nama_pelapor = data.results.nama;
          this.state.dt.tanggal_lahir_pelapor = new Date(
            data.results.tanggal_lahir
          ).toLocaleDateString("id-ID");
          this.state.dt.tempat_lahir_pelapor = data.results.tempat_lahir;
          this.state.dt.jk_pelapor = data.results.jk;
          this.state.dt.status_perkawinan_pelapor =
            data.results.status_perkawinan;
          this.state.dt.agama_pelapor = data.results.data_agama.nama;
          this.state.dt.pekerjaan_pelapor = data.results.data_pekerjaan.nama;
          this.state.dt.alamat_pelapor = alamat;
          this.state.status.dataPenduduk2 = "block";
        } else {
          if (jns == "pasangan") {
            this.state.tanggal_lahir_pasangan = data.results.tanggal_lahir;
            this.state.dt.nama_pasangan = data.results.nama;
            this.state.dt.nik_pasangan = data.results.id;
            this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
            this.state.dt.tempat_lahir_pasangan = data.results.tempat_lahir;
            this.state.dt.jk_pasangan = data.results.jk;
            this.state.dt.status_perkawinan_pasangan =
              data.results.status_perkawinan;
            this.state.dt.agama_pasangan = data.results.data_agama.nama;
            this.state.dt.pekerjaan_pasangan = data.results.data_pekerjaan.nama;
            this.state.dt.alamat_pasangan = alamat;
            this.state.status.dataPenduduk3 = "block";
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              let pctt = 0;
              let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.map(dtp => {
                  if (dtp.status == 0 && dtp.penalti == 1) {
                    pctt = 1;
                    catatan = dtp.catatan;
                  }
                });
              }
              if (pctt == 0) {
                this.state.tanggal_lahir = data.results.tanggal_lahir;
                this.state.dt.nama_pemohon = data.results.nama;
                this.state.dt.tanggal_lahir = new Date(
                  data.results.tanggal_lahir
                ).toLocaleDateString("id-ID");
                this.state.dt.tempat_lahir = data.results.tempat_lahir;
                this.state.dt.jk = data.results.jk;
                this.state.dt.status_perkawinan =
                  data.results.status_perkawinan;
                this.state.dt.agama = data.results.data_agama.nama;
                this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
                this.state.dt.alamat = alamat;
                this.state.status.dataPenduduk = "block";
                this.state.status.btnForm = false;
                this.forceUpdate();
              } else {
                this.state.nikStat = null;
                this.state.frmNik = [];
                this.state.dt.nama_pemohon = "";
                this.state.dt.tanggal_lahir = "";
                this.state.dt.tempat_lahir = "";
                this.state.dt.agama = "";
                this.state.dt.pekerjaan = "";
                this.state.dt.alamat = "";
                this.state.dt.jk = "";
                this.state.dt.status_perkawinan = "";
                this.state.status.dataPenduduk = "none";
                this.forceUpdate();
                this.setState({
                  show: true,
                  basicType: "danger",
                  basicTitle: "Peringatan Catatan Warga",
                  pesanAlert:
                    "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                    catatan
                });
              }
            });
          }
        }

        if (
          this.state.nikStat != null &&
          this.state.nik2Stat != null &&
          this.state.nik3Stat != null
        ) {
          this.state.status.btnForm = false;
        }
        this.forceUpdate();
      });
    } else {
      if (jns == "pelapor") {
        this.state.nik2Stat = null;
        this.state.dt.nama_pelapor = "";
        this.state.dt.tanggal_lahir_pelapor = "";
        this.state.dt.tempat_lahir_pelapor = "";
        this.state.dt.agama_pelapor = "";
        this.state.dt.pekerjaan_pelapor = "";
        this.state.dt.alamat_pelapor = "";
        this.state.dt.jk_pelapor = "";
        this.state.dt.status_perkawinan_pelapor = "";
        this.state.status.dataPenduduk2 = "none";
      } else {
        if (jns == "pasangan") {
          this.state.nik3Stat = null;
          this.state.dt.nik_pasangan = "";
          this.state.dt.nama_pasangan = "";
          this.state.dt.tanggal_lahir_pasangan = "";
          this.state.dt.tempat_lahir_pasangan = "";
          this.state.dt.agama_pasangan = "";
          this.state.dt.pekerjaan_pasangan = "";
          this.state.dt.alamat_pasangan = "";
          this.state.dt.jk_pasangan = "";
          this.state.dt.status_perkawinan_pasangan = "";
          this.state.status.dataPenduduk3 = "none";
        } else {
          this.state.nikStat = null;
          this.state.dt.nama_pemohon = "";
          this.state.dt.tanggal_lahir = "";
          this.state.dt.tempat_lahir = "";
          this.state.dt.agama = "";
          this.state.dt.pekerjaan = "";
          this.state.dt.alamat = "";
          this.state.dt.jk = "";
          this.state.dt.status_perkawinan = "";
          this.state.status.dataPenduduk = "none";
        }
      }
      this.state.status.btnForm = true;

      this.forceUpdate();
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.state.dt.jam = e.value;
          this.setState({ jamStat: e });
          break;
        case "pekerjaan":
          this.state.dt.pekerjaan = e.label;
          this.setState({ pkrStat: e });
          break;
        case "agama":
          this.state.dt.agama = e.label;
          this.setState({ agmStat: e });
          break;
        case "nik":
          this.state.dt.nik = e.value;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik_pelapor = e.value;
          this.setState({ nik2Stat: e });
          break;
        case "nik3":
          this.state.dt.nik_pasangan = e.value;
          this.setState({ nik3Stat: e });
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.state.dt.jam = 0;
          this.setState({ jamStat: e });
          break;
        case "pekerjaan":
          this.state.dt.pekerjaan = "";
          this.setState({ pkrStat: e });
          break;
        case "agama":
          this.state.dt.agama = "";
          this.setState({ agmStat: e });
          break;
        case "nik":
          this.state.dt.nik = 0;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik_pelapor = 0;
          this.setState({ nik2Stat: e });
          break;
        case "nik3":
          this.state.dt.nik_pasangan = 0;
          this.setState({ nik3Stat: e });
          break;
      }
    }

    this.forceUpdate();
  };
  cetakSurat = (e, data) => {
    e.preventDefault();
    this.setState({
      cetak: {
        nama_desa: "Desa Sungai Petai",
        tanggal_surat: data.tanggal_surat,
        nomor_surat: data.nomor,
        nik: data.nik,
        nama_pemohon: data.nama_pemohon.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        // jk : data.jenis_kelamin.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        agama: data.agama.toLowerCase(),
        pekerjaan: data.pekerjaan.toLowerCase(),
        status_perkawinan: data.status_perkawinan.toLowerCase(),

        nik_pasangan: data.nik_pasangan,
        nama_pasangan: data.nama_pasangan.toLowerCase(),
        tanggal_lahir_pasangan: Tanggal(data.tanggal_lahir_pasangan),
        tempat_lahir_pasangan: data.tempat_lahir_pasangan.toLowerCase(),
        // jk_pasangan : data.jenis_kelamin_pasangan.toLowerCase(),
        alamat_pasangan: data.alamat_pasangan.toLowerCase(),
        agama_pasangan: data.agama_pasangan.toLowerCase(),
        pekerjaan_pasangan: data.pekerjaan_pasangan.toLowerCase(),
        status_perkawinan_pasangan: data.status_perkawinan_pasangan.toLowerCase(),

        tanggal_menikah: data.tanggal_menikah,
        tempat_menikah: data.tempat_menikah.toLowerCase(),
        petugas_nikah: data.petugas_nikah.toLowerCase(),
        wali_nikah: data.wali_nikah.toLowerCase(),
        mas_kawin: data.mas_kawin.toLowerCase(),
        saksi_nikah: data.saksi_nikah.toLowerCase(),

        nik_pelapor: data.nik_pelapor,
        nama_pelapor: data.nama_pelapor.toLowerCase(),
        tanggal_lahir_pelapor: HariTanggal(data.tanggal_lahir_pelapor),
        tempat_lahir_pelapor: data.tempat_lahir_pelapor.toLowerCase(),
        // jk_pelapor : data.jenis_kelamin_pelapor.toLowerCase(),
        alamat_pelapor: data.alamat_pelapor.toLowerCase(),
        agama_pelapor: data.agama_pelapor.toLowerCase(),
        pekerjaan_pelapor: data.pekerjaan_pelapor.toLowerCase(),
        status_perkawinan_pelapor: data.status_perkawinan_pelapor.toLowerCase(),

        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : ""
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      if (e.value == "Disetujui") {
        this.state.tableAcc = "";
        this.state.btnFormAcc = true;
        if (this.state.accButton.length > 0) {
          this.state.btnFormAcc = false;
        }
      } else {
        this.state.tableAcc = "none";
        this.state.btnFormAcc = false;
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      this.state.tableAcc = "none";
      this.state.btnFormAcc = true;
    }
    this.state.status.select = false;
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.state.btnFormAcc = true;

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/pernah-menikah/persetujuan/Selesai/" +
        this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };

  render() {
    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      id_jenis: "",
      nomor: "",
      tanggal_surat: new Date(),
      nik: "",
      nama_pemohon: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jk: "",
      pekerjaan: "",
      agama: "",
      alamat: "",
      status_perkawinan: "",

      nik_pasangan: "",
      nama_pasangan: "",
      tempat_lahir_pasangan: "",
      tanggal_lahir_pasangan: "",
      jk_pasangan: "",
      pekerjaan_pasangan: "",
      agama_pasangan: "",
      alamat_pasangan: "",
      status_perkawinan_pasangan: "",

      tanggal_menikah: new Date(),
      tempat_menikah: "",
      wali_nikah: "",
      mas_kawin: "",
      saksi_nikah: "",

      nik_pelapor: "",
      nama_pelapor: "",
      tempat_lahir_pelapor: "",
      tanggal_lahir_pelapor: "",
      jk_pelapor: "",
      pekerjaan_pelapor: "",
      agama_pelapor: "",
      alamat_pelapor: "",
      status_perkawinan: "",

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",
      status_ttd2: "",
      jabatan_ttd2: "",
      jabatan_pengganti_ttd2: "",
      nama_ttd2: "",
      nip_ttd2: ""
    };

    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "tanggal_surat",
        text: "Tanggal",
        formatter: (cell, obj) => {
          console.log(obj.tanggal_surat);
          return obj.tanggal_surat == null ||
            obj.tanggal_surat == "" ||
            obj.tanggal_surat == "0000-00-00"
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor == "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nik",
        text: "NIK",
        sort: true
      },
      {
        dataField: "nama_pemohon",
        text: "Nama",
        sort: true
      },
      {
        dataField: "tanggal_menikah",
        text: "Tanggal Menikah",
        align: "right",
        isDummyField: true,
        formatter: (cell, obj) => {
          return HariTanggal(obj.tanggal_menikah);
        },
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.map(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    const defaultSorted = [
      {
        dataField: "nama",
        order: "asc"
      }
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = e => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = "";
      classname = "btn btn-success";
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {total} Results
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] == 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] == 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div>
        {this.renderRedirect()}
        <Breadcrumb
          title="Data Surat Keterangan Pernah Menikah"
          parent="Admin"
        />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Pernah Menikah</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={this.tambahData}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Data identitas akan terisi secara otomatis saat NIK dipilih.
                Jika ada ketidak sesuaian data, silakan ubah di data master
                penduduk.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK
                </Label>
                <Col sm="9">
                  <Select
                    classNamePrefix="select"
                    onChange={e => {
                      this.getPenduduk(e, "nik", "pemohon");
                    }}
                    defaultValue={this.state.nikStat}
                    value={this.state.nikStat}
                    name="NIK"
                    options={this.state.frmNik}
                    placeholder="Pilh NIK"
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    isClearable
                  />
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama_pemohon}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan"
                      type="text"
                      placeholder="Status Perkawinan"
                      value={this.state.dt.status_perkawinan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK Pasangan
                </Label>
                <Col sm="9">
                  {this.state.formPasangan == "none" ? (
                    <Select
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik3", "pasangan");
                      }}
                      defaultValue={this.state.nik3Stat}
                      value={this.state.nik3Stat}
                      name="NIK3"
                      options={this.state.frmNik3}
                      placeholder="Pilh NIK Pasangan"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik3")}
                      isClearable
                    />
                  ) : (
                    <></>
                  )}
                  <input
                    className="form-control"
                    id="nik_pasangan"
                    type={
                      this.state.formPasangan == "none" ? "hidden" : "number"
                    }
                    required
                    placeholder="NIK Pasangan"
                    defaultValue={this.state.dt.nik_pasangan}
                    value={this.state.dt.nik_pasangan}
                    onChange={e => this.changeInputText(e, "nik_pasangan")}
                    readOnly={this.state.formPasangan == "" ? false : true}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    Data NIK {this.state.formPasangan == "none" ? "tidak" : ""}{" "}
                    tersedia?{" "}
                    <Link
                      to={{
                        pathname: this.props.myroute,
                        state: this.props.location.state
                      }}
                      onClick={this.dataPasangan}
                    >
                      {" "}
                      Klik disini
                    </Link>
                  </small>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk3 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pasangan"
                      type="text"
                      placeholder="Nama Pemohon"
                      defaultValue={this.state.dt.nama_pasangan}
                      value={this.state.dt.nama_pasangan}
                      onChange={e => this.changeInputText(e, "nama_pasangan")}
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir_pasangan"
                      type=""
                      placeholder="Tempat Lahir"
                      defaultValue={this.state.dt.tempat_lahir_pasangan}
                      value={this.state.dt.tempat_lahir_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "tempat_lahir_pasangan")
                      }
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir_pasangan"
                      type="date"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir_pasangan}
                      defaultValue={this.state.dt.tanggal_lahir_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "tanggal_lahir_pasangan")
                      }
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: this.state.formPasangan }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan_pasangan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan_pasangan}
                      defaultValue={this.state.dt.pekerjaan_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "pekerjaan_pasangan")
                      }
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama_pasangan"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama_pasangan}
                      defaultValue={this.state.dt.agama_pasangan}
                      onChange={e => this.changeInputText(e, "agama_pasangan")}
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat_pasangan"
                      type="text"
                      placeholder="Alamat"
                      defaultValue={this.state.dt.alamat_pasangan}
                      value={this.state.dt.alamat_pasangan}
                      onChange={e => this.changeInputText(e, "alamat_pasangan")}
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                    {/* <textarea className="form-control" id="alamat" placeholder="Alamat" value={this.state.dt.alamat}></textarea> */}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan_pasangan"
                      type="text"
                      placeholder="Status Perkawinan Pasangan"
                      defaultValue={this.state.dt.status_perkawinan_pasangan}
                      value={this.state.dt.status_perkawinan_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "status_perkawinan_pasangan")
                      }
                      readOnly={this.state.formPasangan == "" ? false : true}
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Menikah
                </Label>
                <Col sm="9">
                  <Datetime
                    locale="id-ID"
                    input={true}
                    closeOnTab={true}
                    timeFormat={false}
                    initialValue={this.state.tanggal_menikah}
                    onChange={e => {
                      this.gantiTgl(e, "tanggal_menikah");
                      // this.setState({ tanggal_lahirkk:})
                      // console.log(e.format('YYYY-MM-DD'))
                    }}
                  />
                  {/* <Datepicker
                    className="form-control"
                    selected={this.state.tanggal_menikah}
                    onChange={e => this.gantiTgl(e, "tanggal_menikah")}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="tanggal"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Menikah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_menikah"
                    type="text"
                    placeholder="Tempat Menikah"
                    defaultValue={this.state.dt.tempat_menikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Petugas Nikah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="petugas_nikah"
                    type="text"
                    placeholder="Petugas Menikah"
                    defaultValue={this.state.dt.petugas_nikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Wali Nikah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="wali_nikah"
                    type="text"
                    placeholder="Wali Menikah"
                    defaultValue={this.state.dt.wali_nikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Mas Kawin
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="mas_kawin"
                    type="text"
                    placeholder="Mas Kawin"
                    defaultValue={this.state.dt.mas_kawin}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Saksi Nikah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="saksi_nikah"
                    type="text"
                    placeholder="Saksi Nikah"
                    defaultValue={this.state.dt.saksi_nikah}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK Pelapor
                </Label>
                <Col sm="9">
                  <Select
                    classNamePrefix="select"
                    onChange={e => {
                      this.getPenduduk(e, "nik2", "pelapor");
                    }}
                    defaultValue={this.state.nik2Stat}
                    value={this.state.nik2Stat}
                    name="NIK2"
                    options={this.state.frmNik2}
                    placeholder="Pilh NIK Pelapor"
                    onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                    isClearable
                  />
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk2 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pelapor"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir_pelapor"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir_pelapor"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan_pelapor"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama_pelapor"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat_pelapor"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan Pelapor
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan_pelapor"
                      type="text"
                      placeholder="Status Perkawinan Pelapor"
                      value={this.state.dt.status_perkawinan_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-success">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt,i)=>{
                 return(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{dt.data_jabatan.nama}</td>
                    <td>{dt.level}</td>
                    <td>

                    <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      this.disposisi(dt.uuid,this.state.idSurat)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Disposisi
                  </button>
                    </td>
                  </tr>
                   )})}
                </tbody>
              </table>
            </div>
             */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status == 0
                            ? "Menunggu Disposisi"
                            : dt.status == 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah kabupaten kampar
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan kampar kiri hilir
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala desa sungai petai
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    jl. raya sungai pagar km. 35 kode pos 28471
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat keterangan pernah menikah
                </span>
                <br />
                {this.state.cetak.nomor_surat}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Yang bertanda tangan dibawah ini Kepala Desa Sungai Petai,
                  Kecamatan Kampar Kiri Hilir, Kabupaten Kampar, Propinsi Riau,
                  dengan ini menerangkan bahwa :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.nama_pemohon}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tangal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_lahir}
                      </span>
                      , {Tanggal(new Date(this.state.cetak.tanggal_lahir))}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.pekerjaan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.agama}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.status_perkawinan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.alamat}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      width="35%"
                      colspan="2"
                      style={{ paddingLeft: "50px" }}
                    >
                      Telah melaksanakan akad nikah dengan
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.nama_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_lahir_pasangan},{" "}
                        {Tanggal(
                          new Date(this.state.cetak.tanggal_lahir_pasangan)
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.pekerjaan_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.status_perkawinan_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pasangan}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat Terakhir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.alamat_pasangan}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat Menikah
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_menikah}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pada
                    </td>
                    <td>:</td>
                    <td>
                      {Tanggal(new Date(this.state.cetak.tanggal_menikah))}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Mas Lawin
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.mas_kawin}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Wali Nikah
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.wali_nikah}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Saksi Nikah
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.saksi_nikah}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Demikianlah surat keterangan ini dibuat berdasarkan laporan
                  dari :
                </span>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.nama_pelapor}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.tempat_lahir_pelapor},{" "}
                        {Tanggal(
                          new Date(this.state.cetak.tanggal_lahir_pelapor)
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pelapor}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.agama_pelapor}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.pekerjaan_pelapor}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {this.state.cetak.alamat_pelapor}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "justify" }} width="50%">
                <table border="0" width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td width="35%">Dikeluarkan di</td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_desa}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ borderBottom: "1px solid black" }}>
                      Dikeluarkan pada
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>:</td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      {Tanggal(new Date(this.state.cetak.tanggal_surat))}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td width="50%">&nbsp;</td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
