import React, {Component} from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup,CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import {Post, Get, Delete, cekLogin} from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
    uuid: '',
    level : '',
    id_wilayah : '',
    link_web_aplikasi : '',
    link_api : '',
    link_mobile_aplikasi : '',
}

class BasicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form:false,
                btnForm:false,
                btnAksi:false,
                select:false
            },
            awal: "",
            sebelum: "",
            hal: [],
            setelah: "",
            akhir: "",
            dt:frmDef,
            judul: "Tambah Daftar Aplikasi",
            // =========== Select 2 ============
            frmPrv: [],
            prvStat: [],
            frmKbkt: [],
            kbktStat: [],
            frmKec: [],
            kecStat: [],
            frmKlds: [],
            kldsStat: [],
            frmLvl: [],
            lvlStat: [],
            // =================================
            // =========== Alert ============
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
            // ==============================
            // ============= Level ==============
            sttProv: 'none',
            sttKabkota: 'none',
            sttKec: 'none',
            sttKeldes: 'none',
            // ==============================
        };
    }
    
    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = (params = {}) => 
    {    
        let link = "";
        let where = [];
        if (params.where)
        {
            where = params.where;
        }
        if (params.page)
        {
            switch(params.page)
            {
                case '>>': link = "?page="+(Math.ceil(this.state.pagination.total / this.state.pagination.per_page));break;
                case '>': link = "?page="+(parseInt(this.state.pagination.current_page) + 1);break;
                case '<': link = "?page="+(parseInt(this.state.pagination.current_page) - 1);break;
                case '<<': link = "?page=1";break;
                default: link = "?page="+params.page;break;
            }
        }
        Get('daftar-aplikasi'+link, null, (dtkat) => {
            this.setState({data:dtkat.results.data, pagination:dtkat.results});
            this.pagination(dtkat.results);
        });
    }

  
    // Pagination
    pagination = (data) => {
        // const data = this.state.pagination;
        console.log(data);
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0)
        {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3)
            {   
                start = 1;
                end = 5;
                if (data.last_page > data.current_page)
                {
                    n = data.current_page + 1;
                }
            }
            else 
            {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2)
            {
                p = data.current_page - 1;
                if (start >= 5)
                {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i=start;i<=end;i++) 
            {
                let warna = "success";
                if (i == data.current_page)
                {
                    warna = "danger"
                }
                hal.push(<a onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }
            if (p > 0)
            {
                sebelum = (<a onClick={()=>this.fetch({page:p})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }
            if (n > 0)
            {
                setelah = (<a onClick={()=>this.fetch({page:n})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }
            awal = (<a onClick={()=>this.fetch({page:1})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a onClick={()=>this.fetch({page:data.last_page})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }
        this.setState({awal,sebelum,hal,setelah,akhir});
    }
    // Cari Data Table
    cariData = e => {
        if (e.key == "Enter")
        {
            let addDaftarAplikasi = {
                nama : e.target.value,
            };
            Post('daftar-aplikasi', null, addDaftarAplikasi, (data) => {
                this.setState({data:data.data.results.data, pagination:data.data.results});
            });
        }
    }

    // ========================================================================

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();
        if (this.state.dt.id_wilayah > 0)
        {
            this.state.status.btnForm = true;
            this.forceUpdate();
            let addDaftarAplikasi = {
                uuid : document.getElementById("uuid").value,
                level : this.state.dt.level,
                id_wilayah : this.state.dt.id_wilayah,
                link_web_aplikasi : document.getElementById("link_web_aplikasi").value,
                link_mobile_aplikasi : document.getElementById("link_mobile_aplikasi").value,
                link_api : document.getElementById("link_api").value,
            };
            
            let psn = '';
            let resstat = 204;
            let metode = "create";
            if (addDaftarAplikasi.uuid === '')
            {
                psn = "Tambah"; 
                resstat = 201;
                addDaftarAplikasi.uuid = null;
                
            } 
            else 
            {
                psn = "Ubah"; 
                resstat = 200;
                metode = "update";
            }
            Post('daftar-aplikasi/'+metode,addDaftarAplikasi.uuid,addDaftarAplikasi, (res) => {
                this.state.status.btnForm = false;
                this.state.status.form = false;
                this.forceUpdate();
                console.log(res.status);
                if (res.status === resstat)
                {
                    this.setState({ show: true, basicType:'success', basicTitle:'Data Daftar Aplikasi', pesanAlert:'Berhasil '+psn+' Data' });
                }
                else
                {
                    this.setState({ show: true, basicType:'danger', basicTitle:'Data Daftar Aplikasi', pesanAlert:'Gagal '+psn+' Data' });
                }
                this.componentDidMount();
            });
        }
        else
        {
            this.setState({ show: true, basicType:'danger', basicTitle:'Data Daftar Aplikasi', pesanAlert:this.state.lvlStat.label+" tidak boleh kosong" });
        }
    }
    // ========================================================================
    
    // ============================== Ubah Data ===============================
    
    ubahData = (id) => {
        this.state.status.btnAksi = true;
        this.state.judul = "Ubah Daftar Aplikasi";
        this.forceUpdate();
        Get('daftar-aplikasi/find',id, (data) => {
            this.state.status.btnAksi = false;
            this.forceUpdate();
            this.setState({dt:data.results, lvlStat:{value:data.results.level, label:data.results.level}});
            this.ubahPilihan(data.results.level, data.results);
        });
    }
    // ========================================================================
    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="Hapus"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    type="danger"
                    title="Yakin ingin hapus data ?"
                    onCancel={this.hideAlert}
                    onConfirm={()=> this.hapusData(id)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    hapusData = (id) => {
        this.hideAlert();
        let psn = "Hapus";
        Delete('daftar-aplikasi/delete', id, (res) => {
            if (res == 200)
            {
                this.setState({ show: true, basicType:'success', basicTitle:'Data Daftar Aplikasi', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Daftar Aplikasi', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.componentDidMount();
        })
    }
    // ========================================================================

    bukaForm = () => {
        this.state.status.form = true;
        this.forceUpdate();
    }

    tutupForm = () => {
        this.state.status.form = false;
        this.forceUpdate();
    }

    tombolAksi = (cell, row) => {
        return (
            <>
                <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
                <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>  
            </>
        )
    }

    getChild = (e, self, parent, child) => 
    {
        this.state.status.select = true;
        this.forceUpdate();
        this.changeSelectValue(e,self);
        if (e)
        {
            Get(child+'/'+parent+'/'+e.value+'/list', null, (data) => {
                let frm = [];
                data.results.map((dt) => {
                    frm.push({value:dt.id, label:dt.nama});
                });
                switch(child)
                {
                    case 'kab-kota': this.setState({frmKbkt:frm, frmKlds:[]});break;
                    case 'kecamatan': this.setState({frmKec:frm});break;
                    case 'keldes': this.setState({frmKlds:frm});break;
                }
                this.state.status.select = false;
                this.forceUpdate();
            });
        }
    }

    ubahPilihan = (table, data) => 
    {
        // Get(table+'/find', id, (data) => {
            switch(table)
            {
                // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
                case 'Keldes': 
                this.getChild({value:data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id, label:data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama},'provinsi','prov','kab-kota');
                this.getChild({value:data.data_keldes.data_kecamatan.data_kabkota.id, label:data.data_keldes.data_kecamatan.data_kabkota.nama},'kab-kota','kab','kecamatan');
                this.getChild({value:data.data_keldes.data_kecamatan.id, label:data.data_keldes.data_kecamatan.nama},'kecamatan','kec','keldes');
                this.setState({
                    prvStat:{value:data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id, label:data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama}, 
                    kbktStat:{value:data.data_keldes.data_kecamatan.data_kabkota.id, label:data.data_keldes.data_kecamatan.data_kabkota.nama}, 
                    kecStat:{value:data.data_keldes.data_kecamatan.id, label:data.data_keldes.data_kecamatan.nama}, 
                    kldsStat:{value:data.data_keldes.id, label:data.data_keldes.nama},
                    sttKeldes:'block',
                    sttKec:'block',
                    sttKabkota:'block',
                    sttProv:'block',
                });
                break;
                case 'Kecamatan': 
                this.getChild({value:data.data_kecamatan.data_kabkota.data_provinsi.id, label:data.data_kecamatan.data_kabkota.data_provinsi.nama},'provinsi','prov','kab-kota');
                this.getChild({value:data.data_kecamatan.data_kabkota.id, label:data.data_kecamatan.data_kabkota.nama},'kab-kota','kab','kecamatan');
                this.setState({
                    prvStat:{value:data.data_kecamatan.data_kabkota.data_provinsi.id, label:data.data_kecamatan.data_kabkota.data_provinsi.nama}, 
                    kbktStat:{value:data.data_kecamatan.data_kabkota.id, label:data.data_kecamatan.data_kabkota.nama}, 
                    kecStat:{value:data.data_kecamatan.id, label:data.data_kecamatan.nama},
                    sttKeldes:'none',
                    sttKec:'block',
                    sttKabkota:'block',
                    sttProv:'block',
                });
                break;
                case 'Kabkota': 
                this.getChild({value:data.data_kabkota.data_provinsi.id, label:data.data_kabkota.data_provinsi.nama},'provinsi','prov','kab-kota');
                this.setState({
                    prvStat:{value:data.data_kabkota.data_provinsi.id, label:data.data_kabkota.data_provinsi.nama}, 
                    kbktStat:{value:data.data_kabkota.id, label:data.data_kabkota.nama},
                    sttKeldes:'none',
                    sttKec:'none',
                    sttKabkota:'block',
                    sttProv:'block',
                });
                break;
                case 'Provinsi': 
                this.setState({
                    prvStat:{value:data.data_provinsi.data_provinsi.id, label:data.data_provinsi.data_provinsi.nama},
                    sttKeldes:'none',
                    sttKec:'none',
                    sttKabkota:'none',
                    sttProv:'block',
                });
                break;
            }
            this.bukaForm(); 
        // });
    }

    changeSelectValue = (e,sel) =>
    {
        if (e){
            if(this.state.lvlStat.value == sel)
            {
                this.state.dt.id_wilayah = e.value;
            }
            switch(sel)
            {
                case 'provinsi': this.setState({prvStat:e, kbktStat:[], kecStat:[], kldsStat:[]}); break;
                case 'kab-kota': this.setState({kbktStat:e, kecStat:[], kldsStat:[]}); break;
                case 'kecamatan': this.setState({kecStat:e, kldsStat:[]}); break;
                case 'keldes': this.setState({kldsStat:e}); break;
                case 'level': 
                    this.state.dt.id_wilayah = 0;
                    this.state.dt.level = e.value;
                    this.setState({lvlStat:e}); 
                    switch(e.value)
                    {
                        case 'Provinsi':this.state.dt.id_wilayah = this.state.prvStat.value; this.setState({sttKeldes:'none',sttKec:'none',sttKabkota:'none',sttProv:'block'}); break;
                        case 'Kabupaten':this.state.dt.id_wilayah = this.state.kbktStat.value; this.setState({sttKeldes:'none',sttKec:'none',sttKabkota:'block',sttProv:'block'}); break;
                        case 'Kecamatan':this.state.dt.id_wilayah = this.state.kecStat.value; this.setState({sttKeldes:'none',sttKec:'block',sttKabkota:'block',sttProv:'block'}); break;
                        case 'Desa':this.setState({sttKeldes:'block',sttKec:'block',sttKabkota:'block',sttProv:'block'}); break;
                    }
                    break;
            }
        }
        else 
        {
            switch(sel)
            {
                case 'provinsi': this.state.dt.id_wilayah = 0;this.setState({prvStat:[], kbktStat:[], kecStat:[], kldsStat:[], frmKbkt:[], frmKec:[], frmKlds:[]}); break;
                case 'kab-kota': this.state.dt.id_wilayah = this.state.prvStat.value;this.setState({kbktStat:[], kecStat:[], kldsStat:[], frmKec:[], frmKlds:[]}); break;
                case 'kecamatan': this.state.dt.id_wilayah = this.state.kbktStat.value;this.setState({kecStat:[], kldsStat:[], frmKlds:[]}); break;
                case 'keldes': this.state.dt.id_wilayah = this.state.kecStat.value; this.setState({kldsStat:[]}); break;
                case 'level': this.state.dt.id_wilayah = 0; this.state.dt.level = 0; this.setState({lvlStat:[],prvStat:[], kbktStat:[], kecStat:[], kldsStat:[], frmKbkt:[], frmKec:[], frmKlds:[], sttKeldes:'none',sttKec:'none',sttKabkota:'none',sttProv:'none'}); break;
            }
        }
        
        this.state.status.select = false;
        this.forceUpdate();
    }

    componentDidMount() {
        this.fetch({page:null,where:[]});
        Get('provinsi/list', null, (data) => {
            let frmPrv = [];
            data.results.map((dt) => {
                frmPrv.push({value:dt.id, label:dt.nama});
            });
            this.setState({frmPrv});
        });
        Get('select-enum/daftar_aplikasi/level', null, (data) => {
            let frmLvl = [];
            data.map((dt) => {
                frmLvl.push({value:dt, label:dt});
            });
            this.setState({frmLvl});
        })
    }

    render() {
        var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
        // var size = this.state.pagination.total;
        var from = this.state.pagination.from;
        var page = this.state.pagination.current;
        var to = this.state.pagination.to;
        var total = this.state.pagination.total;
        var data = this.state.data;
        const frmDef = {
            uuid: '',
            level : '',
            id_wilayah : '',
            link_web_aplikasi : '',
            link_api : '',
            link_mobile_aplikasi : '',
        }
        
        // const { SearchBar } = Search;
        const columns = [
            {
                dataField: 'uuid',
                text: 'ID',
                sort: false
            },
            {
                dataField: 'level',
                text: 'Level Wilayah',
                sort: true
            },
            {
                dataField: 'nama_wilayah',
                text: 'Nama Wilayah',
                isDummyField: true,
                formatter: (cell, obj)=> {
                    let nama = 'a';
                    switch(obj.level)
                    {
                        case 'Provinsi': nama = obj.data_provinsi.nama;break;
                        case 'Kabupaten': nama = obj.data_kabkota.nama;break;
                        case 'Kecamatan': nama = obj.data_kecamatan.nama;break;
                        case 'Desa': nama = obj.data_keldes.nama;break;
                    }
                    return nama;
                },
                sort: true
            },
            {
                dataField: 'link_web_aplikasi',
                text: 'Link Web',
                sort: true
            },
            {
                dataField: 'link_mobile_aplikasi',
                text: 'Link Mobile',
                sort: true
            },
            {
                dataField: 'link_api',
                text: 'Link Api',
                sort: true
            },
            {
                dataField: 'aksi',
                text: 'Aksi',
                isDummyField: true,
                csvExport: false,
                formatter: this.tombolAksi,
            },
        ];

        const defaultSorted = [{
            dataField: 'nama',
            order: 'asc'
        }];

        const pageButtonRenderer = ({
                                        page,
                                        active,
                                        disable,
                                        title,
                                        onPageChange
                                    }) => {
            const handleClick = (e) => {
                e.preventDefault();
                this.fetch({where:[],page:page});
            };
            var classname = '';
                classname = 'btn btn-success';
            return (
                <li className="page-item pl-1" key={page}>
                    <a href="#" onClick={ handleClick } className={ classname }>{ page }</a>
                </li>
            );
        };

        const options = {
            alwaysShowAllBtns:true,
            pageButtonRenderer,
            // custom: true,
            paginationTotalRenderer: () => (
                <span className="react-bootstrap-table-pagination-total">
                    Showing { from } to { to } of { total } Results
                </span>
            ),
            paginationSize: size, 
            totalSize: total
          };

        return (
            <div>
                <Breadcrumb title="Data Daftar Aplikasi" parent="Admin"/>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/* <h5>Data Daftar Aplikasi</h5> */}
                                            {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <Button  size="sm" color="success" className="btn-square" onClick={()=> {
        this.setState({judul:"Tambah Daftar Aplikasi", dt:frmDef, prvStat:[], kbktStat:[], kecStat:[], kldsStat:[], lvlStat:[]});this.bukaForm();}}>Tambah Data</Button>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body datatable-react">
                                    <BootstrapTable
                                        keyField="id"
                                        data={ data }
                                        columns={ columns }
                                        />
                                    <div className="pull-right text-white">
                                        {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
                    <Form className="theme-form" onSubmit={this.simpan}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.judul}</h5>
                        </div>
                        <div className="modal-body">
                            <input className="form-control" id="uuid" type="hidden" placeholder="Keldes" defaultValue={this.state.dt.uuid}/>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Level</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled = {this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.changeSelectValue(e,'level')})}
                                        defaultValue={this.state.lvlStat} 
                                        value={this.state.lvlStat} 
                                        name="level"
                                        options={this.state.frmLvl}
                                        placeholder="Pilih Level"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{display:this.state.sttProv}}>
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Provinsi</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled = {this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'provinsi', 'prov','kab-kota')})}
                                        defaultValue={this.state.prvStat} 
                                        value={this.state.prvStat} 
                                        name="provinsi"
                                        options={this.state.frmPrv}
                                        placeholder="Pilih Provinsi"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{display:this.state.sttKabkota}}>
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kabupaten / Kota</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled={this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'kab-kota', 'kab','kecamatan')})}
                                        defaultValue={this.state.kbktStat} 
                                        value={this.state.kbktStat} 
                                        name="kabkota"
                                        options={this.state.frmKbkt}
                                        placeholder="Pilih Kabupaten/Kota"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{display:this.state.sttKec}}>
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kecamatan</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled={this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.getChild(e,'kecamatan', 'kec','keldes')})}
                                        defaultValue={this.state.kecStat} 
                                        value={this.state.kecStat} 
                                        name="kecamatan"
                                        options={this.state.frmKec}
                                        placeholder="Pilih Kecamatan"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{display:this.state.sttKeldes}}>
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kelurahan / Desa</Label>
                                <Col sm='9'>
                                    <Select
                                        isDisabled={this.state.status.select}
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.changeSelectValue(e,'keldes')})}
                                        defaultValue={this.state.kldsStat} 
                                        value={this.state.kldsStat} 
                                        name="keldes"
                                        options={this.state.frmKlds}
                                        placeholder="Pilih Kelurahan/Desa"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link Aplikasi Web</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="link_web_aplikasi" type="text" placeholder="Link Aplikasi Web" defaultValue={this.state.dt.link_web_aplikasi}/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link Aplikasi Mobile</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="link_mobile_aplikasi" type="text" placeholder="Link Aplikasi Mobile" defaultValue={this.state.dt.link_web_mobile}/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Link API</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="link_api" type="text" placeholder="Link API" defaultValue={this.state.dt.link_api}/>
                                </Col>
                            </FormGroup>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
                            <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                        </div>
                    </Form>
                </Modal>

            </div>
        )
    }
}


export default BasicTable;
