import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import Datepicker from 'react-datepicker';
import { Redirect } from "react-router-dom";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../function/Koneksi';
import { Tanggal, Hari, HariTanggal, HariTanggalJam } from '../function/Format';
import { logo } from './asset/base64img';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const cetak = {
	nama_desa: '',
	nomor_laporan: '',
	tanggal_laporan: new Date,
	detail: [],
	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	pekerjaan: '',
	kewarganegaraan: '',
	alamat: '',
	jenis_kelamin: '',
	tempat_meninggal: '',
	sebab_meninggal: '',
	waktu_meninggal: '',

	nik2: '',
	nama2: '',
	tempat_lahir2: '',
	tanggal_lahir2: '',
	pekerjaan2: '',
	kewarganegaraan2: '',
	alamat2: '',
	jenis_kelamin: '',
	tempat_meninggal2: '',
	sebab_meninggal2: '',
	waktu_meninggal2: '',

	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: '',
}
let detail = [];
let aw = {
	nama: '',
	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jenis_kelamin: '',
	pekerjaan: ''
}
class Laporan extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: [],
				dataPenduduk: 'none',
				dataPenduduk2: 'none'
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			judul: "Tambah Laporan",
			shdk: "",
			detail: [],
			// ============ Select 2 ==============
			frmJl: [],
			jlStat: null,
			frmStt: [],
			sttStat: null,
			// ===================================
			// ============ Alert ==============
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			// ===================================
			// ============ Tanggal ==============
			tanggal_laporan_dari: new Date,
			tanggal_laporan_sampai: new Date,
			tanggal_lahir: new Date,
			waktu_meninggal: new Date,
			waktu_meninggal2: new Date,
			// ===================================
			// ============ Cetak ==============
			cetak: cetak,
			// ===================================
			// =============== acc ===============
			formAcc: false,
			formSelectAcc: [
				{ value: "Disetujui", label: "Disetujui" },
				{ value: "Ditolak", label: "Ditolak" }
			],
			frmTTD: [],
			accButton: [],
			accuuid: '',
			btnFormAcc: true,
			tableAcc: "none"
		};
	}

	// ================== Tanggal ===================

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_laporan_dari': this.setState({ tanggal_laporan_dari: date }); break;
			case 'tanggal_laporan_sampai': this.setState({ tanggal_laporan_sampai: date }); break;
		}
	}

	// ==============================================

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		let dfil = {
			awal: this.state.tanggal_laporan_dari,
			akhir: this.state.tanggal_laporan_sampai,
			label: this.state.jlStat ? this.state.jlStat.value : null,
			status: this.state.sttStat ? this.state.sttStat.value : null,
		}
		Post('laporan/riwayat', null, dfil, (dtkat) => {
			console.log(dtkat);
			this.setState({ data: dtkat.data.results, pagination: dtkat.data });
			// this.pagination(dtkat.results);
		});
	}

	// Pagination
	pagination = (data) => {
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}
	// Cari Data Table
	cariData = e => {
		if (e.key == "Enter") {
			let addSuratKeterangan = {
				nama: e.target.value,
			};
			Post('surat-keterangan/grup/' + this.props.location.state.uuid, null, addSuratKeterangan, (data) => {
				this.setState({ data: data.data.results.data, pagination: data.data.results });
			});
		}
	}

	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.accStat = [];
		this.state.tableAcc = 'none';
		this.state.formAcc = false;
		this.state.status.form = false;
		this.forceUpdate();
	}

	persetujuan = (id, jawaban) => {
		this.hideAlert();
		Get('surat-keterangan/grup/persetujuan/' + jawaban + '/' + id, null, (res) => {
			if (res.code === 201) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Menyetujui Surat', pesanAlert: 'Berhasil update status' });
			}
			else {
				this.setState({ show: true, basicType: 'warning', basicTitle: 'Menyetujui Surat', pesanAlert: 'Gagal update status' });
			}
			this.componentDidMount();
		});
	}

	tombolAksi = (cell, row) => {
		let btnApproval = false;
		let btnCetak = false;
		let btnAksi = false;
		let status = "";
		row.data_status.map((dt) => {
			status = dt.status;
		});
		if (status == 'Diajukan') {
			btnCetak = true;
			btnAksi = false;
			btnApproval = false;
		}
		else {
			if (status == "Ditolak") {
				btnCetak = true;
			}
			else {
				btnCetak = false;
			}
			btnAksi = true;
			btnApproval = true;
		}
		return (
			<>
				<Button size="xs" color="primary" className="btn-icon" onClick={() => this.konfirmApprove(row.uuid)} disabled={btnApproval}><i className="fa fa-check"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={btnAksi}><i className="fa fa-trash"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="info" className="btn-icon" onClick={(e) => this.cetakSurat(e, row)} disabled={btnCetak}><i className="fa fa-print"></i></Button>
			</>
		)
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });
		Get('jenis-surat/list', null, (data) => {
			let frmJl = [];
			data.results.map((dt) => {
				frmJl.push({ value: dt.nama, label: dt.nama });
			});
			this.setState({ frmJl });
		});
		Get("select-enum/status_surat/status", null, (data) => {
			let frmStt = [];
			data.map((dt) => {
				frmStt.push({ value: dt, label: dt });
			});
			this.setState({ frmStt });
		});

	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld == 'nik') {
				let nikStat = { value: e, label: e }
				let param = {
					nik: e,
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.map((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					})
					this.setState({ frmNik, nikStat });
				});
			}
			else {
				let nik2Stat = { value: e, label: e }
				let param = {
					nik: e,
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik2 = [];
					datanik.data.results.data.map((dt) => {
						frmNik2.push({ value: dt.id, label: dt.id });
					})
					this.setState({ frmNik2, nik2Stat });
				});
			}
		}
	}

	getPenduduk = (e, sel) => {
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				const data_rt = data.results.data_kk.data_rt;
				const data_rw = data_rt.data_rw;
				const data_dusun = data_rw.data_dusun;
				const data_desa = data_dusun.data_keldes;
				const data_kecamatan = data_desa.data_kecamatan;
				const data_kabkota = data_kecamatan.data_kabkota;
				let alamat = 'RT/RW : ' + data_rt.nama +
					'/' + data_rw.nama +
					', DUSUN ' + data_dusun.nama +
					', Desa ' + data_desa.nama +
					', Kecamatan ' + data_kecamatan.nama +
					', ' + data_kabkota.nama;

				if (sel == 'nik2') {
					this.state.tanggal_lahir2 = data.results.tanggal_lahir;
					this.state.dt.nama2 = data.results.nama;
					this.state.dt.tanggal_lahir2 = new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID');
					this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
					this.state.dt.jenis_kelamin2 = data.results.jk;
					this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
					this.state.dt.agama2 = data.results.data_agama.nama;
					this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
					this.state.dt.kewarganegaraan2 = "Indonesia";
					this.state.dt.alamat2 = alamat;
					this.state.status.dataPenduduk2 = "block";
				}
				else {
					if (sel == 'nik3') {
						aw.nik = e.value;
						aw.nama = data.results.nama;
						aw.jenis_kelamin = data.results.jenis_kelamin;
						aw.tanggal_lahir = data.results.tanggal_lahir;
						aw.tempat_lahir = data.results.tempat_lahir;
						aw.pekerjaan = data.results.data_pekerjaan.nama;
					}
					else {

						this.state.tanggal_lahir = data.results.tanggal_lahir;
						this.state.dt.kk = data.results.id_kk;
						this.state.dt.nama = data.results.nama;
						this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID');
						this.state.dt.tempat_lahir = data.results.tempat_lahir;
						this.state.dt.jenis_kelamin = data.results.jk;
						this.state.dt.status_perkawinan = data.results.status_perkawinan;
						this.state.dt.agama = data.results.data_agama.nama;
						this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
						this.state.dt.pendidikan_terakhir = data.results.data_pendidikan_terakhir.nama;
						this.state.dt.kewarganegaraan = "Indonesia";
						this.state.dt.alamat = alamat;
						this.state.status.dataPenduduk = "block";
					}
				}
				this.forceUpdate();
			});
		}
		else {
			if (sel == 'nik2') {
				this.state.nik2Stat = null;
				this.state.dt.nama2 = "";
				this.state.dt.tanggal_lahir2 = "";
				this.state.dt.tempat_lahir2 = "";
				this.state.dt.agama2 = "";
				this.state.dt.pekerjaan2 = "";
				this.state.dt.kewarganegaraan2 = "";
				this.state.dt.alamat2 = "";
				this.state.dt.jenis_kelamin2 = "";
				this.state.dt.status_perkawinan2 = "";
				this.state.dt.tempat_meninggal2 = "";
				this.state.dt.sebab_meninggal2 = "";
				this.state.status.dataPenduduk2 = "none";
			}
			else {
				this.state.nikStat = null;
				this.state.dt.nama = "";
				this.state.dt.tanggal_lahir = "";
				this.state.dt.tempat_lahir = "";
				this.state.dt.agama = "";
				this.state.dt.pekerjaan = "";
				this.state.dt.pendidikan_terakhir = "";
				this.state.dt.kewarganegaraan = "";
				this.state.dt.alamat = "";
				this.state.dt.jenis_kelamin = "";
				this.state.dt.status_perkawinan = "";
				this.state.status.dataPenduduk = "none";
			}
			this.forceUpdate();
		}
	}

	changeInputValue = (e, sel) => {
		switch (sel) {
			case 'shdk': aw.shdk = e.target.value; this.forceUpdate(); break;
		}
	}

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jam': this.state.dt.jam = e.value; this.setState({ jamStat: e }); break;
				case 'pekerjaan': this.state.dt.pekerjaan = e.label; this.setState({ pkrStat: e }); break;
				case 'agama': this.state.dt.agama = e.label; this.setState({ agmStat: e }); break;
				case 'nik': this.state.dt.nik = e.value; this.setState({ nikStat: e }); break;
				case 'nik2': this.state.dt.nik2 = e.value; this.setState({ nik2Stat: e }); break;
				case 'nik3': this.state.dt.nik3 = e.value; this.setState({ nik3Stat: e }); break;
				case 'jenis-layanan': this.setState({ jlStat: e }); break;
				case 'status-layanan': this.setState({ sttStat: e }); break;
			}
		}
		else {
			switch (sel) {
				case 'jam': this.state.dt.jam = 0; this.setState({ jamStat: e }); break;
				case 'pekerjaan': this.state.dt.pekerjaan = ""; this.setState({ pkrStat: e }); break;
				case 'agama': this.state.dt.agama = ""; this.setState({ agmStat: e }); break;
				case 'nik': this.state.dt.nik = 0; this.setState({ nikStat: e }); break;
				case 'nik2': this.state.dt.nik2 = 0; this.setState({ nik2Stat: e }); break;
				case 'nik3': this.state.dt.nik3 = 0; this.setState({ nik3tat: e }); break;
				case 'jenis-layanan': this.setState({ jlStat: e }); break;
				case 'status-layanan': this.setState({ sttStat: e }); break;
			}
		}

		this.forceUpdate();
	}

	cetakSurat = e => {
		e.preventDefault();
		let tanggal = "";
		if (this.state.tanggal_laporan_dari.toString() == this.state.tanggal_laporan_sampai.toString()) {
			tanggal = " Tanggal " + Tanggal(this.state.tanggal_laporan_dari);
		}
		else {
			tanggal = " Dari Tanggal " + Tanggal(this.state.tanggal_laporan_dari) + " s/d " + Tanggal(this.state.tanggal_laporan_sampai);
		}
		this.setState({
			cetak: {
				nama_desa: "Desa Sungai Petai",
				tanggal: tanggal,
				tanggal_laporan: new Date,
				detail: this.state.data,
				status_ttd: "",
				jabatan_ttd: "Kepala Desa Sungai Petai",
				jabatan_pengganti_ttd: "",
				nama_ttd: "Rian Adli S.I.Kom",
				nip_ttd: "",
			}
		})

		setTimeout(function () {
			const content = document.getElementById('cetak-surat').innerHTML;
			const mywindow = window.open('', 'Print', 'height=600,width=1000');

			mywindow.document.write('<html><head><title>Print</title>');
			mywindow.document.write('</head><body >');
			mywindow.document.write(content);
			mywindow.document.write('</body></html>');

			mywindow.document.close();
			mywindow.focus();
			mywindow.print();
			setTimeout(function () {
				mywindow.close();
			}, 1000);
		}, 100);
	}

	render() {
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// // var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		var data = this.state.data;

		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'tanggal',
				text: 'Tanggal',
				isDummyField: true,
				formatter: (cell, obj) => { return new Date(obj.tanggal).toLocaleDateString('id-ID') },
				sort: true
			},
			{
				dataField: 'label',
				text: 'Layanan',
				sort: true
			},
			{
				dataField: 'nik',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'status',
				text: 'Status',
				sort: true
			},
		];


		return (
			<div>
				<Breadcrumb title="Data Laporan" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <h5>Data Laporan</h5> */}
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square mr-3" onClick={() => { this.setState({ judul: "Filter Laporan" }); this.bukaForm(); }}>Filter Data</Button>
											<Button size="sm" color="success" className="btn-square ml-3" onClick={this.cetakSurat}>Cetak Data</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<BootstrapTable
										keyField="id"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{/* {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir} */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<p>Silakan memilih filter.</p>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Surat</Label>
								<Col sm='9'>
									<Row>
										<Col sm='6'>
											<Datepicker
												id="tanggal_laporan_daro"
												todayButton={"Today"}
												dateFormat="dd/MM/yyyy"
												selected={this.state.tanggal_laporan_dari}
												onChange={(e) => this.gantiTgl(e, 'tanggal_laporan_dari')}
												className="form-control"
											/>
										</Col>
										<Col sm='6'>
											<Datepicker
												id="tanggal_laporan"
												todayButton={"Today"}
												dateFormat="dd/MM/yyyy"
												selected={this.state.tanggal_laporan_sampai}
												onChange={(e) => this.gantiTgl(e, 'tanggal_laporan_sampai')}
												className="form-control"
											/>
										</Col>
									</Row>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Layanan</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={((e) => { this.changeSelectValue(e, 'jenis-layanan') })}
										defaultValue={this.state.jlStat}
										value={this.state.jlStat}
										name="JenisLayanan"
										options={this.state.frmJl}
										placeholder="Filter Jenis Layanan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Layanan</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={((e) => { this.changeSelectValue(e, 'status-layanan') })}
										defaultValue={this.state.sttStat}
										value={this.state.sttStat}
										name="StatusLayanan"
										options={this.state.frmStt}
										placeholder="Filter Status Layanan"
										isClearable
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="button" className="btn btn-success" disabled={this.state.status.btnForm} onClick={() => { this.fetch(); this.state.status.form = false; this.forceUpdate() }}>Filter</button>
						</div>
					</Form>
				</Modal>

				<div id="cetak-surat" style={{ display: "none" }}>
					<       style dangerouslySetInnerHTML={{ __html: "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    " }} />
					<table width="100%" border="0">
						<tr>
							<td width="100" align="center"><img src={logo} width="80px" /></td>
							<td align="center">
								<div style={{ fontWeight: "bold" }}>
									<span style={{ fontSize: "24px", textTransform: "uppercase" }}>pemerintah kabupaten kampar</span><br />
									<span style={{ fontSize: "22px", textTransform: "uppercase" }}>kecamatan kampar kiri hilir</span><br />
									<span style={{ fontSize: "28px", textTransform: "uppercase" }}>kantor kepala desa sungai petai</span><br />
									<span style={{ fontSize: "16px", textTransform: "capitalize" }}>jl. raya sungai pagar km. 35 kode pos 28471</span>
								</div>
							</td>
						</tr>
					</table>
					<hr style={{ border: "2px solid black" }} />
					<table border="0" width="90%" style={{ marginTop: "30px", marginLeft: "auto", marginRight: "auto", verticalAlign: "top" }}>
						<tr>
							<td align="center" style={{ paddingBottom: "20px" }}> <span style={{ textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'underline' }}>Laporan Layanan{this.state.cetak.tanggal}</span>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: "justify" }}>
								<p style={{ textIndent: "50px" }}>Berikut Laporan Pelayanan{this.state.jlStat ? " " + this.state.jlStat.value : ""} {this.state.sttStat ? " yang berstatus " + this.state.sttStat.value : ""} Pada Kantor Desa Sungai Petai : </p>
								<table border="1" width="100%" cellpadding="0" cellspacing="0" style={{ fontSize: "14px", marginLeft: "auto", marginRight: "auto" }}>
									<thead>
										<tr>
											<td style={{ padding: "5px 5px 5px 5px" }}>No</td>
											<td style={{ padding: "5px 5px 5px 5px" }}>Tanggal</td>
											<td style={{ padding: "5px 5px 5px 5px" }}>Layanan</td>
											<td style={{ padding: "5px 5px 5px 5px" }}>Nik Pemohon</td>
											<td style={{ padding: "5px 5px 5px 5px" }}>Nama Pemohon</td>
											<td style={{ padding: "5px 5px 5px 5px" }}>Status</td>
										</tr>
									</thead>
									<tbody>
										{this.state.cetak.detail.map((dt, i) => {
											console.log(dt);
											return (
												<tr>
													<td style={{ padding: "5px 5px 5px 5px" }}>{i + 1}</td>
													<td style={{ padding: "5px 5px 5px 5px" }}>{new Date(dt.tanggal).toLocaleDateString('id-ID')}</td>
													<td style={{ padding: "5px 5px 5px 5px" }}>{dt.label}</td>
													<td style={{ padding: "5px 5px 5px 5px" }}>{dt.nik}</td>
													<td style={{ padding: "5px 5px 5px 5px" }}>{dt.nama}</td>
													<td style={{ padding: "5px 5px 5px 5px" }}>{dt.status}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: "justify" }}>
								{this.state.cetak.lokasi}
								<p style={{ textIndent: "50px" }}>Demikianlah laporan ini kami buat dengan sebenar - benar nya.</p>
							</td>
						</tr>
					</table>
					<table border="0" width="100%">
						<tr>
							<td style={{ textAlign: "justify" }} width="50%">
								&nbsp;
                            </td>
							<td style={{ textAlign: "justify" }} width="50%">
								<table border="0" width="100%" cellpadding="0" cellspacing="0">
									<tr>
										<td width="35%">
											Dikeluarkan di
                                        </td>
										<td>
											:
                                        </td>
										<td>
											{this.state.cetak.nama_desa}
										</td>
									</tr>
									<tr>
										<td width="35%" style={{ borderBottom: "1px solid black" }}>
											Dikeluarkan pada
                                        </td>
										<td style={{ borderBottom: "1px solid black" }}>
											:
                                        </td>
										<td style={{ borderBottom: "1px solid black" }}>
											{Tanggal(new Date(this.state.cetak.tanggal_laporan))}
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
					<table border="0" width="100%" style={{ marginTop: "20px" }}>
						<tr>
							<td align="center">
								&nbsp;
                            </td>
							<td>
								&nbsp;
                            </td>
						</tr>
						<tr>
							<td width="50%" style={{ textAlign: "center" }}>
								&nbsp;
                            </td>
							<td width="50%" style={{ paddingLeft: "20px", textAlign: "center" }}>
								<span style={{ fontWeight: "bold" }}>{this.state.cetak.status_ttd} <span style={{ textTransform: "uppercase" }}>{this.state.cetak.jabatan_ttd}</span>{this.state.cetak.jabatan_pengganti_ttd ? <><br />{this.state.cetak.jabatan_pengganti_ttd}</> : ""}</span>
							</td>
						</tr>
						<tr>
							<td height="50">
								&nbsp;
                            </td>
						</tr>
						<tr>
							<td align="center">
								&nbsp;
                            </td>
							<td align="center">
								<span style={{ textTransform: "uppercase", fontWeight: "bold", borderBottom: "1px solid black" }}>{this.state.cetak.nama_ttd}</span><br />
								{this.state.cetak.nip_ttd}
							</td>
						</tr>
					</table>
				</div>
			</div>
		)
	}
}


export default Laporan;
