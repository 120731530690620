import axios from 'axios';

// Production Sungai Petai 
// const API_URL = 'https://prod-ws-digidesa-spti0761.digidesa.com/api/v1/'; 

// Dev Sungai Petai
const API_URL = 'https://ws-dgd-spt0761.digidesa.com/api/v1/';

// Demo Digidesa
// const API_URL = 'https://ws-demo-desa.digidesa.com/api/v1/';

export const ID_DESA = 1401082006;

export function Get(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL + table}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Delete(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	axios
		.delete(`${API_URL + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
		.then((response) => response.status)
		.then((status) => {
			cb(status);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	axios
		.put(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	axios
		.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {
	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((response) => {
			cb(response);
		});
}
export function PostStatus(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function PostUser(table, id, data, cb) {
	if (id === null) {
		axios
			.post('http://localhost/ppdx/web/' + table, data, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export default API_URL;
