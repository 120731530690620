import React, { Component, createContext } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ReactDatePicker from "react-datepicker";
import { logo } from "./asset/base64img";
import CetakSurat from "./cetak-surat";
import ModalUpdate from "./update/modal-update";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  id: 0,
  idbaru: "",
  id_keldes: "",
  nama: ""
};

const cetak = {
  jenis_surat: "",
  nama_desa: "",
  nomor_surat: "",
  tanggal_surat: new Date(),
  detail: [],
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  kewarganegaraan: "",
  alamat: "",
  jenis_kelamin: "",
  tempat_meninggal: "",
  sebab_meninggal: "",
  waktu_meninggal: "",

  nik2: "",
  nama2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  pekerjaan2: "",
  kewarganegaraan2: "",
  alamat2: "",
  jenis_kelamin: "",
  tempat_meninggal2: "",
  sebab_meninggal2: "",
  waktu_meninggal2: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const detailSurat = {
  uuid: "",
  id_jenis: "",
  jenis_surat: "",
  nomor: "",
  tanggal_surat: new Date(),

  nik_anak: "",
  nama_anak: "",
  tempat_lahir_anak: "",
  tanggal_lahir_anak: new Date(),
  jk_anak: "",

  nik_ayah: "",
  nama_ayah: "",
  tempat_lahir_ayah: "",
  tanggal_lahir_ayah: "",
  alamat_ayah: "",

  nik_ibu: "",
  nama_ibu: "",
  tempat_lahir_ibu: "",
  tanggal_lahir_ibu: "",
  alamat_ibu: "",

  nik_pelapor: "",
  nama_pelapor: "",
  tempat_lahir_pelapor: "",
  tanggal_lahir_pelapor: "",
  pekerjaan_pelapor: "",
  agama_pelapor: "",
  alamat_pelapor: "",
  hubungan_pelapor: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  jmlAcara: 0,
  meninggal2: "none",
  jamStat: { value: "08:00", label: "08:00" }
};

let jmlAcara = 0;

const DetailSuratContext = createContext();
const {
  Provider: DetailSuratProvider,
  Consumer: DetailSuratConsumer
} = DetailSuratContext;

class Disposisi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataDitolak: [],
      dataMenungguEksekusi: [],
      dataPenerbitan: [],
      dataPending: [],
      dataSelesai: [],

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationMenungguEksekusi: [],
      paginationPenerbitan: [],
      paginationPending: [],
      paginationSelesai: [],

      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        select: false,
        // ======  Data Acc / Approve =====
        formAcc: false,
        btnFormAcc: true,
        tableAcc: "",
        accStat: [],
        // ======  Data Update Surat =====
        formUpdateSurat: false,
        btnFormUpdateSurat: true,
        // formSelectAcc: [
        // 	{ value: 'Disetujui', label: 'Disetujui' },
        // 	{ value: 'Ditolak', label: 'Ditolak' },
        // ],
        // ======  Data Disposisi =====
        formDisposisi: false,
        formKeterangan: false,
        // ======  Detail Surat Kelahiran =====
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none",
        // == SKGR
        showBio: false,
        showBio2: false
      },

      // Diproses
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Menunggu Eksekusi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Penerbitan
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Pending
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",
      // Ditolak
      awal5: "",
      sebelum5: "",
      hal5: [],
      setelah5: "",
      akhir5: "",
      // Selesai
      awal6: "",
      sebelum6: "",
      hal6: [],
      setelah6: "",
      akhir6: "",

      dt: frmDef,
      judul: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKlds: [],
      kldsStat: [],
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
      active_tab_icon: "1",
      cetak: cetak,
      surat: {
        uuid: "",
        jenis_surat: "",
        tanggal_surat: new Date()
      },
      detailSurat: detailSurat,
      disposisi: {
        uuid_jenis_surat: "",
        data: [],
        riwayat: []
      },
      catatan: {
        nik: "",
        data: []
      },
      keteranganPenduduk: [],
      catatanPenduduk: [],
      usaha: [
        {
          jenis_usaha: "",
          lokasi: "",
          umur: "",
          luas_kebun: "",
          merk: ""
        }
      ],
      listPerangkatDesa: [],
      accButtonPerangkatDesa: [],
      uuidPerangkatDesa: "",
      // Detail Surat Kelahiran
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      dataNik: {
        frmNik: [],
        frmNik2: [],
        frmNik3: []
      },
      dataNikStat: []
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      this.setState({
        active_tab_icon: tab
      });
    }
  }

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "proses":
              link =
                "?page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "menunggu-eksekusi":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationMenungguEksekusi.total /
                    this.state.paginationMenungguEksekusi.per_page
                );
              break;

            case "penerbitan":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationPenerbitan.total /
                    this.state.paginationPenerbitan.per_page
                );
              break;

            case "pending":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationPending.total /
                    this.state.paginationPending.per_page
                );
              break;

            case "ditolak":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                    this.state.paginationDitolak.per_page
                );
              break;

            case "selesai":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationSelesai.total /
                    this.state.paginationSelesai.per_page
                );
              break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "proses":
              link =
                "?page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "menunggu-eksekusi":
              link =
                "?page=" +
                (parseInt(this.state.paginationMenungguEksekusi.current_page) +
                  1);
              break;

            case "penerbitan":
              link =
                "?page=" +
                (parseInt(this.state.paginationPenerbitan.current_page) + 1);
              break;

            case "pending":
              link =
                "?page=" +
                (parseInt(this.state.paginationPending.current_page) + 1);
              break;

            case "ditolak":
              link =
                "?page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;

            case "selesai":
              link =
                "?page=" +
                (parseInt(this.state.paginationSelesai.current_page) + 1);
              break;
          }
          break;
        case "<":
          switch (params.tab) {
            case "proses":
              link =
                "?page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "menunggu-eksekusi":
              link =
                "?page=" +
                (parseInt(this.state.paginationMenungguEksekusi.current_page) -
                  1);
              break;

            case "penerbitan":
              link =
                "?page=" +
                (parseInt(this.state.paginationPenerbitan.current_page) - 1);
              break;

            case "pending":
              link =
                "?page=" +
                (parseInt(this.state.paginationPending.current_page) - 1);
              break;

            case "ditolak":
              link =
                "?page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;

            case "selesai":
              link =
                "?page=" +
                (parseInt(this.state.paginationSelesai.current_page) - 1);
              break;
          }
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    // Get data from db
    Get("list-pengajuan-layanan/" + params.tab + link, null, dtkat => {
      switch (params.tab) {
        case "proses":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "menunggu-eksekusi":
          this.setState({
            dataMenungguEksekusi: dtkat.results.data,
            paginationMenungguEksekusi: dtkat.results
          });
          break;

        case "penerbitan":
          this.setState({
            dataPenerbitan: dtkat.results.data,
            paginationPenerbitan: dtkat.results
          });
          break;

        case "pending":
          this.setState({
            dataPending: dtkat.results.data,
            paginationPending: dtkat.results
          });
          break;

        case "ditolak":
          this.setState({
            dataDitolak: dtkat.results.data,
            paginationDitolak: dtkat.results
          });
          break;

        case "selesai":
          this.setState({
            dataSelesai: dtkat.results.data,
            paginationSelesai: dtkat.results
          });
          break;
      }
      // console.log(dtkat.results);
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "proses":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "menunggu-eksekusi":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      case "penerbitan":
        this.setState({
          awal3: awal,
          sebelum3: sebelum,
          hal3: hal,
          setelah3: setelah,
          akhir3: akhir
        });
        break;
      case "pending":
        this.setState({
          awal4: awal,
          sebelum4: sebelum,
          hal4: hal,
          setelah4: setelah,
          akhir4: akhir
        });
        break;
      case "ditolak":
        this.setState({
          awal5: awal,
          sebelum5: sebelum,
          hal5: hal,
          setelah5: setelah,
          akhir5: akhir
        });
        break;
      case "selesai":
        this.setState({
          awal6: awal,
          sebelum6: sebelum,
          hal6: hal,
          setelah6: setelah,
          akhir6: akhir
        });
        break;
    }
  };

  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let addKeldes = {
        nama: e.target.value
      };
      Post("dusun", null, addKeldes, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    if (this.state.dt.id_keldes > 0) {
      this.state.status.btnForm = true;
      this.forceUpdate();
      let id = parseInt(document.getElementById("id").value);
      let addKeldes = {
        id: this.state.dt.id_keldes + document.getElementById("idbaru").value,
        id_keldes: this.state.dt.id_keldes,
        nama: document.getElementById("nama").value
      };

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (id === 0) {
        psn = "Tambah";
        resstat = 201;
        id = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post("dusun/" + metode, id, addKeldes, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        // console.log(res.status);
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Dusun",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Dusun",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Dusun",
        pesanAlert: "Kelurahan/Desa tidak boleh kosong"
      });
    }
  };
  // =================================================

  // ============= Ubah Catatan Data Pending ===============
  ubahCatatan = id => {
    this.state.status.btnAksi = true;
    this.state.judul = "Ubah Catatan";
    this.setState({ catatanPenduduk: [] });
    this.forceUpdate();

    var catatan = { ...this.state.catatan };

    Get("catatan-penduduk/nik", id, data => {
      catatan.nik = id;
      catatan.data = data.results.data;
      this.setState({ catatan });
      this.forceUpdate();
      this.bukaForm("catatan");
    });
  };

  changeCatatanPenduduk = (e, obj) => {
    if (e) {
      // console.log(uuid_catatan);
      const addCatatan = {
        uuid: obj.uuid,
        nik: obj.nik,
        id_pengguna: obj.id_pengguna,
        catatan: obj.catatan,
        status: e.value
      };

      var catatanPenduduk = this.state.catatanPenduduk.concat(addCatatan);
      this.setState({ catatanPenduduk });
    } else {
    }
  };

  simpanCatatan = e => {
    e.preventDefault();
    let psn = "Ubah";
    let resp = 200;
    let resstat = 200;

    if (this.state.catatanPenduduk.length > 0) {
      for (var [index, catatan] of this.state.catatanPenduduk.entries()) {
        Post("catatan-penduduk/update", catatan.uuid, catatan, res => {
          resp = res.status;
        });
      }
      this.state.status.form = false;

      if (resp === resstat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Catatan",
          pesanAlert: "Berhasil " + psn + " Data Catatan"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Catatan",
          pesanAlert: "Gagal " + psn + " Data Catatan"
        });
      }
      this.componentDidMount();
    }
    this.state.status.form = false;
  };
  // ================== Ubah Catatan Data Pending =====================

  konfirmAccPending = (uuid_jenis, uuid_surat, nik) => {
    let catatanBelumSelesai = 0;
    Get("catatan-penduduk/nik", nik, data => {
      // console.log(data);
      const listCatatan = data.results.data;
      listCatatan.map(catatan => {
        // console.log(catatan);
        if (catatan.status === 0) {
          catatanBelumSelesai++;
        }
      });
      // console.log(catatanBelumSelesai);
      if (catatanBelumSelesai > 0) {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Catatan",
          pesanAlert:
            "Masih ada catatan yang belum selesai, selesaikan catatan terlebih dahulu"
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              showCancel
              confirmBtnText="Penyelesaian Penangguhan"
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              type="warning"
              title="Apakah anda yakin ingin menyelesaikan penangguhan surat ini ?"
              onCancel={this.hideAlert}
              onConfirm={() => this.accPending(uuid_jenis, uuid_surat, nik)}
            >
              Proses ini tidak dapat dibatalkan, pastikan sudah memilih surat
              yang sesuai...
            </SweetAlert>
          )
        });
      }
    });
  };

  accPending = (uuid_jenis, uuid_surat, nik) => {
    this.hideAlert();
    Get(
      "list-pengajuan-layanan/pending/acc/" +
        uuid_jenis +
        "/" +
        uuid_surat +
        "/" +
        nik,
      null,
      data => {
        var psn = "Menyelesaikan Penangguhan";
        // console.log(data);
        if (data.code == 200) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Catatan",
            pesanAlert: "Berhasil " + psn + " Catatan"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Catatan",
            pesanAlert: "Gagal " + psn + " Catatan"
          });
        }
        this.componentDidMount();
      }
    );
  };

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("dusun/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Dusun",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Dusun",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    switch (jenis) {
      case "catatan":
        this.state.status.form = true;
        break;

      case "update":
        this.state.status.formUpdateSurat = true;
        break;

      default:
        break;
    }
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.formAcc = false;
    this.state.status.formDisposisi = false;
    this.state.status.formKeterangan = false;
    this.state.status.form = false;
    this.state.status.formUpdateSurat = false;

    this.state.status.accStat = false;
    this.state.status.tableAcc = "none";

    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    let btnApproval = true;
    let btnCetak = true;
    let btnAksi = true;
    let btnListDisposisi = true;
    let btnListKeterangan = true;
    let btnTerbit = true;
    let btnAccPending = true;
    let btnUpdate = true;

    let status = row.status;

    switch (status) {
      case "Diajukan":
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = false;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;

      case "Disetujui":
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = true;
        btnApproval = false;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;

      case "Menunggu Penerbitan":
        btnListKeterangan = false;
        btnCetak = false;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = true;
        btnTerbit = false;
        btnAccPending = true;
        btnUpdate = false;
        break;

      case "Ditangguhkan":
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = false;
        btnApproval = true;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = false;
        btnUpdate = true;
        break;

      case "Selesai":
        btnListKeterangan = true;
        btnCetak = false;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = false;
        break;

      case "Ditolak":
        btnListKeterangan = false;
        btnCetak = true;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;

      default:
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;
    }

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {btnUpdate ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Perbaiki data surat"
              size="xs"
              color="success"
              className="btn btn-icon"
              onClick={e => this.updateSurat(e, row)}
              disabled={btnUpdate}
            >
              <i className="fa fa-pencil"></i>
            </Button>
          )}

          {btnApproval ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Pilih penandatangan surat"
              size="xs"
              color="primary"
              className="btn btn-icon"
              onClick={() => this.konfirmApprove(row.uuid_surat, row.label)}
              disabled={btnApproval}
            >
              <i className="fa fa-check"> Pilih penandatangan</i>
            </Button>
          )}

          {btnAksi ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Ubah Data"
              size="xs"
              color="success"
              className="btn btn-icon"
              onClick={() => this.ubahCatatan(row.nik)}
              disabled={btnAksi}
            >
              <i className="fa fa-clock-o"></i>
            </Button>
          )}

          {btnAccPending ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Selesaikan penangguhan surat"
              size="xs"
              color="info"
              className="btn btn-icon"
              onClick={() =>
                this.konfirmAccPending(
                  row.uuid_jenis_surat,
                  row.uuid_surat,
                  row.nik
                )
              }
              disabled={btnAccPending}
            >
              <i className="fa fa-check-circle"></i>
            </Button>
          )}

          {btnCetak ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              size="xs"
              title="Cetak Surat"
              color="info"
              className="btn-icon"
              onClick={e => this.cetakSurat(e, row)}
              disabled={btnCetak}
            >
              <i className="fa fa-print"></i>
            </Button>
          )}

          {btnListDisposisi ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Riwayat Disposisi"
              size="xs"
              color="primary"
              className="btn btn-icon"
              onClick={e => this.listDisposisi(row)}
              disabled={btnListDisposisi}
            >
              <i className="fa fa-list"></i>
            </Button>
          )}

          {btnListKeterangan ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Data Keterangan"
              size="xs"
              color="info"
              className="btn btn-icon"
              onClick={e => this.listKeterangan(row)}
              disabled={btnListKeterangan}
            >
              <i className="fa fa-list"></i>
            </Button>
          )}

          {btnTerbit ? (
            ""
          ) : (
            <Button
              // style={{ marginLeft: "5px" }}
              title="Status Penerbitan Surat"
              size="xs"
              color="primary"
              className="btn btn-icon"
              onClick={e =>
                this.konfirmTerbit(
                  row.uuid_jenis_surat,
                  row.uuid_surat,
                  row.nik
                )
              }
              disabled={btnTerbit}
            >
              <i className="fa fa-calendar-check-o"></i>
            </Button>
          )}
        </div>
      </>
    );
  };

  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.map(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKlds: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKlds: frm });
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };

  ubahPilihan = (table, data) => {
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          { value: data.id, label: data.nama },
          "keldes",
          "kel",
          "dusun"
        );
        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kldsStat: { value: data.id, label: data.nama }
        });
        this.bukaForm("");
        break;
    }
    // });
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({
            prvStat: e,
            kbktStat: [],
            kecStat: [],
            kldsStat: []
          });
          break;
        case "kab-kota":
          this.setState({ kbktStat: e, kecStat: [], kldsStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e, kldsStat: [] });
          break;
        case "keldes":
          this.state.dt.id_keldes = e.value;
          this.setState({ kldsStat: e });
          break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({
            prvStat: [],
            kbktStat: [],
            kecStat: [],
            kldsStat: [],
            frmKbkt: [],
            frmKec: [],
            frmKlds: []
          });
          break;
        case "kab-kota":
          this.setState({
            kbktStat: [],
            kecStat: [],
            kldsStat: [],
            frmKec: [],
            frmKlds: []
          });
          break;
        case "kecamatan":
          this.setState({ kecStat: [], kldsStat: [], frmKlds: [] });
          break;
        case "keldes":
          this.state.dt.id_keldes = 0;
          this.setState({ kldsStat: [] });
          break;
      }
    }

    this.state.status.select = false;
    this.forceUpdate();
  };

  componentDidMount() {
    if (typeof this.props.location.tabProps != "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
    }

    // Fetching data proses
    this.fetch({ page: null, where: [], tab: "proses" });
    // Fetcing data menunggu eksekusi
    this.fetch({ page: null, where: [], tab: "menunggu-eksekusi" });
    // Fetcing data penerbitan
    this.fetch({ page: null, where: [], tab: "penerbitan" });
    // Fetcing data pending
    this.fetch({ page: null, where: [], tab: "pending" });
    // Fetcing data ditolak
    this.fetch({ page: null, where: [], tab: "ditolak" });
    // Fetcing data selesai
    this.fetch({ page: null, where: [], tab: "selesai" });

    Get("tanda-tangan/list", null, data => {
      data.results.map((dt, i) => {
        if (dt.aktif == 1) {
          // console.log(dt);
          let temp = this.state.accButtonPerangkatDesa.map(x => 1);
          temp[i] = 0;
          this.setState({
            accButtonPerangkatDesa: temp,
            uuidPerangkatDesa: dt.uuid
          });
        }
      });
      this.forceUpdate();
      this.setState({ listPerangkatDesa: data.results });
    });

    Get("penduduk", null, data => {
      let frmNik = [];
      let frmNik2 = [];
      let frmNik3 = [];
      data.results.data.map(dt => {
        frmNik.push({ value: dt.id, label: dt.id });
        frmNik2.push({ value: dt.id, label: dt.id });
        frmNik3.push({ value: dt.id, label: dt.id });
      });
      // console.log(data.results);
      this.state.dataNik.frmNik = frmNik;
      this.state.dataNik.frmNik2 = frmNik2;
      this.state.dataNik.frmNik3 = frmNik3;
      this.forceUpdate();
    });

    this.state.detailSurat.jmlAcara = 0;
    this.forceUpdate();
  }

  konfirmApprove = (id, jenis_surat) => {
    this.state.status.formAcc = true;
    this.state.status.btnFormAcc = false;
    this.state.status.tableAcc = "";
    this.state.surat.uuid = id;
    this.state.surat.jenis_surat = jenis_surat;

    this.setState({
      judul: "Persetujuan Data "
    });
  };

  konfirmTerbit = (uuid_jenis, uuid_surat, nik) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Terbitkan Surat"
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="default"
          type="warning"
          title="Apakah anda yakin ingin menerbitkan surat ini ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.terbitkanSurat(uuid_jenis, uuid_surat, nik)}
        >
          Proses ini tidak dapat dibatalkan, pastikan sudah memilih surat yang
          sesuai...
        </SweetAlert>
      )
    });
  };

  terbitkanSurat = (uuid_jenis, uuid_surat, nik) => {
    console.log(nik);
    this.hideAlert();
    Get(
      nik != ""
        ? "list-pengajuan-layanan/surat-selesai/" +
            uuid_jenis +
            "/" +
            uuid_surat +
            "/" +
            nik
        : "list-pengajuan-layanan/surat-selesai/" +
            uuid_jenis +
            "/" +
            uuid_surat +
            "/0",
      null,
      data => {
        var psn = "Menerbitkan";
        // console.log(data);
        if (data.code == 200) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Penerbitan Surat",
            pesanAlert: "Berhasil " + psn + " Surat"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Penerbitan Surat",
            pesanAlert: "Gagal " + psn + " Surat"
          });
        }
        this.componentDidMount();
      }
    );
  };

  listDisposisi = data => {
    Get("alur-disposisi/" + data.uuid_jenis_surat, null, dtkat => {
      if (dtkat) {
        this.state.status.formDisposisi = true;
        this.state.surat.uuid = data.uuid;
        this.state.disposisi.data = dtkat.results;
        this.state.disposisi.riwayat = data.status_disposisi;

        this.setState({
          judul: "Disposisi Data "
        });

        this.forceUpdate();
      }
    });
  };

  listKeterangan = data => {
    console.log(data);
    let catatan = [];

    if (data.catatan != null) {
      data.catatan.map((dt, i) => {
        if (dt.status == 1) {
          catatan.push(dt);
        }
      });
    }

    this.state.keteranganPenduduk = catatan;
    this.state.status.formKeterangan = true;
    this.forceUpdate();
    console.log(this.state.keteranganPenduduk);
  };

  // ================== Update detail surat =====================
  getPenduduk = (e, jenis_surat, jns) => {
    this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      // this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        console.log(data);
        let alamat = "";
        if (typeof data.results.data_kk.data_rt != "undefined") {
          const data_rt = data.results.data_kk.data_rt;
          const data_rw = data_rt.data_rw;
          const data_dusun = data_rw.data_dusun;
          const data_desa = data_dusun.data_keldes;
          const data_kecamatan = data_desa.data_kecamatan;
          const data_kabkota = data_kecamatan.data_kabkota;
          alamat =
            "RT/RW : " +
            data_rt.nama +
            "/" +
            data_rw.nama +
            ", DUSUN " +
            data_dusun.nama +
            ", Desa " +
            data_desa.nama +
            ", Kecamatan " +
            data_kecamatan.nama +
            ", " +
            data_kabkota.nama;
        }

        // Pelapor
        if (jns == "pelapor") {
          this.state.nik2Stat = { value: e.value, label: e.label };
          this.state.dataNikStat.nik2Stat = { value: e.value, label: e.label };
          this.state.detailSurat.pendStat2 = { value: e.value, label: e.label };
          // this.state.tanggal_lahir_pelapor = data.results.tanggal_lahir;
          this.state.detailSurat.nama_pelapor = data.results.nama;
          this.state.detailSurat.tanggal_lahir_pelapor = new Date(
            data.results.tanggal_lahir
          ).toLocaleDateString("id-ID");
          this.state.detailSurat.tempat_lahir_pelapor =
            data.results.tempat_lahir;
          this.state.detailSurat.jk_pelapor = data.results.jk;
          this.state.detailSurat.status_perkawinan_pelapor =
            data.results.status_perkawinan;
          this.state.detailSurat.agama_pelapor = data.results.data_agama.nama;
          this.state.detailSurat.pekerjaan_pelapor =
            data.results.data_pekerjaan.nama;
          this.state.detailSurat.alamat_pelapor = alamat;
          this.state.status.dataPenduduk2 = "block";
          this.state.status.showBio2 = true;

          this.forceUpdate();
        } else {
          // Ibu
          if (jns == "ibu") {
            this.state.nik3Stat = { value: e.value, label: e.label };
            this.state.dataNikStat.nik3Stat = {
              value: e.value,
              label: e.label
            };
            // this.state.tanggal_lahir_ibu = data.results.tanggal_lahir;
            this.state.detailSurat.nama_ibu = data.results.nama;
            this.state.detailSurat.tanggal_lahir_ibu = new Date(
              data.results.tanggal_lahir
            ).toLocaleDateString("id-ID");
            this.state.detailSurat.tempat_lahir_ibu = data.results.tempat_lahir;
            this.state.detailSurat.pekerjaan_ibu =
              data.results.data_pekerjaan.nama;
            this.state.detailSurat.alamat_ibu = alamat;
            this.state.status.dataPenduduk3 = "block";
          }
          // Pemohon ( Ayah )
          else if (jns == "pemohon" || jns == "nik") {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              console.log(dpddk);
              if (typeof dpddk.results.data != "undefined") {
                let pctt = 0;
                let catatan = "";
                if (dpddk.results.data) {
                  dpddk.results.data.map(dtp => {
                    if (dtp.status == 0 && dtp.penalti == 1) {
                      pctt = 1;
                      catatan = dtp.catatan;
                    }
                  });
                }
                if (pctt == 0) {
                  switch (jenis_surat) {
                    case "Surat Keterangan Kelahiran":
                      this.state.nikStat = { value: e.value, label: e.label };
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };
                      // this.state.tanggal_lahir_ayah = data.results.tanggal_lahir;
                      this.state.detailSurat.nama_ayah = data.results.nama;
                      this.state.detailSurat.tanggal_lahir_ayah = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir_ayah =
                        data.results.tempat_lahir;
                      this.state.detailSurat.pekerjaan_ayah =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.alamat_ayah = alamat;
                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Keterangan Jalan":
                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;

                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.kewarganegaraan =
                        data.results.kewarganegaraan;

                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.status.showBio = true;
                      break;

                    case "Surat Keterangan Kematian":
                      this.state.nikStat = { value: e.value, label: e.label };
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Kuasa SKGR":
                      if (jns == "nik") {
                        this.state.detailSurat.agama =
                          data.results.data_agama.nama;
                        this.state.detailSurat.alamat = alamat;
                        this.state.detailSurat.jenis_kelamin = data.results.jk;
                        this.state.detailSurat.jk = data.results.jk;
                        this.state.detailSurat.kk = data.results.id_kk;
                        this.state.detailSurat.nama = data.results.nama;
                        this.state.detailSurat.nik = data.results.id;
                        this.state.detailSurat.pekerjaan =
                          data.results.data_pekerjaan.nama;
                        this.state.detailSurat.pendidikan_terakhir =
                          data.results.data_pendidikan_terakhir.nama;
                        this.state.detailSurat.pendStat = {
                          value: e.value,
                          label: e.label
                        };
                        this.state.detailSurat.status_perkawinan =
                          data.results.status_perkawinan;
                        this.state.detailSurat.tanggal_lahir =
                          data.results.tanggal_lahir;
                        this.state.detailSurat.tempat_lahir =
                          data.results.tempat_lahir;
                        this.state.status.showBio = true;
                      }
                      break;

                    case "Surat Keterangan Tidak Mampu":
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      // this.state.status.btnForm = false;
                      // this.state.dataNikStat.nikStat = { value: data.results.nik, label: data.results.nik };
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Keterangan Pernah Menikah":
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Pernyataan Tidak Menikah":
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;

                      if (data.results.status_perkawinan == "Cerai hidup") {
                        this.state.detailSurat.labelAkta = "cerai";
                      }
                      if (data.results.status_perkawinan == "Cerai mati") {
                        this.state.detailSurat.labelAkta = "kematian";
                      }
                      this.state.status.showBio = true;
                      // this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Pernyataan Kehilangan":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;
                      // this.state.status.btnForm = false;
                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.showBio = true;
                      break;

                    case "Surat Pernyataan Belum Menikah":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.showBio = true;
                      // this.state.status.btnForm = false;
                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Pengantar Pindah Nikah":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.nama_nasab_p1 =
                        data.results.nama_ayah_kandung;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.status.showBio = true;
                      break;

                    case "Surat Keterangan Usaha":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      break;

                    case "Surat Keterangan Penghasilan":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.pendidikan_terakhir;
                      this.state.detailSurat.kewarganegaraan =
                        data.results.kewarganegaraan;
                      this.state.detailSurat.tujuan_pembuatan =
                        data.results.tujuan_pembuatan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Keterangan Kepemilikan Rumah":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      // this.state.detailSurat.nama_pemohon = data.results.nama_pemohon;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Pengantar SKCK":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Keterangan Domisili":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;

                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Keterangan Belum Memiliki Rumah":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Keterangan Ahli Waris":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;

                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Keterangan":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Izin Keramaian":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Kuasa":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.showBio = true;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    default:
                      break;
                  }

                  this.state.status.btnFormUpdateSurat = false;
                  this.forceUpdate();
                } else {
                  this.state.dataNik.frmNik = [];
                  this.state.dataNikStat.nikStat = null;
                  this.state.detailSurat.agama = "";
                  this.state.detailSurat.alamat = "";
                  this.state.detailSurat.alamat_ayah = "";
                  this.state.detailSurat.dataNik.frmNik = [];
                  this.state.detailSurat.dataNikStat.nikStat = null;
                  this.state.detailSurat.jk = "";
                  this.state.detailSurat.kk = "";
                  this.state.detailSurat.nama = "";
                  this.state.detailSurat.nama_ayah = "";
                  this.state.detailSurat.nama_pemohon = "";
                  this.state.detailSurat.nik = "";
                  this.state.detailSurat.pekerjaan = "";
                  this.state.detailSurat.pekerjaan_ayah = "";
                  this.state.detailSurat.pendidikan_terakhir = "";
                  this.state.detailSurat.pendStat = [];
                  this.state.detailSurat.status_perkawinan = "";
                  this.state.detailSurat.tanggal_lahir = "";
                  this.state.detailSurat.tanggal_lahir_ayah = "";
                  this.state.detailSurat.tempat_lahir = "";
                  this.state.detailSurat.tempat_lahir_ayah = "";
                  this.state.status.dataPenduduk = "none";
                  this.state.status.showBio = false;

                  this.state.detailSurat.nik2 = "";
                  this.state.detailSurat.nama_pemohon2 = "";
                  this.state.detailSurat.tempat_lahir2 = "";
                  this.state.detailSurat.tanggal_lahir2 = "";
                  this.state.detailSurat.pekerjaan2 = "";
                  this.state.detailSurat.pendidikan_terakhir2 = "";
                  this.state.detailSurat.agama2 = "";
                  this.state.detailSurat.status_perkawinan2 = "";
                  this.state.detailSurat.alamat2 = "";
                  this.state.detailSurat.pendStat2 = [];
                  this.state.status.showBio2 = false;
                  this.state.status.btnForm = true;

                  this.state.status.btnFormUpdateSurat = true;
                  this.forceUpdate();
                  this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Peringatan Catatan Warga",
                    pesanAlert:
                      "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                      catatan
                  });
                }
              }
            });
          }
          // if nik2, nik3, dst
          else {
            switch (jenis_surat) {
              case "Surat Kuasa SKGR":
                if (jns == "nik2") {
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.kk2 = data.results.id_kk;
                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.nik2 = data.results.id;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.pendidikan_terakhir2 =
                    data.results.data_pendidikan_terakhir.nama;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.detailSurat.pendStat2 = {
                    value: e.value,
                    label: e.label
                  };
                  this.state.status.showBio2 = true;
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Tidak Mampu":
                if (jns == "nik2") {
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.kewarganegaraan2 = "Indonesia";
                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.tanggal_lahir2 = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.status.dataPenduduk2 = "block";
                  this.state.status.btnForm = false;
                } else if (jns == "nik3") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Pernah Menikah":
                if (jns == "pasangan") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };

                  this.state.detailSurat.agama_pasangan =
                    data.results.data_agama.nama;
                  this.state.detailSurat.alamat_pasangan = alamat;
                  this.state.detailSurat.jk_pasangan = data.results.jk;
                  this.state.detailSurat.nama_pasangan = data.results.nama;
                  this.state.detailSurat.pekerjaan_pasangan =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.status_perkawinan_pasangan =
                    data.results.status_perkawinan;
                  this.state.detailSurat.tanggal_lahir_pasangan =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.tanggal_lahir_pasangan = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.detailSurat.tempat_lahir_pasangan =
                    data.results.tempat_lahir;
                  this.state.status.dataPenduduk3 = "block";
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Domisili":
                if (jns == "nik3") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Pengantar Pindah Nikah":
                if (jns == "nik2") {
                  this.state.detailSurat.nik_pasangan = data.results.id;
                  this.state.detailSurat.kk_pasangan = data.results.id_kk;

                  this.state.detailSurat.nama_pasangan = data.results.nama;
                  this.state.detailSurat.tempat_lahir_pasangan =
                    data.results.tempat_lahir;
                  this.state.detailSurat.tanggal_lahir_pasangan =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.pekerjaan_pasangan =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.jenis_kelamin_pasangan =
                    data.results.jk;
                  this.state.detailSurat.pendidikan_terakhir_pasangan =
                    data.results.data_pendidikan_terakhir.nama;
                  this.state.detailSurat.agama_pasangan =
                    data.results.data_agama.nama;
                  this.state.detailSurat.status_perkawinan_pasangan =
                    data.results.status_perkawinan;
                  this.state.detailSurat.nama_nasab_p2 =
                    data.results.nama_ayah_kandung;
                  this.state.detailSurat.alamat_pasangan = alamat;

                  this.state.detailSurat.pendStat2 = {
                    value: e.value,
                    label: e.label
                  };

                  this.state.status.showBio2 = true;
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Ahli Waris":
                if (jns == "nik2") {
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.status.btnForm = false;

                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.tanggal_lahir2 = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.kewarganegaraan2 = "Indonesia";
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.status.dataPenduduk2 = "block";

                  this.state.dataNikStat.nik2Stat = {
                    value: e.value,
                    label: e.label
                  };
                } else if (jns == "nik3") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };
                }
                break;

              case "Surat Kuasa":
                if (jns == "nik2") {
                  this.state.detailSurat.nik2 = data.results.id;
                  this.state.detailSurat.kk2 = data.results.id_kk;

                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.pendidikan_terakhir2 =
                    data.results.data_pendidikan_terakhir.nama;
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.status.showBio2 = true;
                  this.state.detailSurat.pendStat2 = {
                    value: e.value,
                    label: e.label
                  };

                  if (
                    this.state.detailSurat.pendStat2.length != 0 &&
                    this.state.detailSurat.pendStat.length != 0
                  ) {
                    this.state.status.btnForm = false;
                  }
                }
                break;

              default:
                break;
            }
          }
        }
        this.forceUpdate();
      });
    } else {
      if (jns == "pelapor") {
        this.state.dataNikStat.nik2Stat = null;
        this.state.detailSurat.nama_pelapor = "";
        this.state.detailSurat.tanggal_lahir_pelapor = "";
        this.state.detailSurat.tempat_lahir_pelapor = "";
        this.state.detailSurat.agama_pelapor = "";
        this.state.detailSurat.pekerjaan_pelapor = "";
        this.state.detailSurat.alamat_pelapor = "";
        this.state.detailSurat.jk_pelapor = "";
        this.state.detailSurat.status_perkawinan_pelapor = "";
        this.state.status.dataPenduduk2 = "none";
      } else {
        if (jns == "ibu") {
          this.state.dataNikStat.nik3Stat = null;
          this.state.detailSurat.nama_ibu = "";
          this.state.detailSurat.tanggal_lahir_ibu = "";
          this.state.detailSurat.tempat_lahir_ibu = "";
          this.state.detailSurat.pekerjaan_ibu = "";
          this.state.detailSurat.alamat_ibu = "";
          this.state.status.dataPenduduk3 = "none";
        }
        // Selain jns ibu dan pelapor (nik, nik2, nik3, pemohon, pasangan)
        else {
          if (jns == "nik3" || jns == "pasangan") {
            this.state.dataNikStat.nik3Stat = null;
            this.state.status.dataPenduduk3 = "none";
          } else if (jns == "nik2") {
            this.state.dataNikStat.nik2Stat = null;
            this.state.detailSurat.pendStat2 = null;
            this.state.status.dataPenduduk2 = "none";

            this.state.detailSurat.agama2 = "";
            this.state.detailSurat.alamat2 = "";
            this.state.detailSurat.nama_pemohon2 = "";
            this.state.detailSurat.nama2 = "";
            this.state.detailSurat.nik2 = "";
            this.state.detailSurat.pekerjaan2 = "";
            this.state.detailSurat.pendidikan_terakhir2 = "";
            this.state.detailSurat.tanggal_lahir2 = "";
            this.state.detailSurat.tempat_lahir2 = "";

            this.state.detailSurat.status_perkawinan2 = "";
            this.state.status.showBio2 = false;
          } else if ((jns == "nik") | (jns == "pemohon")) {
            this.state.dataNikStat.nikStat = null;
            this.state.detailSurat.pendStat = null;
            this.state.status.dataPenduduk = "none";
            this.state.status.showBio = false;

            this.state.detailSurat.agama = "";
            this.state.detailSurat.alamat = "";
            this.state.detailSurat.jenis_kelamin = "";
            this.state.detailSurat.nama_pemohon = "";
            this.state.detailSurat.nik = "";
            this.state.detailSurat.pekerjaan = "";
            this.state.detailSurat.pendidikan_terakhir = "";
            this.state.detailSurat.status_perkawinan = "";
            this.state.detailSurat.tanggal_lahir = "";
            this.state.detailSurat.tempat_lahir = "";

            this.state.detailSurat.alamat_ayah = "";
            this.state.detailSurat.nama_ayah = "";
            this.state.detailSurat.pekerjaan_ayah = "";
            this.state.detailSurat.tanggal_lahir_ayah = "";
            this.state.detailSurat.tempat_lahir_ayah = "";

            this.state.detailSurat.labelAkta = "";
          }

          this.state.detailSurat.status_perkawinan = "";

          this.state.status.btnForm = true;
        }
      }
      this.state.status.btnForm = true;
      this.forceUpdate();
    }
  };

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld == "nik") {
        // let nikStat = {...this.state.detailSurat.nikStat};
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.map(dt => {
            frmNik.push({ value: dt.id, label: dt.id });
          });
          this.state.dataNik.frmNik = frmNik;
          this.state.dataNikStat.nikStat = nikStat;
          this.forceUpdate();
          this.setState({ frmNik, nikStat });
        });
      } else {
        // console.log('masuk sini?');

        if (fld == "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.map(dt => {
              frmNik2.push({ value: dt.id, label: dt.id });
            });
            this.state.dataNik.frmNik2 = frmNik2;
            this.state.dataNikStat.nik2Stat = nik2Stat;
            // console.log('masuk sini');
            this.forceUpdate();
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            console.log(datanik.data.results);
            let frmNik3 = [];
            datanik.data.results.data.map(dt => {
              frmNik3.push({ value: dt.id, label: dt.id });
            });
            this.state.dataNik.frmNik3 = frmNik3;
            this.state.dataNikStat.nik3Stat = nik3Stat;
            this.forceUpdate();
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };

  changeInputText = (e, fld) => {
    switch (fld) {
      case "tempat_lahir_anak":
        this.state.detailSurat.tempat_lahir_anak = e.target.value;
        this.forceUpdate();
        break;
    }
  };

  simpanUpdate = (e, jenis_surat) => {
    // Buat jenis surat
    e.preventDefault();

    let api = "grup";
    let kop = "";
    let addSuratKeterangan = null;
    let formDetail = null;

    switch (jenis_surat) {
      case "Surat Keterangan Kelahiran":
        if (
          this.state.dataNikStat.nikStat &&
          this.state.dataNikStat.nik2Stat &&
          this.state.dataNikStat.nik3Stat
        ) {
          api = "kelahiran";

          let tgl_lhr_ibu = this.state.detailSurat.tanggal_lahir_ibu.split("/");
          let tanggal_lahir_ibu =
            tgl_lhr_ibu[2] + "-" + tgl_lhr_ibu[1] + "-" + tgl_lhr_ibu[0];
          let tgl_lhr_ayah = this.state.detailSurat.tanggal_lahir_ayah.split(
            "/"
          );
          let tanggal_lahir_ayah =
            tgl_lhr_ayah[2] + "-" + tgl_lhr_ayah[1] + "-" + tgl_lhr_ayah[0];
          let tgl_lhr_pl = this.state.detailSurat.tanggal_lahir_pelapor.split(
            "/"
          );
          let tanggal_lahir_pelapor =
            tgl_lhr_pl[2] + "-" + tgl_lhr_pl[1] + "-" + tgl_lhr_pl[0];
          let tgl_lahir_anak = this.state.detailSurat.tanggal_lahir_anak
            .toLocaleDateString("id-ID")
            .split("/");
          let tanggal_lahir_anak =
            tgl_lahir_anak[2] +
            "-" +
            tgl_lahir_anak[1] +
            "-" +
            tgl_lahir_anak[0];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            // nomor : document.getElementById("nomor").value,

            nama_anak: document.getElementById("nama_anak").value,
            jk_anak: this.state.detailSurat.jk_anak,
            // tempat_lahir_anak: this.state.detailSurat.tempat_lahir_anak,
            tempat_lahir_anak: document.getElementById("tempat_lahir_anak")
              .value,
            tanggal_lahir_anak:
              tanggal_lahir_anak +
              " " +
              new Date(
                this.state.detailSurat.tanggal_lahir_anak
              ).toLocaleTimeString("id-ID"),
            // jk_anak: this.state.detailSurat.jk_anak,
            jk_anak: document.getElementById("jk_anak").value,

            nama_ayah: this.state.detailSurat.nama_ayah,
            tempat_lahir_ayah: this.state.detailSurat.tempat_lahir_ayah,
            tanggal_lahir_ayah: tanggal_lahir_ayah,
            nik_ayah: this.state.nikStat.value,
            pekerjaan_ayah: this.state.detailSurat.pekerjaan_ayah,
            alamat_ayah: this.state.detailSurat.alamat_ayah,

            nik_ibu: this.state.nik3Stat.value,
            nama_ibu: this.state.detailSurat.nama_ibu,
            tempat_lahir_ibu: this.state.detailSurat.tempat_lahir_ibu,
            tanggal_lahir_ibu: tanggal_lahir_ibu,
            pekerjaan_ibu: this.state.detailSurat.pekerjaan_ibu,
            alamat_ibu: this.state.detailSurat.alamat_ibu,

            nik_pelapor: this.state.nik2Stat.value,
            nama_pelapor: this.state.detailSurat.nama_pelapor,
            tempat_lahir_pelapor: this.state.detailSurat.tempat_lahir_pelapor,
            tanggal_lahir_pelapor: tanggal_lahir_pelapor,
            jenis_kelamin_pelapor: this.state.detailSurat.jk_pelapor,
            pekerjaan_pelapor: this.state.detailSurat.pekerjaan_pelapor,
            agama_pelapor: this.state.detailSurat.agama_pelapor,
            alamat_pelapor: this.state.detailSurat.alamat_pelapor,
            hubungan_pelapor: document.getElementById("hubungan_pelapor").value,

            status_ttd: null,
            jabatan_ttd: "Kepala Desa Sungai Petai",
            nama_ttd: "Rian Adli, S.I.Kom",
            nip_ttd: "11474242142"
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Ibu, NIK Ayah, dan NIK Pelapor"
          });
        }

        break;

      case "Surat Keterangan Jalan":
        if (this.state.detailSurat.pendStat) {
          formDetail = [
            {
              nama_item: "rencana_berangkat",
              isi_item: this.state.detailSurat.rencana_berangkat
            },
            {
              nama_item: "transportasi",
              isi_item: document.getElementById("transportasi").value
            },
            {
              nama_item: "tujuan_perjalanan",
              isi_item: document.getElementById("tujuan_perjalanan").value
            },
            {
              nama_item: "no_pol",
              isi_item: document.getElementById("no_pol").value
            },
            {
              nama_item: "nama_supir",
              isi_item: document.getElementById("nama_supir").value
            },
            {
              nama_item: "tempat_lahir_supir",
              isi_item: document.getElementById("tempat_lahir_supir").value
            },
            {
              nama_item: "tanggal_lahir_supir",
              isi_item: this.state.detailSurat.tanggal_lahir_supir
            },
            {
              nama_item: "barang",
              isi_item: document.getElementById("barang").value
            },
            {
              nama_item: "pengikut",
              isi_item: document.getElementById("pengikut").value
            }
          ];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            tanggal_surat: this.state.detailSurat.tanggal_surat,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,
            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
            kk: document.getElementById("kk").value,

            detail: formDetail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }

        break;

      case "Surat Keterangan Kematian":
        if (this.state.dataNikStat.nikStat && this.state.dataNikStat.nik2Stat) {
          api = "kematian";

          let tgl_lhr = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir = this.state.detailSurat.tanggal_lahir;

          if (tgl_lhr.length > 1) {
            tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
          }

          let tgl_lhr_pl2 = this.state.detailSurat.tanggal_lahir_pelapor.split(
            "/"
          );
          let tanggal_lahir_pelapor2 =
            tgl_lhr_pl2[2] + "-" + tgl_lhr_pl2[1] + "-" + tgl_lhr_pl2[0];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            tanggal_surat: this.state.detailSurat.tanggal_surat,
            // nomor : document.getElementById("nomor").value,
            nik: this.state.dataNikStat.nikStat.value,
            nama: this.state.detailSurat.nama,
            // nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: tanggal_lahir,
            jenis_kelamin: this.state.detailSurat.jk,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            pekerjaan: this.state.detailSurat.pekerjaan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,
            // pendidikan_terakhir: this.state.detailSurat.pendidikan_terakhir,
            // tujuan_pembuatan: this.state.detailSurat.tujuan_pembuatan,

            waktu_meninggal:
              new Date(this.state.detailSurat.waktu_meninggal)
                .toISOString()
                .slice(0, 10) +
              " " +
              new Date(
                this.state.detailSurat.waktu_meninggal
              ).toLocaleTimeString("id-ID"),
            tempat_meninggal: document.getElementById("tempat_meninggal").value,
            sebab_meninggal: document.getElementById("sebab_meninggal").value,
            tempat_makam: document.getElementById("tempat_makam").value,
            nik_pelapor: this.state.dataNikStat.nik2Stat.value,
            nama_pelapor: this.state.detailSurat.nama_pelapor,
            tempat_lahir_pelapor: this.state.detailSurat.tempat_lahir_pelapor,
            tanggal_lahir_pelapor: tanggal_lahir_pelapor2,
            jenis_kelamin_pelapor: this.state.detailSurat.jk_pelapor,
            status_perkawinan_pelapor: this.state.detailSurat
              .status_perkawinan_pelapor,
            pekerjaan_pelapor: this.state.detailSurat.pekerjaan_pelapor,
            agama_pelapor: this.state.detailSurat.agama_pelapor,
            alamat_pelapor: this.state.detailSurat.alamat_pelapor,
            hubungan_pelapor: document.getElementById("hubungan_pelapor").value
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert:
              "Mohon pilih data NIK warga yang meninggal dan NIK pelapor"
          });
        }

        break;

      case "Surat Kuasa SKGR":
        if (
          this.state.detailSurat.pendStat &&
          this.state.detailSurat.pendStat2
        ) {
          api = "kuasa";

          formDetail = [
            {
              nama_item: "atas_nama",
              isi_item: document.getElementById("atas_nama").value
            },
            {
              nama_item: "no_reg",
              isi_item: document.getElementById("no_reg").value
            },
            {
              nama_item: "tanggal_skgr",
              isi_item: this.state.detailSurat.tanggal_skgr
            },
            {
              nama_item: "luas_tanah",
              isi_item: document.getElementById("luas_tanah").value
            },
            {
              nama_item: "lokasi_tanah",
              isi_item: document.getElementById("lokasi_tanah").value
            }
          ];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pekerjaan: document.getElementById("pekerjaan").value,
            alamat: document.getElementById("alamat").value,

            nik2: document.getElementById("nik2").value,
            nama2: document.getElementById("nama2").value,
            tempat_lahir2: document.getElementById("tempat_lahir2").value,
            tanggal_lahir2: document.getElementById("tanggal_lahir2").value,
            pekerjaan2: document.getElementById("pekerjaan2").value,
            alamat2: document.getElementById("alamat2").value,

            kuasa_atas: document.getElementById("kuasa_atas").value,
            kuasa_untuk: document.getElementById("kuasa_untuk").value,
            tujuan_kuasa: document.getElementById("tujuan_kuasa").value,

            detail: formDetail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemberi Kuasa dan Penerima Kuasa"
          });
        }
        break;

      case "Surat Keterangan Tidak Mampu":
        if (this.state.dataNikStat.nikStat) {
          api = "tidak-mampu";

          let tgl_lhr2 = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir2 = this.state.detailSurat.tanggal_lahir;
          // console.log(tgl_lhr2);
          if (tgl_lhr2.length > 1) {
            tanggal_lahir2 =
              tgl_lhr2[2] + "-" + tgl_lhr2[1] + "-" + tgl_lhr2[0];
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,

            nik: this.state.dataNikStat.nikStat.value,
            kk: this.state.detailSurat.kk,
            nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: tanggal_lahir2,
            jenis_kelamin: this.state.detailSurat.jenis_kelamin,
            pekerjaan: this.state.detailSurat.pekerjaan,
            pendidikan_terakhir: this.state.detailSurat.pendidikan_terakhir,
            kewarganegaraan: this.state.detailSurat.kewarganegaraan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            // tujuan_pembuatan : document.getElementById("tujuan_pembuatan").value,
            tujuan_pembuatan: "-",
            detail: this.state.detailSurat.detail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }

        break;

      case "Surat Keterangan Pernah Menikah":
        api = "pernah-menikah";

        if (
          this.state.dataNikStat.nikStat &&
          this.state.dataNikStat.nik2Stat &&
          this.state.dataNikStat.nik3Stat
        ) {
          let tgl_lhr3 = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir3 = this.state.detailSurat.tanggal_lahir;
          if (tgl_lhr3.length > 1) {
            tanggal_lahir3 =
              tgl_lhr3[2] + "-" + tgl_lhr3[1] + "-" + tgl_lhr3[0];
          }

          let tgl_lhr_ps = this.state.detailSurat.tanggal_lahir_pasangan.split(
            "/"
          );
          let tanggal_lahir_pasangan =
            tgl_lhr_ps[2] + "-" + tgl_lhr_ps[1] + "-" + tgl_lhr_ps[0];

          let tgl_lhr_pl3 = this.state.detailSurat.tanggal_lahir_pelapor.split(
            "/"
          );
          let tanggal_lahir_pelapor3 =
            tgl_lhr_pl3[2] + "-" + tgl_lhr_pl3[1] + "-" + tgl_lhr_pl3[0];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: this.state.dataNikStat.nikStat.value,
            nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: tanggal_lahir3,
            jenis_kelamin: this.state.detailSurat.jk,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            pekerjaan: this.state.detailSurat.pekerjaan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,

            nik_pasangan: this.state.dataNikStat.nik3Stat.value,
            nama_pasangan: this.state.detailSurat.nama_pasangan,
            tempat_lahir_pasangan: this.state.detailSurat.tempat_lahir_pasangan,
            tanggal_lahir_pasangan: tanggal_lahir_pasangan,
            jenis_kelamin_pasangan: this.state.detailSurat.jk_pasangan,
            status_perkawinan_pasangan: this.state.detailSurat
              .status_perkawinan_pasangan,
            pekerjaan_pasangan: this.state.detailSurat.pekerjaan_pasangan,
            agama_pasangan: this.state.detailSurat.agama_pasangan,
            alamat_pasangan: this.state.detailSurat.alamat_pasangan,

            tanggal_menikah:
              new Date(this.state.detailSurat.tanggal_menikah)
                .toISOString()
                .slice(0, 10) +
              " " +
              new Date(
                this.state.detailSurat.tanggal_menikah
              ).toLocaleTimeString("id-ID"),
            tempat_menikah: document.getElementById("tempat_menikah").value,
            petugas_nikah: document.getElementById("petugas_nikah").value,
            wali_nikah: document.getElementById("wali_nikah").value,
            mas_kawin: document.getElementById("mas_kawin").value,
            saksi_nikah: document.getElementById("saksi_nikah").value,

            nik_pelapor: this.state.dataNikStat.nik2Stat.value,
            nama_pelapor: this.state.detailSurat.nama_pelapor,
            tempat_lahir_pelapor: this.state.detailSurat.tempat_lahir_pelapor,
            tanggal_lahir_pelapor: tanggal_lahir_pelapor3,
            jenis_kelamin_pelapor: this.state.detailSurat.jk_pelapor,
            status_perkawinan_pelapor: this.state.detailSurat
              .status_perkawinan_pelapor,
            pekerjaan_pelapor: this.state.detailSurat.pekerjaan_pelapor,
            agama_pelapor: this.state.detailSurat.agama_pelapor,
            alamat_pelapor: this.state.detailSurat.alamat_pelapor,
            // hubungan_pelapor : document.getElementById("hubungan_pelapor").value,
            status_ttd: null,
            jabatan_ttd: "Kepala Desa Sungai Petai",
            nama_ttd: "Rian Adli, S.I.Kom",
            nip_ttd: "11474242142"
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon, Pasangan dan Pelapor"
          });
        }
        break;

      case "Surat Pernyataan Tidak Menikah":
        if (this.state.detailSurat.pendStat) {
          if (
            document.getElementById("status_perkawinan").value ==
              "Cerai hidup" ||
            document.getElementById("status_perkawinan").value == "Cerai mati"
          ) {
            addSuratKeterangan = {
              uuid: document.getElementById("uuid").value,
              id_jenis: this.state.detailSurat.id_jenis,
              nik: document.getElementById("nik").value,
              nama_pemohon: document.getElementById("nama_pemohon").value,
              tempat_lahir: document.getElementById("tempat_lahir").value,
              tanggal_lahir: document.getElementById("tanggal_lahir").value,
              pendidikan_terakhir: document.getElementById(
                "pendidikan_terakhir"
              ).value,
              pekerjaan: document.getElementById("pekerjaan").value,
              agama: document.getElementById("agama").value,
              status_perkawinan: document.getElementById("status_perkawinan")
                .value,
              alamat: document.getElementById("alamat").value,
              jenis_kelamin: document.getElementById("jenis_kelamin").value,
              kewarganegaraan: document.getElementById("kewarganegaraan").value,
              tujuan_pembuatan: "Surat Tidak Pernah Menikah",

              nomor_surat_akta: document.getElementById("nomor_surat_akta")
                .value,
              tanggal_surat_akta: this.state.detailSurat.tanggal_surat_akta,
              // tanggal_surat_akta: document.getElementById("tanggal_surat_akta").value,
              nama_saksi: document.getElementById("nama_saksi").value,
              hubungan_saksi: document.getElementById("hubungan_saksi").value
            };
          } else {
            this.setState({
              // showBio: false,
              show: true,
              basicType: "warning",
              basicTitle: "Data Permohonan Pernyataan Belum Menikah",
              pesanAlert: "Status Perkawinan Penduduk Belum Cerai"
            });
          }
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Pernyataan Kehilangan":
        if (this.state.detailSurat.pendStat) {
          let jmlAcara = this.state.detailSurat.jmlAcara;

          let kehilangan = [];
          for (let i = 1; i <= jmlAcara; i++) {
            let nama_kehilangan = document.getElementById("acara" + i).value;
            if (nama_kehilangan != "") {
              kehilangan.push(nama_kehilangan);
            }
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            lokasi: document.getElementById("lokasi").value,
            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,
            tujuan_pembuatan: kehilangan.toString()
          };
          console.log(addSuratKeterangan);
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Pernyataan Belum Menikah":
        if (this.state.detailSurat.pendStat) {
          let nama_saksi2 = "";
          let hubungan_saksi2 = "";

          if (this.state.detailSurat.formSaksi != "none") {
            nama_saksi2 = document.getElementById("nama_saksi2").value;
            hubungan_saksi2 = document.getElementById("hubungan_saksi2").value;
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,

            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: "Surat Belum Pernah Menikah",

            nama_saksi1: document.getElementById("nama_saksi1").value,
            hubungan_saksi1: document.getElementById("hubungan_saksi1").value,
            nama_saksi2: nama_saksi2,
            hubungan_saksi2: hubungan_saksi2
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Pengantar Pindah Nikah":
        if (
          this.state.detailSurat.pendStat &&
          this.state.detailSurat.pendStat2
        ) {
          //detail
          let formDetail = [
            {
              nama_item: "nama_pasangan",
              isi_item: document.getElementById("nama_pasangan").value
            },
            {
              nama_item: "nama_nasab_p1",
              isi_item: document.getElementById("nama_nasab_p1").value
            },
            {
              nama_item: "nama_nasab_p2",
              isi_item: document.getElementById("nama_nasab_p2").value
            },
            {
              nama_item: "jenis_kelamin_pasangan",
              isi_item: document.getElementById("jenis_kelamin_pasangan").value
            },
            {
              nama_item: "tempat_lahir_pasangan",
              isi_item: document.getElementById("tempat_lahir_pasangan").value
            },
            {
              nama_item: "tanggal_lahir_pasangan",
              isi_item: document.getElementById("tanggal_lahir_pasangan").value
            },
            {
              nama_item: "pekerjaan_pasangan",
              isi_item: document.getElementById("pekerjaan_pasangan").value
            },
            {
              nama_item: "agama_pasangan",
              isi_item: document.getElementById("agama_pasangan").value
            },
            {
              nama_item: "nik_pasangan",
              isi_item: document.getElementById("nik_pasangan").value
            },
            {
              nama_item: "alamat_pasangan",
              isi_item: document.getElementById("alamat_pasangan").value
            },
            {
              nama_item: "tempat_nikah",
              isi_item: document.getElementById("tempat_nikah").value
            }
          ];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,
            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: "Surat Pengantar Pindah Nikah",
            kk: document.getElementById("kk").value,
            detail: formDetail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon dan NIK Pasangan"
          });
        }
        break;

      case "Surat Keterangan Usaha":
        if (this.state.detailSurat.pendStat) {
          let detail = [];
          this.state.detailSurat.usaha.map((dt, idx) => {
            let jenis = document.getElementById("jenis_usaha" + idx).value;
            let umur = document.getElementById("umur" + idx).value;
            let lokasi = document.getElementById("lokasi" + idx).value;
            let luas = document.getElementById("luas_kebun" + idx).value;
            let merk = document.getElementById("merk" + idx).value;
            detail.push({
              jenis_usaha: jenis,
              umur: umur,
              lokasi: lokasi,
              luas_kebun: luas,
              merk: merk
            });
          });

          let frmDetail = [
            {
              nama_item: "usaha",
              isi_item: JSON.stringify(detail)
            }
          ];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,
            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: "Surat Keterangan Usaha",
            kk: document.getElementById("kk").value,

            detail: frmDetail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }

        break;

      case "Surat Keterangan Penghasilan":
        api = "penghasilan";
        if (this.state.dataNikStat.nikStat) {
          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            // nomor : document.getElementById("nomor").value,
            nik: this.state.dataNikStat.nikStat.value,
            nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: new Date(
              this.state.detailSurat.tanggal_lahir
            ).toISOString(),
            // tanggal_lahir: document.getElementById("tanggal_lahir").value,
            jenis_kelamin: this.state.detailSurat.jk,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            pendidikan_terakhir: this.state.detailSurat.pendidikan_terakhir,
            kewarganegaraan: this.state.detailSurat.kewarganegaraan,
            tujuan_pembuatan: this.state.detailSurat.tujuan_pembuatan,
            pekerjaan: this.state.detailSurat.pekerjaan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,
            penghasilan: document.getElementById("penghasilan").value,
            status_ttd: null
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Keterangan Kepemilikan Rumah":
        if (this.state.dataNikStat.nikStat) {
          let tgl_lhr = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir = this.state.detailSurat.tanggal_lahir;
          if (tgl_lhr.length > 1) {
            tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            // nomor : document.getElementById("nomor").value,
            nik: this.state.dataNikStat.nikStat.value,
            kk: this.state.detailSurat.kk,
            nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: tanggal_lahir,
            jenis_kelamin: this.state.detailSurat.jenis_kelamin,
            pekerjaan: this.state.detailSurat.pekerjaan,
            pendidikan_terakhir: this.state.detailSurat.pendidikan_terakhir,
            kewarganegaraan: this.state.detailSurat.kewarganegaraan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
            // tujuan_pembuatan : "-",
            status_ttd: null,
            jabatan_ttd: "Kepala Desa Sungai Petai",
            nama_ttd: "Rian Adli, S.I.Kom",
            nip_ttd: "11474242142"
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Pengantar SKCK":
        if (this.state.detailSurat.pendStat) {
          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,

            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Keterangan Domisili":
        if (this.state.dataNikStat.nikStat) {
          api = "domisili";

          let tgl_lhr = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir = this.state.detailSurat.tanggal_lahir;

          if (tgl_lhr.length > 1) {
            tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: this.state.dataNikStat.nikStat.value,
            kk: this.state.detailSurat.kk,
            nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: tanggal_lahir,
            jenis_kelamin: this.state.detailSurat.jenis_kelamin,
            pekerjaan: this.state.detailSurat.pekerjaan,
            pendidikan_terakhir: this.state.detailSurat.pendidikan_terakhir,
            kewarganegaraan: this.state.detailSurat.kewarganegaraan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
            detail: this.state.detailSurat.detail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Keterangan Belum Memiliki Rumah":
        if (this.state.detailSurat.pendStat) {
          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,

            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
            lokasi: document.getElementById("lokasi").value,
            kk: document.getElementById("kk").value
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Keterangan Ahli Waris":
        if (this.state.dataNikStat.nikStat) {
          api = "ahli-waris";

          let tgl_lhr = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir = this.state.detailSurat.tanggal_lahir;
          if (tgl_lhr.length > 1) {
            tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
          }

          let nik = this.state.dataNikStat.nikStat.value;
          let nama = this.state.detailSurat.nama;
          let tempat_lahir = this.state.detailSurat.tempat_lahir;
          let jenis_kelamin = this.state.detailSurat.jenis_kelamin;
          let pekerjaan = this.state.detailSurat.pekerjaan;
          let kewarganegaraan = this.state.detailSurat.kewarganegaraan;
          let agama = this.state.detailSurat.agama;
          let alamat = this.state.detailSurat.alamat;

          let waktu_meninggal =
            new Date(this.state.detailSurat.waktu_meninggal)
              .toISOString()
              .slice(0, 10) +
            " " +
            new Date(this.state.detailSurat.waktu_meninggal)
              .toLocaleTimeString("id-ID")
              .replace(/\./g, ":");

          let tempat_meninggal = document.getElementById("tempat_meninggal")
            .value;
          let sebab_meninggal = document.getElementById("sebab_meninggal")
            .value;

          if (this.state.dataNikStat.nik2Stat) {
            let tanggal_lahir2 = "";
            if (this.state.detailSurat.tanggal_lahir2) {
              let tgl_lhr_pl = this.state.detailSurat.tanggal_lahir2.split("/");
              tanggal_lahir2 = this.state.detailSurat.tanggal_lahir2;
              if (tgl_lhr_pl.length > 1) {
                tanggal_lahir2 =
                  tgl_lhr_pl[2] + "-" + tgl_lhr_pl[1] + "-" + tgl_lhr_pl[0];
              }
            }

            if (this.state.detailSurat.waktu_meninggal2) {
              waktu_meninggal +=
                "," +
                new Date(this.state.detailSurat.waktu_meninggal2)
                  .toISOString()
                  .slice(0, 10) +
                " " +
                new Date(this.state.detailSurat.waktu_meninggal2)
                  .toLocaleTimeString("id-ID")
                  .replace(/\./g, ":");
            }

            nik += "," + this.state.dataNikStat.nik2Stat.value;
            if (this.state.detailSurat.nama2) {
              nama += "," + this.state.detailSurat.nama2;
            }
            if (this.state.detailSurat.jenis_kelamin) {
              jenis_kelamin += "," + this.state.detailSurat.jenis_kelamin2;
            }
            if (this.state.detailSurat.tempat_lahir2) {
              tempat_lahir += "," + this.state.detailSurat.tempat_lahir2;
            }
            if (this.state.detailSurat.tanggal_lahir2) {
              tanggal_lahir += "," + tanggal_lahir2;
            }
            if (this.state.detailSurat.jenis_kelamin2) {
              jenis_kelamin += "," + this.state.detailSurat.jenis_kelamin2;
            }
            if (this.state.detailSurat.pekerjaan2) {
              pekerjaan += "," + this.state.detailSurat.pekerjaan2;
            }
            if (this.state.detailSurat.kewarganegaraan2) {
              kewarganegaraan += "," + this.state.detailSurat.kewarganegaraan2;
            }
            if (this.state.detailSurat.agama2) {
              agama += "," + this.state.detailSurat.agama2;
            }
            if (this.state.detailSurat.alamat2) {
              alamat += "," + this.state.detailSurat.alamat2;
            }

            if (document.getElementById("tempat_meninggal2").value) {
              tempat_meninggal +=
                "," + document.getElementById("tempat_meninggal2").value;
            }
            if (document.getElementById("sebab_meninggal2").value) {
              sebab_meninggal +=
                "," + document.getElementById("sebab_meninggal2").value;
            }
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: nik,
            nama: nama,
            tempat_lahir: tempat_lahir,
            tanggal_lahir: tanggal_lahir,
            jenis_kelamin: jenis_kelamin,
            pekerjaan: pekerjaan,
            kewarganegaraan: kewarganegaraan,
            agama: agama,
            alamat: alamat,
            waktu_meninggal: waktu_meninggal,
            tempat_meninggal: tempat_meninggal,
            sebab_meninggal: sebab_meninggal,

            detail: this.state.detailSurat.detail
          };
          console.log(addSuratKeterangan);
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Keterangan":
        if (
          this.state.dataNikStat.nikStat &&
          this.state.detailSurat.detail.length > 0
        ) {
          let tgl_lhr = this.state.detailSurat.tanggal_lahir.split("/");
          let tanggal_lahir = this.state.detailSurat.tanggal_lahir;

          if (tgl_lhr.length > 1) {
            tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
          }

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            // nomor : document.getElementById("nomor").value,
            nik: this.state.dataNikStat.nikStat.value,
            kk: this.state.detailSurat.kk,
            nama_pemohon: this.state.detailSurat.nama_pemohon,
            tempat_lahir: this.state.detailSurat.tempat_lahir,
            tanggal_lahir: tanggal_lahir,
            jenis_kelamin: this.state.detailSurat.jenis_kelamin,
            pekerjaan: this.state.detailSurat.pekerjaan,
            pendidikan_terakhir: this.state.detailSurat.pendidikan_terakhir,
            kewarganegaraan: this.state.detailSurat.kewarganegaraan,
            agama: this.state.detailSurat.agama,
            alamat: this.state.detailSurat.alamat,
            status_perkawinan: this.state.detailSurat.status_perkawinan,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
            lokasi: document.getElementById("lokasi").value,
            status_ttd: null,
            jabatan_ttd: "Kepala Desa Sungai Petai",
            nama_ttd: "Rian Adli, S.I.Kom",
            nip_ttd: "11474242142",
            detail: this.state.detailSurat.detail
          };
          console.log(addSuratKeterangan);
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon isi data dengan lengkap"
          });
        }
        break;

      case "Surat Izin Keramaian":
        if (this.state.detailSurat.pendStat) {
          let formDetail = [
            {
              nama_item: "tgl_mulai",
              isi_item: this.state.detailSurat.tanggal_mulai
            },
            {
              nama_item: "tgl_selesai",
              isi_item: this.state.detailSurat.tanggal_selesai
            },
            {
              nama_item: "tempat",
              isi_item: document.getElementById("tempat").value
            },
            {
              nama_item: "hiburan",
              isi_item: document.getElementById("hiburan").value
            }
          ];

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama_pemohon: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
              .value,
            pekerjaan: document.getElementById("pekerjaan").value,
            agama: document.getElementById("agama").value,
            status_perkawinan: document.getElementById("status_perkawinan")
              .value,
            alamat: document.getElementById("alamat").value,
            jenis_kelamin: document.getElementById("jenis_kelamin").value,
            kewarganegaraan: document.getElementById("kewarganegaraan").value,
            tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
            kk: document.getElementById("kk").value,

            detail: formDetail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert: "Mohon pilih data NIK Pemohon"
          });
        }
        break;

      case "Surat Kuasa":
        if (
          this.state.detailSurat.pendStat &&
          this.state.detailSurat.pendStat2
        ) {
          api = "kuasa";

          let detail = [];
          this.state.detailSurat.usaha.map((dt, idx) => {
            let nama_rincian = document.getElementById("nama_rincian" + idx)
              .value;
            let isi_rincian = document.getElementById("isi_rincian" + idx)
              .value;
            detail.push({ nama_item: nama_rincian, isi_item: isi_rincian });
          });

          addSuratKeterangan = {
            uuid: document.getElementById("uuid").value,
            id_jenis: this.state.detailSurat.id_jenis,
            nik: document.getElementById("nik").value,
            nama: document.getElementById("nama_pemohon").value,
            tempat_lahir: document.getElementById("tempat_lahir").value,
            tanggal_lahir: document.getElementById("tanggal_lahir").value,
            pekerjaan: document.getElementById("pekerjaan").value,
            alamat: document.getElementById("alamat").value,

            nik2: document.getElementById("nik2").value,
            nama2: document.getElementById("nama2").value,
            tempat_lahir2: document.getElementById("tempat_lahir2").value,
            tanggal_lahir2: document.getElementById("tanggal_lahir2").value,
            pekerjaan2: document.getElementById("pekerjaan2").value,
            alamat2: document.getElementById("alamat2").value,

            kuasa_atas: document.getElementById("kuasa_atas").value,
            kuasa_untuk: document.getElementById("kuasa_untuk").value,
            tujuan_kuasa: document.getElementById("tujuan_kuasa").value,

            detail: detail
          };
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Data Belum Lengkap",
            pesanAlert:
              "Mohon pilih data NIK Pemberi Kuasa dan NIK Penerima Kuasa"
          });
        }
        break;

      case "Surat Undangan":
        kop = "surat-undangan";
        let acara = [];
        for (let i = 1; i <= this.state.detailSurat.jmlAcara; i++) {
          let nama_acara = document.getElementById("acara" + i).value;
          if (nama_acara != "") {
            acara.push({ nama: nama_acara });
          }
        }

        addSuratKeterangan = {
          uuid: document.getElementById("uuid").value,
          id_jenis: this.state.detailSurat.id_jenis,
          sifat: document.getElementById("sifat").value,
          perihal: document.getElementById("perihal").value,
          kepada: document.getElementById("kepada").value,
          // nomor : document.getElementById("nomor").value,
          waktu:
            new Date(this.state.detailSurat.tanggal_surat)
              .toISOString()
              .slice(0, 10) +
            " " +
            this.state.detailSurat.jamStat.value,
          tempat: document.getElementById("tempat").value,
          acara: JSON.stringify(acara),
          id_pengaju: this.state.detailSurat.id_pengaju
        };
        console.log(addSuratKeterangan);
        break;

      default:
        break;
    }

    if (addSuratKeterangan != null) {
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeterangan.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeterangan.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }

      let address = "surat-keterangan/" + api + "/" + metode;

      switch (jenis_surat) {
        case "Surat Keterangan Jalan":
          address = "surat-keterangan/" + api + "/" + metode + "/with-detail";
          break;

        case "Surat Pengantar Pindah Nikah":
          address = "surat-keterangan/" + api + "/" + metode + "/with-detail";
          break;

        case "Surat Keterangan Usaha":
          address = "surat-keterangan/" + api + "/" + metode + "/with-detail";
          break;

        case "Surat Keterangan":
          address = "surat-keterangan/" + api + "/" + metode + "/with-detail";
          break;

        case "Surat Izin Keramaian":
          address = "surat-keterangan/" + api + "/" + metode + "/with-detail";
          break;

        case "Surat Pernyataan Tidak Menikah":
          address = "surat-keterangan/" + api + "/" + metode + "/tidak-menikah";
          break;

        case "Surat Pernyataan Belum Menikah":
          address = "surat-keterangan/" + api + "/" + metode + "/belum-menikah";
          break;

        case "Surat Undangan":
          address = "surat-undangan/" + metode;
          break;

        default:
          break;
      }

      // let address = 'surat-keterangan/' + api + '/' + metode;
      // if (jenis_surat == 'Surat Keterangan Jalan') {
      // 	address = 'surat-keterangan/' + api + '/' + metode + '/with-detail';
      // } else if (jenis_surat == 'Surat Pernyataan Tidak Menikah') {
      // 	address = 'surat-keterangan/' + api + '/' + metode + '/tidak-menikah';
      // }

      Post(address, addSuratKeterangan.uuid, addSuratKeterangan, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Berhasil " + psn + " " + jenis_surat,
            pesanAlert: ""
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Gagal " + psn + " " + jenis_surat,
            pesanAlert: ""
          });
        }
        this.componentDidMount();
      });
      this.tutupForm();
    }
  };

  updateSurat = (e, data) => {
    e.preventDefault();
    // reset detail surat
    this.state.detailSurat = [];

    let jenis_surat = "";
    let kop_surat = "surat-keterangan";
    const label_surat = data.label;

    switch (label_surat) {
      case "Surat Keterangan Tidak Mampu":
        jenis_surat = "tidak-mampu";
        break;
      case "Surat Keterangan Domisili":
        jenis_surat = "domisili";
        break;
      case "Surat Keterangan Penghasilan":
        jenis_surat = "penghasilan";
        break;
      case "Surat Keterangan Kematian":
        jenis_surat = "kematian";
        break;
      case "Surat Keterangan Pernah Menikah":
        jenis_surat = "pernah-menikah";
        break;
      case "Surat Keterangan Kelahiran":
        jenis_surat = "kelahiran";
        break;
      case "Surat Kuasa SKGR":
        jenis_surat = "kuasa";
        break;
      case "Surat Keterangan Ahli Waris":
        jenis_surat = "ahli-waris";
        break;
      case "Surat Kuasa":
        jenis_surat = "kuasa";
        break;

      case "Surat Undangan":
        kop_surat = "surat-undangan";
        break;

      default:
        jenis_surat = "grup";
        break;
    }

    Get(
      kop_surat != "surat-undangan"
        ? kop_surat + "/" + jenis_surat + "/find"
        : kop_surat + "/find",
      data.uuid_surat,
      data => {
        console.log(data.results);
        if (typeof data.results != "undefined") {
          this.state.detailSurat.id_jenis = data.results.id_jenis;
          this.state.detailSurat.jenis_surat = label_surat;
          this.state.detailSurat.kk = data.results.kk;
          this.state.detailSurat.nama_desa = "Desa Sungai Petai";
          this.state.detailSurat.nik = data.results.nik;
          this.state.detailSurat.nomor = data.results.nomor;
          this.state.detailSurat.tanggal_surat = data.results.tanggal_surat;
          this.state.detailSurat.uuid = data.results.uuid;

          // Cek undefined umum
          this.state.detailSurat.agama =
            typeof data.results.agama != "undefined" ? data.results.agama : "";
          this.state.detailSurat.alamat =
            typeof data.results.alamat != "undefined"
              ? data.results.alamat
              : "";
          this.state.detailSurat.jenis_kelamin =
            typeof data.results.jenis_kelamin != "undefined"
              ? data.results.jenis_kelamin
              : "";
          this.state.detailSurat.jk =
            typeof data.results.jenis_kelamin != "undefined"
              ? data.results.jenis_kelamin
              : "";
          this.state.detailSurat.kewarganegaraan =
            typeof data.results.kewarganegaraan != "undefined"
              ? data.results.kewarganegaraan
              : "";
          this.state.detailSurat.nama =
            typeof data.results.nama != "undefined" ? data.results.nama : "";
          this.state.detailSurat.nama_pemohon =
            typeof data.results.nama_pemohon != "undefined"
              ? data.results.nama_pemohon
              : "";
          this.state.detailSurat.pekerjaan =
            typeof data.results.pekerjaan != "undefined"
              ? data.results.pekerjaan
              : "";
          // this.state.detailSurat.pekerjaan = (typeof data.results.data_pekerjaan != 'undefined') ? data.results.data_pekerjaan.nama : '';
          this.state.detailSurat.pendidikan_terakhir =
            typeof data.results.pendidikan_terakhir != "undefined"
              ? data.results.pendidikan_terakhir
              : "";
          this.state.detailSurat.status_perkawinan =
            typeof data.results.status_perkawinan != "undefined"
              ? data.results.status_perkawinan
              : "";
          this.state.detailSurat.tanggal_lahir =
            typeof data.results.tanggal_lahir != "undefined"
              ? data.results.tanggal_lahir
              : "";
          this.state.detailSurat.tempat_lahir =
            typeof data.results.tempat_lahir != "undefined"
              ? data.results.tempat_lahir
              : "";

          // Cek undefined khusus
          this.state.detailSurat.tujuan_pembuatan =
            typeof data.results.tujuan_pembuatan != "undefined"
              ? data.results.tujuan_pembuatan
              : "";
          this.state.detailSurat.lokasi =
            typeof data.results.lokasi != "undefined"
              ? data.results.lokasi
              : "";
          this.state.detailSurat.nomor_surat =
            typeof data.results.nomor_surat != "undefined"
              ? data.results.nomor_surat
              : "";

          this.state.detailSurat.jabatan_pengganti_ttd =
            data.results.jabatan_pengganti_ttd;
          this.state.detailSurat.jabatan_ttd = data.results.jabatan_ttd;
          this.state.detailSurat.nama_ttd = data.results.nama_ttd;
          this.state.detailSurat.nip_ttd = data.results.nip_ttd;
          this.state.detailSurat.status_ttd = data.results.status_ttd;

          if (typeof data.results.status_ttd2 != "undefined") {
            this.state.detailSurat.status_ttd2 = data.results.status_ttd2;
            this.state.detailSurat.jabatan_ttd2 = data.results.jabatan_ttd2;
            this.state.detailSurat.jabatan_pengganti_ttd2 =
              data.results.jabatan_pengganti_ttd2;
            this.state.detailSurat.nama_ttd2 = data.results.nama_ttd2;
            this.state.detailSurat.nip_ttd2 = data.results.nip_ttd2;
          }

          // Reset nikStat & pendStat
          this.state.dataNikStat = [];
          this.state.detailSurat.pendStat = [];
          this.state.detailSurat.pendStat2 = [];

          switch (label_surat) {
            case "Surat Keterangan Kelahiran":
              this.state.judul = "Update Detail Surat";
              this.state.detailSurat.nama_anak = data.results.nama_anak;
              this.state.detailSurat.tanggal_lahir_anak = new Date(
                data.results.tanggal_lahir_anak
              );
              this.state.detailSurat.tempat_lahir_anak =
                data.results.tempat_lahir_anak;
              this.state.detailSurat.jk_anak = data.results.jk_anak;

              this.state.detailSurat.nik_ayah = data.results.nik_ayah;
              this.state.detailSurat.nama_ayah = data.results.nama_ayah;
              this.state.detailSurat.tempat_lahir_ayah =
                data.results.tempat_lahir_ayah;
              this.state.detailSurat.tanggal_lahir_ayah = new Date(
                data.results.tanggal_lahir_ayah
              ).toLocaleDateString("id-ID");
              this.state.detailSurat.pekerjaan_ayah =
                data.results.pekerjaan_ayah;
              this.state.detailSurat.alamat_ayah = data.results.alamat_ayah;

              this.state.detailSurat.nik_ibu = data.results.nik_ibu;
              this.state.detailSurat.nama_ibu = data.results.nama_ibu;
              this.state.detailSurat.tempat_lahir_ibu =
                data.results.tempat_lahir_ibu;
              this.state.detailSurat.tanggal_lahir_ibu = new Date(
                data.results.tanggal_lahir_ibu
              ).toLocaleDateString("id-ID");
              this.state.detailSurat.pekerjaan_ibu = data.results.pekerjaan_ibu;
              this.state.detailSurat.alamat_ibu = data.results.alamat_ibu;

              this.state.detailSurat.nik_pelapor = data.results.nik_pelapor;
              this.state.detailSurat.nama_pelapor = data.results.nama_pelapor;
              this.state.detailSurat.tanggal_lahir_pelapor = new Date(
                data.results.tanggal_lahir_pelapor
              ).toLocaleDateString("id-ID");
              this.state.detailSurat.tempat_lahir_pelapor =
                data.results.tempat_lahir_pelapor;
              this.state.detailSurat.jk_pelapor =
                data.results.jenis_kelamin_pelapor;
              this.state.detailSurat.status_perkawinan_pelapor =
                data.results.status_perkawinan_pelapor;
              this.state.detailSurat.agama_pelapor = data.results.agama_pelapor;
              this.state.detailSurat.alamat_pelapor =
                data.results.alamat_pelapor;
              this.state.detailSurat.pekerjaan_pelapor =
                data.results.pekerjaan_pelapor;
              this.state.detailSurat.hubungan_pelapor =
                data.results.hubungan_pelapor;

              // For Update Surat
              this.state.detailSurat.status_ttd = data.results.status_ttd;
              this.state.detailSurat.jabatan_ttd = data.results.jabatan_ttd;
              this.state.detailSurat.jabatan_pengganti_ttd =
                data.results.jabatan_pengganti_ttd;
              this.state.detailSurat.nama_ttd = data.results.nama_ttd;
              this.state.detailSurat.nip_ttd = data.results.nip_ttd;

              this.state.nikStat = {
                value: data.results.nik_ayah,
                label: data.results.nik_ayah
              };
              this.state.nik2Stat = {
                value: data.results.nik_pelapor,
                label: data.results.nik_pelapor
              };
              this.state.nik3Stat = {
                value: data.results.nik_ibu,
                label: data.results.nik_ibu
              };

              this.state.status.dataPenduduk = "block";
              this.state.status.dataPenduduk2 = "block";
              this.state.status.dataPenduduk3 = "block";

              this.state.dataNikStat.nikStat = {
                value: data.results.nik_ayah,
                label: data.results.nik_ayah
              };
              this.state.dataNikStat.nik2Stat = {
                value: data.results.nik_pelapor,
                label: data.results.nik_pelapor
              };
              this.state.dataNikStat.nik3Stat = {
                value: data.results.nik_ibu,
                label: data.results.nik_ibu
              };

              // this.setState({ dataNikStat });
              Get("penduduk", null, data => {
                let frmNik = [];
                let frmNik2 = [];
                let frmNik3 = [];
                data.results.data.map(dt => {
                  frmNik.push({ value: dt.id, label: dt.id });
                  frmNik2.push({ value: dt.id, label: dt.id });
                  frmNik3.push({ value: dt.id, label: dt.id });
                });

                // let dataNik = { frmNik: frmNik, frmNik2: frmNik2, frmNik3: frmNik3 };
                this.state.dataNik.frmNik = frmNik;
                this.state.dataNik.frmNik2 = frmNik2;
                this.state.dataNik.frmNik3 = frmNik3;
              });
              break;

            case "Surat Keterangan Jalan":
              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.showBio = true;

              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "rencana_berangkat") {
                  this.state.detailSurat.rencana_berangkat = new Date(
                    dt.isi_item
                  );
                }
                if (dt.nama_item == "barang") {
                  this.state.detailSurat.barang = dt.isi_item;
                }
                if (dt.nama_item == "tempat_lahir_supir") {
                  this.state.detailSurat.tempat_lahir_supir = dt.isi_item;
                }
                if (dt.nama_item == "tanggal_lahir_supir") {
                  this.state.detailSurat.tanggal_lahir_supir = new Date(
                    dt.isi_item
                  );
                }
                if (dt.nama_item == "nama_supir") {
                  this.state.detailSurat.nama_supir = dt.isi_item;
                }
                if (dt.nama_item == "no_pol") {
                  this.state.detailSurat.no_pol = dt.isi_item.toUpperCase();
                }
                if (dt.nama_item == "transportasi") {
                  this.state.detailSurat.transportasi = dt.isi_item;
                }
                if (dt.nama_item == "pengikut") {
                  this.state.detailSurat.pengikut = dt.isi_item;
                }
                if (dt.nama_item == "tujuan_perjalanan") {
                  this.state.detailSurat.tujuan_perjalanan = dt.isi_item;
                }
              });
              break;

            case "Surat Keterangan Kematian":
              this.state.detailSurat.waktu_meninggal = new Date(
                data.results.waktu_meninggal
              );
              // this.state.detailSurat.waktu_meninggal = new Date(data.results.waktu_meninggal);
              this.state.detailSurat.tempat_meninggal = data.results.tempat_meninggal.toLowerCase();
              this.state.detailSurat.sebab_meninggal = data.results.sebab_meninggal.toLowerCase();
              this.state.detailSurat.tempat_makam = data.results.tempat_makam;

              this.state.detailSurat.nik_pelapor = data.results.nik_pelapor;
              this.state.detailSurat.nama_pelapor = data.results.nama_pelapor;
              this.state.detailSurat.tanggal_lahir_pelapor = new Date(
                data.results.tanggal_lahir_pelapor
              ).toLocaleDateString("id-ID");
              this.state.detailSurat.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
              this.state.detailSurat.jk_pelapor =
                data.results.jenis_kelamin_pelapor;

              // this.state.detailSurat.status_perkawinan_pelapor = data.results.status_perkawinan_pelapor.toLowerCase();
              this.state.detailSurat.agama_pelapor = data.results.agama_pelapor.toLowerCase();
              this.state.detailSurat.alamat_pelapor =
                data.results.alamat_pelapor;
              this.state.detailSurat.pekerjaan_pelapor = data.results.pekerjaan_pelapor.toLowerCase();

              this.state.detailSurat.hubungan_pelapor = data.results.hubungan_pelapor.toLowerCase();

              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.dataNikStat.nik2Stat = {
                value: data.results.nik_pelapor,
                label: data.results.nik_pelapor
              };
              this.state.status.dataPenduduk = "block";
              this.state.status.dataPenduduk2 = "block";
              break;

            case "Surat Kuasa SKGR":
              this.state.detailSurat.kepada = data.results.kepada;

              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "tanggal_skgr") {
                  this.state.detailSurat.tanggal_skgr = new Date(dt.isi_item);
                }
                if (dt.nama_item == "atas_nama") {
                  this.state.detailSurat.atas_nama = dt.isi_item;
                }
                if (dt.nama_item == "luas_tanah") {
                  this.state.detailSurat.luas_tanah = dt.isi_item;
                }
                if (dt.nama_item == "no_reg") {
                  this.state.detailSurat.no_reg = dt.isi_item;
                }
                if (dt.nama_item == "lokasi_tanah") {
                  this.state.detailSurat.lokasi_tanah = dt.isi_item;
                }
              });

              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.detailSurat.pendStat2 = {
                value: data.results.nik2,
                label: data.results.nik2
              };

              this.state.detailSurat.nik2 = data.results.nik2;
              this.state.detailSurat.nama2 = data.results.nama2;
              this.state.detailSurat.kepada2 = data.results.kepada2;
              this.state.detailSurat.tempat_lahir2 = data.results.tempat_lahir2;
              this.state.detailSurat.tanggal_lahir2 =
                data.results.tanggal_lahir2;
              this.state.detailSurat.pekerjaan2 = data.results.pekerjaan2;
              this.state.detailSurat.alamat2 = data.results.alamat2;

              this.state.detailSurat.kuasa_atas = data.results.kuasa_atas;
              this.state.detailSurat.kuasa_untuk = data.results.kuasa_untuk;
              this.state.detailSurat.tujuan_kuasa = data.results.tujuan_kuasa;

              this.state.status.showBio = true;
              this.state.status.showBio2 = true;
              break;

            case "Surat Keterangan Tidak Mampu":
              this.state.detailSurat.shdk = "";
              this.state.detailSurat.detail =
                data.results.data_detail_tidak_mampu;
              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.dataPenduduk = "block";
              break;

            case "Surat Keterangan Pernah Menikah":
              this.state.detailSurat.agama_pasangan =
                data.results.agama_pasangan;
              this.state.detailSurat.alamat_pasangan =
                data.results.alamat_pasangan;
              this.state.detailSurat.jk_pasangan =
                data.results.jenis_kelamin_pasangan;
              this.state.detailSurat.nama_pasangan = data.results.nama_pasangan;
              this.state.detailSurat.nik_pasangan = data.results.nik_pasangan;
              this.state.detailSurat.pekerjaan_pasangan =
                data.results.pekerjaan_pasangan;
              this.state.detailSurat.status_perkawinan_pasangan =
                data.results.status_perkawinan_pasangan;
              this.state.detailSurat.status_perkawinan_pasangan =
                data.results.status_perkawinan_pasangan;
              this.state.detailSurat.tanggal_lahir_pasangan = new Date(
                data.results.tanggal_lahir_pasangan
              ).toLocaleDateString("id-ID");
              this.state.detailSurat.tempat_lahir_pasangan =
                data.results.tempat_lahir_pasangan;

              this.state.detailSurat.mas_kawin = data.results.mas_kawin;
              this.state.detailSurat.petugas_nikah = data.results.petugas_nikah;
              this.state.detailSurat.saksi_nikah = data.results.saksi_nikah;
              this.state.detailSurat.tanggal_menikah =
                data.results.tanggal_menikah;
              this.state.detailSurat.tempat_menikah =
                data.results.tempat_menikah;
              this.state.detailSurat.wali_nikah = data.results.wali_nikah;
              this.state.detailSurat.tanggal_menikah = new Date(
                data.results.tanggal_menikah
              );

              this.state.detailSurat.agama_pelapor = data.results.agama_pelapor;
              this.state.detailSurat.alamat_pelapor =
                data.results.alamat_pelapor;
              this.state.detailSurat.jk_pelapor =
                data.results.jenis_kelamin_pelapor;
              this.state.detailSurat.nama_pelapor = data.results.nama_pelapor;
              this.state.detailSurat.nik_pelapor = data.results.nik_pelapor;
              this.state.detailSurat.pekerjaan_pelapor =
                data.results.pekerjaan_pelapor;
              this.state.detailSurat.status_perkawinan_pelapor =
                data.results.status_perkawinan_pelapor;
              this.state.detailSurat.status_perkawinan_pelapor =
                data.results.status_perkawinan_pelapor;
              this.state.detailSurat.tanggal_lahir_pelapor = new Date(
                data.results.tanggal_lahir_pelapor
              ).toLocaleDateString("id-ID");
              this.state.detailSurat.tempat_lahir_pelapor =
                data.results.tempat_lahir_pelapor;

              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.dataNikStat.nik2Stat = {
                value: data.results.nik_pelapor,
                label: data.results.nik_pelapor
              };
              this.state.dataNikStat.nik3Stat = {
                value: data.results.nik_pasangan,
                label: data.results.nik_pasangan
              };
              this.state.status.dataPenduduk = "block";
              this.state.status.dataPenduduk2 = "block";
              this.state.status.dataPenduduk3 = "block";
              break;

            case "Surat Pernyataan Tidak Menikah":
              let nomor_surat_akta;
              let tanggal_surat_akta;
              let nama_saksi;
              let hubungan_saksi;
              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "nomor_surat_akta") {
                  nomor_surat_akta = dt.isi_item;
                }
                if (dt.nama_item == "tanggal_surat_akta") {
                  tanggal_surat_akta = dt.isi_item;
                }
                if (dt.nama_item == "nama_saksi") {
                  nama_saksi = dt.isi_item;
                }
                if (dt.nama_item == "hubungan_saksi") {
                  hubungan_saksi = dt.isi_item;
                }
              });

              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };

              this.state.detailSurat.nomor_surat_akta = nomor_surat_akta;
              this.state.detailSurat.nama_saksi = nama_saksi;
              this.state.detailSurat.hubungan_saksi = hubungan_saksi;
              this.state.detailSurat.tanggal_surat_akta = new Date(
                tanggal_surat_akta
              );

              this.state.status.showBio = true;

              break;

            case "Surat Pernyataan Kehilangan":
              let acara = [];
              let tujuan = data.results.tujuan_pembuatan.split(",");
              tujuan.map(dt => {
                acara.push({ nama: dt });
              });

              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.detailSurat.acara = acara;
              this.state.status.showBio = true;
              break;

            case "Surat Pernyataan Belum Menikah":
              let nama_saksi1;
              let hubungan_saksi1;
              let nama_saksi2;
              let hubungan_saksi2;
              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "nama_saksi2") {
                  nama_saksi2 = dt.isi_item;
                }
                if (dt.nama_item == "hubungan_saksi2") {
                  hubungan_saksi2 = dt.isi_item;
                }
                if (dt.nama_item == "nama_saksi1") {
                  nama_saksi1 = dt.isi_item;
                }
                if (dt.nama_item == "hubungan_saksi1") {
                  hubungan_saksi1 = dt.isi_item;
                }
              });

              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };

              this.state.detailSurat.nama_saksi2 = nama_saksi2;
              this.state.detailSurat.hubungan_saksi2 = hubungan_saksi2;
              this.state.detailSurat.nama_saksi1 = nama_saksi1;
              this.state.detailSurat.hubungan_saksi1 = hubungan_saksi1;

              if (hubungan_saksi2 == "") {
                this.state.detailSurat.formSaksi = "none";
              } else {
                this.state.detailSurat.formSaksi = "";
              }

              this.state.status.showBio = true;
              break;

            case "Surat Pengantar Pindah Nikah":
              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "nama_nasab_p1") {
                  this.state.detailSurat.nama_nasab_p1 = dt.isi_item;
                }

                if (dt.nama_item == "nama_nasab_p2") {
                  this.state.detailSurat.nama_nasab_p2 = dt.isi_item;
                }
                if (dt.nama_item == "nama_pasangan") {
                  this.state.detailSurat.nama_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "jenis_kelamin_pasangan") {
                  this.state.detailSurat.jenis_kelamin_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "tempat_lahir_pasangan") {
                  this.state.detailSurat.tempat_lahir_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "tanggal_lahir_pasangan") {
                  this.state.detailSurat.tanggal_lahir_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "pekerjaan_pasangan") {
                  this.state.detailSurat.pekerjaan_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "agama_pasangan") {
                  this.state.detailSurat.agama_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "nik_pasangan") {
                  this.state.detailSurat.nik_pasangan = dt.isi_item;
                  this.state.detailSurat.pendStat2 = {
                    value: dt.isi_item,
                    label: dt.isi_item
                  };
                }
                if (dt.nama_item == "alamat_pasangan") {
                  this.state.detailSurat.alamat_pasangan = dt.isi_item;
                }
                if (dt.nama_item == "tempat_nikah") {
                  this.state.detailSurat.tempat_nikah = dt.isi_item;
                }
                this.forceUpdate();
              });

              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };

              this.state.status.showBio2 = true;
              this.state.status.showBio = true;
              break;

            case "Surat Keterangan Usaha":
              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "usaha") {
                  this.state.detailSurat.usaha = JSON.parse(dt.isi_item);
                }
              });
              console.log(this.state.detailSurat.usaha);
              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.showBio = true;
              break;

            case "Surat Keterangan Penghasilan":
              this.state.detailSurat.penghasilan = data.results.penghasilan;
              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.dataPenduduk = "block";
              break;

            case "Surat Keterangan Kepemilikan Rumah":
              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.dataPenduduk = "block";
              break;

            case "Surat Pengantar SKCK":
              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.showBio = true;
              this.state.status.btnForm = false;
              break;

            case "Surat Keterangan Domisili":
              this.state.detailSurat.detail = data.results.data_detail_domisili;
              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.dataPenduduk = "block";
              break;

            case "Surat Keterangan Belum Memiliki Rumah":
              this.state.detailSurat.lokasi = data.results.lokasi;
              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.showBio = true;
              this.state.status.btnForm = false;
              break;

            case "Surat Keterangan Ahli Waris":
              let cek = data.results.nik.split(",");
              this.state.status.btnForm = false;

              if (cek.length == 1) {
                this.state.detailSurat.nik = data.results.nik;
                this.state.detailSurat.nama = data.results.nama;
                this.state.detailSurat.tanggal_lahir = new Date(
                  data.results.tanggal_lahir
                ).toLocaleDateString("id-ID");
                this.state.detailSurat.tempat_lahir = data.results.tempat_lahir;
                this.state.detailSurat.alamat = data.results.alamat;
                this.state.detailSurat.jenis_kelamin =
                  data.results.jenis_kelamin;
                this.state.detailSurat.pekerjaan = data.results.pekerjaan;

                this.state.detailSurat.waktu_meninggal =
                  data.results.waktu_meninggal;
                this.state.detailSurat.waktu_meninggal = new Date(
                  data.results.waktu_meninggal
                );
                this.state.detailSurat.tempat_meninggal =
                  data.results.tempat_meninggal;
                this.state.detailSurat.sebab_meninggal =
                  data.results.sebab_meninggal;

                this.state.dataNikStat.nikStat = {
                  value: data.results.nik,
                  label: data.results.nik
                };
                this.state.status.dataPenduduk = "block";
                this.state.detailSurat.meninggal2 = "none";
              } else {
                let nama = data.results.nama.split(",");
                this.state.detailSurat.nama = nama[0];
                this.state.detailSurat.nama2 = nama[1];

                let tanggal_lahir = data.results.tanggal_lahir.split(",");
                this.state.detailSurat.tanggal_lahir = new Date(
                  tanggal_lahir[0]
                ).toLocaleDateString("id-ID");
                this.state.detailSurat.tanggal_lahir2 = new Date(
                  tanggal_lahir[1]
                ).toLocaleDateString("id-ID");

                let tempat_lahir = data.results.tempat_lahir.split(",");
                this.state.detailSurat.tempat_lahir = tempat_lahir[0];
                this.state.detailSurat.tempat_lahir2 = tempat_lahir[1];

                let alamat = data.results.alamat.split(",");
                this.state.detailSurat.alamat = alamat[0];
                this.state.detailSurat.alamat2 = alamat[1];

                let jenis_kelamin = data.results.alamat.split(",");
                this.state.detailSurat.jenis_kelamin = jenis_kelamin[0];
                this.state.detailSurat.jenis_kelamin2 = jenis_kelamin[1];

                let pekerjaan = data.results.pekerjaan.split(",");
                this.state.detailSurat.pekerjaan = pekerjaan[0];
                this.state.detailSurat.pekerjaan2 = pekerjaan[1];

                let kewarganegaraan = data.results.kewarganegaraan.split(",");
                this.state.detailSurat.kewarganegaraan = kewarganegaraan[0];
                this.state.detailSurat.kewarganegaraan2 = kewarganegaraan[1];

                let waktu_meninggal = data.results.waktu_meninggal.split(",");
                this.state.detailSurat.waktu_meninggal = waktu_meninggal[0];
                this.state.detailSurat.waktu_meninggal2 = waktu_meninggal[1];
                this.state.detailSurat.waktu_meninggal = new Date(
                  waktu_meninggal[0]
                );
                this.state.detailSurat.waktu_meninggal2 = new Date(
                  waktu_meninggal[1]
                );

                let tempat_meninggal = data.results.tempat_meninggal.split(",");
                this.state.detailSurat.tempat_meninggal = tempat_meninggal[0];
                this.state.detailSurat.tempat_meninggal2 = tempat_meninggal[1];

                let sebab_meninggal = data.results.sebab_meninggal.split(",");
                this.state.detailSurat.sebab_meninggal = sebab_meninggal[0];
                this.state.detailSurat.sebab_meninggal2 = sebab_meninggal[1];

                this.state.dataNikStat.nikStat = {
                  value: cek[0],
                  label: cek[0]
                };
                this.state.status.dataPenduduk = "block";
                this.state.dataNikStat.nik2Stat = {
                  value: cek[1],
                  label: cek[1]
                };
                this.state.status.dataPenduduk2 = "block";

                this.state.detailSurat.meninggal2 = "";
              }

              this.state.detailSurat.detail = data.results.detail_ahli_waris;
              break;

            case "Surat Keterangan":
              this.state.detailSurat.detail = data.results.data_detail_suket;
              this.state.dataNikStat.nikStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.status.dataPenduduk = "block";
              break;

            case "Surat Izin Keramaian":
              data.results.data_detail_suket.map(dt => {
                if (dt.nama_item == "tgl_mulai") {
                  this.state.detailSurat.tanggal_mulai = new Date(dt.isi_item);
                }

                if (dt.nama_item == "tgl_selesai") {
                  if (dt.isi_item != null) {
                    this.state.detailSurat.tanggal_selesai = new Date(
                      dt.isi_item
                    );
                  }
                }
                if (dt.nama_item == "tempat") {
                  this.state.detailSurat.tempat = dt.isi_item;
                }
                if (dt.nama_item == "hiburan") {
                  this.state.detailSurat.hiburan = dt.isi_item;
                }
                this.forceUpdate();
              });

              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };

              this.state.status.showBio = true;

              break;

            case "Surat Kuasa":
              let detailRincian = [];
              data.results.data_detail_suket.map(dt => {
                let nama_rincian = dt.nama_item;
                let isi_rincian = dt.isi_item;
                detailRincian.push({
                  nama_rincian: nama_rincian,
                  isi_rincian: isi_rincian
                });
              });

              this.state.detailSurat.kepada = data.results.kepada;
              this.state.detailSurat.pendStat = {
                value: data.results.nik,
                label: data.results.nik
              };
              this.state.detailSurat.pendStat2 = {
                value: data.results.nik2,
                label: data.results.nik2
              };
              this.state.detailSurat.nik2 = data.results.nik2;
              this.state.detailSurat.nama2 = data.results.nama2;
              this.state.detailSurat.kepada2 = data.results.kepada2;
              this.state.detailSurat.tempat_lahir2 = data.results.tempat_lahir2;
              this.state.detailSurat.tanggal_lahir2 =
                data.results.tanggal_lahir2;
              this.state.detailSurat.pekerjaan2 = data.results.pekerjaan2;
              this.state.detailSurat.alamat2 = data.results.alamat2;

              this.state.detailSurat.kuasa_atas = data.results.kuasa_atas;
              this.state.detailSurat.kuasa_untuk = data.results.kuasa_untuk;
              this.state.detailSurat.tujuan_kuasa = data.results.tujuan_kuasa;

              this.state.status.showBio = true;
              this.state.status.showBio2 = true;
              this.state.status.btnForm = false;

              this.state.detailSurat.usaha = detailRincian;
              break;

            case "Surat Undangan":
              let frmJam = [];
              let jamnya = "";
              for (let i = 1; i <= 24; i++) {
                jamnya = i;
                if (i < 10) {
                  jamnya = "0" + i;
                }
                jamnya += ":00";
                frmJam.push({ value: jamnya, label: jamnya });
              }
              this.state.detailSurat.frmJam = frmJam;

              let tgl = data.results.tanggal_surat;
              let wkt = data.results.waktu;
              // console.log(wkt.slice(11,16));
              this.state.detailSurat.tanggal_surat = new Date(tgl);
              this.state.detailSurat.tanggal_acara = new Date(wkt);
              this.state.detailSurat.jamStat = {
                value: wkt.slice(11, 16),
                label: wkt.slice(11, 16)
              };

              // this.setState({
              // 	tanggal_surat: new Date(tgl),
              // 	tanggal_acara: new Date(wkt),
              // 	jamStat: { value: wkt.slice(11, 16), label: wkt.slice(11, 16) }
              // });

              this.state.detailSurat.id_pengaju = data.results.id_pengaju;
              this.state.detailSurat.sifat = data.results.sifat;
              this.state.detailSurat.kepada = data.results.kepada;
              this.state.detailSurat.perihal = data.results.perihal;
              this.state.detailSurat.waktu = data.results.waktu;
              this.state.detailSurat.tempat = data.results.tempat;
              this.state.detailSurat.acara = JSON.parse(data.results.acara);
              break;
          }

          this.state.status.btnForm = false;
          // Buka form
          // console.log('sampai akhir')
          this.forceUpdate();
          this.bukaForm("update");
        }
      }
    );
  };

  tambahAcara = e => {
    let jmlAcara = this.state.detailSurat.jmlAcara;

    // console.log(jmlAcara);
    jmlAcara++;
    this.state.detailSurat.jmlAcara = jmlAcara;
    this.forceUpdate();

    let acara = [];
    for (let i = 1; i < jmlAcara; i++) {
      let nama_acara = document.getElementById("acara" + i).value;
      acara.push({ nama: nama_acara });
    }
    acara.push({ nama: "" });

    this.state.detailSurat.acara = acara;
    this.forceUpdate();

    // jmlAcara++;
    // let acara = [];
    // for (let i = 1; i < jmlAcara; i++) {
    //   let nama_acara = document.getElementById("acara" + i).value;
    //   acara.push({ nama: nama_acara });
    // }
    // acara.push({ nama: "" });
    // this.state.dt.acara = acara;
    // this.forceUpdate();
  };

  switchSaksi2 = e => {
    if (this.state.detailSurat.formSaksi == "none") {
      this.state.detailSurat.formSaksi = "";
    } else {
      this.state.detailSurat.formSaksi = "none";
    }
    this.forceUpdate();
  };

  tambahAw = (e, jenis = null) => {
    // FIX THIS
    // let detail = { ...this.state.detailSurat.detail };
    if (typeof this.state.dataNikStat.nik3Stat != "undefined") {
      Get("penduduk/find", this.state.dataNikStat.nik3Stat.value, data => {
        if (data.results) {
          console.log(data.results);
          if (jenis == "ahli-waris") {
            this.state.detailSurat.detail.push({
              id_surat_keterangan_ahli_waris: this.state.detailSurat.uuid,
              nik: this.state.dataNikStat.nik3Stat.value,
              nama: data.results.nama,
              // jenis_kelamin: data.results.jenis_kelamin,
              jk: data.results.jk,
              tanggal_lahir: data.results.tanggal_lahir,
              tempat_lahir: data.results.tempat_lahir,
              pekerjaan: data.results.data_pekerjaan.nama,
              shdk: this.state.detailSurat.shdk,
              keterangan: ""
            });
          } else {
            this.state.detailSurat.detail.push({
              nik: this.state.dataNikStat.nik3Stat.value,
              nama: data.results.nama,
              jenis_kelamin: data.results.jk,
              tanggal_lahir: data.results.tanggal_lahir,
              tempat_lahir: data.results.tempat_lahir,
              pekerjaan: data.results.data_pekerjaan.nama,
              pendidikan: data.results.data_pendidikan_terakhir.nama,
              shdk: this.state.detailSurat.shdk,
              keterangan: ""
            });
          }

          this.state.dataNikStat.nik3Stat = null;
          this.state.detailSurat.shdk = "";
          this.state.status.btnForm = false;
          this.forceUpdate();
          // this.setState({ detail });
        }
        this.forceUpdate();
      });
    } else if (jenis == "umum") {
      if (
        document.getElementById("nama_item").value != "" &&
        document.getElementById("isi_item").value != ""
      ) {
        // detail = this.state.detail;
        this.state.detailSurat.detail.push({
          nama_item: document.getElementById("nama_item").value,
          isi_item: document.getElementById("isi_item").value
        });
        // this.setState({ detail });
        this.forceUpdate();
        document.getElementById("nama_item").value = "";
        document.getElementById("isi_item").value = "";
      }
    }
  };

  hapusAw = i => {
    this.state.detailSurat.detail.splice(i, 1);
    this.forceUpdate();
  };

  addShdk = (e, sel) => {
    if (e) {
      this.state.detailSurat.shdk = e.target.value;
    } else {
      this.state.detailSurat.shdk = "";
    }
    this.forceUpdate();
  };

  handleAddShareholder = () => {
    this.state.detailSurat.usaha = this.state.detailSurat.usaha.concat([
      { name: "" }
    ]);
    this.forceUpdate();
    console.log(this.state.detailSurat.usaha);
    // this.setState({
    //   usaha: this.state.usaha.concat([{ name: "" }])
    // });
  };

  handleRemoveShareholder = idx => () => {
    console.log(this.state.detailSurat.usaha);

    this.state.detailSurat.usaha = this.state.detailSurat.usaha.filter(
      (s, sidx) => idx !== sidx
    );
    this.forceUpdate();
    console.log(this.state.detailSurat.usaha);
    // this.setState({
    //   usaha: this.state.detailSurat.usaha.filter((s, sidx) => idx !== sidx)
    // });
  };

  tambahMeninggal = e => {
    e.preventDefault();
    if (this.state.detailSurat.meninggal2 == "none") {
      this.state.detailSurat.meninggal2 = "inherit";
      // this.setState({ meninggal2: "inherit" });
    } else {
      this.state.detailSurat.meninggal2 = "none";
      this.state.dataNikStat.nik2Stat = null;
      // this.setState({ meninggal2: "none" });
    }
    this.forceUpdate();
  };

  // ================== Update detail surat =====================

  cetakSurat = (e, data) => {
    e.preventDefault();

    var jenis_surat = "";
    let kop = "";
    const label_surat = data.label;

    switch (label_surat) {
      case "Surat Keterangan Tidak Mampu":
        jenis_surat = "tidak-mampu";
        break;
      case "Surat Keterangan Domisili":
        jenis_surat = "domisili";
        break;
      case "Surat Keterangan Penghasilan":
        jenis_surat = "penghasilan";
        break;
      case "Surat Keterangan Kematian":
        jenis_surat = "kematian";
        break;
      case "Surat Keterangan Pernah Menikah":
        jenis_surat = "pernah-menikah";
        break;
      case "Surat Keterangan Kelahiran":
        jenis_surat = "kelahiran";
        break;
      case "Surat Kuasa SKGR":
        jenis_surat = "kuasa";
        break;
      case "Surat Kuasa":
        jenis_surat = "kuasa";
        break;
      case "Surat Keterangan Ahli Waris":
        jenis_surat = "ahli-waris";
        break;

      case "Surat Undangan":
        kop = "surat-undangan";
        break;

      default:
        jenis_surat = "grup";
        break;
    }

    // get data detail for cetak
    var cetak = { ...this.state.cetak };

    Get(
      kop == ""
        ? "surat-keterangan/" + jenis_surat + "/find"
        : "surat-undangan/find",
      data.uuid_surat,
      data => {
        console.log(data.results);

        cetak.uuid = data.results.uuid;
        cetak.id_jenis = data.results.id_jenis;
        cetak.jenis_surat = label_surat;
        cetak.nomor = data.results.nomor;
        cetak.tanggal_surat = data.results.tanggal_surat;

        cetak.nama_desa = "Desa Sungai Petai";
        cetak.nik = data.results.nik;
        cetak.kk = data.results.kk;

        // Cek undefined
        cetak.nama_pemohon =
          typeof data.results.nama_pemohon != "undefined"
            ? data.results.nama_pemohon.toUpperCase()
            : "";
        cetak.nama =
          typeof data.results.nama != "undefined"
            ? data.results.nama.toUpperCase()
            : "";
        cetak.tanggal_lahir =
          typeof data.results.tanggal_lahir != "undefined"
            ? new Date(data.results.tanggal_lahir)
            : "";
        cetak.nomor_surat =
          typeof data.results.nomor_surat != "undefined"
            ? data.results.nomor_surat
            : "";
        cetak.tempat_lahir =
          typeof data.results.tempat_lahir != "undefined"
            ? data.results.tempat_lahir.toLowerCase()
            : "";

        cetak.kewarganegaraan =
          typeof data.results.kewarganegaraan != "undefined"
            ? data.results.kewarganegaraan
            : "";
        cetak.status_perkawinan =
          typeof data.results.status_perkawinan != "undefined"
            ? data.results.status_perkawinan
            : "";
        cetak.tujuan_pembuatan =
          typeof data.results.tujuan_pembuatan != "undefined"
            ? data.results.tujuan_pembuatan
            : "";
        cetak.pendidikan_terakhir =
          typeof data.results.pendidikan_terakhir != "undefined"
            ? data.results.pendidikan_terakhir
            : "";
        cetak.jenis_kelamin =
          typeof data.results.jenis_kelamin != "undefined"
            ? data.results.jenis_kelamin
            : "";

        cetak.alamat =
          typeof data.results.alamat != "undefined" ? data.results.alamat : "";
        cetak.agama =
          typeof data.results.agama != "undefined" ? data.results.agama : "";
        cetak.pekerjaan =
          typeof data.results.pekerjaan != "undefined"
            ? data.results.pekerjaan
            : "";

        cetak.status_ttd = data.results.status_ttd;
        cetak.jabatan_ttd = data.results.jabatan_ttd.toLowerCase();
        cetak.jabatan_pengganti_ttd = data.results.jabatan_pengganti_ttd;
        cetak.nama_ttd = data.results.nama_ttd.toUpperCase();
        cetak.nip_ttd = data.results.nip_ttd;

        if (typeof data.results.status_ttd2 != "undefined") {
          cetak.status_ttd2 = data.results.status_ttd2;
          cetak.jabatan_ttd2 = data.results.jabatan_ttd2;
          cetak.jabatan_pengganti_ttd2 = data.results.jabatan_pengganti_ttd2;
          cetak.nama_ttd2 = data.results.nama_ttd2.toUpperCase();
          cetak.nip_ttd2 = data.results.nip_ttd2;
        }
        // console.log('sampai sini')

        switch (label_surat) {
          case "Surat Keterangan Tidak Mampu":
            cetak.detail = data.results.data_detail_tidak_mampu;
            break;

          case "Surat Keterangan Domisili":
            cetak.detail = data.results.data_detail_domisili;
            break;

          case "Surat Keterangan Penghasilan":
            cetak.penghasilan = data.results.penghasilan;
            break;

          case "Surat Keterangan Belum Memiliki Rumah":
            cetak.lokasi = data.results.lokasi;
            break;

          case "Surat Keterangan Usaha":
            data.results.data_detail_suket.map(dt => {
              switch (dt.nama_item) {
                case "usaha":
                  cetak.detail = JSON.parse(dt.isi_item);
                  break;
              }
            });
            break;

          case "Surat Keterangan Jalan":
            console.log(data.results.data_detail_suket);
            console.log(cetak);
            data.results.data_detail_suket.map(dt => {
              if (dt.nama_item == "rencana_berangkat") {
                cetak.detail.rencana_berangkat = new Date(dt.isi_item);
              }
              if (dt.nama_item == "barang") {
                cetak.detail.barang = dt.isi_item;
              }
              if (dt.nama_item == "tempat_lahir_supir") {
                cetak.detail.tempat_lahir_supir = dt.isi_item;
              }
              if (dt.nama_item == "tanggal_lahir_supir") {
                cetak.detail.tanggal_lahir_supir = new Date(dt.isi_item);
              }
              if (dt.nama_item == "nama_supir") {
                cetak.detail.nama_supir = dt.isi_item;
              }
              if (dt.nama_item == "no_pol") {
                cetak.detail.no_pol = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item == "transportasi") {
                cetak.detail.transportasi = dt.isi_item;
              }
              if (dt.nama_item == "pengikut") {
                cetak.detail.pengikut = dt.isi_item;
              }
              if (dt.nama_item == "tujuan_perjalanan") {
                cetak.detail.tujuan_perjalanan = dt.isi_item;
              }
            });
            // this.forceUpdate();
            console.log(cetak);

            break;

          case "Surat Pengantar Pindah Nikah":
            data.results.data_detail_suket.map(dt => {
              if (dt.nama_item == "nama_nasab_p1") {
                cetak.nama_nasab_p1 = dt.isi_item.toUpperCase();
              }

              if (dt.nama_item == "nama_nasab_p2") {
                cetak.nama_nasab_p2 = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item == "nama_pasangan") {
                cetak.nama_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "jenis_kelamin_pasangan") {
                cetak.jenis_kelamin_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "tempat_lahir_pasangan") {
                cetak.tempat_lahir_pasangan = dt.isi_item.toLowerCase();
              }
              if (dt.nama_item == "tanggal_lahir_pasangan") {
                cetak.tanggal_lahir_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "pekerjaan_pasangan") {
                cetak.pekerjaan_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "agama_pasangan") {
                cetak.agama_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "nik_pasangan") {
                cetak.nik_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "alamat_pasangan") {
                cetak.alamat_pasangan = dt.isi_item;
              }
              if (dt.nama_item == "tempat_nikah") {
                cetak.tempat_nikah = dt.isi_item;
              }
              this.forceUpdate();
            });

            cetak.lokasi = data.results.lokasi;

            break;

          case "Surat Keterangan Kematian":
            cetak.detail.waktu_meninggal = data.results.waktu_meninggal;
            // cetak.detail.waktu_meninggal = new Date(data.results.waktu_meninggal);
            cetak.detail.tempat_meninggal = data.results.tempat_meninggal.toLowerCase();
            cetak.detail.sebab_meninggal = data.results.sebab_meninggal.toLowerCase();
            cetak.detail.tempat_makam = data.results.tempat_makam;

            cetak.detail.nik_pelapor = data.results.nik_pelapor;
            cetak.detail.nama_pelapor = data.results.nama_pelapor;
            // cetak.detail.tanggal_lahir_pelapor = new Date(data.results.tanggal_lahir_pelapor).toLocaleDateString('id-ID');
            cetak.detail.tanggal_lahir_pelapor = new Date(
              data.results.tanggal_lahir_pelapor
            );
            cetak.detail.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
            // cetak.detail.jk_pelapor = data.results.jenis_kelamin_pelapor;
            // cetak.detail.status_perkawinan_pelapor = data.results.status_perkawinan_pelapor.toLowerCase();
            cetak.detail.agama_pelapor = data.results.agama_pelapor.toLowerCase();
            cetak.detail.alamat_pelapor = data.results.alamat_pelapor;
            cetak.detail.pekerjaan_pelapor = data.results.pekerjaan_pelapor.toLowerCase();
            cetak.detail.hubungan_pelapor = data.results.hubungan_pelapor.toLowerCase();

            cetak.detail.status_ttd2 = data.results.status_ttd2;
            cetak.detail.jabatan_ttd2 = data.results.jabatan_ttd2;
            cetak.detail.jabatan_pengganti_ttd2 =
              data.results.jabatan_pengganti_ttd2;
            cetak.detail.nama_ttd2 = data.results.nama_ttd2;
            cetak.detail.nip_ttd2 = data.results.nip_ttd2;
            break;

          case "Surat Keterangan Pernah Menikah":
            cetak.nik_pasangan = data.results.nik_pasangan;
            cetak.nama_pasangan = data.results.nama_pasangan;
            cetak.tanggal_lahir_pasangan = data.results.tanggal_lahir_pasangan;
            cetak.tempat_lahir_pasangan = data.results.tempat_lahir_pasangan.toLowerCase();
            cetak.jk_pasangan = data.results.jenis_kelamin_pasangan;
            cetak.status_perkawinan_pasangan =
              data.results.status_perkawinan_pasangan;
            cetak.agama_pasangan = data.results.agama_pasangan;
            cetak.alamat_pasangan = data.results.alamat_pasangan;
            cetak.pekerjaan_pasangan = data.results.pekerjaan_pasangan;
            cetak.status_perkawinan_pasangan =
              data.results.status_perkawinan_pasangan;

            cetak.tanggal_menikah = data.results.tanggal_menikah;
            // this.state.tanggal_menikah = new Date(data.results.tanggal_menikah);
            cetak.tempat_menikah = data.results.tempat_menikah;
            cetak.wali_nikah = data.results.wali_nikah;
            cetak.petugas_nikah = data.results.petugas_nikah;
            cetak.mas_kawin = data.results.mas_kawin;
            cetak.saksi_nikah = data.results.saksi_nikah;

            cetak.nik_pelapor = data.results.nik_pelapor;
            cetak.nama_pelapor = data.results.nama_pelapor;
            cetak.tanggal_lahir_pelapor = data.results.tanggal_lahir_pelapor;
            cetak.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
            cetak.jk_pelapor = data.results.jenis_kelamin_pelapor;
            cetak.status_perkawinan_pelapor =
              data.results.status_perkawinan_pelapor;
            cetak.agama_pelapor = data.results.agama_pelapor;
            cetak.alamat_pelapor = data.results.alamat_pelapor;
            cetak.pekerjaan_pelapor = data.results.pekerjaan_pelapor;
            cetak.status_perkawinan_pelapor =
              data.results.status_perkawinan_pelapor;
            break;

          case "Surat Pernyataan Belum Menikah":
            console.log(data.results.data_detail_suket);
            data.results.data_detail_suket.map(dt => {
              if (dt.nama_item == "nama_saksi2") {
                cetak.nama_saksi2 = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item == "hubungan_saksi2") {
                cetak.hubungan_saksi2 = dt.isi_item;
              }
              if (dt.nama_item == "nama_saksi1") {
                cetak.nama_saksi1 = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item == "hubungan_saksi1") {
                cetak.hubungan_saksi1 = dt.isi_item;
              }
              if (dt.nama_item == "nama_ayah") {
                cetak.nama_ayah_kandung = dt.isi_item.toUpperCase();
              }
            });
            break;

          case "Surat Pernyataan Tidak Menikah":
            data.results.data_detail_suket.map(dt => {
              if (dt.nama_item == "nomor_surat_akta") {
                cetak.nomor_surat_akta = dt.isi_item;
              }
              if (dt.nama_item == "tanggal_surat_akta") {
                cetak.tanggal_surat_akta = dt.isi_item;
              }
              if (dt.nama_item == "nama_saksi") {
                cetak.nama_saksi = dt.isi_item;
              }
              if (dt.nama_item == "hubungan_saksi") {
                cetak.hubungan_saksi = dt.isi_item;
              }
              if (dt.nama_item == "nama_ayah") {
                cetak.nama_ayah_kandung = dt.isi_item;
              }
            });
            break;

          case "Surat Keterangan Kelahiran":
            cetak.nama_anak = data.results.nama_anak.toUpperCase();
            cetak.tanggal_lahir_anak = new Date(
              data.results.tanggal_lahir_anak
            );
            cetak.tempat_lahir_anak = data.results.tempat_lahir_anak.toLowerCase();
            cetak.jk_anak = data.results.jk_anak.toLowerCase();

            cetak.nik_ayah = data.results.nik_ayah;
            cetak.nama_ayah = data.results.nama_ayah;
            cetak.tempat_lahir_ayah = data.results.tempat_lahir_ayah.toLowerCase();
            cetak.tanggal_lahir_ayah = new Date(
              data.results.tanggal_lahir_ayah
            );
            cetak.pekerjaan_ayah = data.results.pekerjaan_ayah.toLowerCase();
            cetak.alamat_ayah = data.results.alamat_ayah;

            cetak.nik_ibu = data.results.nik_ibu;
            cetak.nama_ibu = data.results.nama_ibu;
            cetak.tempat_lahir_ibu = data.results.tempat_lahir_ibu.toLowerCase();
            cetak.tanggal_lahir_ibu = new Date(data.results.tanggal_lahir_ibu);
            cetak.pekerjaan_ibu = data.results.pekerjaan_ibu.toLowerCase();
            cetak.alamat_ibu = data.results.alamat_ibu;

            cetak.nik_pelapor = data.results.nik_pelapor;
            cetak.nama_pelapor = data.results.nama_pelapor;
            cetak.tanggal_lahir_pelapor = new Date(
              data.results.tanggal_lahir_pelapor
            );
            cetak.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
            cetak.jk_pelapor = data.results.jenis_kelamin_pelapor;
            cetak.status_perkawinan_pelapor =
              data.results.status_perkawinan_pelapor;
            cetak.agama_pelapor = data.results.agama_pelapor.toLowerCase();
            cetak.alamat_pelapor = data.results.alamat_pelapor;
            cetak.pekerjaan_pelapor = data.results.pekerjaan_pelapor.toLowerCase();
            cetak.hubungan_pelapor = data.results.hubungan_pelapor.toLowerCase();
            break;

          case "Surat Kuasa SKGR":
            data.results.data_detail_suket.map(dt => {
              if (dt.nama_item == "tanggal_skgr") {
                cetak.tanggal_skgr = new Date(dt.isi_item);
              }
              if (dt.nama_item == "atas_nama") {
                cetak.atas_nama = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item == "luas_tanah") {
                cetak.luas_tanah = dt.isi_item;
              }
              if (dt.nama_item == "no_reg") {
                cetak.no_reg = dt.isi_item;
              }
              if (dt.nama_item == "lokasi_tanah") {
                cetak.lokasi_tanah = dt.isi_item;
              }
            });

            cetak.kepada = data.results.kepada;

            cetak.nik2 = data.results.nik2;
            cetak.nama2 = data.results.nama2;
            cetak.kepada2 = data.results.kepada2;
            cetak.tempat_lahir2 = data.results.tempat_lahir2;
            this.state.tanggal_lahir2 = data.results.tanggal_lahir2;
            cetak.pekerjaan2 = data.results.pekerjaan2;
            cetak.alamat2 = data.results.alamat2;

            cetak.kuasa_atas = data.results.kuasa_atas;
            cetak.kuasa_untuk = data.results.kuasa_untuk;
            cetak.tujuan_kuasa = data.results.tujuan_kuasa;
            break;

          case "Surat Pernyataan Kehilangan":
            let acara = [];
            let tujuan = data.results.tujuan_pembuatan.split(",");
            tujuan.map(dt => {
              acara.push({ nama: dt });
            });
            cetak.acara = acara;
            cetak.lokasi = data.results.lokasi;
            break;

          case "Surat Keterangan Ahli Waris":
            cetak.detail = data.results.detail_ahli_waris;

            let cek = data.results.nik.split(",");

            if (cek.length == 1) {
              cetak.nik = data.results.nik;
              cetak.nama = data.results.nama;
              cetak.tanggal_lahir = 
                data.results.tanggal_lahir
              
              cetak.tempat_lahir = data.results.tempat_lahir;
              cetak.alamat = data.results.alamat;
              cetak.jenis_kelamin = data.results.jenis_kelamin;
              cetak.pekerjaan = data.results.pekerjaan;

              cetak.tanggal_lahir =data.results.tanggal_lahir;

              cetak.waktu_meninggal = data.results.waktu_meninggal;
              cetak.waktu_meninggal = data.results.waktu_meninggal;
              cetak.tempat_meninggal = data.results.tempat_meninggal;
              cetak.sebab_meninggal = data.results.sebab_meninggal;

              // this.state.nikStat = {
              // 	value: data.results.nik,
              // 	label: data.results.nik
              // };
              // this.state.status.dataPenduduk = "block";
            } else {
              let nama = data.results.nama.split(",");
              cetak.nama = nama[0];
              cetak.nama2 = nama[1];

              let tanggal_lahir = data.results.tanggal_lahir.split(",");
              console.log(tanggal_lahir)
              cetak.tanggal_lahir = 
                tanggal_lahir[0]
             
              cetak.tanggal_lahir2 = 
                tanggal_lahir[1]
              

              let tempat_lahir = data.results.tempat_lahir.split(",");
              cetak.tempat_lahir = tempat_lahir[0];
              cetak.tempat_lahir2 = tempat_lahir[1];

              let alamat = data.results.alamat.split(",");
              cetak.alamat = alamat[0];
              cetak.alamat2 = alamat[1];

              let jenis_kelamin = data.results.alamat.split(",");
              cetak.jenis_kelamin = jenis_kelamin[0];
              cetak.jenis_kelamin2 = jenis_kelamin[1];

              let pekerjaan = data.results.pekerjaan.split(",");
              cetak.pekerjaan = pekerjaan[0];
              cetak.pekerjaan2 = pekerjaan[1];

              let kewarganegaraan = data.results.kewarganegaraan.split(",");
              cetak.kewarganegaraan = kewarganegaraan[0];
              cetak.kewarganegaraan2 = kewarganegaraan[1];

              let waktu_meninggal = data.results.waktu_meninggal.split(",");
              cetak.waktu_meninggal = waktu_meninggal[0];
              cetak.waktu_meninggal2 = waktu_meninggal[1];
              cetak.waktu_meninggal =waktu_meninggal[0];
              cetak.waktu_meninggal2 = waktu_meninggal[1];

              let tempat_meninggal = data.results.tempat_meninggal.split(",");
              cetak.tempat_meninggal = tempat_meninggal[0];
              cetak.tempat_meninggal2 = tempat_meninggal[1];

              let sebab_meninggal = data.results.sebab_meninggal.split(",");
              cetak.sebab_meninggal = sebab_meninggal[0];
              cetak.sebab_meninggal2 = sebab_meninggal[1];

              // this.state.nikStat = { value: cek[0], label: cek[0] };
              // this.state.status.dataPenduduk = "block";
              // this.state.nik2Stat = { value: cek[1], label: cek[1] };
              // this.state.status.dataPenduduk2 = "block";
            }
            break;

          case "Surat Kuasa":
            let detailRincian = [];
            data.results.data_detail_suket.map(dt => {
              let nama_rincian = dt.nama_item;
              let isi_rincian = dt.isi_item;
              detailRincian.push({
                nama_rincian: nama_rincian,
                isi_rincian: isi_rincian
              });
            });

            cetak.kepada = data.results.kepada;

            cetak.pendStat = {
              value: data.results.nik,
              label: data.results.nik
            };
            cetak.pendStat2 = {
              value: data.results.nik2,
              label: data.results.nik2
            };

            cetak.nik2 = data.results.nik2;
            cetak.nama2 = data.results.nama2;
            cetak.kepada2 = data.results.kepada2;
            cetak.tempat_lahir2 = data.results.tempat_lahir2;
            cetak.tanggal_lahir2 = data.results.tanggal_lahir2;
            cetak.pekerjaan2 = data.results.pekerjaan2;
            cetak.alamat2 = data.results.alamat2;

            cetak.kuasa_atas = data.results.kuasa_atas;
            cetak.kuasa_untuk = data.results.kuasa_untuk;
            cetak.tujuan_kuasa = data.results.tujuan_kuasa;

            cetak.usaha = detailRincian;

            this.state.status.showBio = true;
            this.state.status.showBio2 = true;
            this.state.status.btnForm = false;
            break;

          case "Surat Undangan":
            let frmJam = [];
            let jamnya = "";
            for (let i = 1; i <= 24; i++) {
              jamnya = i;
              if (i < 10) {
                jamnya = "0" + i;
              }
              jamnya += ":00";
              frmJam.push({ value: jamnya, label: jamnya });
            }
            cetak.frmJam = frmJam;

            let tgl = data.results.tanggal_surat;
            let wkt = data.results.waktu;
            // console.log(wkt.slice(11,16));
            cetak.tanggal_surat = new Date(tgl);
            cetak.tanggal_acara = new Date(wkt);
            cetak.jamStat = {
              value: wkt.slice(11, 16),
              label: wkt.slice(11, 16)
            };

            // this.setState({
            // 	tanggal_surat: new Date(tgl),
            // 	tanggal_acara: new Date(wkt),
            // 	jamStat: { value: wkt.slice(11, 16), label: wkt.slice(11, 16) }
            // });

            cetak.id_pengaju = data.results.id_pengaju;
            cetak.sifat = data.results.sifat;
            cetak.kepada = data.results.kepada;
            cetak.perihal = data.results.perihal;
            cetak.waktu = data.results.waktu.slice(11, 16);
            cetak.tempat = data.results.tempat;

            let acara2 = JSON.parse(data.results.acara).map((dt, i) => {
              return (
                <tr key={i}>
                  <td style={{ width: "2%" }}>{i + 1}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {dt.nama.toLowerCase()}
                  </td>
                </tr>
              );
            });

            // cetak.acara = JSON.parse(data.results.acara);
            cetak.acara = acara2;
            break;

          case "Surat Izin Keramaian":
            console.log(data.results.data_detail_suket);
            data.results.data_detail_suket.map(dt => {
              if (dt.nama_item == "tgl_mulai") {
                // cetak.tanggal_mulai = new Date(dt.isi_item);
                cetak.tanggal_mulai = dt.isi_item;
              }

              if (dt.nama_item == "tgl_selesai") {
                console.log("dt.isi_item");
                console.log(dt.isi_item);
                if (dt.isi_item != null) {
                  // cetak.tanggal_selesai = new Date(dt.isi_item);
                  cetak.tanggal_selesai = dt.isi_item;
                }
              }
              if (dt.nama_item == "tempat") {
                cetak.tempat = dt.isi_item;
              }
              if (dt.nama_item == "hiburan") {
                cetak.hiburan = dt.isi_item;
              }
              console.log("sampai sini");

              this.forceUpdate();
            });
            cetak.lokasi = data.results.lokasi;
            break;

          case "Surat Keterangan":
            cetak.detail = data.results.data_detail_suket;
            break;
        }

        console.log(cetak);

        this.setState({ cetak });

        this.forceUpdate();

        setTimeout(function() {
          const content = document.getElementById("cetak-surat").innerHTML;
          const mywindow = window.open("", "Print", "height=600,width=1000");

          mywindow.document.write("<html><head><title>Print</title>");
          mywindow.document.write("</head><body >");
          mywindow.document.write(content);
          mywindow.document.write("</body></html>");

          mywindow.document.close();
          mywindow.focus();
          mywindow.print();
          setTimeout(function() {
            mywindow.close();
          }, 1000);
        }, 100);
      }
    );
  };

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "satu",
      "dua",
      "tiga",
      "empat",
      "lima",
      "enam",
      "tujuh",
      "delapan",
      "sembilan",
      "sepuluh",
      "sebelas"
    ];
    if (angka == 1) {
      return "setahun";
    }
    // pencocokan dimulai dari satuan angka terkecil
    else if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka] + " tahun";
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " belas tahun";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " puluh " + bilangan[hasil_mod] + " tahun";
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return (
        bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod) + " tahun"
      );
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return (
        this.terbilang(hasil_bagi) +
        " Ribu " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return (
        this.terbilang(hasil_bagi) +
        " Juta " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) +
        " Milyar " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) +
        " Triliun " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else {
      return "Wow...";
    }
  };

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.state.surat.tanggal_surat = date;
        this.state.detailSurat.tanggal_surat = date;
        break;
      case "tanggal_acara":
        // this.setState({ tanggal_acara: date });
        this.state.detailSurat.tanggal_acara = date;
        break;
      case "tanggal_lahir_anak":
        this.state.detailSurat.tanggal_lahir_anak = date;
        break;
      case "tanggal_lahir_supir":
        this.state.detailSurat.tanggal_lahir_supir = date;
        break;
      case "rencana_berangkat":
        this.state.detailSurat.rencana_berangkat = date;
        break;
      case "waktu_meninggal":
        this.state.detailSurat.waktu_meninggal = date;
        break;
      case "waktu_meninggal2":
        this.state.detailSurat.waktu_meninggal2 = date;
        break;
      case "tanggal_skgr":
        this.state.detailSurat.tanggal_skgr = date;
        break;
      case "tanggal_menikah":
        this.state.detailSurat.tanggal_menikah = date;
        break;
      case "tanggal_surat_akta":
        this.state.detailSurat.tanggal_surat_akta = date;
        break;
      case "tanggal_mulai":
        this.state.detailSurat.tanggal_mulai = date;
        break;
      case "tanggal_selesai":
        this.state.detailSurat.tanggal_selesai = date;
        break;
    }
    this.forceUpdate();
  };

  pilihPerangkatDesa = (uuid, id) => {
    let temp = this.state.accButtonPerangkatDesa.map(x => 1);
    temp[id] = 0;
    this.state.accButtonPerangkatDesa = temp;
    this.state.uuidPerangkatDesa = uuid;
    if (this.state.status.accStat) {
      this.state.status.btnFormAcc = false;
    }
    this.forceUpdate();
  };

  changeSelectValue2 = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.state.detailSurat.jam = e.value;
          this.state.detailSurat.jamStat = e;
          // this.setState({ jamStat: e });
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.state.detailSurat.jam = 0;
          this.state.detailSurat.jamStat = { value: "08:00", label: "08:00" };
          // this.setState({ jamStat: { value: "08:00", label: "08:00" } });
          break;
      }
    }
    this.forceUpdate();
  };

  // Untuk form pada tab menunggu eksekusi
  simpanAcc = e => {
    e.preventDefault();
    this.state.status.btnFormAcc = true;

    let add = {
      uuid: this.state.uuidPerangkatDesa,
      tanggal_surat: new Date(this.state.surat.tanggal_surat)
        .toISOString()
        .slice(0, 10)
    };

    let kop_surat = "surat-keterangan";
    let jenis_surat = "";
    switch (this.state.surat.jenis_surat) {
      case "Surat Keterangan Tidak Mampu":
        jenis_surat = "tidak-mampu";
        break;
      case "Surat Keterangan Domisili":
        jenis_surat = "domisili";
        break;
      case "Surat Keterangan Ahli Waris":
        jenis_surat = "ahli-waris";
        break;
      case "Surat Keterangan Kelahiran":
        jenis_surat = "kelahiran";
        break;
      case "Surat Keterangan Kematian":
        jenis_surat = "kematian";
        break;
      case "Surat Keterangan Penghasilan":
        jenis_surat = "penghasilan";
        break;
      case "Surat Keterangan Pernah Menikah":
        jenis_surat = "pernah-menikah";
        break;
      case "Surat Kuasa":
        jenis_surat = "kuasa";
        break;
      case "Surat Kuasa SKGR":
        jenis_surat = "kuasa";
        break;
      case "Surat Undangan":
        kop_surat = "surat-undangan";
        jenis_surat = "";
        break;

      default:
        jenis_surat = "grup";
        break;
    }
    // console.log(this.state.status);
    // making dynamic api based on jenis surat
    Post(
      kop_surat != "surat-undangan"
        ? kop_surat +
            "/" +
            jenis_surat +
            "/persetujuan/Selesai/" +
            this.state.surat.uuid
        : kop_surat + "/persetujuan/Selesai/" + this.state.surat.uuid,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.state.status.formAcc = false;
          this.setState({
            // formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.state.status.btnFormAcc = false;
          this.setState({
            // btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );

    this.tutupForm();
  };

  render() {
    // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;
    var dataDitolak = this.state.dataDitolak;
    var dataMenungguEksekusi = this.state.dataMenungguEksekusi;
    var dataPenerbitan = this.state.dataPenerbitan;
    var dataPending = this.state.dataPending;
    var dataSelesai = this.state.dataSelesai;

    var no = 0;
    const frmDef = {
      id: 0,
      idbaru: "",
      id_keldes: "",
      nama: ""
    };
    // const { SearchBar } = Search;

    // Data diproses
    const columns = [
      {
        dataField: "tanggal",
        text: "Tanggal",
        sort: true,
        formatter: (cell, obj) => {
          const list_bulan = [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember"
          ];
          const year = obj.tanggal.substr(0, 4);
          const month = obj.tanggal.substr(5, 2);
          const day = obj.tanggal.substr(8, 2);
          const time = obj.tanggal.substr(11, 8);
          return `${day} ${list_bulan[parseInt(month) - 1]} ${year} ${time}`;
        }
      },
      {
        dataField: "label",
        text: "Label"
        // isDummyField: true,

        // sort: true
      },
      {
        dataField: "nik",
        text: "NIK"
        // sort: true
      },
      {
        dataField: "nama",
        text: "Nama"
        // sort: true
      },
      {
        dataField: "status",
        text: "Status"
        // sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // Data ditolak
    const columns2 = [
      {
        dataField: "tanggal",
        text: "Tanggal",
        sort: true,
        formatter: (cell, obj) => {
          const list_bulan = [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember"
          ];
          const year = obj.tanggal.substr(0, 4);
          const month = obj.tanggal.substr(5, 2);
          const day = obj.tanggal.substr(8, 2);
          const time = obj.tanggal.substr(11, 8);
          return `${day} ${list_bulan[parseInt(month) - 1]} ${year} ${time}`;
        }
      },
      {
        dataField: "label",
        text: "Label"
        // isDummyField: true,

        // sort: true
      },
      {
        dataField: "nik",
        text: "NIK"
        // sort: true
      },
      {
        dataField: "nama",
        text: "Nama"
        // sort: true
      },
      {
        dataField: "status",
        text: "Status"
        // sort: true
      }
    ];

    // Data Catatan
    const columns3 = [
      {
        dataField: "catatan",
        text: "Catatan"
      },
      {
        dataField: "status",
        text: "Status Aksi",
        formatter: (cell, obj) => {
          return (
            <>
              <div className="row">
                <Col sm="12">
                  <Select
                    key={obj.uuid}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeCatatanPenduduk(e, obj);
                    }}
                    name={"status-" + cell}
                    options={[
                      { value: 1, label: "Selesai" },
                      { value: 0, label: "Belum Selesai" }
                    ]}
                    defaultValue={
                      obj.status == 1
                        ? { value: 1, label: "Selesai" }
                        : { value: 0, label: "Belum Selesai" }
                    }
                    // defaultValue={{ value: 0, label: 'Belum Selesai' }}
                    placeholder="Status"
                  />
                </Col>
              </div>
            </>
          );
        }
      }
    ];

    const defaultSorted = [
      {
        dataField: "nama",
        order: "asc"
      }
    ];

    // Context variable
    const detailSuratState = {
      detailSurat: this.state.detailSurat,
      status: this.state.status,
      dataNik: this.state.dataNik,
      dataNikStat: this.state.dataNikStat,
      tutupForm: this.tutupForm,
      gantiTgl: this.gantiTgl,
      simpanUpdate: this.simpanUpdate,
      getPenduduk: this.getPenduduk,
      handleInputChangeNIK: this.handleInputChangeNIK,
      tambahAw: this.tambahAw,
      hapusAw: this.hapusAw,
      addShdk: this.addShdk,
      tambahAcara: this.tambahAcara,
      switchSaksi2: this.switchSaksi2,
      handleAddShareholder: this.handleAddShareholder,
      handleRemoveShareholder: this.handleRemoveShareholder,
      tambahMeninggal: this.tambahMeninggal,
      changeSelectValue2: this.changeSelectValue2
    };

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = (e) => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = '';
    //   classname = 'btn btn-success';
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>{page}</a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing { from} to { to} of { total} Results
    //     </span>
    //   ),
    //   // paginationSize: size,
    //   totalSize: total
    // };

    no = 0;
    const tableTTD = this.state.listPerangkatDesa.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilihPerangkatDesa(dt.uuid, i)}
              color={
                this.state.accButtonPerangkatDesa.length > 0
                  ? this.state.accButtonPerangkatDesa[i] == 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButtonPerangkatDesa.length > 0
                ? this.state.accButtonPerangkatDesa[i] == 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <Breadcrumb title="Disposisi Surat" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Disposisi Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* <Button size="sm" color="success" className="btn-square" onClick={() => {
                        this.setState({ judul: "Tambah Dusun", dt: frmDef, prvStat: [], kbktStat: [], kecStat: [], kldsStat: [] }); this.bukaForm();
                      }}>Tambah Data</Button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="icofont icofont-ui-home"></i>
                        Diproses
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses"></i>
                        Menunggu Eksekusi
                      </NavLink>
                    </NavItem>
                    {/* <NavItem style={{ cursor: 'pointer' }}>
											<NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
												onClick={() => { this.toggle_icon('3'); }} >
												<i className="icofont icofont-man-in-glasses"></i>Menunggu Penerbitan</NavLink>
										</NavItem> */}
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses"></i>
                        Pending
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "5"
                        })}
                        onClick={() => {
                          this.toggle_icon("5");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses"></i>
                        Ditolak
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "6"
                        })}
                        onClick={() => {
                          this.toggle_icon("6");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses"></i>
                        Selesai
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataMenungguEksekusi}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal2}
                              {this.state.sebelum2}
                              {this.state.hal2.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah2}
                              {this.state.akhir2}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataPenerbitan}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal3}
                              {this.state.sebelum3}
                              {this.state.hal3.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah3}
                              {this.state.akhir3}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataPending}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal4}
                              {this.state.sebelum4}
                              {this.state.hal4.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah4}
                              {this.state.akhir4}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataDitolak}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal5}
                              {this.state.sebelum5}
                              {this.state.hal5.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah5}
                              {this.state.akhir5}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataSelesai}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal6}
                              {this.state.sebelum6}
                              {this.state.hal6.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah6}
                              {this.state.akhir6}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <DetailSuratProvider value={detailSuratState}>
          <ModalUpdate
            jenis_surat={this.state.detailSurat.jenis_surat}
            detail_surat_context={DetailSuratContext}
          />
        </DetailSuratProvider>

        <Modal
          styles={{ modal: { width: "80%" } }}
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanCatatan}>
            <div className="modal-header">
              <h5 className="modal-title">Ubah Catatan</h5>
            </div>
            <div className="modal-body">
              <BootstrapTable
                keyField="uuid"
                data={this.state.catatan.data}
                columns={columns3}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                onClick={this.simpanCatatan}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal disposisi */}
        <Modal
          open={this.state.status.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.disposisi.riwayat.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status == 0
                            ? "Menunggu Disposisi"
                            : dt.status == 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnFormAcc}
              >
                Simpan
              </button>
            </div> */}
          </Form>
        </Modal>

        <Modal
          open={this.state.status.formKeterangan}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Daftar Keterangan Perangkat</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Pejabat</th>
                    <th>Status</th>
                    <th>Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.keteranganPenduduk != null
                    ? this.state.keteranganPenduduk.map((dt, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{dt.data_perangkat.data_jabatan.nama}</td>
                            <td>
                              {dt.status == 0
                                ? "Menunggu Disposisi"
                                : dt.status == 1
                                ? "Telah di disposisi"
                                : "Ditolak"}
                            </td>
                            <td>
                              {dt.keterangan != ""
                                ? dt.keterangan
                                : "Tidak ada keterangan"}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </Form>
        </Modal>

        <Modal
          open={this.state.status.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid_surat"
                type="hidden"
                placeholder=""
                defaultValue={this.state.surat.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <ReactDatePicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.surat.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Persetujuan
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeAcc(e, "status-acc");
                    }}
                    defaultValue={this.state.status.accStat}
                    value={this.state.status.accStat}
                    name="Status "
                    options={this.state.status.formSelectAcc}
                    placeholder="Pilih Status Persetujuan "
                    isClearable
                  />
                </Col>
              </FormGroup> */}
              <Col sm="12" style={{ display: this.state.status.tableAcc }}>
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        {/* Data Cetak Surat */}
        <CetakSurat
          cetak={this.state.cetak}
          jenis={this.state.cetak.jenis_surat}
        />
      </div>
    );
  }
}

export default Disposisi;
