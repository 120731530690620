import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Datepicker from 'react-datepicker';
// Import the library

import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';


// koneksi
import { Post, PostStatus, Get, Delete, cekLogin } from "../function/Koneksi";
import { Tanggal } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');
const frmDef = {
  id: "",
  id_rt: "",
  alamat: "",
};
class KartuKeluarga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        view: false,
        form: false,
        btnForm: false,
        btnAksi: false,
      },
      datakk: {
        id: '',
        nama: '',
        tempat_lahir: '',
        nama_ibu_kandung: '',
        nama_ayah_kandung: ''
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      shdk: [],
      detailshdk:[],
      batasKK:'',
      penduduk_kk_baru: [],
      data_keluarga: [],
      diskk: false,
      tampilkk: 'inherit',
      wilayah: 'inherit',
      addForm: false,
      addMode: 'provinsi',
      addID: '',
      addNama: '',
      frmAnggota : "none",
      detail : [],    
      view_kk: '',
      view_penduduk: [],
      dt: frmDef,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      pekerjaan:[],
      hubungan:[],
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: null,
      frmKbkt: [],
      kbktStat: null,
      frmKec: [],
      kecStat: null,
      frmKel: [],
      kelStat: null,
      dusStat: null,
      frmDus: [],
      frmShdk: [],
      shdkStat: null,
      frmAgm: [],
      agmStat: null,
      frmPtr: [],
      ptrStat: null,
      frmPkr: [],
      pkrStat: null,
      frmKk: [],
      kkStat: null,
      frmStp: [],
      stpStat: null,
      frmJk: [],
      jkStat: null,
      frmSpk: [],
      spkStat: null,
      agmkkStat: null,
      ptrkkStat: null,
      pkrkkStat: null,
      stpkkStat: null,
      jkkkStat: null,
      spkkkStat: null,
      // ============ Tanggal ==============
      startDate: new Date,
      startDatekk: new Date,
      // ===================================
      data_penduduk: "",
      statUbah:false,
      pecahKK: false,
      dataKK:{
        id:''
      },
      tanggal_lahir:'',
      tanggal_lahirkk:'',
    };
  }

    // ================== Tanggal ===================
    
    gantiTgl = (e,kk) => {
    // e.preventDefault();
      if(kk=="kk"){
        // console.log(typeof e.format('YYYY-MM-DD'))
        this.state.tanggal_lahirkk=e.format('YYYY-MM-DD')

        this.forceUpdate();
        //  this.setState({tanggal_lahirkk:e.format('YYYY-MM-DD')})
         console.log(this.state.tanggal_lahirkk)
      } else{
        // this.setState({tanggal_lahir:e.format('YYYY-MM-DD')})
        this.state.tanggal_lahir=e.format('YYYY-MM-DD')
        this.forceUpdate();

      }
    }

  // ==============================================
  
  // ================== Additional Form ==================
  addForm = (e,mode) => {
    e.preventDefault();
    if (mode == "provinsi") 
    {
      this.setState({addMode:mode,addForm:true});
    }
    else 
    {
      switch(mode)
      {
        case "kabupaten/kota":
          if (this.state.prvStat.value)
          {
            this.setState({addMode:mode,addForm:true}); 
          }
          else 
          {
            this.setState({ show: true, basicType:'info', basicTitle:'Data Kabupaten/Kota', pesanAlert:'Untuk menambah Kabupaten/Kota, Silakan pilih terlebih dahulu Provinsi nya.' });
          }
          break;
        case "kecamatan":
          if (this.state.kbktStat.value)
          {
            this.setState({addMode:mode,addForm:true}); 
          }
          else 
          {
            this.setState({ show: true, basicType:'info', basicTitle:'Data Kecamatan', pesanAlert:'Untuk menambah Kecamatan, Silakan pilih terlebih dahulu Kabupaten/Kota nya.' });
          }
          break;
        case "kelurahan/desa":
          if (this.state.kecStat.value)
          {
            this.setState({addMode:mode,addForm:true}); 
          }
          else 
          {
            this.setState({ show: true, basicType:'info', basicTitle:'Data Kelurahan/Desa',   pesanAlert:'Untuk menambah Kelurahan/Desa, Silakan pilih terlebih dahulu Kecamatan nya.' });
          }
          break;
        case "dusun":
          if (this.state.kelStat.value)
          {
            this.setState({addMode:mode,addForm:true}); 
          }
          else 
          {
            this.setState({ show: true, basicType:'info', basicTitle:'Data Dusun', pesanAlert:'Untuk menambah Dusun, Silakan pilih terlebih dahulu Kelurahan/Desa nya.' });
          }
          break;
        case "rw":
          if (this.state.dusStat.value)
          {
            this.setState({addMode:mode,addForm:true}); 
          }
          else 
          {
            this.setState({ show: true, basicType:'info', basicTitle:'Data RW', pesanAlert:'Untuk menambah RW, Silakan pilih terlebih dahulu Dusun nya.' });
          }
          break;
        case "rt":
          if (this.state.rwStat.value)
          {
            this.setState({addMode:mode,addForm:true}); 
          }
          else 
          {
            this.setState({ show: true, basicType:'info', basicTitle:'Data RT', pesanAlert:'Untuk menambah RT, Silakan pilih terlebih dahulu RW nya.' });
          }
          break;
      }
    }
  }

  tutupAddForm = e => {
    e.preventDefault();
    this.setState({mode:"provinsi",addID:'',addNama:'',addForm:false});
  }

  addSimpan = (e,mode) => {
    this.state.status.btnForm = true;
    this.forceUpdate();
    e.preventDefault();
    let addWil = {}
    switch(mode)
    {
      case "provinsi":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('provinsi/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.componentDidMount();
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Provinsi",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Provinsi",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });break;
      case "kabupaten/kota":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          id_provinsi : this.state.prvStat.value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('kab-kota/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.getChild(
              this.state.prvStat,
              "provinsi",
              "prov",
              "kab-kota"
            );
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Kabupaten/Kota",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Kabupaten/Kota",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });
        break;
      case "kecamatan":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          id_kabkota : this.state.kbktStat.value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('kecamatan/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.getChild(
              this.state.kbktStat,
              "kab-kota",
              "kab",
              "kecamatan"
            );
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Kecamatan",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Kecamatan",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });
        break;
      case "kelurahan/desa":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          id_kecamatan : this.state.kecStat.value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('keldes/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.getChild(
              this.state.kecStat,
              "kecamatan",
              "kec",
              "keldes"
            );
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Kelurahan/Desa",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Kelurahan/Desa",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });
        break;
      case "dusun":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          id_keldes : this.state.kelStat.value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('dusun/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.getChild(
              this.state.kelStat,
              "keldes",
              "kec",
              "dusun"
            );
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Dusun",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Dusun",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });
        break;
      case "rw":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          id_dusun : this.state.dusStat.value,
          id_keldes : this.state.kelStat.value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('rw/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.getChild(
              this.state.dusStat,
              "dusun",
              "dus",
              "rw"
            );
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "RW",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "RW",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });
        break;
      case "rt":
        addWil = {
          id : document.getElementById("id_wilayah").value,
          id_rw : this.state.rwStat.value,
          nama : document.getElementById("nama_wilayah").value,
        }
        Post('rt/create', null, addWil, (res) => {
          this.state.status.btnForm = false;
          this.forceUpdate();
          if (res.status == 201)
          {
            this.getChild(
              this.state.rwStat,
              "rw",
              "rw",
              "rt"
            );
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "RT",
              pesanAlert: "Berhasil Tambah Data",addMode:"provinsi",addID:'',addNama:'',addForm:false
            });
          }
          else 
          {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "RT",
              pesanAlert: "Gagal Tambah Data. Mohon coba lagi.",
            });
          }
        });
        break;
    }
  }

  // =====================================================
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onRecieveInput = (value) => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Post("kartu-keluarga" + link, null,this.state.dataKK ,(dtkat) => {
      this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results });
      this.pagination(dtkat.data.results);
  });
}

  // Cari Data Table
cariData = e => {
    if (e.key == "Enter")
    {
        let dataKK = {
            id : e.target.value,
        };
        this.setState({dataKK})
        this.fetch()
        // Post('kartu-keluarga',null, dataKK, (data) => {
        //     this.setState({data:data.data.results.data, pagination:data.data.results});
        // });
    }
}
// Pagination
pagination = (data) => {
  // const data = this.state.pagination;
  console.log(data);
  let awal = "";
  let hal = [];
  let sebelum = "";
  let setelah = "";
  let akhir = "";
  if (data.total > 0)
  {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3)
      {   
          start = 1;
          end = 5;
          if (data.last_page > data.current_page)
          {
              n = data.current_page + 1;
          }
      }
      else 
      {
          p = data.current_page - 1;
          n = data.current_page + 1;
          start = data.current_page - 2;
          end = data.current_page + 2;
      }
      if (end >= data.last_page - 2)
      {
          p = data.current_page - 1;
          if (start >= 5)
          {
              start = data.last_page - 4;
          }
          end = data.last_page;
      }

      for (let i=start;i<=end;i++) 
      {
          let warna = "success";
          if (i == data.current_page)
          {
              warna = "danger"
          }
          hal.push(<a onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
      }
      if (p > 0)
      {
          sebelum = (<a onClick={()=>this.fetch({page:p})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
      }
      if (n > 0)
      {
          setelah = (<a onClick={()=>this.fetch({page:n})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
      }
      awal = (<a onClick={()=>this.fetch({page:1})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
      akhir = (<a onClick={()=>this.fetch({page:data.last_page})} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
  }
  this.setState({awal,sebelum,hal,setelah,akhir});
}


  // ========================================================================

  // ================== simpan data ==================
  simpan = (e,mode) => {
    e.preventDefault();
    if (((this.state.rtStat && this.state.wilayah == "inherit") || this.state.wilayah == "none") &&  this.state.agmkkStat && this.state.pkrkkStat && this.state.ptrkkStat && this.state.spkkkStat && this.state.stpkkStat && this.state.jkkkStat && this.state.kelStat) {
      let kepala = {
        id : document.getElementById("nikkk").value,
        id_kk : document.getElementById("idBaru").value,
        id_keldes : this.state.kelStat?this.state.kelStat.value:null,
        nama : document.getElementById("namakk").value,
        alamat: document.getElementById("alamat").value,
        tempat_lahir: document.getElementById("tempat_lahirkk").value,
        tanggal_lahir: this.state.tanggal_lahirkk,
        jk: this.state.jkkkStat.value,
        shdk: "fbbc9dda-f721-49ac-a611-430269d83cc2",
        agama: this.state.agmkkStat.value,
        status_perkawinan: this.state.spkkkStat.value,
        pendidikan_terakhir: this.state.ptrkkStat.value,
        pekerjaan: this.state.pkrkkStat.value,
        nama_ibu_kandung: document.getElementById("nama_ibu_kandungkk").value,
        nama_ayah_kandung: document.getElementById("nama_ayah_kandungkk").value,
        status_penduduk: this.state.stpkkStat.value,
      }
      this.state.detail.push(kepala);
      this.state.status.btnForm = true;
      this.forceUpdate();
      let id =document.getElementById("id").value;
      let add = {
        id: parseInt(document.getElementById("idBaru").value),
        id_rt: this.state.dt.id_rt,
        id_desa: this.state.kelStat.value,
        alamat: document.getElementById("alamat").value, 
        detail : this.state.detail
      };
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (this.state.detail)
      {
        metode = "create/with-detail";
        if (this.state.pecahKK)
        {
          metode = "create";
        }
      }
      else 
      {
        delete add.detail;
      }
      if (id == 0) {
        psn = "Tambah";
        resstat = 201;
        id = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
        delete add.detail;
      }
      PostStatus("kartu-keluarga/" + metode, id, add, (res) => {
        if (res === resstat) {
          this.tutupForm();
          if (this.state.pecahKK)
          {
            let penduduk = this.state.data_penduduk.split(";"); 
            console.log(this.state.detailshdk);
            Post("kartu-keluarga/update/anggota/shdk", null, {detail:this.state.detailshdk}, (res) => {
              console.log(res);
              for (let i=1;i<penduduk.length;i++)
              {
                let data = {
                  id_kk:parseInt(document.getElementById("idBaru").value)
                }
                let pddk = penduduk[i].split("-");
                PostStatus("penduduk/update", pddk[0], data, (res) => {
                  if (mode == 1)
                  {
                    Get('kartu-keluarga/find',pddk[1],(dtkk) => {
                      this.getPenduduk(dtkk.results);
                      this.state.dt.id = 0;
                      this.state.dt.alamat = "";
                      document.getElementById("id").value = 0;
                      document.getElementById("idBaru").value = 0;
                      document.getElementById("alamat").value = "";
                      this.state.statUbah = false;
                      this.forceUpdate();
                    });
                  }
                  else 
                  {
                    this.state.status.form = false;
                    this.forceUpdate();
                  }
                  if (penduduk.length == i+1)
                  {
                    this.setState({
                      show: true,
                      basicType: "success",
                      basicTitle: "Data Kartu Keluarga",
                      pesanAlert: "Berhasil Pecah KK ke "+add.id,
                    });
                    this.componentDidMount();
                  }
                })
              }
            });
          }
          else 
          {
            this.state.status.btnForm = false;
            if (mode == 1)
            {
              this.state.dt.id = 0;
              this.state.dt.alamat = "";
              document.getElementById("id").value = 0;
              document.getElementById("idBaru").value = 0;
              document.getElementById("alamat").value = "";
              this.state.statUbah = false;
              this.state.frmAnggota = "none";
              this.state.detail= [];
            }
            else 
            {
              this.state.status.form = false;
            }
            this.forceUpdate();
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Kartu Keluarga",
              pesanAlert: "Berhasil " + psn + " Data",
            });
            this.componentDidMount();
          }
        } else {
          this.state.status.btnForm = false;
          this.forceUpdate();
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Kartu Keluarga",
            pesanAlert: "Gagal " + psn + " Data",
          });
          this.componentDidMount();
        }
      });
    } else {
      let luar = "";
      if (this.state.wilayah == "inherit")
      {
        luar = "RT, ";
      }
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Kartu Keluarga",
        pesanAlert: "Data belum lengkap. Mohon Pastikan data "+luar+"Agama, Pendidikan Terakhir, Pekerjaan, Status Perkawinan, Status Penduduk dan Jenis Kelamin sudah dipilih",
      });
    }
  };


  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = (id) => {
    this.state.tampilkk = 'none';
    this.state.diskk = true;
    this.state.status.btnAksi = true;
    this.state.pecahKK = false;
    this.forceUpdate();
    Get("kartu-keluarga/find", id, (data) => {
      this.state.dt.id = data.results.id;
      this.state.dt.id_rt = data.results.id_rt;
      this.state.dt.alamat = data.results.alamat;
      this.state.status.btnAksi = false;
      if (data.results.data_rt)
      {
        this.state.wilayah = "inherit";
      }
      else 
      {
        this.state.wilayah = "none";
      }
      data.results.data_keluarga.map((dt) => {
        if (dt.shdk == "fbbc9dda-f721-49ac-a611-430269d83cc2")
        {
          this.state.datakk.id = dt.id;
          this.state.datakk.nama = dt.nama;
          this.state.datakk.tempat_lahir = dt.tempat_lahir;
          this.state.datakk.nama_ibu_kandung = dt.nama_ibu_kandung;
          this.state.datakk.nama_ayah_kandung = dt.nama_ayah_kandung;
          this.state.agmkkStat = {value:dt.data_agama.uuid, label:dt.data_agama.nama};
          this.state.pkrkkStat = {value:dt.data_pekerjaan.uuid, label:dt.data_pekerjaan.nama};
          this.state.ptrkkStat = {value:dt.data_pendidikan_terakhir.uuid, label:dt.data_pendidikan_terakhir.nama};
          this.state.jkkkStat = {value:dt.jk, label:dt.jk};
          this.state.startDatekk = new Date(dt.tanggal_lahir);
          this.state.spkkkStat = {value:dt.status_perkawinan, label:dt.status_perkawinan};
          this.state.stpkkStat = {value:dt.status_penduduk, label:dt.status_penduduk};
        }
        else 
        {
          this.state.pekerjaan[dt.pekerjaan] = dt.data_pekerjaan.nama;
          this.state.hubungan[dt.shdk] = dt.data_shdk.nama;
          this.state.detail.push({    
            id : dt.id,
            id_kk : dt.id_kk,
            id_keldes : dt.id_keldes,
            nama : dt.nama,
            alamat: dt.alamat,
            tempat_lahir: dt.tempat_lahir,
            tanggal_lahir: dt.tanggal_lahir,
            jk: dt.jk,
            shdk: dt.shdk,
            agama: dt.agama,
            status_perkawinan: dt.status_perkawinan,
            pendidikan_terakhir: dt.pendidikan_terakhir,
            pekerjaan: dt.pekerjaan,
            nama_ibu_kandung: dt.nama_ibu_kandung,
            nama_ayah_kandung: dt.nama_ayah_kandung,
            status_penduduk: dt.status_penduduk,
          });
        }
      })
      this.forceUpdate();
      if (data.results.data_rt)
      {
        this.ubahPilihan("rt", data.results.data_rt);
      }
      else 
      {
        this.ubahPilihan("keldes", data.results.data_keldes);
      }
     
    });
  };
  ubahPilihan = (table, data) => {
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "kecamatan":
        this.getChild(
          {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama,
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          { value: data.data_kabkota.id, label: data.data_kabkota.nama },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.setState({
          prvStat: {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama,
          },
          kbktStat: {
            value: data.data_kabkota.id,
            label: data.data_kabkota.nama,
          },
          kecStat: { value: data.id, label: data.nama },
        });
        case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama,
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama,
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );
        

        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama,
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama,
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama,
          },
          kelStat: { value: data.id, label: data.nama },
        });
        this.bukaForm();
        break;
      case "rt":
        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama,
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama,
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id, label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          { value: data.data_rw.data_dusun.data_keldes.id, label: data.data_rw.data_dusun.data_keldes.nama },
          "keldes",
          "kel",
          "dusun"
        );

        this.getChild(
          { value: data.data_rw.data_dusun.id, label: data.data_rw.data_dusun.nama },
          "dusun",
          "dus",
          "rw"
        );

        this.getChild(
          { value: data.data_rw.id, label: data.data_rw.nama },
          "rw",
          "rw",
          "rt"
        );

        this.setState({
          prvStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama,
          },
          kbktStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama,
          },
          kecStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama,
          },
          kelStat: { value: data.data_rw.data_dusun.data_keldes.id, label: data.data_rw.data_dusun.data_keldes.nama },

          dusStat: { value: data.data_rw.data_dusun.id, label: data.data_rw.data_dusun.nama },
          rwStat: { value: data.data_rw.id, label: data.data_rw.nama },
          rtStat: { value: data.id, label: data.nama },
        });
        this.bukaForm();
        break;
    }
    // });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = (id) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      ),
    });
  };

  hapus = (i) => {
    this.state.detail.splice(i,1);
    this.forceUpdate();
  }
  
  formAnggota = e => {
    e.preventDefault();
    if (this.state.kelStat)
    {
      this.state.status.anggota = true;
      this.forceUpdate();
    }
    else 
    {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Anggota Keluarga",
        pesanAlert: "Data Kelurahan/Desa harus dipilih",
      });
    }
  }

  tutupAnggota = e => {
    e.preventDefault();
    document.getElementById("nik").value = "";
    document.getElementById("nama").value = "";
    document.getElementById("nama_ibu_kandung").value = "";
    document.getElementById("nama_ayah_kandung").value = "";
    this.state.shdkStat = null;
    this.state.agmStat = null;
    this.state.pkrStat = null;
    this.state.ptrStat = null;
    this.state.spkStat = null;
    this.state.stpStat = null;
    this.state.status.anggota = false;
    this.forceUpdate();
  }

  tambahAnggota = e => {
    e.preventDefault();
    if (this.state.shdkStat && this.state.agmStat && this.state.pkrStat && this.state.ptrStat && this.state.spkStat && this.state.stpStat && this.state.jkStat)
    {
      let detail = this.state.detail;
      let hubungan = this.state.hubungan;
      let pekerjaan = this.state.pekerjaan;
      pekerjaan[this.state.pkrStat.value] = this.state.pkrStat.label;
      hubungan[this.state.shdkStat.value] = this.state.shdkStat.label;
      detail.push({
        id : document.getElementById("nik").value,
        id_kk : document.getElementById("idBaru").value,
        id_keldes : this.state.kelStat.value,
        nama : document.getElementById("nama").value,
        alamat: document.getElementById("alamat").value,
        tempat_lahir: document.getElementById("tempat_lahir").value,
        tanggal_lahir: this.state.tanggal_lahir,
        jk: this.state.dt.jk,
        shdk: this.state.dt.shdk,
        agama: this.state.dt.agama,
        status_perkawinan: this.state.dt.status_perkawinan,
        pendidikan_terakhir: this.state.dt.pendidikan_terakhir,
        pekerjaan: this.state.dt.pekerjaan,
        nama_ibu_kandung: document.getElementById("nama_ibu_kandung").value,
        nama_ayah_kandung: document.getElementById("nama_ayah_kandung").value,
        status_penduduk: this.state.dt.status_penduduk,
      });
      this.setState({detail,pekerjaan,hubungan,jkStat:null,shdkStat:null,agmStat:null,spkStat:null,stpStat:null,pkrStat:null,ptrStat:null});
      document.getElementById("nik").value = "";
      document.getElementById("nama").value = "";
      document.getElementById("tempat_lahir").value = "";
      document.getElementById("nama_ibu_kandung").value = "";
      document.getElementById("nama_ayah_kandung").value = "";
    }
    else 
    {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Anggota Keluarga",
        pesanAlert: "Data belum lengkap. Pastikan data SHDK, Agama, Pendidikan, Pekerjaan, Status Perkawinan, Status Penduduk telah dipilih",
      });
    }
  }

  hapusData = (id) => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kartu-keluarga/delete", id, (res) => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Kartu Keluarga",
          pesanAlert: "Berhasil " + psn + " Data",
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Kartu Keluarga",
          pesanAlert: "Gagal " + psn + " Data",
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaView = (id) => {
    Get('kartu-keluarga/keluarga', id, (data) => {
      this.state.view_kk = id;
      this.state.view_penduduk = data.results;
      this.state.status.view = true;
      this.forceUpdate();
    })
  };

  tutupView = () => {
    this.state.status.view = false;
    this.forceUpdate();
  };

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.state.agmkkStat = null;
    this.state.pkrkkStat = null;
    this.state.ptrkkStat = null;
    this.state.spkkkStat = null;
    this.state.stpkkStat = null;
    this.state.jkkkStat = null;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    let pecah = "";
    if (Object.keys(row.data_keluarga).length > 1)
    {
      pecah = 
      <Button
        size="xs"
        color="primary"
        className="btn-icon mr-3"
        onClick={() => {
          this.setState({
            judul: "Pecah Kartu Keluarga Untuk Nomor KK " + row.id,
            pecahKK:true
          });
          this.pecahKK(row);
        }}
        disabled={this.state.status.btnAksi}
      >
        <i className="fa fa-exchange"></i>
      </Button>
    }
    return (
      <>
        {pecah}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon mr-3"
          onClick={() => {
            this.bukaView(row.id);
          }}
        >
          <i className="fa fa-list"></i>
        </Button> */}
        <Button
          size="xs"
          color="success"
          className="btn-icon mr-3"
          onClick={() => {
            this.setState({
              judul: "Ubah Kartu Keluarga",
              statUbah:true,
            });
            this.ubahData(row.id);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.id)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, (data) => {
        let frm = [];
        data.results.map((dt) => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKec: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKel: frm });
            break;
          case "dusun":
            this.setState({ frmDus: frm });
            break;

          case "rw":
            this.setState({ frmRw: frm });
            break;

          case "rt":
            this.setState({ frmRt: frm });
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: e });
          this.setState({ kbktStat: null });
          this.setState({ kecStat: null });
          break;
        case "kab-kota":
          this.setState({ kbktStat: e });
          this.setState({ kecStat: null });
          break;
        case "kecamatan":
          this.setState({ kecStat: e });
          this.setState({ kelStat: null });

          break;
        case "keldes":
          this.setState({ kelStat: e });
          this.setState({ dusStat: null });

          break;

        case "dusun":
          this.setState({ dusStat: e });
          this.setState({ rwStat: null });

          break;

        case "rw":
          this.setState({ rwStat: e });
          this.setState({ rtStat: null });

          break;

        case "rt":
          this.state.dt.id_rt=e.value;
          this.setState({ rtStat: e });
          break;
        case "ukuran_kertas":
          this.state.dt.id_ukuran_kertas = e.value;
          this.setState({ ukkStat: e });
          break;
          
        case 'shdk': this.state.dt.shdk = e.value;this.setState({shdkStat:e}); break;
        case 'agama': this.state.dt.agama = e.value;this.setState({agmStat:e}); break;
        case 'pendidikan-terakhir': this.state.dt.pendidikan_terakhir = e.value;this.setState({ptrStat:e}); break;
        case 'pekerjaan': this.state.dt.pekerjaan = e.value;this.setState({pkrStat:e}); break;
        case 'kk': this.state.dt.id_kk = e.value;this.setState({kkStat:e}); break;
        case 'status-penduduk': this.state.dt.status_penduduk = e.value;this.setState({stpStat:e}); break;
        case 'jk': this.state.dt.jk = e.value;this.setState({jkStat:e}); break;
        case 'status-perkawinan': this.state.dt.status_perkawinan = e.value;this.setState({spkStat:e}); break;
        case 'agamakk': this.setState({agmkkStat:e}); break;
        case 'pendidikan-terakhirkk': this.setState({ptrkkStat:e}); break;
        case 'pekerjaankk': this.setState({pkrkkStat:e}); break;
        case 'status-pendudukkk': this.setState({stpkkStat:e}); break;
        case 'jkkk': this.setState({jkkkStat:e}); break;
        case 'status-perkawinankk': this.setState({spkkkStat:e}); break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: null });
          this.setState({ kbktStat: null, frmKbkt: [] });
          this.setState({ kecStat: null, frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({ kelStat: null, frmKel: [] ,frmDus: [],dusStat: null,frmRw: [],rwStat: null,frmRt: [],rtStat: null});
          break;
        case "kab-kota":
          this.setState({ kbktStat: null });
          this.setState({ kecStat: null, frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({ kelStat: null, frmKel: [],frmDus: [],dusStat: null,frmRw: [],rwStat: null,frmRt: [],rtStat: null });

          break;
        case "kecamatan":
          this.setState({ kecStat: null });
          this.state.dt.id_rt = 0;
          this.setState({ kelStat: null, frmKel: [],frmDus: [],dusStat: null,frmRw: [],rwStat: null,frmRt: [],rtStat: null });

          break;
        case "keldes":
          this.state.dt.id_rt = 0;
          this.setState({ kelStat: null ,frmDus: [],dusStat: null,frmRw: [],rwStat: null,frmRt: [],rtStat: null });
          break;
        case "dusun":
          this.state.dt.id_rt = 0;
          this.setState({ dusStat: null,frmrw: [],rwStat: null,frmRt: [],rtStat: null });
            break;
        case "rw":
          this.state.dt.id_rt = 0;
          this.setState({ rwStat: null,frmRt: [],rtStat: null });
          break;
        case "rt":
          this.state.dt.id_rt = 0;
          this.setState({ rtStat: null });
          break;
        case 'shdk': this.state.dt.shdk = 0;this.setState({shdkStat:null}); break;
        case 'agama': this.state.dt.agama = 0;this.setState({agmStat:null}); break;
        case 'pendidikan-terakhir': this.state.dt.pendidikan_terakhir = 0;this.setState({ptrStat:null}); break;
        case 'pekerjaan': this.state.dt.pekerjaan = 0;this.setState({pkrStat:null}); break;
        case 'kk': this.state.dt.id_kk = 0;this.setState({kkStat:null}); break;
        case 'status-penduduk': this.state.dt.status_penduduk = 0;this.setState({stpStat:e}); break;
        case 'jk': this.state.dt.jk = 0;this.setState({jkStat:e}); break;
        case 'status-perkawinan': this.state.dt.status_perkawinan = '';this.setState({spkStat:e}); break;
        case 'jenis-berkas': this.setState({jbkStat:e}); break;
        case 'status-berkas': this.setState({sbkStat:e});break;
        case 'agamakk': this.setState({agmkkStat:e}); break;
        case 'pendidikan-terakhirkk': this.setState({ptrkkStat:e}); break;
        case 'pekerjaankk': this.setState({pkrkkStat:e}); break;
        case 'status-pendudukkk': this.setState({stpkkStat:e}); break;
        case 'jkkk': this.setState({jkkkStat:e}); break;
        case 'status-perkawinankk': this.setState({spkkkStat:e}); break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  componentDidMount() {
    this.fetch({ page: null, where: [] });
    Get("provinsi/list", null, (data) => {
      let frmPrv = [];
      data.results.map((dt) => {
        frmPrv.push({ value: dt.id, label: dt.nama });
      });
      this.setState({ frmPrv });
    });
    Get('shdk/list', null, (data) => {
        let frmShdk = [];
        data.results.map((dt) => {
          if (dt.uuid != "fbbc9dda-f721-49ac-a611-430269d83cc2")
          {
            frmShdk.push({value:dt.uuid, label:dt.nama});
          }
        });
        this.setState({frmShdk});
    });
    Get('agama/list', null, (data) => {
        let frmAgm = [];
        data.results.map((dt) => {
            frmAgm.push({value:dt.uuid, label:dt.nama});
        });
        this.setState({frmAgm});
    });
    Get('jenjang-pendidikan/list', null, (data) => {
        let frmPtr = [];
        data.results.map((dt) => {
            frmPtr.push({value:dt.uuid, label:dt.nama});
        });
        this.setState({frmPtr});
    });
    Get('pekerjaan/list', null, (data) => {
        let frmPkr = [];
        data.results.map((dt) => {
            frmPkr.push({value:dt.uuid, label:dt.nama});
        });
        this.setState({frmPkr});
    });
    Get('select-enum/penduduk/status_penduduk', null, (data) => {
        let frmStp = [];
        data.map((dt) => {
            frmStp.push({value:dt, label:dt});
        });
        this.setState({frmStp});
    });
    Get('select-enum/penduduk/jk', null, (data) => {
        let frmJk = [];
        data.map((dt) => {
            frmJk.push({value:dt, label:dt});
        });
        this.setState({frmJk});
    });
    Get('select-enum/penduduk/status_perkawinan', null, (data) => {
        let frmSpk = [];
        data.map((dt) => {
            frmSpk.push({value:dt, label:dt});
        });
        this.setState({frmSpk});
    });
  }

  pecahKK = (data) => {
    this.state.data_keluarga = data.data_keluarga;
    // this.getPenduduk(data);
    this.state.tampilkk = "none";
    this.state.diskk = true;
    this.bukaForm();
  }

  ambilPenduduk = (e, nik, kk, idx) =>{
    e.preventDefault();
    let data_penduduk = "";
    let penduduk_kk_baru = this.state.penduduk_kk_baru;
    let dt = this.state.data_keluarga[idx];
    data_penduduk = this.state.data_penduduk+";"+nik+"-"+kk;
    penduduk_kk_baru.push(dt);
    this.state.datakk.id = dt.id;
    this.state.datakk.nama = dt.nama;
    this.state.datakk.tempat_lahir = dt.tempat_lahir;
    this.state.datakk.nama_ibu_kandung = dt.nama_ibu_kandung;
    this.state.datakk.nama_ayah_kandung = dt.nama_ayah_kandung;
    this.state.agmkkStat = {value:dt.data_agama.uuid, label:dt.data_agama.nama};
    this.state.pkrkkStat = {value:dt.data_pekerjaan.uuid, label:dt.data_pekerjaan.nama};
    this.state.ptrkkStat = {value:dt.data_pendidikan_terakhir.uuid, label:dt.data_pendidikan_terakhir.nama};
    this.state.jkkkStat = {value:dt.jk, label:dt.jk};
    this.state.startDatekk = new Date(dt.tanggal_lahir);
    this.state.spkkkStat = {value:dt.status_perkawinan, label:dt.status_perkawinan};
    this.state.stpkkStat = {value:dt.status_penduduk, label:dt.status_penduduk};
    this.state.data_keluarga.splice(idx,1);
    this.forceUpdate();
    if (Object.keys(this.state.data_keluarga).length < 2)
    {
      this.setState({batasKK:' disabled'});
    }
    else 
    {
      this.setState({batasKK:''});
    }
    this.setState({data_penduduk, penduduk_kk_baru});
  }
  hapusPenduduk = (e, nik, kk, idx) =>{
    e.preventDefault();
    let data_penduduk = "";
    let data_keluarga = this.state.data_keluarga;
    let dt = this.state.penduduk_kk_baru[idx];
      data_keluarga.push(dt);
    this.state.penduduk_kk_baru.splice(idx,1);
    this.state.shdk.splice(nik,1);
    this.forceUpdate();
    data_penduduk = this.state.data_penduduk.replace(";"+nik+"-"+kk,"");
    this.setState({data_penduduk, data_keluarga, batasKK:''});
  }

  getJumlahKeluarga = (cell,row) => {
    let jumlah = 0;
    if (Object.keys(row.data_keluarga).length > 0)
    {
      jumlah = Object.keys(row.data_keluarga).length;
    }
    
    return <a  href="#"  onClick={() => {
      this.bukaView(row.id);
    }}> {jumlah} Orang</a>;
  }

  getPenduduk = (data) => {
    let penduduk_kk = [];
    let shdk = [];
    this.setState({data_keluarga:data.data_keluarga});
    penduduk_kk = this.state.data_keluarga.map((dt,i) => {
      return(
        <tr key={dt.id}>
          <td style={{padding:"5px 5px 5px 5px"}}>
            <a href="#" className={"btn btn-success btn-xs" + this.state.batasKK} onClick={(e)=>this.ambilPenduduk(e,dt.id,data.id,i)}>Pilih</a>
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.id}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.nama}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.data_shdk.nama}
          </td>
        </tr>
      )
    })
    this.setState({penduduk_kk});
  }

  gantiShdkBaru = (e,dt) => {
    this.state.shdk[dt.id] = {nik:dt.id, shdk:e.target.value}
    this.state.detailshdk.push({nik:dt.id, shdk:e.target.value});
    // if (e.target.value == "fbbc9dda-f721-49ac-a611-430269d83cc2")
    // {
    // }
    this.forceUpdate();  
    console.log(this.state.detailshdk);
  }

  render() {
    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      id: '',
      id_rt: 0,
      alamat: "",
    };
    
    const penduduk_kk = this.state.data_keluarga.map((dt,i) => {
      return(
        <tr key={dt.id}>
          <td style={{padding:"5px 5px 5px 5px"}}>
            <a href="#"className={"btn btn-success btn-xs" + this.state.batasKK} onClick={(e)=>this.ambilPenduduk(e,dt.id,dt.id_kk,i)}>Pilih</a>
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.id}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.nama}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.data_shdk.nama}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            <select name="shdk_baru" onChange={(e)=> this.gantiShdkBaru(e,dt)} className="form-control">
              <option value="">Pilih SHDK Baru</option>
              <option value="fbbc9dda-f721-49ac-a611-430269d83cc2">Kepala Keluarga</option>
              {this.state.frmShdk.map((dtsh => 
                <option value={dtsh.value}>{dtsh.label}</option>
              ))}
            </select>
          </td>
        </tr>
      )
    })
    const penduduk_kk_baru = this.state.penduduk_kk_baru.map((dt,i) => {
      return(
        <tr key={dt.id}>
          <td style={{padding:"5px 5px 5px 5px"}}>
            <a href="#" className="btn btn-danger btn-xs" onClick={(e)=>this.hapusPenduduk(e,dt.id,dt.id_kk,i)}>Batal</a>
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.id}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.nama}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            {dt.data_shdk.nama}
          </td>
          <td style={{padding:"5px 5px 5px 5px"}}>
            <select name="shdk_baru" onChange={(e)=> this.gantiShdkBaru(e,dt)} className="form-control">
              <option value="">Pilih SHDK Baru</option>
              <option value="fbbc9dda-f721-49ac-a611-430269d83cc2">Kepala Keluarga</option>
              {this.state.frmShdk.map((dtsh => 
                <option value={dtsh.value}>{dtsh.label}</option>
              ))}
            </select>
          </td>
        </tr>
        )
    })

    // this.setState({penduduk_kk});
    let pilihPenduduk = "";
    if (this.state.pecahKK)
    {
      pilihPenduduk = 
      <FormGroup className="row">
          <Label className="col-sm-12 col-form-label" htmlFor="inputEmail3"><b>Pilih Penduduk</b></Label>
          <Label className="col-sm-12 col-form-label" htmlFor="inputEmail3">KK Lama</Label>
          <Col sm='12'>
              <table style={{width:"100%"}} border="1" cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th style={{padding:"5px 5px 5px 5px"}}>Pilih</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>NIK</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>Nama</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>TTL</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>SHDK</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>SHDK Baru</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penduduk_kk}
                  </tbody>
              </table>
          </Col>
          <Label className="col-sm-12 col-form-label" htmlFor="inputEmail3">KK Baru</Label>
          <Col sm='12'>
              <table style={{width:"100%"}} border="1" cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th style={{padding:"5px 5px 5px 5px"}}>Batal</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>NIK</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>Nama</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>TTL</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>SHDK</th>
                      <th style={{padding:"5px 5px 5px 5px"}}>SHDK Baru</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penduduk_kk_baru}
                  </tbody>
              </table>
          </Col>
      </FormGroup>
    }

    const input_anggota = this.state.detail.map((dt,i) => {
      return (
        <tr>
          <td>{i+1}</td>
          <td>{dt.id}</td>
          <td>{dt.nama}</td>
          <td>{dt.tempat_lahir}, {Tanggal(new Date(dt.tanggal_lahir))}</td>
          <td>{this.state.pekerjaan[dt.pekerjaan]}</td>
          <td>{this.state.hubungan[dt.shdk]}</td>
          <td><Button size="xs" onClick={()=>this.hapus(i)} color="danger" className="btn-icon"><i className="fa fa-trash"></i></Button></td>
        </tr>
      )
    });

    const { SearchBar } = Search;
    const columns = [
      // {
      //     dataField: 'uuid',
      //     text: 'ID UUD',
      //     sort: false
      // },

      {
        dataField: "id",
        text: "Nomor Kartu Keluarga",
        sort: true,
      },

      {
        dataField: "id_a",
        text: "Nama Penduduk",
        formatter: (cell, obj) => {
          console.log(obj.data_keluarga.length);
          let nama = obj.data_keluarga.length==0?"":obj.data_keluarga[0].nama +' ('+obj.data_keluarga[0].data_shdk.nama+') '
          return nama;
        },
        isDummyField: true,

        sort: true,
      },
      {
        dataField: "id_rt",
        text: "Wilayah",
        isDummyField: true,
        formatter: (cell, obj) => {
          let rtRw = "";
          if (obj.data_rt)
          {
            rtRw += "RT "+obj.data_rt.nama;
            if (obj.data_rt.data_rw)
            {
              rtRw += ", RW "+obj.data_rt.data_rw.nama;
              if (obj.data_rt.data_rw.data_dusun)
              {
                rtRw += ", "+obj.data_rt.data_rw.data_dusun.nama;
                if (obj.data_rt.data_rw.data_dusun.data_keldes)
                {
                  rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.nama;
                  if (obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan)
                  {
                    rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama;
                    if (obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota)
                    {
                      rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
                      if (obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi)
                      {
                        rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
                      }
                    }
                  }
                }
              }
              else 
              {
                rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.nama;
                if (obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan)
                {
                  rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama;
                  if (obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota)
                  {
                    rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
                    if (obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi)
                    {
                      rtRw += ", "+obj.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
                    }
                  }
                }

              }
            }
          }
          return rtRw;
        },
        sort: true,
      },
      {
        dataField: "alamat",
        text: "Alamat",
        sort: true,
      },
      {
        dataField: "jml_keluarga",
        text: "Anggota",
        isDummyField: true,
        formatter: this.getJumlahKeluarga,
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi,
      },
    ];

    const defaultSorted = [
      {
        dataField: "nama_KartuKeluarga",
        order: "asc",
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = "";
      classname = "btn btn-success";
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {total} Results
        </span>
      ),
      paginationSize: size,
      totalSize: total,
    };

    return (
      <div>
        <Breadcrumb title="Data Kartu Keluarga" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Kartu Keluarga</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span>< span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square mr-3"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Kartu Keluarga",
                            dt: frmDef,
                            diskk: false,
                            tampilkk: 'inherit',
                            wilayah: 'inherit',
                            prvStat: null,
                            kbktStat: null,
                            kecStat: null,
                            kelStat: null,
                            dusStat: null,
                            rwStat: null,
                            rtStat: null,
                            pecahKK: false,
                            statUbah:false
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data Dalam Kota
                      </Button>
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square ml-3"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Kartu Keluarga",
                            dt: frmDef,
                            diskk: false,
                            tampilkk: 'inherit',
                            wilayah: 'none',
                            prvStat: null,
                            kbktStat: null,
                            kecStat: null,
                            kelStat: null,
                            dusStat: null,
                            rwStat: null,
                            rtStat: null,
                            pecahKK: false,
                            statUbah:false
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data Luar Kota
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data berdasarkan No KK"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div>
                  <BootstrapTable
                      keyField="ikeyd"
                      data={ data }
                      columns={ columns }
                      />
                  <div className="pull-right text-white">
                      {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <Modal open={this.state.addForm} onClose={this.tutupAddForm} closeOnEsc={false} closeOnOverlayClick={false}>
          <div className="modal-header">
            <h5 className="modal-title">{this.state.addMode}</h5>
          </div>
          <Form className="theme-form" onSubmit={(e)=>this.addSimpan(e,this.state.addMode)}>
            <div className="modal-body">
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  ID Wilayah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="id_wilayah"
                    type="text"
                    placeholder="Masukkan Kode Administratif Wilayah"
                    required
                    defaultValue={this.state.addID}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Wilayah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_wilayah"
                    type="text"
                    placeholder="Masukkan Nama Wilayah"
                    required
                    defaultValue={this.state.addNama}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupAddForm}
                >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
          <div className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={(e)=>this.simpan(e,0)}>
            <div className="modal-body">
                <p>Data KK adalah induk dari data penduduk. Data ini digunakan sebagai penghubung untuk pengurusan surat. Penduduk dalam 1 KK bisa saling mengajukan pelayanan surat dan perizinan.</p>
                {pilihPenduduk}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Provinsi
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                        <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={(e) => {
                            this.getChild(e, "provinsi", "prov", "kab-kota");
                          }}
                          defaultValue={this.state.prvStat}
                          value={this.state.prvStat}
                          name="provinsi"
                          options={this.state.frmPrv}
                          placeholder="Pilih Provinsi"
                          isClearable
                        />
                      </div>
                      <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                        <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"provinsi")}><i className="fa fa-plus"></i></a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kabupaten / Kota
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                        <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={(e) => {
                            this.getChild(e, "kab-kota", "kab", "kecamatan");
                          }}
                          defaultValue={this.state.kbktStat}
                          value={this.state.kbktStat}
                          name="kabkota"
                          options={this.state.frmKbkt}
                          placeholder="Pilih Kabupaten/Kota"
                          isClearable
                        />
                        </div>
                        <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                          <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"kabupaten/kota")}><i className="fa fa-plus"></i></a>
                        </div>
                      </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kecamatan
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, "kecamatan", "kec", "keldes");
                        }}
                        defaultValue={this.state.kecStat}
                        value={this.state.kecStat}
                        name="kecamatan"
                        options={this.state.frmKec}
                        placeholder="Pilih Kecamatan"
                        isClearable
                      />
                      </div>
                      <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                        <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"kecamatan")}><i className="fa fa-plus"></i></a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kelurahan / Desa
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, "keldes", "kel", "dusun");
                        }}
                        defaultValue={this.state.kelStat}
                        value={this.state.kelStat}
                        name="keldes"
                        options={this.state.frmKel}
                        placeholder="Pilih Kelurahan/Desa"
                        isClearable
                        required
                      />
                      </div>
                      <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                        <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"kelurahan/desa")}><i className="fa fa-plus"></i></a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
              <div style={{display:this.state.wilayah}}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Dusun
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, "dusun", "dus", "rw");
                        }}
                        defaultValue={this.state.dusStat}
                        value={this.state.dusStat}
                        name="dusun"
                        options={this.state.frmDus}
                        placeholder="Pilih Dusun"
                        isClearable
                        required
                      />
                      </div>
                      <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                        <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"dusun")}><i className="fa fa-plus"></i></a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Rw
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, "rw", "rw", "rt");
                        }}
                        defaultValue={this.state.rwStat}
                        value={this.state.rwStat}
                        name="rw"
                        options={this.state.frmRw}
                        placeholder="Pilih Rw"
                        isClearable
                        required
                      />
                      </div>
                      <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                        <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"rw")}><i className="fa fa-plus"></i></a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Rt
                  </Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.changeSelectValue(e, "rt");
                        }}
                        defaultValue={this.state.rtStat}
                        value={this.state.rtStat}
                        name="rt"
                        options={this.state.frmRt}
                        placeholder="Pilih Rt"
                        isClearable
                        required
                      />
                      </div>
                      <div className="col-sm-1 p-0" style={{verticalAlign:"middle"}}>
                        <a href="#" className="btn btn-xs btn-success" onClick={(e)=>this.addForm(e,"rt")}><i className="fa fa-plus"></i></a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nomor KK
                </Label>
                <Col sm="9">
                <input
                    className="form-control"
                    id="id"
                    type="hidden"
                    min={0}
                    placeholder="masukan nomor KK"
                    required
                    defaultValue={this.state.dt.id}
                  />
                  <input
                    className="form-control"
                    id="idBaru"
                    type="number"
                    min={0}
                    readOnly={this.state.statUbah}

                    placeholder="masukan nomor KK"
                    required
                    defaultValue={this.state.dt.id}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="masukan nama Alamat"
                    required
                    defaultValue={this.state.dt.alamat}
                  />
                </Col>
              </FormGroup>
              <div style={{display:this.state.tampilkk}}>
                <div className="row">
                  <h5>Form Kepala Keluarga</h5>
                </div>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK</Label>
                    <Col sm='9'>
                        <input className="form-control" id="nikkk" type="text" defaultValue={this.state.datakk.id} placeholder="NIK" disabled={this.state.diskk} required/>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Agama</Label>
                    <Col sm='9'>
                        <Select
                            isDisabled={this.state.status.select}
                            classNamePrefix="select"
                            onChange={((e)=>{ this.changeSelectValue(e,'agamakk')})}
                            defaultValue={this.state.agmkkStat} 
                            value={this.state.agmkkStat} 
                            name="AgamaKK"
                            options={this.state.frmAgm}
                            placeholder="Pilih Agama"
                            isClearable
                            isDisabled={this.state.diskk}
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pendidikan Terakhir</Label>
                    <Col sm='9'>
                        <Select
                            isDisabled={this.state.status.select}
                            classNamePrefix="select"
                            onChange={((e)=>{ this.changeSelectValue(e,'pendidikan-terakhirkk')})}
                            defaultValue={this.state.ptrkkStat} 
                            value={this.state.ptrkkStat} 
                            name="PendidikanKK"
                            options={this.state.frmPtr}
                            placeholder="Pilih Pendidikan Terakhir"
                            isClearable
                            isDisabled={this.state.diskk}
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan</Label>
                    <Col sm='9'>
                        <Select
                            isDisabled={this.state.status.select}
                            classNamePrefix="select"
                            onChange={((e)=>{ this.changeSelectValue(e,'pekerjaankk')})}
                            defaultValue={this.state.pkrkkStat} 
                            value={this.state.pkrkkStat} 
                            name="PekerjaanKK"
                            options={this.state.frmPkr}
                            placeholder="Pilih Pekerjaan"
                            isClearable
                            isDisabled={this.state.diskk}
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Lengkap</Label>
                    <Col sm='9'>
                        <input className="form-control" id="namakk" type="text" placeholder="Nama Lengkap"  defaultValue={this.state.datakk.nama} disabled={this.state.diskk} required/>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">JK</Label>
                    <Col sm='9'>
                        <Select
                            isDisabled={this.state.status.select}
                            classNamePrefix="select"
                            onChange={((e)=>{ this.changeSelectValue(e,'jkkk')})}
                            defaultValue={this.state.jkkkStat} 
                            value={this.state.jkkkStat} 
                            name="Jenis Kelamin"
                            options={this.state.frmJk}
                            placeholder="Pilih Jenis Kelamin"
                            isClearable
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
                    <Col sm='9'>
                        <input className="form-control" id="tempat_lahirkk" type="text" placeholder="Tempat Lahir"  defaultValue={this.state.datakk.tempat_lahir} disabled={this.state.diskk} required/>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
                    <Col sm='9'>
                    <Datetime locale="id-ID" input={true} timeFormat={false}  closeOnTab={true} initialValue={new Date()}
                    onChange={((e)=>{ 
                      this.gantiTgl(e,'kk')
                      // this.setState({ tanggal_lahirkk:})
                      // console.log(e.format('YYYY-MM-DD'))
                    } )}
                    />
    
                
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Perkawinan</Label>
                    <Col sm='9'>
                        <Select
                            isDisabled={this.state.status.select}
                            classNamePrefix="select"
                            onChange={((e)=>{ this.changeSelectValue(e,'status-perkawinankk')})}
                            defaultValue={this.state.spkkkStat} 
                            value={this.state.spkkkStat} 
                            name="Status Perkawinan"
                            options={this.state.frmSpk}
                            placeholder="Pilih Status Perkawinan"
                            isClearable
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Penduduk</Label>
                    <Col sm='9'>
                        <Select
                            isDisabled={this.state.status.select}
                            classNamePrefix="select"
                            onChange={((e)=>{ this.changeSelectValue(e,'status-pendudukkk')})}
                            defaultValue={this.state.stpkkStat} 
                            value={this.state.stpkkStat} 
                            name="Status Penduduk"
                            options={this.state.frmStp}
                            placeholder="Pilih Status Penduduk"
                            isClearable
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Ibu Kandung</Label>
                    <Col sm='9'>
                        <input className="form-control" id="nama_ibu_kandungkk" type="text" placeholder="Nama Ibu Kandung"  defaultValue={this.state.datakk.nama_ibu_kandung} required/>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Ayah Kandung</Label>
                    <Col sm='9'>
                        <input className="form-control" id="nama_ayah_kandungkk" type="text" placeholder="Nama Ayah Kandung"  defaultValue={this.state.datakk.nama_ayah_kandung} required/>
                    </Col>
                </FormGroup>
                <div className="row">
                  <div className="col-lg-12">
                    <a href="#" className="btn btn-info" onClick={this.formAnggota}>Tambah Anggota Keluarga</a>
                  </div>
                </div>
                <br/>
                <FormGroup className="row">
                    <Col sm='12'>  
                      <table className="table table-bordered table-stripes table-hovered" style={{fontSize:"12px"}} width="100%">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>NIK</th>
                                <th>Nama</th>
                                <th>Tempat/Tanggal Lahir</th>
                                <th>Pekerjaan</th>
                                <th>Hubungan Keluarga</th>
                                <th><i className="fa fa-trash"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            {input_anggota}
                        </tbody>
                    </table>
                    </Col>
                </FormGroup>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
                >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                // onClick={(e)=>this.simpan(e,0)}
                >
                Simpan
              </button>
              {/* <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                onClick={(e)=>this.simpan(e,1)}
                >
                Simpan & Tambah
              </button> */}
            </div>
          </Form>
        </Modal>
        <Modal open={this.state.status.anggota} onClose={this.tutupAnggota} closeOnEsc={false} closeOnOverlayClick={false}>
          <Form className="theme-form" onSubmit={this.tambahAnggota}>
            <div className="modal-header">
              <h5 className="modal-title">Anggota Keluarga</h5>
            </div>
            <div className="modal-body">
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK</Label>
                  <Col sm='9'>
                      <input className="form-control" id="nik" type="text" placeholder="NIK" required/>
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">SHDK</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'shdk')})}
                          defaultValue={this.state.shdkStat} 
                          value={this.state.shdkStat} 
                          name="SHDK"
                          options={this.state.frmShdk}
                          placeholder="Pilih SHDK"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Agama</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'agama')})}
                          defaultValue={this.state.agmStat} 
                          value={this.state.agmStat} 
                          name="Agama"
                          options={this.state.frmAgm}
                          placeholder="Pilih Agama"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pendidikan Terakhir</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'pendidikan-terakhir')})}
                          defaultValue={this.state.ptrStat} 
                          value={this.state.ptrStat} 
                          name="Pendidikan"
                          options={this.state.frmPtr}
                          placeholder="Pilih Pendidikan Terakhir"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'pekerjaan')})}
                          defaultValue={this.state.pkrStat} 
                          value={this.state.pkrStat} 
                          name="Pekerjaan"
                          options={this.state.frmPkr}
                          placeholder="Pilih Pekerjaan"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Lengkap</Label>
                  <Col sm='9'>
                      <input className="form-control" id="nama" type="text" placeholder="Nama Lengkap" defaultValue={this.state.dt.nama} required/>
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">JK</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'jk')})}
                          defaultValue={this.state.jkStat} 
                          value={this.state.jkStat} 
                          name="Jenis Kelamin"
                          options={this.state.frmJk}
                          placeholder="Pilih Jenis Kelamin"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
                  <Col sm='9'>
                      <input className="form-control" id="tempat_lahir" type="text" placeholder="Tempat Lahir" defaultValue={this.state.dt.tempat_lahir} required/>
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
                  <Col sm='9'>
                  <Datetime locale="id-ID" input={true} timeFormat={false}  closeOnTab={true} initialValue={new Date()}
                    onChange={((e)=>{ 
                      this.gantiTgl(e,'tanggal_lahir')
                      // this.setState({ tanggal_lahirkk:})
                      // console.log(e.format('YYYY-MM-DD'))
                    } )} />
                  {/* <input className="form-control" id="tanggal_lahir" type="date" placeholder="Tempat Lahir" defaultValue={this.state.dt.tanggal_lahir} required /> */}
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Perkawinan</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'status-perkawinan')})}
                          defaultValue={this.state.spkStat} 
                          value={this.state.spkStat} 
                          name="Status Perkawinan"
                          options={this.state.frmSpk}
                          placeholder="Pilih Status Perkawinan"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Penduduk</Label>
                  <Col sm='9'>
                      <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={((e)=>{ this.changeSelectValue(e,'status-penduduk')})}
                          defaultValue={this.state.stpStat} 
                          value={this.state.stpStat} 
                          name="Status Penduduk"
                          options={this.state.frmStp}
                          placeholder="Pilih Status Penduduk"
                          isClearable
                          />
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Ibu Kandung</Label>
                  <Col sm='9'>
                      <input className="form-control" id="nama_ibu_kandung" type="text" placeholder="Nama Ibu Kandung" defaultValue={this.state.dt.nama_ibu_kandung} required/>
                  </Col>
              </FormGroup>
              <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Ayah Kandung</Label>
                  <Col sm='9'>
                      <input className="form-control" id="nama_ayah_kandung" type="text" placeholder="Nama Ayah Kandung" defaultValue={this.state.dt.nama_ayah_kandung} required/>
                  </Col>
              </FormGroup>
            </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  disabled={this.state.status.btnForm}
                  onClick={this.tutupAnggota}
                  >
                  Tutup
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={this.state.status.btnForm}
                  >
                  Masukkan Anggota
                </button>
              </div>
            </Form>
        </Modal>
        <Modal open={this.state.status.view} onClose={this.tutupView} closeOnEsc={false} closeOnOverlayClick={false}>
          <div className="modal-header">
            <h5 className="modal-title">Anggota Keluarga {this.state.view_kk}</h5>
          </div>
          <div className="modal-body">
              <table border={0} cellPadding={0} cellSpacing={0} width="100%">
                  <tbody>
                      <td rowSpan={2}></td>
                  </tbody>
              </table>
              <table border="1" cellPadding="0" cellSpacing="0" width="100%">
              <thead>
                <tr>
                  <th>No</th>
                  <th>NIK</th>
                  <th>Nama</th>
                  <th>JK</th>
                  <th>TTL</th>
                  <th>SHDK</th>
                </tr>
              </thead>
              <tbody>
                {this.state.view_penduduk.map((dt,no) => {
                  return (
                    <tr key={dt.id}>
                      <td style={{padding:"5px 5px 5px 5px"}}>
                        {no+1}
                      </td>
                      <td style={{padding:"5px 5px 5px 5px"}}>
                        {dt.id}
                      </td>
                      <td style={{padding:"5px 5px 5px 5px"}}>
                        {dt.nama}
                      </td>
                      <td style={{padding:"5px 5px 5px 5px"}}>
                        {dt.jk}
                      </td>
                      <td style={{padding:"5px 5px 5px 5px"}}>
                        {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
                      </td>
                      <td style={{padding:"5px 5px 5px 5px"}}>
                        {dt.nama_shdk}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              onClick={this.tutupView}
            >
              Tutup
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default KartuKeluarga;
