import React, {Component} from 'react';
import {Post, Get, Delete, cekLogin} from '../function/Koneksi';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";
  
import SweetAlert from 'react-bootstrap-sweetalert';
var images = require.context('../assets/images/', true);

class Samplepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
        };
    }
     // ================== Sweet Alert ==================
     closeAlert = () => {
        this.setState({
            show: false
        });
    }
    reset = event => {
        event.preventDefault();
        let dataRes = {
            email : document.getElementById("email").value,
            link : document.getElementById("link").value
        };
        console.log(dataRes);
        let resstat = 200;
      
        cekLogin('auth/reset/password',dataRes, (res) => {
          
            console.log(res);
                if (res.status === resstat)   
                {
                  
                    this.setState({ show: true, basicType:'success', basicTitle:'Login', pesanAlert:'Berhasil reset password, cek email!' });
                 
                }
                else {
                    this.setState({ show: true, basicType:'danger', basicTitle:'Login', pesanAlert:'Username atau password salah!' });
                }
        })
      }
    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }

    render() {
        let style = this.state.style;
        const background = require('../assets/images/auth-layer.png')

        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>
                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}

                                        <div className="authentication-main">
                                            <div className="row">
                                                <div className="col-md-4 p-0">
                                                    <div className="auth-innerleft" style ={{ backgroundImage: "url("+background+")" }}>
                                                        <div className="text-center">
                                                            <img src={require('../assets/images/key.png')} className="img-fluid security-icon" alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 p-0">
                                                    <div className="auth-innerright">
                                                        <div className="reset-password-box">
                                                            <div className="card mt-4  mb-0">
                                                                <form className="theme-form" onSubmit={this.reset}>
                                                                    <h6 className="f-14 mt-4 mb-3">Reset Password Anda</h6>
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Email </label>
                                                                        <input type="email"  required className="form-control" 
                                                                        id="email" placeholder="masukan email anda"/>
                                                                        <input type="hidden" id="link"   className="form-control" placeholder="masukan email anda" value={window.location.protocol +"//"+window.location.host+"/reset-password/"}/>
                                                                    </div>
                                                                    <div className="form-group form-row mb-2">
                                                                        <div className="col-md-12">
                                                                            <button type="submit"
                                                                                    className="btn btn-success btn-block">Reset
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                <div className="form-row mt-4">
                                                                    <div className="col-sm-12 text-center">
                                                                        <Link to="/"  className="btn btn-warning btn-block">Kembali
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                       {/*Reset Password page end*/}


                        </div>
                    </div>
                </div>
        )
    }
}


export default Samplepage;