import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { Tanggal, HariTanggal } from "../function/Format";
import { logo } from "./asset/base64img";
// Import the library

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  alamat: "",

  nik2: "",
  nama2: "",
  kepada2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  pekerjaan2: "",
  alamat2: "",
  kuasa_untuk: "",
  kuasa_atas: "",
  tujuan_kuasa: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  detail: []
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  nik: "",
  nama: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  alamat: "",

  nik2: "",
  nama2: "",
  kepada2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  pekerjaan2: "",
  alamat2: "",
  kuasa_untuk: "",
  kuasa_atas: "",
  tujuan_kuasa: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",
  detail: []
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: []
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Kuasa SKGR",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_skgr: new Date(),

      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio2: true,
      showBio: false,
      tanggal_mulai: new Date(),
      tanggal_selesai: null,
      usaha: [{ jenis: "", lokasi: "", umur: "" }],
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: [],
      formPenerimaKuasa: "none"
    };
  }

  // ================== dinamic form ===================
  handleNameChange = evt => {
    this.setState({ name: evt.target.value });
  };

  handleShareholderNameChange = idx => evt => {
    const newusaha = this.state.usaha.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    this.setState({ usaha: newusaha });
  };
  handleAddShareholder = () => {
    this.setState({
      usaha: this.state.usaha.concat([{ name: "" }])
    });
  };

  handleRemoveShareholder = idx => () => {
    this.setState({
      usaha: this.state.usaha.filter((s, sidx) => idx !== sidx)
    });
  };
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_lahir_pasangan":
        this.setState({ tanggal_lahir_pasangan: date });
        break;
      case "tanggal_lahir2":
        this.setState({ tanggal_lahir2: date });
        break;
      case "tanggal_skgr":
        this.setState({ tanggal_skgr: date.format("YYYY-MM-DD") });
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/kuasa/" + this.props.location.state.uuid + "" + link,
      null,
      dtkat => {
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);

      }
    );
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let addSuratUndangan = {
        q: e.target.value
      };
      Post("surat-keterangan/kuasa", null, addSuratUndangan, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
        this.pagination(data.data.results);
      });
    }
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log("paginate");
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  //change select
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jk":
          this.state.dt.jk = e.value;
          this.setState({ jkStat: e });
          break;
      }
    } else {
      switch (sel) {
        case "jk":
          this.state.dt.jk = 0;
          this.setState({ jkStat: e });
          break;
      }
    }

    this.state.status.select = false;
    this.forceUpdate();
  };

  // ========================================================================
  // ================== change input text ==================
  changeInputText = (e, fld) => {
    switch (fld) {
      case "nik2":
        console.log(e.target.value);
        this.state.dt.nik2 = e.target.value;
        if (e) {
          this.state.status.btnForm = false;
        }
        this.forceUpdate();
        break;
      case "nama2":
        console.log(e.target.value);
        this.state.dt.nama2 = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir2":
        console.log(e.target.value);
        this.state.dt.tempat_lahir2 = e.target.value;
        this.forceUpdate();
        break;
      case "tanggal_lahir2":
        console.log(e.target.value);
        this.state.dt.tanggal_lahir2 = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan2":
        console.log(e.target.value);

        this.state.dt.pekerjaan2 = e.target.value;
        this.forceUpdate();
        break;
      case "alamat2":
        console.log(e.target.value);
        this.state.dt.alamat2 = e.target.value;
        this.forceUpdate();
        break;
    }
  };

  // data ayah
  dataPenerimaKuasa = () => {
    console.log(this.props.location.state);
    if (this.state.pendStat2 != null) {
      this.state.showBio2 = true;
    } else {
      this.state.showBio2 = this.state.showBio2 == false ? true : false;
    }

    this.state.pendStat2 = null;
    this.state.formPenerimaKuasa =
      this.state.formPenerimaKuasa == "" ? "none" : "";
    this.state.dt.nik2 = "";
    this.state.dt.nama2 = "";
    this.state.dt.tempat_lahir2 = "";
    this.state.dt.tanggal_lahir2 = "";
    this.state.dt.pekerjaan2 = "";
    this.state.dt.alamat2 = "";
    this.forceUpdate();
  };
  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.state.status.btnForm = true;
    this.forceUpdate();

    let uuid = document.getElementById("uuid").value;
    //detail
    let formDetail = [
      {
        nama_item: "atas_nama",
        isi_item: document.getElementById("atas_nama").value
      },
      {
        nama_item: "no_reg",
        isi_item: document.getElementById("no_reg").value
      },
      {
        nama_item: "tanggal_skgr",
        isi_item: this.state.tanggal_skgr
      },
      {
        nama_item: "luas_tanah",
        isi_item: document.getElementById("luas_tanah").value
      },
      {
        nama_item: "lokasi_tanah",
        isi_item: document.getElementById("lokasi_tanah").value
      }
    ];

    let addSkck = {
      id_jenis: this.props.location.state.uuid,
      nik: document.getElementById("nik").value,
      nama: document.getElementById("nama_pemohon").value,
      tempat_lahir: document.getElementById("tempat_lahir").value,
      tanggal_lahir: document.getElementById("tanggal_lahir").value,
      pekerjaan: document.getElementById("pekerjaan").value,
      alamat: document.getElementById("alamat").value,

      nik2: this.state.dt.nik2,
      nama2: this.state.dt.nama2,
      tempat_lahir2: this.state.dt.tempat_lahir2,
      tanggal_lahir2: this.state.dt.tanggal_lahir2,
      pekerjaan2: this.state.dt.pekerjaan2,
      alamat2: this.state.dt.alamat2,

      kuasa_atas: document.getElementById("kuasa_atas").value,
      kuasa_untuk: document.getElementById("kuasa_untuk").value,
      tujuan_kuasa: document.getElementById("tujuan_kuasa").value,

      detail: formDetail
    };
    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (uuid === "") {
      psn = "Tambah";
      resstat = 201;
      uuid = null;
    } else {
      psn = "Ubah";
      resstat = 200;
      metode = "update";
    }
    Post("surat-keterangan/kuasa/" + metode, uuid, addSkck, res => {
      this.tutupForm();
      this.state.status.btnForm = false;
      this.state.status.form = false;
      this.forceUpdate();
      if (res.status === resstat) {
        this.setState({
          showBio: false,
          show: true,
          basicType: "success",
          basicTitle: "Data Permohonan Kuasa",
          pesanAlert: "Berhasil " + psn + " Data",
          successAlert: true
        });
      } else {
        this.setState({
          showBio: false,
          show: true,
          basicType: "danger",
          basicTitle: "Data Permohonan Kuasa",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      console.log(dtkat);
      if (dtkat) {
        console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.componentDidMount();
    Get("surat-keterangan/kuasa/find", id, data => {
      if (typeof data.results != "undefined") {
        Get("penduduk/find", data.results.nik2, data1 => {
          let nik2From = false;

          if (data1.results == null) {
            nik2From = true;
            this.state.formPenerimaKuasa = "";
            this.state.showBio2 = false;
            this.state.pendStat2 = null;
          } else {
            this.state.formPenerimaKuasa = "none";
            this.state.showBio2 = true;
            // this.state.pendStat2= null;
            this.state.pendStat2 = {
              value: data.results.nik2,
              label: data.results.nik2
            };
          }

          data.results.data_detail_suket.map(dt => {
            if (dt.nama_item == "tanggal_skgr") {
              this.state.tanggal_skgr = new Date(dt.isi_item);
            }
            if (dt.nama_item == "atas_nama") {
              this.state.dt.atas_nama = dt.isi_item;
            }
            if (dt.nama_item == "luas_tanah") {
              this.state.dt.luas_tanah = dt.isi_item;
            }
            if (dt.nama_item == "no_reg") {
              this.state.dt.no_reg = dt.isi_item;
            }
            if (dt.nama_item == "lokasi_tanah") {
              this.state.dt.lokasi_tanah = dt.isi_item;
            }

            this.forceUpdate();
          });

          this.state.dt.uuid = data.results.uuid;
          this.state.dt.nomor = data.results.nomor;
          this.state.tanggal_surat = new Date(data.results.tanggal_surat);

          this.state.dt.nik = data.results.nik;
          this.state.dt.nama = data.results.nama;

          this.state.dt.kepada = data.results.kepada;
          this.state.pendStat = {
            value: data.results.nik,
            label: data.results.nik
          };

          this.state.dt.tempat_lahir = data.results.tempat_lahir;
          this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
          this.state.dt.pekerjaan = data.results.pekerjaan;
          this.state.dt.alamat = data.results.alamat;
          console.log(data.results.tanggal_lahir2);
          this.state.dt.nik2 = data.results.nik2;
          this.state.dt.nama2 = data.results.nama2;
          this.state.dt.kepada2 = data.results.kepada2;
          this.state.dt.tempat_lahir2 = data.results.tempat_lahir2;
          this.state.dt.tanggal_lahir2 =
            nik2From != true
              ? new Date(data.results.tanggal_lahir2).toLocaleDateString(
                  "id-ID"
                )
              : data.results.tanggal_lahir2;
          this.state.dt.pekerjaan2 = data.results.pekerjaan2;
          this.state.dt.alamat2 = data.results.alamat2;

          this.state.dt.kuasa_atas = data.results.kuasa_atas;
          this.state.dt.kuasa_untuk = data.results.kuasa_untuk;
          this.state.dt.tujuan_kuasa = data.results.tujuan_kuasa;

          this.state.showBio = true;
          this.state.showBio2 = true;
          this.state.status.btnForm = false;
          this.state.judul = "Ubah Permohonan Kuasa";
          this.forceUpdate();
          // this.state.status.btnAksi = false;
          this.componentDidMount();
          this.bukaForm();
        });
      }
    });
  };

  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/kuasa/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;

    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.dt.pekerjaan2 = "";
    this.state.dt.alamat2 = "";
    this.state.dt.nik2 = "";
    this.state.tanggal_skgr = new Date();
    this.state.showBio = false;
    this.state.showBio2 = false;
    this.state.pendStat = [];
    this.state.pendStat2 = [];
    this.state.status.btnForm = true;
    this.state.formAcc = false;
    this.state.tableAcc = "none";
    this.state.formDisposisi = false;
    this.state.accStat = [];
    this.state.status.form = false;
    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/kuasa/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    let btnApproval = false;
    let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.map(dt => {
      status = dt.status;
    });
    if (status == "Diajukan") {
      btnCetak = true;
      btnAksi = true;
      btnApproval = true;
    } else {
      btnAksi = true;
      btnApproval = true;
      if (status == "Ditolak") {
        btnCetak = true;
      } else if (status == "Disetujui") {
        btnAksi = false;
        btnCetak = true;
        btnApproval = false;
      } else if ((status == "Menunggu Penerbitan") | (status == "Selesai")) {
        // btnAksi = false;
        btnCetak = false;
        // btnApproval = false;
      } else {
        btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="primary"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="primary"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld == "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.map(dt => {
            frmPend.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.map(dt => {
            frmNik2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };
  // ambil data penduduk
  getPenduduk = (e, label) => {
    if (e) {
      Get("penduduk/find/" + e.value, null, data => {
        let alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", Dusun " +
          data.results.data_kk.data_rt.data_rw.data_dusun.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.data_kabkota.nama;

        // let daerah = data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
        //       ", " +
        //       data.results.data_kk.results.dataKkbj.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .data_provinsi.nama

        if (label == "nik") {
          Get("catatan-penduduk/nik", data.results.id, dpddk => {
            let pctt = 0;
            let catatan = "";
            if (dpddk.results.data) {
              dpddk.results.data.map(dtp => {
                if (dtp.status == 0 && dtp.penalti == 1) {
                  pctt = 1;
                  catatan = dtp.catatan;
                }
              });
            }
            if (pctt == 0) {
              this.state.dt.nik = data.results.id;
              this.state.dt.kk = data.results.id_kk;
              this.state.dt.nama = data.results.nama;
              this.state.dt.tempat_lahir = data.results.tempat_lahir;
              this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
              this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
              this.state.dt.jenis_kelamin = data.results.jk;
              this.state.dt.pendidikan_terakhir =
                data.results.data_pendidikan_terakhir.nama;
              this.state.dt.agama = data.results.data_agama.nama;
              this.state.dt.status_perkawinan = data.results.status_perkawinan;
              this.state.dt.alamat = alamat;
              this.state.showBio = true;
              this.state.pendStat = { value: e.value, label: e.label };
              this.forceUpdate();
            } else {
              this.state.dt.nik = "";
              this.state.dt.nama_pemohon = "";
              this.state.dt.tempat_lahir = "";
              this.state.dt.tanggal_lahir = "";
              this.state.dt.pekerjaan = "";
              this.state.dt.pendidikan_terakhir = "";
              this.state.dt.agama = "";
              this.state.dt.status_perkawinan = "";
              this.state.dt.alamat = "";
              this.state.showBio = false;
              this.state.btnForm = true;
              this.forceUpdate();
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Peringatan Catatan Warga",
                pesanAlert:
                  "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                  catatan,
                pendStat: null,
                frmPend: []
              });
            }
          });
        }
        if (label == "nik2") {
          this.state.dt.agama2 = data.results.data_agama.nama;
          this.state.dt.alamat2 = alamat;
          this.state.dt.jenis_kelamin2 = data.results.jk;
          this.state.dt.kk2 = data.results.id_kk;
          this.state.dt.nama2 = data.results.nama;
          this.state.dt.nik2 = data.results.id;
          this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
          this.state.dt.pendidikan_terakhir2 =
            data.results.data_pendidikan_terakhir.nama;
          this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
          this.state.dt.tanggal_lahir2 = data.results.tanggal_lahir;
          this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
          this.state.pendStat2 = { value: e.value, label: e.label };
          this.state.showBio2 = true;
        }

        if (
          this.state.pendStat2.length != 0 &&
          this.state.pendStat.length != 0
        ) {
          this.state.status.btnForm = false;
        }
        // this.state.status.btnAksi = false;
        this.forceUpdate();
      });
    } else {
      if (label == "nik") {
        this.state.dt.nik = "";
        this.state.dt.nama_pemohon = "";
        this.state.dt.tempat_lahir = "";
        this.state.dt.tanggal_lahir = "";
        this.state.dt.pekerjaan = "";
        this.state.dt.pendidikan_terakhir = "";
        this.state.dt.agama = "";
        this.state.dt.status_perkawinan = "";
        this.state.dt.alamat = "";
        this.state.showBio = false;
        this.state.btnForm = true;
        this.state.pendStat = [];
      }
      if (label == "nik2") {
        this.state.dt.nik2 = "";
        this.state.dt.nama_pemohon2 = "";
        this.state.dt.tempat_lahir2 = "";
        this.state.dt.tanggal_lahir2 = "";
        this.state.dt.pekerjaan2 = "";
        this.state.dt.pendidikan_terakhir2 = "";
        this.state.dt.agama2 = "";
        this.state.dt.status_perkawinan2 = "";
        this.state.dt.alamat2 = "";
        this.state.showBio2 = false;
        this.state.btnForm = true;
        this.state.pendStat2 = [];
      }
      this.state.status.btnForm = true;

      this.forceUpdate();

      // this.state.status.btnAksi = false;
    }
  };
  ///acc
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  //change select
  changAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      if (e.value == "Disetujui") {
        this.state.tableAcc = "";
        this.state.btnFormAcc = true;
        if (this.state.accButton.length > 0) {
          this.state.btnFormAcc = false;
        }
      } else {
        this.state.tableAcc = "none";
        this.state.btnFormAcc = false;
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      this.state.tableAcc = "none";
      this.state.btnFormAcc = true;
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  simpanAcc = e => {
    e.preventDefault();
    this.state.btnFormAcc = true;

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/kuasa/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            tableAcc: "none",
            accStat: [],
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            btnFormAcc: false,

            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };
  componentDidMount() {
    if (this.props.location.state == undefined) {
      this.setState({ redirect: true });
    } else {
      this.fetch({ page: null, where: [] });

      Get("penduduk", null, data => {
        let frmPend = [];
        let frmPend2 = [];
        data.results.data.map(dt => {
          frmPend.push({ value: dt.id, label: dt.id });
          frmPend2.push({ value: dt.id, label: dt.id });
        });
        this.setState({ frmPend, frmPend2 });
      });
      Get("tanda-tangan/list", null, data => {
        data.results.map((dt, i) => {
          if (dt.aktif == 1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.state.accButton = temp;
            this.state.accuuid = dt.uuid;
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
      Get("select-enum/penduduk/jk", null, data => {
        let frmJk = [];
        data.map(dt => {
          frmJk.push({ value: dt, label: dt });
        });
        this.setState({ frmJk });
      });
    }
  }

  cetakSurat = (e, data) => {
    e.preventDefault();

    let detail;

    let tanggal_skgr = null;
    let atas_nama = null;
    let luas_tanah = null;
    let no_reg = null;
    let lokasi_tanah = null;

    data.data_detail_suket.map(dt => {
      if (dt.nama_item == "tanggal_skgr") {
        tanggal_skgr = dt.isi_item;
        console.log(dt.isi_item);
      }
      if (dt.nama_item == "atas_nama") {
        atas_nama = dt.isi_item;
      }
      if (dt.nama_item == "luas_tanah") {
        luas_tanah = dt.isi_item;
      }
      if (dt.nama_item == "lokasi_tanah") {
        lokasi_tanah = dt.isi_item;
      }
      if (dt.nama_item == "no_reg") {
        no_reg = dt.isi_item;
      }
      this.forceUpdate();
    });
    detail = {
      tanggal_skgr: tanggal_skgr,
      atas_nama: atas_nama,
      luas_tanah: luas_tanah,
      no_reg: no_reg,
      lokasi_tanah: lokasi_tanah
    };

    this.setState({
      cetak: {
        nama_desa: "Desa Sungai Petai",
        tanggal_surat: data.tanggal_surat,

        nomor_surat: data.nomor,
        nik: data.nik,
        nama: data.nama.toLowerCase(),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        pekerjaan: data.pekerjaan.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        nik2: data.nik2,
        nama2: data.nama2.toLowerCase(),
        tempat_lahir2: data.tempat_lahir2.toLowerCase(),
        tanggal_lahir2: Tanggal(data.tanggal_lahir2),
        pekerjaan2: data.pekerjaan2.toLowerCase(),
        alamat2: data.alamat2.toLowerCase(),

        tujuan_kuasa: data.tujuan_kuasa,
        kuasa_untuk: data.kuasa_untuk,
        kuasa_atas: data.kuasa_atas,

        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        detail: detail,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : ""
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 2000);
    }, 200);
  };

  //
  gantiTglMulai = date => {
    this.setState({ tanggal_mulai: date });
  };
  gantiTglSelesai = date => {
    this.setState({ tanggal_selesai: date });
  };
  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    let frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      tanggal_surat: "",
      nik: "",
      nama: "",
      kepada: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      pekerjaan: "",
      alamat: "",

      nik2: "",
      nama2: "",
      kepada2: "",
      tempat_lahir2: "",
      tanggal_lahir2: "",
      pekerjaan2: "",
      alamat2: "",
      kuasa_untuk: "",
      kuasa_atas: "",
      tujuan_kuasa: "",

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",

      detail: []
    };
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "tanggal_surat",
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat == null ||
            obj.tanggal_surat == "" ||
            obj.tanggal_surat == "0000-00-00"
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor == "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.map(dt => {
            status = dt.status;
          });
          if (status == "Diajukan") {
            this.state.status.btnAksi[cell] = true;
          } else {
            this.state.status.btnAksi[cell] = false;
          }
          this.forceUpdate();
          return status;
        },
        sort: true,
        isDummyField: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    const defaultSorted = [
      {
        dataField: "nama",
        order: "asc"
      }
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = e => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = "";
      classname = "btn btn-success";
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {total} Results
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };
    const usaha = this.state.usaha.map((dt, idx) => (
      <div className="shareholder" id={idx}>
        <Label className="col-sm-2 col-form-label" htmlFor="inputEmail3">
          Usaha {idx + 1}
        </Label>

        <FormGroup className="row">
          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
            Jenis
          </Label>
          <Col sm="9">
            <input
              className="form-control"
              id={"jenis" + idx}
              type="text"
              required
              placeholder="Jenis"
              defaultValue={dt.jenis}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
            Lokasi
          </Label>
          <Col sm="9">
            <input
              className="form-control"
              id={"lokasi" + idx}
              type="text"
              required
              placeholder="Lokasi"
              defaultValue={dt.lokasi}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
            Umur
          </Label>
          <Col sm="9">
            <input
              className="form-control"
              id={"umur" + idx}
              type="text"
              required
              placeholder="Umur"
              defaultValue={dt.umur}
            />
          </Col>
        </FormGroup>
        <div className="float-right">
          <button
            type="button"
            className="btn btn-danger small"
            onClick={this.handleRemoveShareholder(idx)}
          >
            -
          </button>
        </div>
      </div>
    ));
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] == 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] == 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div>
        {this.renderRedirect()}

        <Breadcrumb title="Data Surat Kuasa SKGR" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Surat Kuasa SKGR</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Kuasa SKGR",
                            dt: frmDef,
                            usaha: [{ jenis: "", lokasi: "", umur: "" }],
                            showBio: false,
                            showBio2: false,
                            pendStat: [],
                            pendStat2: [],
                            // showBio2: true,
                            formPenerimaKuasa: "none"
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kuasa Dari{" "}
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.getPenduduk(e, "nik");
                    }}
                    defaultValue={this.state.pendStat}
                    value={this.state.pendStat}
                    name="penduduk"
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    options={this.state.frmPend}
                    placeholder="Pilih NIK"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <div
                style={{ display: this.state.showBio == false ? "none" : "" }}
              >
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik"
                  type="hidden"
                  readOnly
                  placeholder="NIK"
                  value={this.state.dt.nik}
                />
                {/* </Col>
                </FormGroup> */}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pemohon"
                      readOnly
                      type="text"
                      placeholder="nama_pemohon"
                      value={this.state.dt.nama}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      readOnly
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      value={this.state.dt.tempat_lahir}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      readOnly
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  readOnly
                  id="pekerjaan"
                  type="hidden"
                  placeholder="Pekerjaan"
                  value={this.state.dt.pekerjaan}
                />
                {/* </Col>
                </FormGroup> */}

                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="alamat"
                  type="hidden"
                  readOnly
                  placeholder="Alamat"
                  value={this.state.dt.alamat}
                />
                {/* </Col>
                </FormGroup> */}
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Yang Diberi Kuasa{" "}
                </Label>
                <Col sm="9">
                  {this.state.formPenerimaKuasa == "none" ? (
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2");
                      }}
                      defaultValue={this.state.pendStat2}
                      value={this.state.pendStat2}
                      name="penduduk"
                      options={this.state.frmPend2}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                      isClearable
                    />
                  ) : (
                    <></>
                  )}
                  <input
                    className="form-control"
                    id="nik2"
                    type={
                      this.state.formPenerimaKuasa == "none"
                        ? "hidden"
                        : "number"
                    }
                    placeholder="Nik ayah"
                    defaultValue={this.state.dt.nik2}
                    value={this.state.dt.nik2}
                    required
                    onChange={e => this.changeInputText(e, "nik2")}
                  />

                  <small id="emailHelp" className="form-text text-muted">
                    Data NIK{" "}
                    {this.state.formPenerimaKuasa == "none" ? "tidak" : ""}{" "}
                    tersedia?{" "}
                    <Link
                      to={{
                        pathname: this.props.myroute,
                        state: this.props.location.state
                      }}
                      onClick={this.dataPenerimaKuasa}
                    >
                      {" "}
                      Klik disini
                    </Link>
                  </small>
                </Col>
              </FormGroup>
              <div
                style={{ display: this.state.showBio2 == false ? "none" : "" }}
              >
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik2"
                  readOnly
                  type="hidden"
                  required
                  placeholder="NIK"
                  defaultValue={this.state.dt.nik2}
                />
                {/* </Col>
                </FormGroup> */}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama2"
                      // required
                      type="text"
                      // readOnly
                      placeholder="Nama"
                      // defaultValue={this.state.dt.nama2}
                      //   readOnly
                      onChange={e => this.changeInputText(e, "nama2")}
                      defaultValue={this.state.dt.nama2}
                      value={this.state.dt.nama2}
                      readOnly={
                        this.state.formPenerimaKuasa == "" ? false : true
                      }
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      required
                      id="tempat_lahir2"
                      readOnly
                      type="text"
                      placeholder="Tempat lahir"
                      // defaultValue={this.state.dt.tempat_lahir2}

                      onChange={e => this.changeInputText(e, "tempat_lahir2")}
                      defaultValue={this.state.dt.tempat_lahir2}
                      value={this.state.dt.tempat_lahir2}
                      readOnly={
                        this.state.formPenerimaKuasa == "" ? false : true
                      }
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      readOnly
                      id="tanggal_lahir2"
                      // readOnly
                      type={
                        this.state.formPenerimaKuasa == "" ? "date" : "text"
                      }
                      placeholder="Tanggal Lahir"
                      // value={this.state.dt.tanggal_lahir2}

                      onChange={e => this.changeInputText(e, "tanggal_lahir2")}
                      defaultValue={this.state.dt.tanggal_lahir2}
                      value={this.state.dt.tanggal_lahir2}
                      readOnly={
                        this.state.formPenerimaKuasa == "" ? false : true
                      }
                      required
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: this.state.formPenerimaKuasa }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      required
                      id="pekerjaan2"
                      // readOnly
                      type="text"
                      placeholder="Pekerjaan"
                      // defaultValue={this.state.dt.pekerjaan2}
                      onChange={e => this.changeInputText(e, "pekerjaan2")}
                      defaultValue={this.state.dt.pekerjaan2}
                      value={this.state.dt.pekerjaan2}
                      readOnly={
                        this.state.formPenerimaKuasa == "" ? false : true
                      }
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat2"
                      // readOnly
                      type="text"
                      required
                      placeholder="Alamat"
                      // defaultValue={this.state.dt.alamat2}
                      onChange={e => this.changeInputText(e, "alamat2")}
                      defaultValue={this.state.dt.alamat2}
                      value={this.state.dt.alamat2}
                      readOnly={
                        this.state.formPenerimaKuasa == "" ? false : true
                      }
                      required
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kuasa Untuk
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kuasa_untuk"
                    type="text"
                    required
                    placeholder="Kuasa Untuk"
                    defaultValue={this.state.dt.kuasa_untuk}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kuasa Atas
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kuasa_atas"
                    type="text"
                    required
                    placeholder="Kuasa Atas"
                    defaultValue={this.state.dt.kuasa_atas}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tujuan Kuasa
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tujuan_kuasa"
                    type="text"
                    required
                    placeholder="Contoh : sebagai pinjaman bank"
                    defaultValue={this.state.dt.tujuan_kuasa}
                  />
                </Col>
              </FormGroup>{" "}
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Detail Data SKGR
              </Label>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Atas nama
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="atas_nama"
                    type="text"
                    required
                    placeholder="Atas Nama"
                    defaultValue={this.state.dt.atas_nama}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  No. Reg
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="no_reg"
                    type="text"
                    required
                    placeholder="No. Reg"
                    defaultValue={this.state.dt.no_reg}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    id="tanggal_skgr"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_skgr}
                    onChange={e => this.gantiTgl(e, "tanggal_skgr")}
                    className="form-control"
                  /> */}
                  <Datetime
                    locale="id-ID"
                    input={true}
                    closeOnTab={true}
                    timeFormat={false}
                    initialValue={this.state.tanggal_skgr}
                    onChange={e => {
                      this.gantiTgl(e, "tanggal_skgr");
                      // this.setState({ tanggal_lahirkk:})
                      // console.log(e.format('YYYY-MM-DD'))
                    }}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Lokasi Tanah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="lokasi_tanah"
                    type="text"
                    required
                    placeholder="Lokasi Tanah"
                    defaultValue={this.state.dt.lokasi_tanah}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Luas Tanah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="luas_tanah"
                    type="text"
                    required
                    placeholder="Luas Tanah"
                    defaultValue={this.state.dt.luas_tanah}
                  />
                </Col>
              </FormGroup>{" "}
              {/* {usaha}
              <button
                type="button"
                onClick={this.handleAddShareholder}
                className="btn btn-success small"
              >
                Tambah Usaha
              </button> */}
              <hr />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-success">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Level</th>
                      <th>TTD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.datDisposisi.map((dt,i)=>{
                  return(
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{dt.data_jabatan.nama}</td>
                      <td>{dt.level}</td>
                      <td>

                      <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        this.disposisi(dt.uuid,this.state.idSurat)}
                      }
                      disabled={this.state.status.btnForm}
                    >
                      Disposisi
                    </button>
                      </td>
                    </tr>
                    )})}
                  </tbody>
                </table>
              </div>
              */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status == 0
                            ? "Menunggu Disposisi"
                            : dt.status == 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat kuasa
                </span>
                {/* <br />
                {this.state.cetak.nomor_surat} */}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Yang bertanda tangan dibawah ini yakni :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir},{" "}
                      {Tanggal(this.state.cetak.tanggal_lahir)}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nik}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Dengan ini memberi kuasa kepada :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir2},{" "}
                      {this.state.cetak.tanggal_lahir2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nik2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat2}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Untuk {this.state.cetak.kuasa_untuk}{" "}
                  {this.state.cetak.kuasa_atas} {this.state.cetak.tujuan_kuasa}.
                  Adapun data SKGR tersebut adalah :
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Atas Nama
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.detail.atas_nama}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      No. Reg
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.detail.no_reg}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tanggal
                    </td>
                    <td>:</td>
                    <td>{Tanggal(this.state.cetak.detail.tanggal_skgr)}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Lokasi Tanah
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.detail.lokasi_tanah}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Luas Tanah
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.detail.luas_tanah}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "0px" }}>
                  Demikianlah surat kuasa ini dibuat dengan sebenarnya untuk
                  dapat dipergunakan seperlunya.
                </span>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "center" }} width="50%">
                {this.state.cetak.nama_desa},{" "}
                {Tanggal(new Date(this.state.cetak.tanggal_surat))}{" "}
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Yang diberi kuasa,</span>
              </td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Yang memberi kuasa,</span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width="50%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama2}
                </span>
              </td>
              <td width="50%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama}
                </span>
              </td>
            </tr>
          </table>

          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td
                width="100%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span>Mengetahui :</span>
              </td>
            </tr>
            <tr>
              <td
                width="100%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width="100%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
