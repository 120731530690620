import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';

function EditUndangan({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  let jmlAcara = 0;

  const acara = detailSuratProps.detailSurat.acara.map((dt, i) => {
    let no = i + 1;
    jmlAcara++;
    detailSuratProps.detailSurat.jmlAcara = jmlAcara;

    return (
      <input
        className="form-control mb-2"
        key={i}
        id={"acara" + no}
        type="text"
        placeholder={"Acara " + no}
        defaultValue={dt.nama}
      />
    );
  });

  return (
    <div>
      <Modal
        open={detailSuratProps.status.formUpdateSurat}
        onClose={detailSuratProps.tutupForm}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Ubah {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>
              Untuk isian acara, dapat ditambah dengan menekan tombol tambah
              acara dan tidak ada batasan untuk jumlah acara yang ingin
              dimasukkan ke dalam undangan.
              </p>
            <input
              className="form-control"
              id="uuid"
              type="hidden"
              placeholder=""
              defaultValue={detailSuratProps.detailSurat.uuid}
            />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Sifat
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="sifat"
                  type="text"
                  placeholder="Sifat"
                  defaultValue={detailSuratProps.detailSurat.sifat}
                  required
                />
              </Col>
            </FormGroup>
            {/* <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="nomor" type="text" placeholder="Nomor Surat" defaultValue={detailSuratProps.detailSurat.nomor}/>
                                </Col>
                            </FormGroup> */}
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Perihal
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="perihal"
                  type="text"
                  placeholder="Perihal"
                  defaultValue={detailSuratProps.detailSurat.perihal}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Kepada
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="kepada"
                  type="text"
                  placeholder="kepada"
                  defaultValue={detailSuratProps.detailSurat.kepada}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Tanggal Acara
                </Label>
              <Col sm="9">
                <Datepicker
                  id="tanggal_acara"
                  todayButton={"Today"}
                  dateFormat="dd/MM/yyyy"
                  selected={detailSuratProps.detailSurat.tanggal_acara}
                  onChange={e => detailSuratProps.gantiTgl(e, "tanggal_acara")}
                  className="form-control"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Jam
                </Label>
              <Col sm="9">
                <Select
                  // isDisabled={this.state.status.select}
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.changeSelectValue2(e, "jam");
                  }}
                  defaultValue={detailSuratProps.detailSurat.jamStat}
                  value={detailSuratProps.detailSurat.jamStat}
                  name="Jam Acara"
                  options={detailSuratProps.detailSurat.frmJam}
                  placeholder="Pilih Jam Acara"
                  isClearable
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Tempat
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="tempat"
                  type="text"
                  placeholder="Tempat Acara"
                  defaultValue={detailSuratProps.detailSurat.tempat}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Acara
                </Label>
              <Col sm="9">
                {acara}
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={detailSuratProps.tambahAcara}
                >
                  Tambah Acara
                  </button>
              </Col>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              // disabled={detailSuratProps.status.btnForm}
              onClick={detailSuratProps.tutupForm}
            >
              Tutup
              </button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >
              Simpan
              </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditUndangan
