import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

// ** Import custom components **
import store from './store';
import App from './components/app';
import ChartWidget from './components/widget/chart-widget';

// Custom
import Role from './feature/role';
import Provinsi from './feature/provinsi';
import KabKota from './feature/kabkota';
import Kecamatan from './feature/kecamatan';
import Keldes from './feature/keldes';
import Dusun from './feature/dusun';
import RW from './feature/rw';
import RT from './feature/rt';
import Pekerjaan from './feature/pekerjaan';

import Agama from './feature/agama';

import JenjangPendidikan from './feature/jenjangpendidikan';
import Shdk from './feature/shdk';
import UkuranKertas from './feature/ukurankertas';
import JabatanPerangkat from './feature/jabatanperangkat';
import Penduduk from './feature/penduduk';
import PersyaratanSurat from './feature/persyaratansurat';
import PejabatPenandatangan from './feature/pejabatpenandatangan';
import DaftarAplikasi from './feature/daftaraplikasi';
import JenisSurat from './feature/jenis-surat';
import KartuKeluarga from './feature/kartu-keluarga';
import DataPotensi from './feature/data-potensi';
import Perangkat from './feature/perangkat';
import Pengguna from './feature/pengguna';
import Login from './feature/login';
import Register from './feature/register';
import ForgetPassword from './feature/forget-password';
import ResetPassword from './feature/reset-password';
import Profile from './feature/profile';
import PengaturanAplikasi from './feature/pengaturan-aplikasi';
import TandaTangan from './feature/tandatangan';
import AlurDisposisi from './feature/alur-disposisi';
// Surat
import SuratUndangan from './feature/suratundangan';
import Suratskck from './feature/suratskck';
import Suratkehilangan from './feature/suratkehilangan';
import Suratbelummemilikirumah from './feature/suratbelummemilikirumah';
import SuratKeteranganPenghasilan from './feature/suratketeranganpenghasilan';
import Suratjalan from './feature/suratjalan';
import SuratKeteranganKematian from './feature/suratketerangankematian';
import SuratKeteranganPernahMenikah from './feature/suratketeranganpernahmenikah';
import SuratKeteranganKelahiran from './feature/suratketerangankelahiran';
import SuratKeteranganAhliWaris from './feature/suratketeranganahliwaris';
import SuratKeteranganDomisili from './feature/suratketerangandomisili';
import SuratKeteranganTidakMampu from './feature/suratketerangantidakmampu';
import SuratKeteranganKepemilikanRumah from './feature/suratketerangankepemilikanrumah';
import SuratKeterangan from './feature/suratketerangan';
import Suratbelumnikah from './feature/suratbelumnikah';
import Surattidaknikah from './feature/surattidaknikah';
import Berita from './feature/berita';

// // Dashboards
import Dashboard from './feature/dashboard';
import Default from './feature/dashboard';
import Laporan from './feature/laporan';
import Catatan from './feature/catatan';

// Surat
import Suratpindahnikah from './feature/suratpindahnikah';
import Suratizinkeramaian from './feature/suratizinkeramaian';
import Suratketeranganusaha from './feature/suratketeranganusaha';
import Suratkuasaskgr from './feature/suratkuasaskgr';
import Suratkuasa from './feature/suratkuasa';
import Comingsoon from './components/pages/comingsoon';
import Maintenance from './components/pages/maintenance';
import Landing from './components/pages/landing';

import { DataToken } from './function/Format';
import Disposisi from './feature/disposisi';
import Verifikasi from './feature/verifikasi';
//perubahan data
import PerubahanNik from './feature/perubahan-data/nik-kk';
import PindahKK from './feature/perubahan-data/pindah-kk';

// private route n public route
const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('access_token') ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>
			)}
	/>
);

// Route untuk public, tidak perlu login bisa akses route ini
const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('access_token') ? (
				<Redirect
					to={{
						pathname: '/admin/dashboard',
						state: { from: props.location }
					}}
				/>
			) : (
				<Component {...props} /> // apa maksudnya ... props ?
			)}
	/>
);

// Route khusus role 'admin'
const SuperRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (localStorage.getItem('access_token')) {
				let token = DataToken(localStorage.getItem('access_token'));
				if (token.sub.data_role.nama_role == 'Admin') {
					return (
						<Redirect
							to={{
								pathname: '/admin/dashboard',
								state: { from: props.location }
							}}
						/>
					);
				} else {
					return <Component {...props} />;
				}
			} else {
				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				);
			}
		}}
	/>
);

class Root extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter basename={'/'}>
					<ScrollContext>
						<Switch>
							<PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />

							<PublicRoute path={`${process.env.PUBLIC_URL}/login`} component={Login} />

							<PublicRoute path={`${process.env.PUBLIC_URL}/register`} component={Register} />
							<PublicRoute
								path={`${process.env.PUBLIC_URL}/forget-password`}
								component={ForgetPassword}
							/>
							<PublicRoute path={`${process.env.PUBLIC_URL}/reset-password/:token`} component={ResetPassword} />

							<Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
							<Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
							<Route path={`${process.env.PUBLIC_URL}/pages/Landing`} component={Landing} />

							<App>
								{
									<PrivateRoute
										path={`${process.env.PUBLIC_URL}/dashboard/default`}
										component={Default}
									/>
								}
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/role`} component={Role} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/provinsi`} component={Provinsi} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/kabkota`} component={KabKota} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/kecamatan`} component={Kecamatan} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/keldes`} component={Keldes} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/dusun`} component={Dusun} />

								

								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/pekerjaan`} component={Pekerjaan} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/agama`} component={Agama} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/shdk`} component={Shdk} />
								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/jenjang-pendidikan`}
									component={JenjangPendidikan}
								/>
								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/ukuran-kertas`}
									component={UkuranKertas}
								/>

								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan`}
									component={SuratKeterangan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/jabatan-perangkat`}
									component={JabatanPerangkat}
								/>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/rw`} component={RW} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/rt`} component={RT} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/penduduk`} component={Penduduk} />

                <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/verifikasi`} component={Verifikasi} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/disposisi`} component={Disposisi} />

								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/persyaratan-surat`}
									component={PersyaratanSurat}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/pejabat-penandatangan`}
									component={PejabatPenandatangan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/daftar-aplikasi`}
									component={DaftarAplikasi}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/jenis-surat`}
									component={JenisSurat}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-belum-menikah`}
									component={Suratbelumnikah}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-tidak-menikah`}
									component={Surattidaknikah}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/kartu-keluarga`}
									component={KartuKeluarga}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/data-potensi`}
									component={DataPotensi}
								/>

								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/perangkat`} component={Perangkat} />
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/pengguna`} component={Pengguna} />
								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/tanda-tangan`}
									component={TandaTangan}
								/>

								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/dashboard`}
									component={Dashboard}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-undangan/:id_jenis?`}
									component={SuratUndangan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pengantar-skck/:id_jenis?`}
									component={Suratskck}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pernyataan-kehilangan/:id_jenis?`}
									component={Suratkehilangan}
								/>
								<PrivateRoute
									path={`${process.env
										.PUBLIC_URL}/admin/surat-keterangan-belum-memiliki-rumah/:id_jenis?`}
									component={Suratbelummemilikirumah}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-jalan/:id_jenis?`}
									component={Suratjalan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-pengantar-pindah-nikah/:id_jenis?`}
									component={Suratpindahnikah}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-izin-keramaian/:id_jenis?`}
									component={Suratizinkeramaian}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-usaha/:id_jenis?`}
									component={Suratketeranganusaha}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-kuasa-skgr/:id_jenis?`}
									component={Suratkuasaskgr}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-kuasa/:id_jenis?`}
									component={Suratkuasa}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-penghasilan/:id_jenis?`}
									component={SuratKeteranganPenghasilan}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-kematian/:id_jenis?`}
									component={SuratKeteranganKematian}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-pernah-menikah/:id_jenis?`}
									component={SuratKeteranganPernahMenikah}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-kelahiran/:id_jenis?`}
									component={SuratKeteranganKelahiran}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-ahli-waris/:id_jenis?`}
									component={SuratKeteranganAhliWaris}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-domisili/:id_jenis?`}
									component={SuratKeteranganDomisili}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/surat-keterangan-tidak-mampu/:id_jenis?`}
									component={SuratKeteranganTidakMampu}
								/>
								<PrivateRoute
									path={`${process.env
										.PUBLIC_URL}/admin/surat-keterangan-kepemilikan-rumah/:id_jenis?`}
									component={SuratKeteranganKepemilikanRumah}
								/>
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/profile-desa`}
									component={Profile}
								/>

								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/laporan`} component={Laporan} />
								<PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/profile-desa`}
									component={Profile}
								/>
								
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/catatan`} component={Catatan} />

								<SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/alur-disposisi/:idalur`}
									component={AlurDisposisi}
								/>
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/berita`} component={Berita} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/perubahan-data/nik-kk`} component={PerubahanNik} />
								<PrivateRoute path={`${process.env.PUBLIC_URL}/admin/perubahan-data/pindah-kk`} component={PindahKK} />
								
								<SuperRoute path={`${process.env.PUBLIC_URL}/admin/pengaturan-aplikasi`} component={PengaturanAplikasi} />

								
							</App>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
