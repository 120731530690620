import React, { Component } from 'react';
import { Post, Get, Delete, cekLogin } from '../function/Koneksi';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
	BrowserRouter as Router,
	Link,
} from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';

var images = require.context('../assets/images/', true);
const jwt = require('jsonwebtoken');

class Samplepage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			style: {},
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
		};
	}
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================
	handleSubmit = event => {
		event.preventDefault();
		let cekLog = {
			username: document.getElementById("username").value,
			password: document.getElementById("password").value
		};
		console.log(cekLog);
		let resstat = 200;

		cekLogin('auth/login', cekLog, (res) => {

			console.log(res);
			if (res.status === resstat) {

				localStorage.setItem("access_token", res.data.token);

				this.props.history.push("/"); // apa gunanya ini ?

			}
			else if (res.status === 401) {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Login', pesanAlert: 'Akun belum diverifikasi, Silahkan hubungi admin!' });

			}
			else {

				this.setState({ show: true, basicType: 'danger', basicTitle: 'Login', pesanAlert: 'Username atau password salah!' });
			}
		})
	}
	componentDidMount() {
		setTimeout(function () {
			this.setState({ style: { display: 'none' } });
		}.bind(this), 1000)
	}

	render() {
		let style = this.state.style;
		const background = require('../assets/images/auth-layer.png')

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>
				{/* Loader starts */}
				<div className="loader-wrapper" style={style}>
					<div className="loader bg-white">
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<h4>Have a great day at work today <span>&#x263A;</span></h4>
					</div>
				</div>
				{/* Loader ends */}

				{/*page-wrapper Start*/}
				<div className="page-wrapper">
					<div className="container-fluid">
						{/*login page start*/}
						<div className="authentication-main" >
							<div className="row">
								<div className="col-md-4 p-0">
									<div className="auth-innerleft" style={{ backgroundImage: "url(" + background + ")" }}>
										<div className="text-center">
											<img src={require('../assets/images/digdesa_logo.png')}
												alt="" style={{ maxHeight: "100px" }} />
										</div>
									</div>
								</div>
								<div className="col-md-8 p-0">
									<div className="auth-innerright">
										<div className="authentication-box">
											<div className="text-center">
												<h4>DigiDesa</h4>
												<h6>Semua Kemudahan Layanan Dalam Satu Genggaman</h6>
											</div>
											<div className="card mt-4 p-4 mb-0">
												<Form className="theme-form" onSubmit={this.handleSubmit}>
													<div className="form-group">
														<label className="col-form-label pt-0"> Username</label>
														<input required type="text" id="username" className="form-control form-control-lg" placeholder="masukan username anda" />
													</div>
													<div className="form-group">
														<label className="col-form-label">Password</label>
														<input required type="password" id="password" className="form-control form-control-lg" placeholder="masukan password anda" />
													</div>
													<div className="text-right">
														{/* <h6><Link to="/forget-password" ><u className="text-success">Reset Kata Sandi</u></Link></h6> */}
													</div>
													<div className="text-right mt-4">
														<button type="submit" className="btn btn-success"> Masuk</button>
													</div>
												</Form>

											</div>
											<div className="text-center mt-4">
												{/* <h6>Belum punya akun? <Link to="#" ><u className="text-success">Daftar disini</u></Link></h6> */}
												{/* <h6>Belum punya akun? <Link to="/register" ><u className="text-success">Daftar disini</u></Link></h6> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*login page end*/}
					</div>
				</div>
				{/*page-wrapper Ends*/}

			</div>

		)
	}
}


export default Samplepage;
