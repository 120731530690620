import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';

function EditPernahMenikah({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  return (
    <div>
      <Modal
        open={detailSuratProps.status.formUpdateSurat}
        onClose={detailSuratProps.tutupForm}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>
              Data identitas akan terisi secara otomatis saat NIK dipilih.
              Jika ada ketidak sesuaian data, silakan ubah di data master
              penduduk.
              </p>
            <input
              className="form-control"
              id="uuid"
              type="hidden"
              placeholder=""
              defaultValue={detailSuratProps.detailSurat.uuid}
            />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK
                </Label>
              <Col sm="9">
                <Select
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, "pemohon");
                  }}
                  defaultValue={detailSuratProps.dataNikStat.nikStat}
                  value={detailSuratProps.dataNikStat.nikStat}
                  name="NIK"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilh NIK"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik")}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pemohon
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Nama Pemohon"
                    value={detailSuratProps.detailSurat.nama_pemohon}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_lahir"
                    type="text"
                    placeholder="Tempat Lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir"
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={detailSuratProps.detailSurat.tanggal_lahir}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pekerjaan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pekerjaan"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.pekerjaan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Agama
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="agama"
                    type="text"
                    placeholder="Agama"
                    value={detailSuratProps.detailSurat.agama}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="Alamat"
                    value={detailSuratProps.detailSurat.alamat}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Perkawinan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="status_perkawinan"
                    type="text"
                    placeholder="Status Perkawinan"
                    value={detailSuratProps.detailSurat.status_perkawinan}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK Pasangan
                </Label>
              <Col sm="9">
                <Select
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, "pasangan");
                  }}
                  defaultValue={detailSuratProps.dataNikStat.nik3Stat}
                  value={detailSuratProps.dataNikStat.nik3Stat}
                  name="NIK3"
                  options={detailSuratProps.dataNik.frmNik3}
                  placeholder="Pilh NIK Pasangan"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik3")}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk3 }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_pasangan"
                    type="text"
                    placeholder="Nama Pasangan"
                    value={detailSuratProps.detailSurat.nama_pasangan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_lahir_pasangan"
                    type="text"
                    placeholder="Tempat Lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir_pasangan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir_pasangan"
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={detailSuratProps.detailSurat.tanggal_lahir_pasangan}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pekerjaan Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pekerjaan_pasangan"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.pekerjaan_pasangan}
                    readOnly
                  />
                  {/* <Select
                                            classNamePrefix="select"
                                            onChange={((e)=>{ this.changeSelectValue(e,'pekerjaan')})}
                                            value={this.state.pkrStat} 
                                            value={this.state.pkrStat} 
                                            name="pekerjaan"
                                            options={this.state.frmPkr}
                                            placeholder="Pilih Pekerjaan"
                                            isClearable
                                        /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Agama Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="agama_pasangan"
                    type="text"
                    placeholder="Agama"
                    value={detailSuratProps.detailSurat.agama_pasangan}
                    readOnly
                  />
                  {/* <Select
                                            classNamePrefix="select"
                                            onChange={((e)=>{ this.changeSelectValue(e,'agama')})}
                                            value={this.state.agmStat} 
                                            value={this.state.agmStat} 
                                            name="agama"
                                            options={this.state.frmAgm}
                                            placeholder="Pilih Agama"
                                            isClearable
                                            /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat_pasangan"
                    type="text"
                    placeholder="Alamat"
                    value={detailSuratProps.detailSurat.alamat_pasangan}
                    readOnly
                  />
                  {/* <textarea className="form-control" id="alamat" placeholder="Alamat" value={detailSuratProps.detailSurat.alamat}></textarea> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Perkawinan Pasangan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="status_perkawinan_pasangan"
                    type="text"
                    placeholder="Status Perkawinan Pasangan"
                    value={detailSuratProps.detailSurat.status_perkawinan_pasangan}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Tanggal Menikah
                </Label>
              <Col sm="9">
                <Datepicker
                  className="form-control"
                  selected={detailSuratProps.detailSurat.tanggal_menikah}
                  onChange={e => detailSuratProps.gantiTgl(e, "tanggal_menikah")}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="tanggal"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Tempat Menikah
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="tempat_menikah"
                  type="text"
                  placeholder="Tempat Menikah"
                  defaultValue={detailSuratProps.detailSurat.tempat_menikah}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Petugas Nikah
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="petugas_nikah"
                  type="text"
                  placeholder="Petugas Menikah"
                  defaultValue={detailSuratProps.detailSurat.petugas_nikah}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Wali Nikah
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="wali_nikah"
                  type="text"
                  placeholder="Wali Menikah"
                  defaultValue={detailSuratProps.detailSurat.wali_nikah}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Mas Kawin
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="mas_kawin"
                  type="text"
                  placeholder="Mas Kawin"
                  defaultValue={detailSuratProps.detailSurat.mas_kawin}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Saksi Nikah
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="saksi_nikah"
                  type="text"
                  placeholder="Saksi Nikah"
                  defaultValue={detailSuratProps.detailSurat.saksi_nikah}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK Pelapor
                </Label>
              <Col sm="9">
                <Select
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, "pelapor");
                  }}
                  defaultValue={detailSuratProps.dataNikStat.nik2Stat}
                  value={detailSuratProps.dataNikStat.nik2Stat}
                  name="NIK2"
                  options={detailSuratProps.dataNik.frmNik2}
                  placeholder="Pilh NIK Pelapor"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik2")}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk2 }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pelapor
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_pelapor"
                    type="text"
                    placeholder="Nama Pelapor"
                    value={detailSuratProps.detailSurat.nama_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_lahir_pelapor"
                    type="text"
                    placeholder="Tempat Lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir_pelapor"
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={detailSuratProps.detailSurat.tanggal_lahir_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pekerjaan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pekerjaan_pelapor"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.pekerjaan_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Agama
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="agama_pelapor"
                    type="text"
                    placeholder="Agama"
                    value={detailSuratProps.detailSurat.agama_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat_pelapor"
                    type="text"
                    placeholder="Alamat"
                    value={detailSuratProps.detailSurat.alamat_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Perkawinan Pelapor
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="status_perkawinan_pelapor"
                    type="text"
                    placeholder="Status Perkawinan Pelapor"
                    value={detailSuratProps.detailSurat.status_perkawinan_pelapor}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              onClick={detailSuratProps.tutupForm}
            >
              Tutup
              </button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >
              Simpan
              </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditPernahMenikah
