import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';

function EditKematian({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  return (
    <div>
      <Modal open={detailSuratProps.status.formUpdateSurat} onClose={detailSuratProps.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>Data identitas akan terisi secara otomatis saat NIK dipilih. Jika ada ketidak sesuaian data, silakan ubah di data master penduduk.</p>
            <input className="form-control" id="uuid" type="hidden" placeholder="" defaultValue={detailSuratProps.detailSurat.uuid} />
            <FormGroup className="row">
              <Label id="nikStat" className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK</Label>
              <Col sm='9'>
                <Select
                  classNamePrefix="select"
                  onChange={((e) => { detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'pemohon') })}
                  defaultValue={detailSuratProps.dataNikStat.nikStat}
                  value={detailSuratProps.dataNikStat.nikStat}
                  name="NIK"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilh NIK"
                  onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama</Label>
                <Col sm='9'>
                  <input className="form-control" id="nama" type="text" placeholder="Nama Pemohon" value={detailSuratProps.detailSurat.nama} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tempat_lahir" type="text" placeholder="Tempat Lahir" value={detailSuratProps.detailSurat.tempat_lahir} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tanggal_lahir" type="text" placeholder="Tanggal Lahir" value={detailSuratProps.detailSurat.tanggal_lahir} readOnly />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan</Label>
                <Col sm='9'>
                  <input className="form-control" id="pekerjaan" type="text" placeholder="Pekerjaan" value={detailSuratProps.detailSurat.pekerjaan} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Agama</Label>
                <Col sm='9'>
                  <input className="form-control" id="agama" type="text" placeholder="Agama" value={detailSuratProps.detailSurat.agama} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
                <Col sm='9'>
                  <input className="form-control" id="alamat" type="text" placeholder="Alamat" value={detailSuratProps.detailSurat.alamat} readOnly />
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Waktu Meninggal</Label>
              <Col sm='9'>
                <Datepicker
                  className="form-control"
                  selected={detailSuratProps.detailSurat.waktu_meninggal}
                  onChange={(e) => detailSuratProps.gantiTgl(e, 'waktu_meninggal')}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="waktu"
                  dateFormat="dd/MM/yyyy HH:mm"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Meninggal</Label>
              <Col sm='9'>
                <input className="form-control" id="tempat_meninggal" type="text" placeholder="Tempat Meninggal" defaultValue={detailSuratProps.detailSurat.tempat_meninggal} required />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Sebab Meninggal</Label>
              <Col sm='9'>
                <input className="form-control" id="sebab_meninggal" type="text" placeholder="Sebab Meninggal" defaultValue={detailSuratProps.detailSurat.sebab_meninggal} required />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Dimakamkan di</Label>
              <Col sm='9'>
                <input className="form-control" id="tempat_makam" type="text" placeholder="Tempat Makam" defaultValue={detailSuratProps.detailSurat.tempat_makam} required />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label id="nik2Stat" className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK Pelapor</Label>
              <Col sm='9'>
                <Select
                  classNamePrefix="select"
                  onChange={((e) => { detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'pelapor') })}
                  defaultValue={detailSuratProps.dataNikStat.nik2Stat}
                  value={detailSuratProps.dataNikStat.nik2Stat}
                  name="NIK Pelapor"
                  options={detailSuratProps.dataNik.frmNik2}
                  placeholder="Pilh NIK Pelapor"
                  onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik2')}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk2 }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Pemohon</Label>
                <Col sm='9'>
                  <input className="form-control" id="nama" type="text" placeholder="Nama Pemohon" value={detailSuratProps.detailSurat.nama_pelapor} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tempat_lahir" type="text" placeholder="Tempat Lahir" value={detailSuratProps.detailSurat.tempat_lahir_pelapor} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tanggal_lahir" type="text" placeholder="Tanggal Lahir" value={detailSuratProps.detailSurat.tanggal_lahir_pelapor} readOnly />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan</Label>
                <Col sm='9'>
                  <input className="form-control" id="pekerjaan" type="text" placeholder="Pekerjaan" value={detailSuratProps.detailSurat.pekerjaan_pelapor} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Agama</Label>
                <Col sm='9'>
                  <input className="form-control" id="agama" type="text" placeholder="Agama" value={detailSuratProps.detailSurat.agama_pelapor} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
                <Col sm='9'>
                  <input className="form-control" id="alamat" type="text" placeholder="Alamat" value={detailSuratProps.detailSurat.alamat_pelapor} readOnly />
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Hubungan Pelapor</Label>
              <Col sm='9'>
                <input className="form-control" id="hubungan_pelapor" type="text" placeholder="Hubungan Pelapor" defaultValue={detailSuratProps.detailSurat.hubungan_pelapor} required />
              </Col>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-warning" onClick={detailSuratProps.tutupForm}>Tutup</button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >Simpan</button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditKematian
