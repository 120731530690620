import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";

import { Redirect } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
var jwt = require("jsonwebtoken");
var images = require.context("../assets/images/", true);

class Samplepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      token: this.props.match.params.token,
      uuid: null,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      showToLogin: false,
      redirect: false,
    };
  }
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
  toLogin = () => {
    this.setState({
      showToLogin: false,
      redirect: true,
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  change = (event) => {
    event.preventDefault();
    if (
      document.getElementById("password1").value ==
      document.getElementById("password2").value
    ) {
      let dataRes = {
        uuid: document.getElementById("uuid").value,
        password: document.getElementById("password1").value,
      };
      console.log(dataRes);
      let resstat = 200;

      cekLogin("auth/change/password", dataRes, (res) => {
        console.log(res);
        if (res.status === resstat) {
          this.setState({
            showToLogin: true,
            basicType: "success",
            basicTitle: "Reset Password",
            pesanAlert: "Berhasil reset password, Silahkan Login",
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Reset Password",
            pesanAlert: "Gagal reset password!",
          });
        }
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Reset Password",
        pesanAlert: "Password anda tidak sama!",
      });
    }
  };
  componentDidMount() {
    console.log(this.state.token);
    setTimeout(
      function() {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  render() {
    let style = this.state.style;
    const background = require("../assets/images/auth-layer.png");

    const { token } = this.state;
    let decodeToken = jwt.decode(token);
    if (Date.now() >= decodeToken.exp * 1000) {
      this.state.redirect = true;
      this.forceUpdate();
    }
    return (
      <div>
        {this.renderRedirect()}
        {/* Loader starts */}
        <div className="loader-wrapper" style={style}>
          <div className="loader bg-white">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <h4>
              Have a great day at work today <span>&#x263A;</span>
            </h4>
          </div>
        </div>
        {/* Loader ends */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>
        <SweetAlert
          show={this.state.showToLogin}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.toLogin}
        >
          {this.state.pesanAlert}
        </SweetAlert>
        {/*page-wrapper Start*/}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/*sign up page start*/}

            <div className="authentication-main">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div
                    className="auth-innerleft"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div className="text-center">
                      <img
                        src={require("../assets/images/key.png")}
                        className="img-fluid security-icon"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 p-0">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <h3>RESET PASSWORD ANDA </h3>
                      <div className="card mt-4 p-4">
                        <Form className="theme-form" onSubmit={this.change}>
                          <h5 className="f-16 mb-3">Buat password anda</h5>
                          <div className="form-group">
                            <label className="col-form-label">
                              Password Baru
                            </label>
                            <input
                              type="password"
                              id="password1"
                              className="form-control"
                              placeholder="*****"
                            />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">
                              Ketik Ulang Password
                            </label>
                            <input
                              id="password2"
                              type="password"
                              className="form-control"
                              placeholder="*****"
                            />
                            <input
                              id="uuid"
                              type="hidden"
                              value={decodeToken.sub.uuid}
                              className="form-control"
                              placeholder="*****"
                            />
                          </div>
                          <div className="form-group form-row mb-0">
                            <div className="col-md-2">
                              <button
                                type="submit"
                                className="btn btn-secondary"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Reset Password page end*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Samplepage;
