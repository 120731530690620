import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Datepicker from 'react-datepicker';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import CountUp from 'react-countup';
import { Chart } from 'react-google-charts';
import Highcharts, { map } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';

// koneksi
import { Post, PostStatus, PostUser, Get, Delete, cekLogin } from '../function/Koneksi';
import { Tanggal } from '../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../assets/css/site.css';

drilldown(Highcharts);

//json file
// var data = require('../assets/json/company');
const frmDef = {
	id: '',
	id_rt: '',
	alamat: ''
};
class DataPotensi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_rt: [],
			pagination: [],
			pagination_rt: [],
			status: {
				view: false,
				form: false,
				btnForm: false,
				btnAksi: false
			},
			status2: {
				view: false,
				form: false,
				btnForm: false,
				btnEdit: false,
				btnDelete: false
			},
			datakk: {
				id: '',
				nama: '',
				tempat_lahir: '',
				nama_ibu_kandung: '',
				nama_ayah_kandung: ''
			},
			active_tab_icon: '1',
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			shdk: [],
			detailshdk: [],
			batasKK: '',
			penduduk_kk_baru: [],
			data_keluarga: [],
			diskk: false,
			tampilkk: 'inherit',
			wilayah: 'inherit',
			addForm: false,
			addMode: 'provinsi',
			addID: '',
			addNama: '',
			frmAnggota: 'none',
			detail: [],
			view_kk: '',
			view_penduduk: [],
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			pekerjaan: [],
			hubungan: [],
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			dusStat: null,
			frmDus: [],
			frmShdk: [],
			shdkStat: null,
			frmAgm: [],
			agmStat: null,
			frmPtr: [],
			ptrStat: null,
			frmPkr: [],
			pkrStat: null,
			frmKk: [],
			kkStat: null,
			frmStp: [],
			stpStat: null,
			frmJk: [],
			jkStat: null,
			frmSpk: [],
			spkStat: null,
			agmkkStat: null,
			ptrkkStat: null,
			pkrkkStat: null,
			stpkkStat: null,
			jkkkStat: null,
			spkkkStat: null,
			// ============ Tanggal ==============
			startDate: new Date(),
			startDatekk: new Date(),
			// ===================================
			data_penduduk: '',
			statUbah: false,
			pecahKK: false,

			// =========== Data Potensi ========
			data_kk_terdaftar: [],
			data_potensi_penduduk: [],
			list_penduduk: [],
			tmp_nik: '',

			// ============= Highcharts data ================
			data_agama: [],
			drilldown_data_agama: [],
			data_pekerjaan: [],
			drilldown_data_pekerjaan: [],
			data_pernikahan: [],
			drilldown_data_pernikahan: [],
			data_usia: [],
			drilldown_data_usia: [],
			data_air_minum: [],
			drilldown_data_air_minum: [],
			data_kepemilikan_rumah: [],
			drilldown_data_kepemilikan_rumah: [],

			data_kepemilikan_lahan: [],
			drilldown_data_kepemilikan_lahan: [],
			data_detail_produksi: [],
			drilldown_detail_produksi: [],
			data_aset: [],
			drilldown_data_aset: [],
			data_lembaga_ekonomi: [],
			drilldown_data_lembaga_ekonomi: [],
			data_aset_pilihan: [],
			drilldown_data_aset_pilihan: [],
			data_produksi_galian: [],
			drilldown_data_produksi_galian: []
		};
	}

	// ================== Tanggal ===================

	gantiTgl = (date) => {
		this.setState({ startDate: date });
	};

	// ==============================================

	// ================== Additional Form ==================
	addForm = (e, mode) => {
		e.preventDefault();
		if (mode == 'provinsi') {
			this.setState({ addMode: mode, addForm: true });
		} else {
			switch (mode) {
				case 'kabupaten/kota':
					if (this.state.prvStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Kabupaten/Kota',
							pesanAlert: 'Untuk menambah Kabupaten/Kota, Silakan pilih terlebih dahulu Provinsi nya.'
						});
					}
					break;
				case 'kecamatan':
					if (this.state.kbktStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Kecamatan',
							pesanAlert: 'Untuk menambah Kecamatan, Silakan pilih terlebih dahulu Kabupaten/Kota nya.'
						});
					}
					break;
				case 'kelurahan/desa':
					if (this.state.kecStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Kelurahan/Desa',
							pesanAlert: 'Untuk menambah Kelurahan/Desa, Silakan pilih terlebih dahulu Kecamatan nya.'
						});
					}
					break;
				case 'dusun':
					if (this.state.kelStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Dusun',
							pesanAlert: 'Untuk menambah Dusun, Silakan pilih terlebih dahulu Kelurahan/Desa nya.'
						});
					}
					break;
				case 'rw':
					if (this.state.dusStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data RW',
							pesanAlert: 'Untuk menambah RW, Silakan pilih terlebih dahulu Dusun nya.'
						});
					}
					break;
				case 'rt':
					if (this.state.rwStat.value) {
						this.setState({ addMode: mode, addForm: true });
					} else {
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data RT',
							pesanAlert: 'Untuk menambah RT, Silakan pilih terlebih dahulu RW nya.'
						});
					}
					break;
			}
		}
	};

	tutupAddForm = (e) => {
		e.preventDefault();
		this.setState({ mode: 'provinsi', addID: '', addNama: '', addForm: false });
	};

	addSimpan = (e, mode) => {
		this.state.status.btnForm = true;
		this.forceUpdate();
		e.preventDefault();
		let addWil = {};
		switch (mode) {
			case 'provinsi':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('provinsi/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.componentDidMount();
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Provinsi',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Provinsi',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
			case 'kabupaten/kota':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					id_provinsi: this.state.prvStat.value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('kab-kota/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.getChild(this.state.prvStat, 'provinsi', 'prov', 'kab-kota');
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Kabupaten/Kota',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Kabupaten/Kota',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
			case 'kecamatan':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					id_kabkota: this.state.kbktStat.value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('kecamatan/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.getChild(this.state.kbktStat, 'kab-kota', 'kab', 'kecamatan');
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Kecamatan',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Kecamatan',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
			case 'kelurahan/desa':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					id_kecamatan: this.state.kecStat.value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('keldes/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.getChild(this.state.kecStat, 'kecamatan', 'kec', 'keldes');
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Kelurahan/Desa',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Kelurahan/Desa',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
			case 'dusun':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					id_keldes: this.state.kelStat.value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('dusun/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.getChild(this.state.kelStat, 'keldes', 'kec', 'dusun');
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Dusun',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Dusun',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
			case 'rw':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					id_dusun: this.state.dusStat.value,
					id_keldes: this.state.kelStat.value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('rw/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.getChild(this.state.dusStat, 'dusun', 'dus', 'rw');
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'RW',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'RW',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
			case 'rt':
				addWil = {
					id: document.getElementById('id_wilayah').value,
					id_rw: this.state.rwStat.value,
					nama: document.getElementById('nama_wilayah').value
				};
				Post('rt/create', null, addWil, (res) => {
					this.state.status.btnForm = false;
					this.forceUpdate();
					if (res.status == 201) {
						this.getChild(this.state.rwStat, 'rw', 'rw', 'rt');
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'RT',
							pesanAlert: 'Berhasil Tambah Data',
							addMode: 'provinsi',
							addID: '',
							addNama: '',
							addForm: false
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'RT',
							pesanAlert: 'Gagal Tambah Data. Mohon coba lagi.'
						});
					}
				});
				break;
		}
	};

	// =====================================================
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let link_rt = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					link_rt = '?page=' + Math.ceil(this.state.pagination_rt.total / this.state.pagination_rt.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					link_rt = '?page=' + (parseInt(this.state.pagination_rt.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					link_rt = '?page=' + (parseInt(this.state.pagination_rt.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					link_rt = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					link_rt = '?page=' + params.page;
					break;
			}
		}
		Get('potensi-desa/data-masyarakat' + link, null, (dtkat) => {
			this.setState({ data: dtkat.results.data, pagination: dtkat.results });
			console.log(this.state.data);
			this.pagination(dtkat.results);
		});
		Get('data-rt' + link_rt, null, (dtkat) => {
			// console.log(dtkat);
			this.setState({
				data_rt: dtkat.results.data,
				pagination_rt: dtkat.results
			});
			this.pagination(dtkat.results);
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// ========================================================================

	simpanUser = (e, mode) => {
		e.preventDefault();

		var data_rt = new FormData();
		if (typeof this.state.pendStat != 'undefined') {
			data_rt.append('nik', this.state.pendStat.value);
			data_rt.append('username', document.getElementById('username').value);
			data_rt.append('password', document.getElementById('password').value);
			data_rt.append('email', document.getElementById('email').value);

			// Continue this
			PostUser('rt/create', null, data_rt, (res) => {
				console.log(res);
				if (res === 200) {
					this.tutupForm();
					this.state.status.btnForm = false;
					this.forceUpdate();
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Potensi Masyarakat',
						pesanAlert: 'Berhasil ' + 'tambah' + ' Data'
					});
					this.componentDidMount();
				}
			});
		}
	};

	// ================== simpan data ==================
	simpan = (e, mode) => {
		e.preventDefault();
		if (
			((this.state.rtStat && this.state.wilayah == 'inherit') || this.state.wilayah == 'none') &&
			this.state.agmkkStat &&
			this.state.pkrkkStat &&
			this.state.ptrkkStat &&
			this.state.spkkkStat &&
			this.state.stpkkStat &&
			this.state.jkkkStat &&
			this.state.kelStat
		) {
			let kepala = {
				id: document.getElementById('nikkk').value,
				id_kk: document.getElementById('idBaru').value,
				id_keldes: this.state.kelStat ? this.state.kelStat.value : null,
				nama: document.getElementById('namakk').value,
				alamat: document.getElementById('alamat').value,
				tempat_lahir: document.getElementById('tempat_lahirkk').value,
				tanggal_lahir: new Date(this.state.startDatekk).toISOString().slice(0, 10),
				jk: this.state.jkkkStat.value,
				shdk: 'fbbc9dda-f721-49ac-a611-430269d83cc2',
				agama: this.state.agmkkStat.value,
				status_perkawinan: this.state.spkkkStat.value,
				pendidikan_terakhir: this.state.ptrkkStat.value,
				pekerjaan: this.state.pkrkkStat.value,
				nama_ibu_kandung: document.getElementById('nama_ibu_kandungkk').value,
				nama_ayah_kandung: document.getElementById('nama_ayah_kandungkk').value,
				status_penduduk: this.state.stpkkStat.value
			};
			this.state.detail.push(kepala);
			this.state.status.btnForm = true;
			this.forceUpdate();
			let id = document.getElementById('id').value;
			let add = {
				id: parseInt(document.getElementById('idBaru').value),
				id_rt: this.state.dt.id_rt,
				id_desa: this.state.kelStat.value,
				alamat: document.getElementById('alamat').value,
				detail: this.state.detail
			};
			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (this.state.detail) {
				metode = 'create/with-detail';
				if (this.state.pecahKK) {
					metode = 'create';
				}
			} else {
				delete add.detail;
			}
			if (id == 0) {
				psn = 'Tambah';
				resstat = 201;
				id = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
				delete add.detail;
			}
			PostStatus('kartu-keluarga/' + metode, id, add, (res) => {
				if (res === resstat) {
					this.tutupForm();
					if (this.state.pecahKK) {
						let penduduk = this.state.data_penduduk.split(';');
						// console.log(this.state.detailshdk);
						Post('kartu-keluarga/update/anggota/shdk', null, { detail: this.state.detailshdk }, (res) => {
							// console.log(res);
							for (let i = 1; i < penduduk.length; i++) {
								let data = {
									id_kk: parseInt(document.getElementById('idBaru').value)
								};
								let pddk = penduduk[i].split('-');
								PostStatus('penduduk/update', pddk[0], data, (res) => {
									if (mode == 1) {
										Get('kartu-keluarga/find', pddk[1], (dtkk) => {
											this.getPenduduk(dtkk.results);
											this.state.dt.id = 0;
											this.state.dt.alamat = '';
											document.getElementById('id').value = 0;
											document.getElementById('idBaru').value = 0;
											document.getElementById('alamat').value = '';
											this.state.statUbah = false;
											this.forceUpdate();
										});
									} else {
										this.state.status.form = false;
										this.forceUpdate();
									}
									if (penduduk.length == i + 1) {
										this.setState({
											show: true,
											basicType: 'success',
											basicTitle: 'Data Potensi Masyarakat',
											pesanAlert: 'Berhasil Pecah KK ke ' + add.id
										});
										this.componentDidMount();
									}
								});
							}
						});
					} else {
						this.state.status.btnForm = false;
						if (mode == 1) {
							this.state.dt.id = 0;
							this.state.dt.alamat = '';
							document.getElementById('id').value = 0;
							document.getElementById('idBaru').value = 0;
							document.getElementById('alamat').value = '';
							this.state.statUbah = false;
							this.state.frmAnggota = 'none';
							this.state.detail = [];
						} else {
							this.state.status.form = false;
						}
						this.forceUpdate();
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Potensi Masyarakat',
							pesanAlert: 'Berhasil ' + psn + ' Data'
						});
						this.componentDidMount();
					}
				} else {
					this.state.status.btnForm = false;
					this.forceUpdate();
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Potensi Masyarakat',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.componentDidMount();
				}
			});
		} else {
			let luar = '';
			if (this.state.wilayah == 'inherit') {
				luar = 'RT, ';
			}
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data Potensi Masyarakat',
				pesanAlert:
					'Data belum lengkap. Mohon Pastikan data ' +
					luar +
					'Agama, Pendidikan Terakhir, Pekerjaan, Status Perkawinan, Status Penduduk dan Jenis Kelamin sudah dipilih'
			});
		}
	};

	// Cari Data Table
	cariData = (e) => {
		if (e.key == 'Enter') {
			let dataPenduduk = {
				nik: e.target.value
			};
			Post('potensi-desa/data-masyarakat', null, dataPenduduk, (data) => {
				this.setState({
					data: data.results.data,
					pagination: data.results
				});
				this.pagination(data.results);
			});
		}
	};

	// ========================================================================

	bukaView = (id) => {
		Get('kartu-keluarga/keluarga', id, (data) => {
			this.state.view_kk = id;
			this.state.view_penduduk = data.results;
			this.state.status.view = true;
			this.forceUpdate();
		});
	};

	tutupView = () => {
		this.state.status.view = false;
		this.forceUpdate();
	};

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.status.form = false;
		this.state.agmkkStat = null;
		this.state.pkrkkStat = null;
		this.state.ptrkkStat = null;
		this.state.spkkkStat = null;
		this.state.stpkkStat = null;
		this.state.jkkkStat = null;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		return (
			<div>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => this.ubahData(row.id)}
					disabled={this.state.status2.btnEdit}
				>
					<i className="fa fa-pencil" />
				</Button>{' '}
				&nbsp;&nbsp;
				{/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.id)} disabled={this.state.status2.btnDelete}><i className="fa fa-trash"></i></Button> */}
			</div>
		);
	};

	componentDidMount() {
		this.fetch({ page: null, where: [] });

		console.log(this.state.data);

		// Data Potensi Desa
		Get('potensi-desa/data-masyarakat', null, (dtkat) => {
			console.log(dtkat.results);
			let data_kk_terdaftar = { total: dtkat.results.data.length };
			this.setState({ data_kk_terdaftar });
		});
		Get('potensi-desa/jumlah-masyarakat', null, (data) => {
			let data_potensi_penduduk = {
				total: data.results.total,
				laki_laki: data.results.laki_laki,
				perempuan: data.results.perempuan
			};
			// console.log(data);
			this.setState({ data_potensi_penduduk });
		});
		Get('penduduk', null, (data) => {
			let frmPend = [];
			data.results.data.map((dt) => {
				frmPend.push({ value: dt.id, label: dt.id });
			});
			this.setState({ frmPend });
		});

		// Highcharts data
		// Pie
		Get('potensi-desa/agama-masyarakat', null, (data) => {
			var data_agama = [];
			var drilldown_data_agama = []; // Fitur untuk detail

			for (var [ index, agama ] of data.results.entries()) {
				data_agama.push({
					name: agama.data_agama.nama,
					y: agama.total,
					drilldown: agama.data_agama.nama + index
				});
			}
			this.setState({ data_agama });
			this.setState({ drilldown_data_agama });
		});
		Get('potensi-desa/pekerjaan-masyarakat', null, (data) => {
			var data_pekerjaan = [];
			var drilldown_data_pekerjaan = []; // Fitur untuk detail

			for (var [ index, pekerjaan ] of data.results.entries()) {
				data_pekerjaan.push({
					name: pekerjaan.data_pekerjaan.nama,
					y: pekerjaan.total,
					drilldown: pekerjaan.data_pekerjaan.nama + index
				});
			}
			this.setState({ data_pekerjaan });
			this.setState({ drilldown_data_pekerjaan });
		});
		Get('potensi-desa/rentang-umur-masyarakat', null, (data) => {
			var data_usia = [];
			var drilldown_data_usia = []; // Fitur untuk detail

			data_usia.push({
				name: 'Balita (0-5 tahun)',
				y: data.results.balita,
				drilldown: 'balita'
			});
			data_usia.push({
				name: 'Kanak-kanak (5-11 tahun)',
				y: data.results.kanak_kanak,
				drilldown: 'kanak_kanak'
			});
			data_usia.push({
				name: 'Remaja Awal (12-16 tahun)',
				y: data.results.remaja_awal,
				drilldown: 'remaja_awal'
			});
			data_usia.push({
				name: 'Remaja Akhir (17-25 tahun)',
				y: data.results.remaja_akhir,
				drilldown: 'remaja_akhir'
			});
			data_usia.push({
				name: 'Dewasa Awal (26-35 tahun)',
				y: data.results.dewasa_awal,
				drilldown: 'dewasa_awal'
			});
			data_usia.push({
				name: 'Dewasa Akhir (36-45 tahun)',
				y: data.results.dewasa_akhir,
				drilldown: 'dewasa_akhir'
			});
			data_usia.push({
				name: 'Lansia Awal (46-55 tahun)',
				y: data.results.lansia_awal,
				drilldown: 'lansia_awal'
			});
			data_usia.push({
				name: 'Lansia Akhir (56-65 tahun)',
				y: data.results.lansia_akhir,
				drilldown: 'lansia_akhir'
			});
			data_usia.push({
				name: 'Manula (> 65 tahun)',
				y: data.results.manula,
				drilldown: 'manula'
			});

			this.setState({ data_usia });
			this.setState({ drilldown_data_usia });
		});

		// Donut
		Get('potensi-desa/pernikahan-masyarakat', null, (data) => {
			var data_pernikahan = [];
			var drilldown_data_pernikahan = []; // Fitur untuk detail

			for (var [ index, data_nikah ] of data.results.entries()) {
				data_pernikahan.push({
					name: data_nikah.status_perkawinan,
					y: data_nikah.total,
					drilldown: data_nikah.status_perkawinan + index
				});
			}

			this.setState({ data_pernikahan });
			this.setState({ drilldown_data_pernikahan });
		});
		Get('potensi-desa/sumber-air-minum', null, (data) => {
			var data_air_minum = [];
			var drilldown_data_air_minum = []; // Fitur untuk detail

			for (var [ index, air_minum ] of data.results.entries()) {
				if (air_minum.data_sumber != null) {
					data_air_minum.push({
						name: air_minum.data_sumber.nama,
						y: air_minum.total,
						drilldown: air_minum.data_sumber.nama + index
					});
				}
			}

			this.setState({ data_air_minum });
			this.setState({ drilldown_data_air_minum });
		});
		Get('potensi-desa/kepemilikan-rumah', null, (data) => {
			var data_kepemilikan_rumah = [];
			var drilldown_data_kepemilikan_rumah = []; // Fitur untuk detail

			for (var [ index, rumah ] of data.results.entries()) {
				data_kepemilikan_rumah.push({
					name: rumah.data_sumber_kepemilikan.nama_status,
					y: rumah.total,
					drilldown: rumah.data_sumber_kepemilikan.nama_status + index
				});
			}

			this.setState({ data_kepemilikan_rumah });
			this.setState({ drilldown_data_kepemilikan_rumah });
		});

		// Statistik
		Get('potensi-desa/kepemilikan-lahan', null, (data) => {
			var data_kepemilikan_lahan = [];
			var drilldown_data_kepemilikan_lahan = [];

			for (var [ index, jenis_lahan ] of data.results.entries()) {
				var tmp_jumlah = 0;
				var sub_lahan = []; // Untuk drilldown
				for (var [ index2, lahan ] of jenis_lahan.child.entries()) {
					if (lahan.status != 'Tidak Memiliki') {
						sub_lahan.push([ lahan.status, lahan.total ]); // Untuk drilldown
						tmp_jumlah += lahan.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_kepemilikan_lahan.push({
					name: jenis_lahan.nama,
					y: tmp_jumlah,
					drilldown: jenis_lahan.nama + index
				});

				drilldown_data_kepemilikan_lahan.push({
					id: jenis_lahan.nama + index,
					name: 'Total Kepemilikan ' + jenis_lahan.nama,
					data: sub_lahan
				});
				// }
			}

			this.setState({ data_kepemilikan_lahan });
			this.setState({ drilldown_data_kepemilikan_lahan });
		});
		Get('potensi-desa/komoditas-masyarakat', null, (data) => {
			var data_detail_produksi = [];
			var drilldown_detail_produksi = [];

			if (data.results != null || typeof data.results != 'undefined') {
				data.results.forEach((jenis_komoditas, index) => {
					data_detail_produksi.push({
						name: jenis_komoditas.nama,
						y: jenis_komoditas.total,
						drilldown: jenis_komoditas.nama + index
					});

					var drilldown2 = [];
					jenis_komoditas.child.forEach((komoditas, index2) => {
						var drilldown3_id = komoditas.nama + index2;
						// set data series
						drilldown2.push({
							name: komoditas.nama,
							y: komoditas.total,
							drilldown: drilldown3_id
						});

						var drilldown3 = [];
						komoditas.child.forEach((penduduk, index3) => {
							drilldown3.push([ penduduk.data_penduduk.nama, parseInt(penduduk.jumlah) ]);
						});

						// push data ke drilldown
						drilldown_detail_produksi.push({
							id: drilldown3_id,
							name: 'Jumlah ' + komoditas.nama,
							data: drilldown3
						});
					});

					drilldown_detail_produksi.push({
						id: jenis_komoditas.nama + index,
						name: 'Total Kepemilikan ' + jenis_komoditas.nama,
						data: drilldown2
					});
				});
			}

			this.setState({ data_detail_produksi });
			this.setState({ drilldown_detail_produksi });
		});
		Get('potensi-desa/aset-masyarakat', null, (data) => {
			var data_aset = []; // data turunan petama
			var drilldown_data_aset = []; // data turunan kedua,dst

			console.log(data.results);

			if (data.results != null || typeof data.results != 'undefined') {
				for (var [ index, kategori_aset ] of data.results.entries()) {
					data_aset.push({
						name: kategori_aset.nama,
						y: parseInt(kategori_aset.total),
						drilldown: kategori_aset.nama + index
					});
					var sub_data_aset = [];
					for (var [ index2, aset ] of kategori_aset.child.entries()) {
						var tmp_id = '';
						var sub_data_aset2 = [];
						for (var [ index3, penduduk ] of aset.child.entries()) {
							console.log(penduduk.jumlah);
							tmp_id = aset.nama + index3;
							sub_data_aset2.push([ penduduk.data_penduduk.nama, penduduk.jumlah ]);
						}

						// push data turunan ketiga
						drilldown_data_aset.push({
							id: tmp_id,
							name: 'Jumlah ' + aset.nama,
							data: sub_data_aset2
						});

						// pengumpulan data turunan kedua
						sub_data_aset.push({
							name: aset.nama,
							y: aset.total,
							drilldown: tmp_id
						});
					}
					// push data turunan kedua
					drilldown_data_aset.push({
						id: kategori_aset.nama + index,
						name: 'Jumlah Kepemilikan',
						data: sub_data_aset
					});
				}
			}

			this.setState({ data_aset });
			this.setState({ drilldown_data_aset });
			console.log(this.state.data_aset);
			console.log(this.state.drilldown_data_aset);
		});
		Get('potensi-desa/lembaga-ekonomi-masyarakat', null, (data) => {
			var data_lembaga_ekonomi = [];
			var drilldown_data_lembaga_ekonomi = [];

			for (var [ index, jenis_lembaga_ekonomi ] of data.results.entries()) {
				data_lembaga_ekonomi.push({
					name: jenis_lembaga_ekonomi.data_lembaga.nama,
					y: jenis_lembaga_ekonomi.total,
					drilldown: jenis_lembaga_ekonomi.data_lembaga.nama + index
				});
			}
			this.setState({ data_lembaga_ekonomi });
			// this.setState({ drilldown_data_lembaga_ekonomi });
		});
		Get('potensi-desa/aset-pilihan-masyarakat', null, (data) => {
			console.log(data.results);
			var data_aset_pilihan = [];
			var drilldown_data_aset_pilihan = [];

			for (var [ index, jenis_aset_pilihan ] of data.results.entries()) {
				data_aset_pilihan.push({
					name: jenis_aset_pilihan.nama,
					y: jenis_aset_pilihan.total,
					drilldown: jenis_aset_pilihan.nama + index
				});
				var sub_aset_pilihan = [];
				for (var [ index2, aset_pilihan ] of jenis_aset_pilihan.child.entries()) {
					sub_aset_pilihan.push([ aset_pilihan.nama, aset_pilihan.total ]);
				}
				drilldown_data_aset_pilihan.push({
					id: jenis_aset_pilihan.nama + index,
					name: 'Total Kepemilikan',
					data: sub_aset_pilihan
				});
			}

			this.setState({ data_aset_pilihan });
			this.setState({ drilldown_data_aset_pilihan });
		});
		Get('potensi-desa/galian', null, (data) => {
			// console.log(data.results);
			var data_produksi_galian = [];
			var drilldown_data_produksi_galian = [];

			data.results.forEach((jenis_galian, index) => {
				data_produksi_galian.push({
					name: jenis_galian.nama,
					y: jenis_galian.total,
					drilldown: jenis_galian.nama + index
				});

				var drilldown2 = [];
				jenis_galian.child.forEach((kepemilikan, index2) => {
					var drilldown3_id = kepemilikan.nama + index2;
					drilldown2.push({
						name: kepemilikan.nama,
						y: kepemilikan.total,
						drilldown: drilldown3_id
					});

					var drilldown3 = [];
					kepemilikan.child.forEach((penduduk, index3) => {
						drilldown3.push([ penduduk.data_penduduk.nama, penduduk.hasil ]);
					});

					drilldown_data_produksi_galian.push({
						id: drilldown3_id,
						name: 'Jumlah ' + kepemilikan.nama,
						data: drilldown3
					});
				});

				drilldown_data_produksi_galian.push({
					id: jenis_galian.nama + index,
					name: 'Total Kepemilikan ' + jenis_galian.nama,
					data: drilldown2
				});
			});

			this.setState({
				data_produksi_galian,
				drilldown_data_produksi_galian
			});
			console.log(this.state.data_produksi_galian);
			console.log(this.state.drilldown_data_produksi_galian);
		});
	}

	getJumlahKeluarga = (cell, row) => {
		let jumlah = 0;
		if (Object.keys(row.data_keluarga).length > 0) {
			jumlah = Object.keys(row.data_keluarga).length;
		}
		return jumlah + ' Orang';
	};

	getKepalaKeluarga = (cell, row) => {
		const kepala_keluarga = row.data_kk.data_keluarga.find(this.findKepalaKeluarga);
		// console.log(row);
		if (kepala_keluarga != undefined) {
			return kepala_keluarga.nama;
		} else {
			return row.data_kk.data_keluarga[0].nama;
		}
	};

	findKepalaKeluarga = (list_data_keluarga) => {
		if (list_data_keluarga.data_shdk.nama == 'Kepala Keluarga') {
			return list_data_keluarga.nama;
		}
	};

	getPenduduk = (data) => {
		let penduduk_kk = [];
		let shdk = [];
		this.setState({ data_keluarga: data.data_keluarga });
		penduduk_kk = this.state.data_keluarga.map((dt, i) => {
			return (
				<tr key={dt.id}>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						<a
							href="#"
							className={'btn btn-success btn-xs' + this.state.batasKK}
							onClick={(e) => this.ambilPenduduk(e, dt.id, data.id, i)}
						>
							Pilih
						</a>
					</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.id}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.nama}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
					</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.data_shdk.nama}</td>
				</tr>
			);
		});
		this.setState({ penduduk_kk });
	};

	// ambil data penduduk
	getPenduduk2 = (e) => {
		if (e) {
			Get('penduduk/find/' + e.value, null, (data) => {
				let alamat =
					' RT/RW : ' +
					data.results.data_kk.data_rt.nama +
					'/ ' +
					data.results.data_kk.data_rt.data_rw.nama +
					', Dusun ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					', ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					', Kec. ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
					', ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

				Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
					let pctt = 0;
					let catatan = '';
					if (dpddk.results.data) {
						dpddk.results.data.map((dtp) => {
							if (dtp.status == 0 && dtp.penalti == 1) {
								pctt = 1;
								catatan = dtp.catatan;
							}
						});
					}
					if (pctt == 0) {
						this.state.dt.nik = data.results.id;
						this.state.dt.kk = data.results.id_kk;

						this.state.dt.nama_pemohon = data.results.nama;
						this.state.dt.tempat_lahir = data.results.tempat_lahir;
						this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
						this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
						this.state.dt.jenis_kelamin = data.results.jk;
						this.state.dt.pendidikan_terakhir = data.results.data_pendidikan_terakhir.nama;
						this.state.dt.agama = data.results.data_agama.nama;
						this.state.dt.status_perkawinan = data.results.status_perkawinan;
						this.state.dt.alamat = alamat;
						this.state.showBio = true;
						this.state.status.btnForm = false;
						this.state.pendStat = { value: e.value, label: e.label };

						// this.state.status.btnAksi = false;
						this.forceUpdate();
					} else {
						this.state.dt.nik = '';
						this.state.dt.nama_pemohon = '';
						this.state.dt.tempat_lahir = '';
						this.state.dt.tanggal_lahir = '';
						this.state.dt.pekerjaan = '';
						this.state.dt.pendidikan_terakhir = '';
						this.state.dt.agama = '';
						this.state.dt.status_perkawinan = '';
						this.state.dt.alamat = '';
						this.state.showBio = false;
						this.state.btnForm = true;
						this.forceUpdate();
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Peringatan Catatan Warga',
							pesanAlert:
								'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
								catatan,
							pendStat: null,
							frmPend: []
						});
					}
				});
			});
		} else {
			this.state.dt.nik = '';
			this.state.dt.nama_pemohon = '';
			this.state.dt.tempat_lahir = '';
			this.state.dt.tanggal_lahir = '';
			this.state.dt.pekerjaan = '';
			this.state.dt.pendidikan_terakhir = '';
			this.state.dt.agama = '';
			this.state.dt.status_perkawinan = '';
			this.state.dt.alamat = '';
			this.state.showBio = false;
			this.state.status.btnForm = true;
			this.state.pendStat = [];

			// this.state.status.btnAksi = false;
			this.forceUpdate();
		}
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld == 'nik') {
				let pendStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmPend = [];
					datanik.data.results.data.map((dt) => {
						frmPend.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmPend, pendStat });
				});
			} else {
				// let nik2Stat = { value: e, label: e };
				// let param = {
				// 	nik: e
				// };
				// Post("penduduk", null, param, datanik => {
				// 	let frmNik2 = [];
				// 	datanik.data.results.data.map(dt => {
				// 		frmNik2.push({ value: dt.id, label: dt.id });
				// 	});
				// 	this.setState({ frmNik2, nik2Stat });
				// });
			}
		}
	};

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	highchartPieFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'pie',
				height: 280
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 20,
						overflow: 'allow',
						style: {
							fontSize: '9px'
						}
					}
					// showInLegend: true
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				}
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: 'Total',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	highchartDonutFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				// plotBackgroundColor: null,
				// plotBorderWidth: 0,
				plotShadow: false,
				height: 280
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 10,
						style: {
							fontSize: '9px'
						}
					}
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				},
				align: 'center'
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: 'Total',
					type: 'pie',
					innerSize: '60%',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	highchartStatisticFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'column'
			},
			title: {
				text: title_chart
			},
			xAxis: {
				type: 'category'
			},
			legend: {
				enabled: false
			},
			series: [
				{
					name: 'Total Kepemilikan',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var data_rt = this.state.data_rt;
		var data_potensi_penduduk = this.state.data_potensi_penduduk;
		var data_kk_terdaftar = this.state.data_kk_terdaftar;

		var persentase_data_masyarakat = data_kk_terdaftar.total / data_potensi_penduduk.total * 100;

		const frmDef = {
			id: '',
			id_rt: 0,
			alamat: ''
		};

		const { SearchBar } = Search;
		// #tablecolumn
		const columns = [
			{
				dataField: 'no_kk',
				text: 'Nomor KK',
				sort: true
				// formatter: this.getKepalaKeluarga
			},
			{
				dataField: 'data_kk.data_keluarga[0].data_keldes.nama',
				text: 'Kelurahan / Desa',
				sort: true
			},
			// {
			// 	dataField: 'data_kk',
			// 	text: 'Nama Kepala Keluarga',
			// 	sort: true,
			// 	formatter: this.getKepalaKeluarga
			// },
			{
				dataField: 'data_kk.alamat',
				text: 'Alamat',
				sort: true
				// isDummyField: true,
				// formatter: this.getJumlahKeluarga
			},
			{
				dataField: 'data_user.username',
				text: 'Diinputkan oleh',
				sort: true
			}
		];

		const columns_rt = [
			{
				dataField: 'username',
				text: 'Username',
				sort: true
			},
			{
				dataField: 'data_penduduk.nama',
				text: 'Nama RT',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				formatter: this.tombolAksi
			}
		];

		const defaultSorted = [
			{
				dataField: 'nama_KartuKeluarga',
				order: 'asc'
			}
		];

		const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>
						{page}
					</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing {from} to {to} of {total} Results
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Potensi Masyarakat" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <h5></h5> */}
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span>< span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<Nav tabs className={'tabs-color'}>
										<NavItem>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '1'
												})}
												onClick={() => {
													this.toggle_icon('1');
												}}
											>
												<i className="icofont icofont-ui-home"> </i>Data Statistik Potensi
												Masyarakat
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '2'
												})}
												onClick={() => {
													this.toggle_icon('2');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />Data Potensi Masyarakat
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '3'
												})}
												onClick={() => {
													this.toggle_icon('3');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />Data RT
											</NavLink>
										</NavItem>
									</Nav>

									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											<div style={{ padding: 15 }}>
												<Row>
													<Col sm="4">
														<div className="card o-hidden">
															<div className="bg-success b-r-4 card-body">
																<div className="media static-top-widget">
																	<div className="align-self-center text-center">
																		<i className="icofont icofont-users-social" />
																	</div>
																	<div className="media-body">
																		<span className="m-0">Jumlah Penduduk</span>
																		<h4 className="counter mb-0">
																			{/* <CountUp end={500} /> */}
																			<span>{data_potensi_penduduk.total}</span>
																		</h4>
																		<i className="icofont icofont-users-social icon-bg" />
																	</div>
																</div>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card o-hidden">
															<div className="bg-info b-r-4 card-body">
																<div className="media static-top-widget">
																	<div className="align-self-center text-center">
																		<i className="icofont icofont-business-man" />
																	</div>
																	<div className="media-body">
																		<span className="m-0">
																			Jumlah Penduduk Laki-laki
																		</span>
																		<h4 className="counter mb-0">
																			<span>
																				{data_potensi_penduduk.laki_laki}
																			</span>
																		</h4>
																		<i className="icofont icofont-business-man icon-bg" />
																	</div>
																</div>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card o-hidden">
															<div className="bg-danger b-r-4 card-body">
																<div className="media static-top-widget">
																	<div className="align-self-center text-center">
																		<i className="icofont icofont-girl" />
																	</div>
																	<div className="media-body">
																		<span className="m-0">
																			Jumlah Penduduk Perempuan
																		</span>
																		<h4 className="counter mb-0">
																			<span>
																				{data_potensi_penduduk.perempuan}
																			</span>
																		</h4>
																		<i className="icofont icofont-girl icon-bg" />
																	</div>
																</div>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="4">
														<div className="card">
															<div className="card-body chart-block p-2">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartPieFunction(
																		this.state.data_agama,
																		this.state.drilldown_data_agama,
																		'Data Agama Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card">
															<div className="card-body chart-block p-2">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartPieFunction(
																		this.state.data_pekerjaan,
																		this.state.drilldown_data_pekerjaan,
																		'Data Pekerjaan Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card">
															<div className="card-body chart-block p-2">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartPieFunction(
																		this.state.data_usia,
																		this.state.drilldown_data_usia,
																		'Data Rentang Usia Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="4">
														<div className="card">
															<div className="card-body chart-block p-2">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartDonutFunction(
																		this.state.data_pernikahan,
																		this.state.drilldown_data_pernikahan,
																		'Data Status Perkawinan Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card">
															<div className="card-body chart-block p-2">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartDonutFunction(
																		this.state.data_air_minum,
																		this.state.drilldown_data_air_minum,
																		'Data Sumber Air Minum Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
													<Col sm="4">
														<div className="card">
															<div className="card-body chart-block p-2">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartDonutFunction(
																		this.state.data_kepemilikan_rumah,
																		this.state.drilldown_data_kepemilikan_rumah,
																		'Data Kepemilikan Rumah Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card">
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_kepemilikan_lahan,
																		this.state.drilldown_data_kepemilikan_lahan,
																		'Data Kepemilikan Lahan Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card">
															<dic className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_detail_produksi,
																		this.state.drilldown_detail_produksi,
																		'Data Komoditas Masyarakat'
																	)}
																/>
															</dic>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card">
															<dic className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_aset,
																		this.state.drilldown_data_aset,
																		'Data Aset Masyarakat'
																	)}
																/>
															</dic>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card">
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_lembaga_ekonomi,
																		this.state.drilldown_data_lembaga_ekonomi,
																		'Data Lembaga Ekonomi Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card">
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_aset_pilihan,
																		this.state.drilldown_data_aset_pilihan,
																		'Data Aset Lanjutan Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="card">
															<div className="card-body chart-block">
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_produksi_galian,
																		this.state.drilldown_data_produksi_galian,
																		'Data Produksi Galian Masyarakat'
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<h5>Data Masyarakat</h5>
													</Col>
												</Row>
												<Row>
													<Col sm="12">
														<div className="col-sm-4 p-0 m-0">
															<input
																type="text"
																// onKeyPress={this.cariData}
																placeholder="Cari data penduduk..."
																className="form-control"
																style={{ marginBottom: '10px' }}
															/>
														</div>
														<BootstrapTable keyField="uuid" data={data} columns={columns} />
														<div className="pull-right text-white">
															{this.state.awal}
															{this.state.sebelum}
															{this.state.hal.map((dt) => {
																return dt;
															})}
															{this.state.setelah}
															{this.state.akhir}
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="3">
											<div style={{ marginTop: 15 }}>
												<Row>
													<div className="col-sm-6">
														<h5>Data RT</h5>
													</div>
													<div className="col-sm-6 text-right">
														<Button
															size="sm"
															color="success"
															className="btn-square mr-3"
															onClick={() => {
																this.bukaForm();
															}}
														>
															Tambah Data RT
														</Button>
													</div>
													<div className="col-sm-12" style={{ marginTop: '10px' }}>
														<div className="col-sm-4 p-0 m-0">
															{/* <input type="text" onChange={this.cariData} placeholder="Cari data..." className="form-control" style={{ marginBottom: "10px" }} /> */}
														</div>
														<ToolkitProvider
															keyField="username"
															data={data_rt}
															columns={columns_rt}
															search
														>
															{(props) => (
																<div>
																	<SearchBar {...props.searchProps} />
																	<BootstrapTable
																		{...props.baseProps}
																		keyField="username"
																		data={data_rt}
																		columns={columns_rt}
																		pagination={paginationFactory()}
																	/>
																</div>
															)}
														</ToolkitProvider>
													</div>
												</Row>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.addForm}
					onClose={this.tutupAddForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.addMode}</h5>
					</div>
					<Form className="theme-form" onSubmit={(e) => this.addSimpan(e, this.state.addMode)}>
						<div className="modal-body">
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									ID Wilayah
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="id_wilayah"
										type="text"
										placeholder="Masukkan Kode Administratif Wilayah"
										required
										defaultValue={this.state.addID}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Wilayah
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_wilayah"
										type="text"
										placeholder="Masukkan Nama Wilayah"
										required
										defaultValue={this.state.addNama}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupAddForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<Form className="theme-form" onSubmit={(e) => this.simpanUser(e, 0)}>
						<div className="modal-body">
							<p>
								Data RT adalah data penduduk yang menjadi ketua RT. Jika RT sudah terdaftar, maka RT
								tersebut dapat menginputkan data potensi warganya masing-masing melalui sistem.
							</p>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									NIK
								</Label>
								<Col sm="9">
									<div className="row">
										<div className="col-sm-12">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.getPenduduk2(e);
												}}
												required
												defaultValue={this.state.pendStat}
												value={this.state.pendStat}
												id="nik"
												name="nik"
												options={this.state.frmPend}
												placeholder="Pilih NIK"
												onInputChange={(e) => this.handleInputChangeNIK(e, 'nik')}
												isClearable
											/>
										</div>
									</div>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Username
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="username"
										name="username"
										type="text"
										placeholder="Username"
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Password
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="password"
										name="password"
										type="password"
										placeholder="Password"
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									E-mail
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="email"
										name="email"
										type="email"
										placeholder="E-mail"
										required
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								disabled={this.state.status.btnForm}
								onClick={(e) => this.simpanUser(e, 0)}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default DataPotensi;
