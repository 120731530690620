import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import CountUp from "react-countup";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { DataToken } from "../function/Format";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      perizinan: [],
      menuUtama: "inherit",
      menuWilayah: "none",
      menuPengaturan: "none",
      menuPerangkat: "none",
      menuLaporan: "none",
      menuAdministrasi: "none",
      menuPerubahanData:'none',
      akun: DataToken(localStorage.getItem("access_token"))
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  getData = (fil = null) => {
    if (fil) {
      let filJenisSurat = {
        nama: fil
      };
      Post("jenis-surat/list", null, filJenisSurat, data => {
        console.log(data);
        let layanan = [];
        let perizinan = [];
        data.data.results.map(dt => {
          if (dt.kategori == "Layanan Masyarakat") {
            layanan.push(dt);
          }
          if (dt.kategori == "Perizinan Masyarakat") {
            perizinan.push(dt);
          }
        });
        this.setState({ layanan, perizinan });
      });
    } else {
      Get("jenis-surat/list", null, data => {
        let layanan = [];
        let perizinan = [];
        data.results.map(dt => {
          if (dt.kategori == "Layanan Masyarakat") {
            layanan.push(dt);
          }
          if (dt.kategori == "Perizinan Masyarakat") {
            perizinan.push(dt);
          }
        });
        this.setState({ layanan, perizinan });
      });
    }
  };

  bukaMenu = wil => {
    switch (wil) {
      case "laporan":
        this.setState({ menuUtama: "none", menuLaporan: "inherit" });
        break;
      case "wilayah":
        this.setState({ menuUtama: "none", menuWilayah: "inherit" });
        break;
      case "perangkat":
        this.setState({ menuUtama: "none", menuPerangkat: "inherit" });
        break;
      case "pengaturan":
        this.setState({ menuUtama: "none", menuPengaturan: "inherit" });
        break;
        
      case "perubahan_data":
        this.setState({ menuUtama: "none",menuAdministrasi: "none", menuPerubahanData: "inherit" });
        break;
        case "administrasi":
        this.setState({ menuUtama: "none",  menuPerubahanData: "none" ,menuAdministrasi: "inherit" });
        break;
      case "utama":
        this.setState({
          menuPengaturan: "none",
          menuPerangkat: "none",
          menuWilayah: "none",
          menuLaporan: "none",
          menuAdministrasi: "none",
          menuPerubahanData: "none",
          menuUtama: "inherit"
        });
        break;
    }
  };

  // =================================================

  componentDidMount() {
    this.getData();
    console.log(this.state.akun);
  }

  render() {
    const SearchIcon = require("../assets/images/dashboard/search.png");
    const tampilLayanan = this.state.layanan.map(dt => {
      // console.log(dt);
      let link = dt.nama.toLowerCase().replace(/ /g, "-");
      return (
        <div className="col-sm-2 mb-2 pr-0">
          <Link to={{ pathname: "/admin/" + link, state: dt }}>
            <div className="card o-hidden mb-0">
              <div
                className="card-body pl-1 pr-1 mr-0 pb-1 text-white"
                style={{ backgroundColor: "#2e99b0" }}
              >
                <div className="media static-top-widget">
                  <div
                    className="media-body p-0"
                    style={{
                      lineHeight: "1.5em",
                      minHeight: "4.5em",
                      height: "100%",
                      fontWeight: "bold",
                      paddingTop: "0px",
                      paddingBottom: "0px"
                    }}
                  >
                    <span className="m-0 p-0" style={{ fontSize: "18px" }}>
                      {dt.nama == "Surat Keterangan Jalan"
                        ? "Surat Jalan"
                        : dt.nama
                            .replace("Surat Keterangan ", "")
                            .replace("Surat Keterangan", "Umum")
                            .replace("Surat ", "")}
                    </span>
                    <i className="icofont icofont-ebook icon-bg text-white"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    });

    const tampilPerizinan = this.state.perizinan.map(dt => {
      let link = dt.nama.toLowerCase().replace(/ /g, "-");
      return (
        <div className="col-sm-2 mb-2 pr-0">
          <Link to={{ pathname: "/admin/" + link, state: dt }}>
            <div className="card o-hidden mb-0">
              <div className="bg-success card-body pl-1 pr-1 mr-0 pb-1">
                <div className="media static-top-widget p-0">
                  <div
                    className="media-body p-0"
                    style={{
                      lineHeight: "1.5em",
                      minHeight: "4.5em",
                      height: "100%",
                      fontWeight: "bold",
                      paddingTop: "0px",
                      paddingBottom: "0px"
                    }}
                  >
                    <span className="m-0 p-0">{dt.nama}</span>
                    <i className="icofont icofont-ebook icon-bg"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    });
    const menuWithRole = () => {
      if (this.state.akun.sub.data_role.nama_role != "Admin") {
        return (
          <div className="row" style={{ display: this.state.menuUtama }}>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/kartu-keluarga"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-file-o"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>
                    Kartu Keluarga
                  </p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/data-masyarakat"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-bar-chart-o"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>
                    Data Potensi Masyarakat
                  </p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/penduduk"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-users"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Penduduk</p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-user"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Pengguna</p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <a
                href="javascript:void"
                onClick={() => {
                  this.bukaMenu("wilayah");
                }}
              >
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-globe"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Wilayah</p>
                </div>
              </a>
            </div>

            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <a
                href="javascript:void"
                onClick={() => {
                  this.bukaMenu("perangkat");
                }}
              >
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-building"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Perangkat</p>
                </div>
              </a>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <a
                href="javascript:void"
                onClick={() => {
                  this.bukaMenu("pengaturan");
                }}
              >
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-wrench"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Pengaturan</p>
                </div>
              </a>
            </div>
          </div>
        );
      } else if (this.state.akun.sub.data_role.nama_role == "Admin") {
        return (
          <div className="row" style={{ display: this.state.menuUtama }}>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/kartu-keluarga"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-file-o"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>
                    Kartu Keluarga
                  </p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/data-potensi"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-bar-chart-o"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>
                    Data Potensi Masyarakat
                  </p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/penduduk"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-users"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Penduduk</p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <a
                href="javascript:void"
                onClick={() => {
                  this.bukaMenu("wilayah");
                }}
              >
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-globe"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Wilayah</p>
                </div>
              </a>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <a
                href="javascript:void"
                onClick={() => {
                  this.bukaMenu("laporan");
                }}
              >
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-files-o"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Laporan</p>
                </div>
              </a>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/catatan"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-edit"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>Catatan</p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <Link to={process.env.PUBLIC_URL + "/admin/berita"}>
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="icofont icofont-newspaper"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>
                    Berita/Pengumuman
                  </p>
                </div>
              </Link>
            </div>
            <div className="card col-sm-1 p-0 mr-2 mb-0">
              <a
                href="javascript:void"
                onClick={() => {
                  this.bukaMenu("administrasi");
                }}
              >
                <div className="card-body p-1 text-center text-middle">
                  <i
                    className="fa fa-envelope-o"
                    style={{ fontSize: "60px", color: "#2e99b0" }}
                  ></i>
                  <p style={{ fontSize: "10px", color: "black" }}>
                    Administrasi
                  </p>
                </div>
              </a>
            </div>
          </div>
        );
      }
    };
    // console.log(this.state.layanan);
    return (
      <div>
        <Breadcrumb title="Dashboard" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0 m-0">
              <div className="row">
                <div className="card col-lg-12 pt-2 pl-4 pb-2 bg-light">
                  <div
                    className="row"
                    style={{ display: this.state.menuPerangkat }}
                  >
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("utama");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-home"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Menu Utama
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link
                        to={process.env.PUBLIC_URL + "/admin/jabatan-perangkat"}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-building"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Jabatan Perangkat
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/perangkat"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-building"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Perangkat Desa
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: this.state.menuPengaturan }}
                  >
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("utama");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-home"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Menu Utama
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link
                        to={{
                          pathname:
                            process.env.PUBLIC_URL + "/admin/jenis-surat",
                          state: "kode"
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-wrench"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Kode Surat
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/profile-desa"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-wrench"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Profile Desa
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/tanda-tangan"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-wrench"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Tanda Tangan
                          </p>
                        </div>
                      </Link>
                    </div>

                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/pengaturan-aplikasi"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-wrench"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Pengaturan Aplikasi
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: this.state.menuWilayah }}
                  >
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("utama");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-home"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Menu Utama
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/dusun"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-globe"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Dusun
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/rw"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-globe"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>RW</p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/rt"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-globe"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>RT</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: this.state.menuLaporan }}
                  >
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("utama");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-home"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Menu Utama
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/laporan"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-files-o"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Laporan Rekap
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/laporan"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-files-o"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Laporan Rincian
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: this.state.menuAdministrasi }}
                  >
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("utama");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-home"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Menu Utama
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/verifikasi"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-envelope"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Verifikasi Data
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/disposisi"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-envelope"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Disposisi Surat
                          </p>
                        </div>
                      </Link>
                    </div>

                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("perubahan_data");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-users"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Perubahan Data
                          </p>
                        </div>
                      </a>
                      {/* <Link
                        to={process.env.PUBLIC_URL + "/admin/perubahan-nik"}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-envelope"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Perubahan Data
                          </p>
                        </div>
                      </Link> */}
                    </div>
                  </div>
                  
                  <div
                    className="row"
                    style={{ display: this.state.menuPerubahanData }}
                  >
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <a
                        href="javascript:void"
                        onClick={() => {
                          this.bukaMenu("administrasi");
                        }}
                      >
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-envelope"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            Menu Administrasi
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/perubahan-data/nik-kk"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                           NIK & KK
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="card col-sm-1 p-0 mr-2 mb-0">
                      <Link to={process.env.PUBLIC_URL + "/admin/perubahan-data/pindah-kk"}>
                        <div className="card-body p-1 text-center text-middle">
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "60px", color: "#2e99b0" }}
                          ></i>
                          <p style={{ fontSize: "10px", color: "black" }}>
                           Pindah KK
                          </p>
                        </div>
                      </Link>
                    </div>
                   
                   </div>
                 {/* menuWithRole adalah menu layanan masyarakat yang ditampilkan berdasarkan user yang sedang login */}
                  {menuWithRole()}
                </div>
                <div className="card col-lg-12 pt-2 pl-2 bg-light">
                  <div className="row">
                    <div className="col-sm-9 pl-2"></div>
                    <div className="col-sm-3 pl-2">
                      <div className="form-group">
                        <input
                          type="search"
                          placeholder="Filter Layanan"
                          className="form-control"
                          onChange={e => {
                            this.getData(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.akun.sub.data_role.nama_role == "Admin" ? (
                  <div className="card col-lg-12 pt-2 pl-2 pb-2 bg-light">
                    <div className="row">
                      <div
                        className="col-sm-12 mb-0"
                        style={{ color: "black" }}
                      >
                        <h4>Layanan Masyarakat</h4>
                      </div>
                    </div>
                    <div className="row">{tampilLayanan}</div>
                  </div>
                ) : (
                  <div className="card col-lg-12 pt-2 pl-2 pb-2 bg-light">
                    <div className="row">
                      <div
                        className="col-sm-12 mb-0"
                        style={{ color: "black" }}
                      >
                        <h4>Menu Super Operator</h4>
                      </div>
                    </div>
                    <div className="row">{/* {tampilLayanan} */}</div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-lg-3 pl-5 m-0">
                            <div className="row">
                                <div className="card col-lg-12 pt-2 pb-2 bg-light text-center" style={{height:'400px', color:'black', textTransform:'uppercase'}}>
                                    Chat Component
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
