import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Redirect } from "react-router-dom";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { Tanggal, HariTanggal } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pekerjaan: "",
  agama: "",
  jenis_kelamin: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  tujuan_pembuatan: "",
  lokasi: "",
  kk: "",
  agama: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  tgl_mulai: "",
  tgl_selesai: "",
  waktu_mulai: "",
  waktu_selesai: "",
  tempat: "",
  hiburan: ""
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  nik: "",
  jenis_kelamin: "",
  nama_pemohon: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pendidikan_terakhir: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  lokasi: "",
  kk: "",

  tujuan_pembuatan: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  //detail
  detail: {
    tgl_mulai: "",
    tgl_selesai: "",
    waktu_mulai: "",
    waktu_selesai: "",
    tempat: "",
    hiburan: ""
  }
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: []
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Izin Keramaian",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio: false,
      tanggal_mulai: new Date(),
      tanggal_selesai: null,
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none"
    };
  }
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_lahir_pasangan":
        this.setState({ tanggal_lahir_pasangan: date });
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: true
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "alur-disposisi/" + this.props.match.params.idalur + "" + link,
      null,
      dtkat => {
        this.setState({ data: dtkat.results, pagination: dtkat.results });
        this.pagination(dtkat.results);
      }
    );
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  //change select
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jk":
          this.state.dt.jk = e.value;
          this.setState({ jkStat: e });
          break;
      }
    } else {
      switch (sel) {
        case "jk":
          this.state.dt.jk = 0;
          this.setState({ jkStat: e });
          break;
      }
    }

    this.state.status.select = false;
    this.forceUpdate();
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/grup/" + this.props.match.params.idalur,
        null,
        dataSuket,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.state.status.btnForm = true;
    this.forceUpdate();

    let uuid = document.getElementById("uuid").value;
    //detail
  

    let addAlur = {
        id_jenis: this.props.match.params.idalur,
        id_jabatan_perangkat:this.state.jpStat.value,
        level: document.getElementById("level").value,
    };
    console.log(JSON.stringify(addAlur));
    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (uuid === "") {
      psn = "Tambah";
      resstat = 201;
      uuid = null;
    } else {
      psn = "Ubah";
      resstat = 200;
      metode = "update";
    }
    Post(
      "alur-disposisi/"+ this.props.match.params.idalur+'/'+ metode ,
      uuid,
      addAlur,
      res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            showBio: false,
            show: true,
            status :{
                ... this.state.status,
                btnForm:true},
            basicType: "success",
            basicTitle: "Data Alur Disposisi",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            showBio: false,
            show: true,
            basicType: "danger",
            basicTitle: "Data Alur Disposisi",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      }
    );
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.componentDidMount();
    Get("alur-disposisi/find", id, data => {
        
        this.setState({
            dt: {
                ... this.state.dt,
                level: data.results.level,
                uuid:data.results.uuid
            },
            status :{
                ... this.state.status,
                btnForm:false},
            jpStat:{value:data.results.id_jabatan_perangkat,label:data.results.data_jabatan.nama}

        })
        // this.state.status.btnAksi = false;
        this.componentDidMount();
        this.bukaForm();
    });
  };

  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";

    Delete("alur-disposisi/"+this.props.match.params.idalur+"/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Alur Disposisi",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Alur Disposisi",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;

    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.showBio = false;
    this.state.pendStat = [];
    this.state.pendStat2 = [];
    this.state.status.btnForm = true;
    this.state.formAcc = false;
    this.state.tableAcc = "none";
    this.state.accStat = [];
    this.state.status.form = false;
    this.forceUpdate();
  };

  getJp=(e)=>{
      if(e){
        console.log(e);
        this.setState({
            status :{
                ... this.state.status,
                btnForm:false},
            jpStat:{value:e.value,label:e.label}})
      } else {
        this.setState({status :{
            ... this.state.status,
            btnForm:true},
            jpStat:[]})

      }
  }
  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/grup/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    let btnApproval = false;
    let btnCetak = false;
    let btnAksi = false;
    let status = "";
    
    
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
       
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld == "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.map(dt => {
            frmPend.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.map(dt => {
            frmNik2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };

  ///acc
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  //change select
  changAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      if (e.value == "Disetujui") {
        this.state.tableAcc = "";
        this.state.btnFormAcc = true;
        if (this.state.accButton.length > 0) {
          this.state.btnFormAcc = false;
        }
      } else {
        this.state.tableAcc = "none";
        this.state.btnFormAcc = false;
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      this.state.tableAcc = "none";
      this.state.btnFormAcc = true;
    }
    this.state.status.select = false;
    this.forceUpdate();
  };


  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };
  componentDidMount() {
    if (this.props.location.state == undefined) {
      this.setState({ redirect: true });
    } else {
      this.fetch({ page: null, where: [] });

      Get("jabatan-perangkat", null, data => {
        let frmJp = [];
        data.results.data.map(dt => {
            frmJp.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmJp });
      });
    
   
    }
  }


  //
  gantiTglMulai = date => {
    this.setState({ tanggal_mulai: date });
  };
  gantiTglSelesai = date => {
    this.setState({ tanggal_selesai: date });
  };
  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    let frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      tanggal_surat: "",
      nik: "",
      nama_pemohon: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      pekerjaan: "",
      pekerjaan: "",
      agama: "",
      status_perkawinan: "",
      alamat: "",
      kewarganegaraan: "Indonesia",
      tujuan_pembuatan: "",
      lokasi: "",
      kk: "",
      detail: [],
      agama: "",
      jenis_kelamin: "",
      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",
      //detail
      //detail
      tgl_mulai: "",
      tgl_selesai: "",
      waktu_mulai: "",
      waktu_selesai: "",
      tempat: "",
      hiburan: ""
    };
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "id_jabatan_perangkat",
        text: "Disposisi",
        formatter: (cell, obj) => {
          return obj.data_jabatan.nama;
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "level",
        text: "Level",
        sort: true
      },
    
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    const defaultSorted = [
      {
        dataField: "nama",
        order: "asc"
      }
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = e => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = "";
      classname = "btn btn-success";
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {total} Results
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] == 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] == 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div>
        {this.renderRedirect()}

        <Breadcrumb title={"Data Alur Disposisi"} parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>{this.props.location.state.nama }</h5>
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Alur Disposisi",
                            dt: frmDef,
                            jpStat: []
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="cariTable"
                              placeholder="Cari Data berdasarkan nama pemohon, atau nomor surat"
                              onKeyPress={this.cariData}
                            />
                          </div>
                        </div>
                  <BootstrapTable
                                keyField="id"
                                data={data}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal}
                                {this.state.sebelum}
                                {this.state.hal.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah}
                                {this.state.akhir}
                              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jabatan Perangkat{" "}
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.getJp(e);
                    }}
                    defaultValue={this.state.jpStat}
                    value={this.state.jpStat}
                    name="penduduk"
                    options={this.state.frmJp}
                    placeholder="Pilih Jabatan Perangkat"
                    isClearable
                  />
                </Col>
              </FormGroup>

              
               <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Level
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="level"
                    type="number"
                    required
                    placeholder="Level"
                    defaultValue={this.state.dt.level}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

       
    </div>
    );
  }
}

export default BasicTable;
