import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { Tanggal, HariTanggal } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pekerjaan: "",
  agama: "",
  jenis_kelamin: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  tujuan_pembuatan: "",
  lokasi: "",
  kk: "",
  agama: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  nama_nasab_p1: "",
  nama_nasab_p2: "",
  nama_pasangan: "",
  jenis_kelamin_pasangan: "",
  tempat_lahir_pasangan: "",
  tanggal_lahir_pasangan: "",
  pekerjaan_pasangan: "",
  agama_pasangan: "",
  nik_pasangan: "",
  alamat_pasangan: "",
  tempat_nikah: ""
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  nik: "",
  jenis_kelamin: "",
  nama_pemohon: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pendidikan_terakhir: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  lokasi: "",
  kk: "",

  tujuan_pembuatan: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  nama_nasab_p1: "",
  nama_nasab_p2: "",
  nama_pasangan: "",
  jenis_kelamin_pasangan: "",
  tempat_lahir_pasangan: "",
  tanggal_lahir_pasangan: "",
  pekerjaan_pasangan: "",
  agama_pasangan: "",
  nik_pasangan: "",
  alamat_pasangan: "",
  tempat_nikah: ""
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: []
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Keterangan Pindah Nikah",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio: false,
      showBio2: false,
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: [],
      formPasangan: "none"
    };
  }
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_lahir_pasangan":
        this.setState({ tanggal_lahir_pasangan: date });
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: true,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/grup/" + this.props.location.state.uuid + "" + link,
      null,
      dtkat => {
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);
      }
    );
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  //change select
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jk":
          this.state.dt.jk = e.value;
          this.setState({ jkStat: e });
          break;
      }
    } else {
      switch (sel) {
        case "jk":
          this.state.dt.jk = 0;
          this.setState({ jkStat: e });
          break;
      }
    }

    this.state.status.select = false;
    this.forceUpdate();
  };
  // Cari Data Table
  cariData = e => {
    if (e.key == "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/grup/" + this.props.location.state.uuid,
        null,
        dataSuket,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================
  changeInputText = (e, fld) => {
    switch (fld) {
      case "nama_pasangan":
        console.log(e.target.value);
        this.state.dt.nama_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "nik_pasangan":
        console.log(e.target.value);
        this.state.dt.nik_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "nama_nasab_p2":
        console.log(e.target.value);
        this.state.dt.nama_nasab_p2 = e.target.value;
        this.forceUpdate();
        break;
      case "jenis_kelamin_pasangan":
        console.log(e.target.value);
        this.state.dt.jenis_kelamin_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir_pasangan":
        console.log(e.target.value);

        this.state.dt.tempat_lahir_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "tanggal_lahir_pasangan":
        console.log(e.target.value);
        this.state.dt.tanggal_lahir_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan_pasangan":
        console.log(e.target.value);
        this.state.dt.pekerjaan_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "agama_pasangan":
        console.log(e.target.value);
        this.state.dt.agama_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "alamat_pasangan":
        console.log(e.target.value);
        this.state.dt.alamat_pasangan = e.target.value;
        this.forceUpdate();
        break;
    }
  };
  // data ayah
  dataPasangan = () => {
    console.log(this.props.location.state);
    if (this.state.pendStat2 != null) {
      this.state.showBio2 = true;
    } else {
      this.state.showBio2 = this.state.showBio2 == false ? true : false;
    }

    this.state.pendStat2 = null;
    this.state.formPasangan = this.state.formPasangan == "" ? "none" : "";
    this.state.dt.nama_nasab_p2 = "";
    this.state.dt.nama_pasangan = "";
    this.state.dt.jenis_kelamin_pasangan = "";
    this.state.dt.tempat_lahir_pasangan = "";
    this.state.dt.tanggal_lahir_pasangan = "";
    this.state.dt.pekerjaan_pasangan = "";
    this.state.dt.agama_pasangan = "";
    this.state.dt.nik_pasangan = "";
    this.state.dt.alamat_pasangan = "";
    this.forceUpdate();
  };
  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

    if (this.state.dt.nik != "" && this.state.dt.nik_pasangan != "") {
      this.state.status.btnForm = true;
      this.forceUpdate();

      let uuid = document.getElementById("uuid").value;
      //detail
      let formDetail = [
        {
          nama_item: "nama_pasangan",
          isi_item: document.getElementById("nama_pasangan").value
        },
        {
          nama_item: "nama_nasab_p1",
          isi_item: document.getElementById("nama_nasab_p1").value
        },
        {
          nama_item: "nama_nasab_p2",
          isi_item: document.getElementById("nama_nasab_p2").value
        },
        {
          nama_item: "jenis_kelamin_pasangan",
          isi_item: document.getElementById("jenis_kelamin_pasangan").value
        },
        {
          nama_item: "tempat_lahir_pasangan",
          isi_item: document.getElementById("tempat_lahir_pasangan").value
        },
        {
          nama_item: "tanggal_lahir_pasangan",
          isi_item: document.getElementById("tanggal_lahir_pasangan").value
        },
        {
          nama_item: "pekerjaan_pasangan",
          isi_item: document.getElementById("pekerjaan_pasangan").value
        },
        {
          nama_item: "agama_pasangan",
          isi_item: document.getElementById("agama_pasangan").value
        },
        {
          nama_item: "nik_pasangan",
          isi_item: document.getElementById("nik_pasangan").value
        },
        {
          nama_item: "alamat_pasangan",
          isi_item: document.getElementById("alamat_pasangan").value
        },
        {
          nama_item: "tempat_nikah",
          isi_item: document.getElementById("tempat_nikah").value
        }
      ];

      let addSkck = {
        id_jenis: this.props.location.state.uuid,
        nik: document.getElementById("nik").value,
        nama_pemohon: document.getElementById("nama_pemohon").value,
        tempat_lahir: document.getElementById("tempat_lahir").value,
        tanggal_lahir: document.getElementById("tanggal_lahir").value,
        pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
          .value,
        pekerjaan: document.getElementById("pekerjaan").value,
        agama: document.getElementById("agama").value,
        status_perkawinan: document.getElementById("status_perkawinan").value,
        alamat: document.getElementById("alamat").value,
        jenis_kelamin: document.getElementById("jenis_kelamin").value,
        kewarganegaraan: document.getElementById("kewarganegaraan").value,
        tujuan_pembuatan: "Surat Pengantar Pindah Nikah",
        kk: document.getElementById("kk").value,
        detail: formDetail
      };
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (uuid === "") {
        psn = "Tambah";
        resstat = 201;
        uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/grup/" + metode + "/with-detail",
        uuid,
        addSkck,
        res => {
          this.tutupForm();

          this.state.status.btnForm = false;
          this.state.status.form = false;

          this.state.pendStat = [];
          this.state.pendStat2 = [];
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              showBio: false,
              showBio2: false,
              show: true,
              basicType: "success",
              basicTitle: "Data Permohonan Pindah Nikah",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              showBio: false,
              showBio2: false,
              show: true,
              basicType: "danger",
              basicTitle: "Data Permohonan Pindah Nikah",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon pilih data NIK"
      });
    }
  };
  // ========================================================================
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      console.log(dtkat);
      if (dtkat) {
        console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.componentDidMount();
    Get("surat-keterangan/grup/find", id, data => {
      if (typeof data.results != "undefined") {
        let nik_pasangan;
        let tgl_pasangan;
        data.results.data_detail_suket.map(dt => {
          if (dt.nama_item == "nama_nasab_p1") {
            this.state.dt.nama_nasab_p1 = dt.isi_item;
          }

          if (dt.nama_item == "nama_nasab_p2") {
            this.state.dt.nama_nasab_p2 = dt.isi_item;
          }
          if (dt.nama_item == "nama_pasangan") {
            this.state.dt.nama_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "jenis_kelamin_pasangan") {
            this.state.dt.jenis_kelamin_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "tempat_lahir_pasangan") {
            this.state.dt.tempat_lahir_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "tanggal_lahir_pasangan") {
            tgl_pasangan = dt.isi_item;
            // this.state.dt.tanggal_lahir_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "pekerjaan_pasangan") {
            this.state.dt.pekerjaan_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "agama_pasangan") {
            this.state.dt.agama_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "nik_pasangan") {
            nik_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "alamat_pasangan") {
            this.state.dt.alamat_pasangan = dt.isi_item;
          }
          if (dt.nama_item == "tempat_nikah") {
            this.state.dt.tempat_nikah = dt.isi_item;
          }
          this.forceUpdate();
        });

        Get("penduduk/find", nik_pasangan, data1 => {
          let nikPasanganFrom = false;

          console.log(tgl_pasangan);
          if (data1.results == null) {
            nikPasanganFrom = true;
            this.state.dt.nik_pasangan = nik_pasangan;
            console.log(tgl_pasangan);
            this.state.dt.tanggal_lahir_pasangan = tgl_pasangan;
            this.state.formPasangan = "";
            this.state.showBio2 = false;
            this.state.pendStat2 = null;
          } else {
            this.state.formPasangan = "none";
            this.state.showBio2 = true;
            // this.state.pendStat2= null;
            this.state.dt.nik_pasangan = nik_pasangan;
            this.state.dt.tanggal_lahir_pasangan = tgl_pasangan;

            this.state.pendStat2 = {
              value: nik_pasangan,
              label: nik_pasangan
            };
          }

          this.state.dt.uuid = data.results.uuid;
          this.state.dt.nomor = data.results.nomor;
          this.state.dt.tujuan_pembuatan = data.results.tujuan_pembuatan;
          this.state.dt.lokasi = data.results.lokasi;
          this.state.dt.kk = data.results.kk;
          this.state.tanggal_surat = new Date(data.results.tanggal_surat);
          this.state.dt.nik = data.results.nik;
          this.state.pendStat = {
            value: data.results.nik,
            label: data.results.nik
          };
          this.state.dt.nik = data.results.nik;

          this.state.dt.nama_pemohon = data.results.nama_pemohon;
          this.state.dt.tempat_lahir = data.results.tempat_lahir;
          this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
          this.state.dt.pekerjaan = data.results.pekerjaan;
          this.state.dt.pendidikan_terakhir = data.results.pendidikan_terakhir;
          this.state.dt.agama = data.results.agama;
          this.state.dt.jenis_kelamin = data.results.jenis_kelamin;
          this.state.dt.status_perkawinan = data.results.status_perkawinan;
          this.state.dt.alamat = data.results.alamat;
          this.state.showBio2 = true;
          this.state.showBio = true;
          this.state.status.btnForm = false;
          this.state.judul = "Ubah Permohonan Pindah Nikah";
          this.forceUpdate();
          // this.state.status.btnAksi = false;
          this.componentDidMount();
          this.bukaForm();
        });
      }
    });
  };

  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/grup/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;

    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.dt.nik_pasangan = "";
    this.state.dt.tempat_nikah = "";
    this.state.showBio = false;
    this.state.showBio2 = false;
    this.state.pendStat = [];
    this.state.pendStat2 = [];
    this.state.status.btnForm = true;
    this.state.formDisposisi = false;
    this.state.formAcc = false;
    this.state.tableAcc = "none";
    this.state.accStat = [];
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    let btnApproval = false;
    let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.map(dt => {
      status = dt.status;
    });
    if (status == "Diajukan") {
      btnCetak = true;
      btnAksi = true;
      btnApproval = true;
    } else {
      btnAksi = true;
      btnApproval = true;
      if (status == "Ditolak") {
        btnCetak = true;
      } else if (status == "Disetujui") {
        btnAksi = false;
        btnCetak = true;
        btnApproval = false;
      } else if ((status == "Menunggu Penerbitan") | (status == "Selesai")) {
        // btnAksi = false;
        btnCetak = false;
        // btnApproval = false;
      } else {
        btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="primary"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "} */}
        &nbsp;&nbsp;
        {/*
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="primary"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld == "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.map(dt => {
            frmPend.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.map(dt => {
            frmNik2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };
  // ambil data penduduk
  getPenduduk = (e, label) => {
    if (e) {
      Get("penduduk/find/" + e.value, null, data => {
        let alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", Dusun " +
          data.results.data_kk.data_rt.data_rw.data_dusun.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
            .data_kecamatan.data_kabkota.nama;

        // let daerah = data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
        //       ", " +
        //       data.results.data_kk.results.dataKkbj.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .data_provinsi.nama

        if (label == "nik") {
          Get("catatan-penduduk/nik", data.results.id, dpddk => {
            let pctt = 0;
            let catatan = "";
            if (dpddk.results.data) {
              dpddk.results.data.map(dtp => {
                if (dtp.status == 0 && dtp.penalti == 1) {
                  pctt = 1;
                  catatan = dtp.catatan;
                }
              });
            }
            if (pctt == 0) {
              this.state.dt.nik = data.results.id;
              this.state.dt.kk = data.results.id_kk;

              this.state.dt.nama_pemohon = data.results.nama;
              this.state.dt.tempat_lahir = data.results.tempat_lahir;
              this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
              this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
              this.state.dt.jenis_kelamin = data.results.jk;
              this.state.dt.pendidikan_terakhir =
                data.results.data_pendidikan_terakhir.nama;
              this.state.dt.nama_nasab_p1 = data.results.nama_ayah_kandung;
              this.state.dt.agama = data.results.data_agama.nama;
              this.state.dt.status_perkawinan = data.results.status_perkawinan;
              this.state.dt.alamat = alamat;
              this.state.showBio = true;
              this.state.pendStat = { value: e.value, label: e.label };
              this.forceUpdate();
            } else {
              this.state.dt.nik = "";
              this.state.dt.nama_pemohon = "";
              this.state.dt.tempat_lahir = "";
              this.state.dt.tanggal_lahir = "";
              this.state.dt.pekerjaan = "";
              this.state.dt.pendidikan_terakhir = "";
              this.state.dt.agama = "";
              this.state.dt.status_perkawinan = "";
              this.state.dt.alamat = "";
              this.state.showBio = false;
              this.state.btnForm = true;
              this.forceUpdate();
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Peringatan Catatan Warga",
                pesanAlert:
                  "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                  catatan,
                pendStat: null,
                frmPend: []
              });
            }
          });
        }
        if (label == "nik2") {
          this.state.dt.nik_pasangan = data.results.id;
          this.state.dt.kk_pasangan = data.results.id_kk;

          this.state.dt.nama_pasangan = data.results.nama;
          this.state.dt.tempat_lahir_pasangan = data.results.tempat_lahir;
          this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
          this.state.dt.pekerjaan_pasangan = data.results.data_pekerjaan.nama;
          this.state.dt.jenis_kelamin_pasangan = data.results.jk;
          this.state.dt.pendidikan_terakhir_pasangan =
            data.results.data_pendidikan_terakhir.nama;
          this.state.dt.agama_pasangan = data.results.data_agama.nama;
          this.state.dt.status_perkawinan_pasangan =
            data.results.status_perkawinan;
          this.state.dt.nama_nasab_p2 = data.results.nama_ayah_kandung;
          this.state.dt.alamat_pasangan = alamat;
          this.state.showBio2 = true;
          this.state.pendStat2 = { value: e.value, label: e.label };
        }
        if (this.state.pendStat2 != null && this.state.pendStat != null) {
          this.state.status.btnForm = false;
        }

        // this.state.status.btnAksi = false;
        this.forceUpdate();
      });
    } else {
      if (label == "nik") {
        this.state.dt.nik = "";
        this.state.dt.nama_pemohon = "";
        this.state.dt.tempat_lahir = "";
        this.state.dt.tanggal_lahir = "";
        this.state.dt.pekerjaan = "";
        this.state.dt.pendidikan_terakhir = "";
        this.state.dt.agama = "";
        this.state.dt.status_perkawinan = "";
        this.state.dt.alamat = "";
        this.state.showBio = false;
        this.state.pendStat = [];
      }
      if (label == "nik2") {
        this.state.dt.nik = "";
        this.state.dt.nama_pasangan = "";
        this.state.dt.tempat_lahir = "";
        this.state.dt.tanggal_lahir = "";
        this.state.dt.pekerjaan = "";
        this.state.dt.pendidikan_terakhir = "";
        this.state.dt.agama = "";
        this.state.dt.status_perkawinan = "";
        this.state.dt.alamat = "";
        this.state.showBio2 = false;
        this.state.pendStat2 = [];
      }
      this.state.status.btnForm = true;

      // this.state.status.btnAksi = false;
      this.forceUpdate();
    }
  };
  componentDidMount() {
    if (this.props.location.state == undefined) {
      this.setState({ redirect: true });
    } else {
      this.fetch({ page: null, where: [] });

      Get("penduduk", null, data => {
        let frmPend = [];
        let frmPend2 = [];
        data.results.data.map(dt => {
          frmPend.push({ value: dt.id, label: dt.id });
          frmPend2.push({ value: dt.id, label: dt.id });
        });
        this.setState({ frmPend, frmPend2 });
      });
      Get("tanda-tangan/list", null, data => {
        data.results.map((dt, i) => {
          if (dt.aktif == 1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.state.accButton = temp;
            this.state.accuuid = dt.uuid;
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
      Get("select-enum/penduduk/jk", null, data => {
        let frmJk = [];
        data.map(dt => {
          frmJk.push({ value: dt, label: dt });
        });
        this.setState({ frmJk });
      });
    }
  }

  cetakSurat = (e, data) => {
    e.preventDefault();
    let nama_nasab_p1 = null;
    let nama_nasab_p2 = null;
    let nama_pasangan = null;
    let jenis_kelamin_pasangan = null;
    let tempat_lahir_pasangan = null;
    let tanggal_lahir_pasangan = null;
    let pekerjaan_pasangan = null;
    let agama_pasangan = null;
    let alamat_pasangan = null;
    let nik_pasangan = null;
    let tempat_nikah = null;

    data.data_detail_suket.map(dt => {
      if (dt.nama_item == "nama_nasab_p1") {
        nama_nasab_p1 = dt.isi_item;
      }

      if (dt.nama_item == "nama_nasab_p2") {
        nama_nasab_p2 = dt.isi_item;
      }
      if (dt.nama_item == "nama_pasangan") {
        nama_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "jenis_kelamin_pasangan") {
        jenis_kelamin_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "tempat_lahir_pasangan") {
        tempat_lahir_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "tanggal_lahir_pasangan") {
        tanggal_lahir_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "pekerjaan_pasangan") {
        pekerjaan_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "agama_pasangan") {
        agama_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "nik_pasangan") {
        nik_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "alamat_pasangan") {
        alamat_pasangan = dt.isi_item;
      }
      if (dt.nama_item == "tempat_nikah") {
        tempat_nikah = dt.isi_item;
      }
    });
    this.setState({
      cetak: {
        nama_desa: "Sungai Petai",
        tanggal_surat: Tanggal(data.tanggal_surat),
        nomor_surat: data.nomor,
        nik: data.nik,
        nama_pemohon: data.nama_pemohon.toLowerCase(),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        pekerjaan: data.pekerjaan.toLowerCase(),
        pendidikan_terakhir: data.pendidikan_terakhir.toLowerCase(),
        status_perkawinan: data.status_perkawinan.toLowerCase(),
        agama: data.agama.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        jenis_kelamin: data.jenis_kelamin.toLowerCase(),
        kewarganegaraan: data.kewarganegaraan.toLowerCase(),
        tujuan_pembuatan: data.tujuan_pembuatan.toLowerCase(),
        lokasi: data.lokasi,
        kk: data.kk,
        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : "",

        nama_nasab_p1: nama_nasab_p1.toLowerCase(),
        nama_nasab_p2: nama_nasab_p2.toLowerCase(),
        nama_pasangan: nama_pasangan.toLowerCase(),
        jenis_kelamin_pasangan: jenis_kelamin_pasangan.toLowerCase(),
        tempat_lahir_pasangan: tempat_lahir_pasangan.toLowerCase(),
        tanggal_lahir_pasangan: tanggal_lahir_pasangan.toLowerCase(),
        pekerjaan_pasangan: pekerjaan_pasangan.toLowerCase(),
        agama_pasangan: agama_pasangan.toLowerCase(),
        alamat_pasangan: alamat_pasangan.toLowerCase(),
        nik_pasangan: nik_pasangan,

        tempat_nikah: tempat_nikah.toLowerCase()
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //// ACC
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  //change select
  changAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      if (e.value == "Disetujui") {
        this.state.tableAcc = "";
        this.state.btnFormAcc = true;
        if (this.state.accButton.length > 0) {
          this.state.btnFormAcc = false;
        }
      } else {
        this.state.tableAcc = "none";
        this.state.btnFormAcc = false;
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
      }
      this.state.tableAcc = "none";
      this.state.btnFormAcc = true;
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  simpanAcc = e => {
    e.preventDefault();
    this.state.btnFormAcc = true;

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/grup/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            btnFormAcc: false,

            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };
  render() {
    var size = Math.ceil(
      this.state.pagination.total / this.state.pagination.per_page
    );
    // var size = this.state.pagination.total;
    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;

    let frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      tanggal_surat: "",
      nik: "",
      nama_pemohon: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      pekerjaan: "",
      pekerjaan: "",
      agama: "",
      status_perkawinan: "",
      alamat: "",
      kewarganegaraan: "Indonesia",
      tujuan_pembuatan: "",
      lokasi: "",
      kk: "",
      detail: [],
      agama: "",
      jenis_kelamin: "",
      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",
      //detail
      nama_nasab_p1: "",
      nama_nasab_p2: "",
      nama_pasangan: "",
      jenis_kelamin_pasangan: "",
      tempat_lahir_pasangan: "",
      tanggal_lahir_pasangan: "",
      pekerjaan_pasangan: "",
      agama_pasangan: "",
      nik_pasangan: "",
      alamat_pasangan: "",
      tempat_nikah: ""
    };
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "tanggal_surat",
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat == null
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor == "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_pemohon",
        text: "Nama Pemohon",
        sort: true
      },
      {
        dataField: "tujuan_pembuatan",
        text: "Tujuan",
        formatter: (cell, obj) => {
          let tujuan = obj.tujuan_pembuatan.split(",");
          return tujuan[0];
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.map(dt => {
            status = dt.status;
          });
          if (status == "Diajukan") {
            this.state.status.btnAksi[cell] = true;
          } else {
            this.state.status.btnAksi[cell] = false;
          }
          this.forceUpdate();
          return status;
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    const defaultSorted = [
      {
        dataField: "nama",
        order: "asc"
      }
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = e => {
        e.preventDefault();
        this.fetch({ where: [], page: page });
      };
      var classname = "";
      classname = "btn btn-success";
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>
            {page}
          </a>
        </li>
      );
    };

    const options = {
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      // custom: true,
      paginationTotalRenderer: () => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {total} Results
        </span>
      ),
      paginationSize: size,
      totalSize: total
    };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] == 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] == 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div>
        {this.renderRedirect()}

        <Breadcrumb title="Data Surat Pindah Nikah" parent="Admin" />
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Surat Keterangan Pindah Nikah</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Pindah Nikah",
                            dt: frmDef,
                            showBio2: false,
                            pendStat2: [],
                            pendStat: [],
                            formPasangan: "none"
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK Pemohon{" "}
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.getPenduduk(e, "nik");
                    }}
                    defaultValue={this.state.pendStat}
                    value={this.state.pendStat}
                    name="penduduk"
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    options={this.state.frmPend}
                    placeholder="Pilih NIK "
                    isClearable
                  />
                </Col>
              </FormGroup>

              <div
                style={{ display: this.state.showBio == false ? "none" : "" }}
              >
                <Label
                  className="col-sm-12 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Detail Data
                </Label>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pemohon"
                      readOnly
                      type="text"
                      placeholder="nama_pemohon"
                      value={this.state.dt.nama_pemohon}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Nasab
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_nasab_p1"
                      type="text"
                      required
                      readOnly
                      placeholder="Nama Nasab"
                      defaultValue={this.state.dt.nama_nasab_p1}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik"
                  type="hidden"
                  readOnly
                  placeholder="NIK"
                  value={this.state.dt.nik}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    KK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="kk"
                  type="hidden"
                  readOnly
                  placeholder="kk"
                  value={this.state.dt.kk}
                />
                {/* </Col>
                </FormGroup> */}

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      readOnly
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      value={this.state.dt.tempat_lahir}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      readOnly
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="jenis_kelamin"
                  readOnly
                  type="hidden"
                  placeholder="jenis_kelamin"
                  value={this.state.dt.jenis_kelamin}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  readOnly
                  id="pekerjaan"
                  type="hidden"
                  placeholder="Pekerjaan"
                  value={this.state.dt.pekerjaan}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="pendidikan_terakhir"
                  readOnly
                  type="hidden"
                  placeholder="Pendidikan Terakhir"
                  value={this.state.dt.pendidikan_terakhir}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="agama"
                  readOnly
                  type="hidden"
                  placeholder="Agama"
                  value={this.state.dt.agama}
                />
                {/* </Col>
                </FormGroup> */}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan"
                      type="text"
                      readOnly
                      placeholder="Status Perkawinan"
                      value={this.state.dt.status_perkawinan}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="alamat"
                  type="hidden"
                  readOnly
                  placeholder="Alamat"
                  value={this.state.dt.alamat}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="kewarganegaraan"
                  type="hidden"
                  readOnly={true}
                  placeholder="Kewarganegaraan"
                  value={this.state.dt.kewarganegaraan}
                />
                {/* </Col>
                </FormGroup> */}
              </div>
              <hr />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK Pasangan{" "}
                </Label>
                <Col sm="9">
                  {this.state.formPasangan == "none" ? (
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2");
                      }}
                      defaultValue={this.state.pendStat2}
                      value={this.state.pendStat2}
                      name="penduduk"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      options={this.state.frmPend2}
                      placeholder="Pilih NIK "
                      isClearable
                    />
                  ) : (
                    <></>
                  )}
                  <input
                    className="form-control"
                    id="nik_pasangan"
                    type={
                      this.state.formPasangan == "none" ? "hidden" : "number"
                    }
                    required
                    placeholder="NIK Pasangan"
                    defaultValue={this.state.dt.nik_pasangan}
                    value={this.state.dt.nik_pasangan}
                    onChange={e => this.changeInputText(e, "nik_pasangan")}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    Data NIK {this.state.formPasangan == "none" ? "tidak" : ""}{" "}
                    tersedia?{" "}
                    <Link
                      to={{
                        pathname: this.props.myroute,
                        state: this.props.location.state
                      }}
                      onClick={this.dataPasangan}
                    >
                      {" "}
                      Klik disini
                    </Link>
                  </small>
                </Col>
              </FormGroup>
              <div
                style={{ display: this.state.showBio2 == false ? "none" : "" }}
              >
                <Label
                  className="col-sm-12 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Detail Data
                </Label>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pasangan"
                      type="text"
                      required
                      readOnly={this.state.formPasangan == "" ? false : true}
                      placeholder=" Nama Pasangan"
                      defaultValue={this.state.dt.nama_pasangan}
                      value={this.state.dt.nama_pasangan}
                      onChange={e => this.changeInputText(e, "nama_pasangan")}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Nasab
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_nasab_p2"
                      type="text"
                      required
                      readOnly={this.state.formPasangan == "" ? false : true}
                      placeholder="Nama Nasab"
                      defaultValue={this.state.dt.nama_nasab_p2}
                      value={this.state.dt.nama_nasab_p2}
                      onChange={e => this.changeInputText(e, "nama_nasab_p2")}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="jenis_kelamin_pasangan"
                      type="text"
                      readOnly={this.state.formPasangan == "" ? false : true}
                      placeholder="jenis_kelamin"
                      value={this.state.dt.jenis_kelamin_pasangan}
                      defaultValue={this.state.dt.jenis_kelamin_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "jenis_kelamin_pasangan")
                      }
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir_pasangan"
                      type="text"
                      required
                      readOnly={this.state.formPasangan == "" ? false : true}
                      placeholder="Tempat Lahir Pasangan"
                      defaultValue={this.state.dt.tempat_lahir_pasangan}
                      value={this.state.dt.tempat_lahir_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "tempat_lahir_pasangan")
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir_pasangan"
                      type={this.state.formPasangan == "" ? "date" : "text"}
                      readOnly={this.state.formPasangan == "" ? false : true}
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir_pasangan}
                      defaultValue={this.state.dt.tanggal_lahir_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "tanggal_lahir_pasangan")
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan_pasangan"
                      type="text"
                      readOnly={this.state.formPasangan == "" ? false : true}
                      required
                      placeholder="Pekerjaan Pasangan"
                      defaultValue={this.state.dt.pekerjaan_pasangan}
                      value={this.state.dt.pekerjaan_pasangan}
                      onChange={e =>
                        this.changeInputText(e, "pekerjaan_pasangan")
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama_pasangan"
                      type="text"
                      readOnly={this.state.formPasangan == "" ? false : true}
                      required
                      placeholder="Agama Pasangan"
                      defaultValue={this.state.dt.agama_pasangan}
                      value={this.state.dt.agama_pasangan}
                      onChange={e => this.changeInputText(e, "agama_pasangan")}
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat Pasangan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat_pasangan"
                      type="text"
                      readOnly={this.state.formPasangan == "" ? false : true}
                      required
                      placeholder=" Alamat Pasangan "
                      defaultValue={this.state.dt.alamat_pasangan}
                      value={this.state.dt.alamat_pasangan}
                      onChange={e => this.changeInputText(e, "alamat_pasangan")}
                    />
                  </Col>
                </FormGroup>
                {}
              </div>
              <hr />

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Nikah
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_nikah"
                    type="text"
                    required
                    placeholder="Tempat Nikah"
                    defaultValue={this.state.dt.tempat_nikah}
                    onFocus={e => e.currentTarget.select()}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                // disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-success">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Level</th>
                      <th>TTD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.datDisposisi.map((dt,i)=>{
                  return(
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{dt.data_jabatan.nama}</td>
                      <td>{dt.level}</td>
                      <td>

                      <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        this.disposisi(dt.uuid,this.state.idSurat)}
                      }
                      disabled={this.state.status.btnForm}
                    >
                      Disposisi
                    </button>
                      </td>
                    </tr>
                    )})}
                  </tbody>
                </table>
              </div>
              */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status == 0
                            ? "Menunggu Disposisi"
                            : dt.status == 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        <div id="cetak-surat" style={{ display: "none" }}>
          <title />
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n            td {\n                vertical-align: top;\n            }\n        "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah kabupaten kampar
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan kampar kiri hilir
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala desa sungai petai
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    jl. raya sungai pagar km. 35 kode pos 28471
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "center" }} width="50%">
                {this.state.cetak.nama_desa},{" "}
                {Tanggal(new Date(this.state.cetak.tanggal_surat))}
              </td>
            </tr>
          </table>

          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "17.7628%" }}>Nomor</td>
                        <td style={{ width: "8.0701%" }}>:</td>
                        <td style={{ width: "74.1108%" }}>
                          {this.state.cetak.nomor_surat}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "17.7628%" }}>Lampiran</td>
                        <td style={{ width: "8.0701%" }}>: </td>
                        <td style={{ width: "74.1108%" }}>Satu berkas N.A </td>
                      </tr>
                      <tr>
                        <td style={{ width: "17.7628%" }}>Perihal</td>
                        <td style={{ width: "8.0701%" }}>:</td>
                        <td style={{ width: "74.1108%" }}>
                          <strong>Rekomendasi Pindah Nikah</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "50%" }}>
                  <table border="0" width="100%">
                    <tr>
                      <td style={{ textAlign: "justify" }}></td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "justify" }}>Kepada Yth.:</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "justify" }}>
                        Bapak KUA Kec. Kampar Hilir
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "justify" }}>Di-</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            textDecoration: "underline"
                          }}
                        >
                          Sungai Pagar
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p>Assalamu Allaikum Wr.Wb.</p>

                <p style={{ textIndent: "50px" }}>
                  Yang bertanda tangan dibawah ini Kepala Desa Sungai Petai,
                  Kecamatan Kampar Kiri Hilir, Kab. Kampar, dengan ini memohon
                  rekomendasi pindah nikah untuk :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize"
                      }}
                    >
                      {this.state.cetak.nama_pemohon}
                      &nbsp;
                      {this.state.cetak.jenis_kelamin == "Laki-Laki"
                        ? "bin"
                        : "binti"}{" "}
                      &nbsp;{this.state.cetak.nama_nasab_p1}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir},
                      {Tanggal(this.state.cetak.tanggal_lahir)}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.agama}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.status_perkawinan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Untuk melaksanakan pernikahan dengan
                </span>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize"
                      }}
                    >
                      {this.state.cetak.nama_pasangan}
                      &nbsp;
                      {this.state.cetak.jenis_kelamin_pasangan == "Laki-Laki"
                        ? "bin"
                        : "binti"}{" "}
                      &nbsp;{this.state.cetak.nama_nasab_p2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir_pasangan},
                      {Tanggal(this.state.cetak.tanggal_lahir_pasangan)}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan_pasangan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.agama_pasangan}
                    </td>
                  </tr>

                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pasangan}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat_pasangan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat Nikah di
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_nikah}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Demikianlah permohonan rekomendasi ini kami sampaikan, atas
                  bantuan dan perhatiannya kami ucapkan terima kasih.
                </span>
              </td>
            </tr>
          </table>

          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td width="50%">&nbsp;</td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
