import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin } from '../function/Koneksi';
import { DataToken } from '../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
	uuid: '',
	nikStat: null,
	sttStat: null,
	pntStat: null,
	catatan: '',
}
const status = ["Belum Selesai", "Selesai"];
const penalti = ["Tidak", "Ya"];
class BasicTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				dataPenduduk: 'none',
			},
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			dt: frmDef,
			judul: "Tambah Catatan",
			// =========== Select 2 ============
			frmNik: [],
			nikStat: null,
			frmPnt: [
				{ value: 0, label: "Tidak" },
				{ value: 1, label: "Ya" },
			],
			pntStat: null,
			frmStt: [
				{ value: 0, label: "Belum Selesai" },
				{ value: 1, label: "Selesai" },
			],
			sttStat: null,
			// =================================
			// =========== Alert ============
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			pesanAlert: '',
			// ==============================
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = "";
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>': link = "?page=" + (Math.ceil(this.state.pagination.total / this.state.pagination.per_page)); break;
				case '>': link = "?page=" + (parseInt(this.state.pagination.current_page) + 1); break;
				case '<': link = "?page=" + (parseInt(this.state.pagination.current_page) - 1); break;
				case '<<': link = "?page=1"; break;
				default: link = "?page=" + params.page; break;
			}
		}
		Get('catatan-penduduk' + link, null, (dtkat) => {
			this.setState({ data: dtkat.results.data, pagination: dtkat.results });
			this.pagination(dtkat.results);
		});
	}

	// Pagination
	pagination = (data) => {
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			}
			else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "success";
				if (i == data.current_page) {
					warna = "danger"
				}
				hal.push(<a onClick={() => this.fetch({ page: i })} className={"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
			}
			if (p > 0) {
				sebelum = (<a onClick={() => this.fetch({ page: p })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
			}
			if (n > 0) {
				setelah = (<a onClick={() => this.fetch({ page: n })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
			}
			awal = (<a onClick={() => this.fetch({ page: 1 })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
			akhir = (<a onClick={() => this.fetch({ page: data.last_page })} className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	}
	// Cari Data Table
	cariData = e => {
		if (e.key == "Enter") {
			let addCatatan = {
				nama: e.target.value,
			};
			Post('catatan-penduduk', null, addCatatan, (data) => {
				this.setState({ data: data.data.results, pagination: data.data });
			});
		}
	}

	// ========================================================================

	// ================== simpan data ==================
	simpan = e => {
		e.preventDefault();
		const token = DataToken(localStorage.getItem("access_token"));
		if (this.state.nikStat && this.state.sttStat && this.state.pntStat) {
			this.state.status.btnForm = true;
			this.forceUpdate();
			let addCatatan = {
				uuid: document.getElementById("uuid").value,
				nik: this.state.nikStat.value,
				catatan: document.getElementById("catatan").value,
				penalti: this.state.pntStat.value,
				status: this.state.sttStat.value,
				id_pengguna: token.sub.uuid,
			};

			let psn = '';
			let resstat = 204;
			let metode = "create";
			if (addCatatan.uuid === '') {
				psn = "Tambah";
				resstat = 201;
				addCatatan.uuid = null;

			}
			else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post('catatan-penduduk/' + metode, addCatatan.uuid, addCatatan, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({ show: true, basicType: 'success', basicTitle: 'Data Catatan', pesanAlert: 'Berhasil ' + psn + ' Data' });
				}
				else {
					this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Catatan', pesanAlert: 'Gagal ' + psn + ' Data' });
				}
				this.componentDidMount();
			});
		}
		else {
			this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Catatan', pesanAlert: "NIK, Pembatasan Layanand dan Status harus diisi" });
		}
	}
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = "Ubah Catatan";
		this.forceUpdate();
		Get('catatan-penduduk/find', id, (data) => {
			this.state.dt.uuid = data.results.uuid;
			this.state.dt.catatan = data.results.catatan;
			this.state.nikStat = { value: data.results.nik, label: data.results.nik };
			this.state.sttStat = { value: data.results.status, label: status[parseInt(data.results.status)] };
			this.state.pntStat = { value: data.results.penalti, label: penalti[parseInt(data.results.penalti)] };
			this.state.status.btnAksi = false;
			this.getPenduduk({ value: data.results.nik, label: data.results.nik }, "nik");
			this.bukaForm();
		});
	}
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	}

	hapusData = (id) => {
		this.hideAlert();
		let psn = "Hapus";
		Delete('catatan-penduduk/delete', id, (res) => {
			if (res == 200) {
				this.setState({ show: true, basicType: 'success', basicTitle: 'Data Catatan', pesanAlert: 'Berhasil ' + psn + ' Data' });
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Catatan', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		})
	}
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	}

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	}

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
				<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
			</>
		)
	}

	ubahPilihan = (table, id) => {
		Get(table + '/find', id, (data) => {
			switch (table) {
				case 'provinsi': this.setState({ prvStat: { value: data.results.id, label: data.results.nama } });
					this.bukaForm(); break;
			}
		});
	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld == 'nik') {
				let nikStat = { value: e, label: e }
				let param = {
					nik: e,
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.map((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					})
					this.setState({ frmNik, nikStat });
				});
			}
		}
	}

	getPenduduk = (e, sel) => {
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				const data_rt = data.results.data_kk.data_rt;
				const data_rw = data_rt.data_rw;
				const data_dusun = data_rw.data_dusun;
				const data_desa = data_dusun.data_keldes;
				const data_kecamatan = data_desa.data_kecamatan;
				const data_kabkota = data_kecamatan.data_kabkota;
				let alamat = 'RT/RW : ' + data_rt.nama +
					'/' + data_rw.nama +
					', DUSUN ' + data_dusun.nama +
					', Desa ' + data_desa.nama +
					', Kecamatan ' + data_kecamatan.nama +
					', ' + data_kabkota.nama;


				this.state.tanggal_lahir = data.results.tanggal_lahir;
				this.state.dt.kk = data.results.id_kk;
				this.state.dt.nama = data.results.nama;
				this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID');
				this.state.dt.tempat_lahir = data.results.tempat_lahir;
				this.state.status.dataPenduduk = "inherit";
				this.forceUpdate();
			});
		}
		else {
			this.state.nikStat = null;
			this.state.dt.nama = "";
			this.state.dt.tanggal_lahir = "";
			this.state.dt.tempat_lahir = "";
			this.state.status.dataPenduduk = "none";
			this.forceUpdate();
		}
	}

	changeSelectValue = (e, sel) => {
		switch (sel) {
			case 'nik': this.setState({ nikStat: e }); break;
			case 'penalti': this.setState({ pntStat: e }); break;
			case 'status': this.setState({ sttStat: e }); break;
		}
	}

	componentDidMount() {
		this.fetch({ page: null, where: [] });

	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			id: '',
			nikStat: null,
			sttStat: null,
			pntStat: null,
			catatan: '',
		}
		// const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'nik',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				isDummyField: true,
				formatter: (cell, obj) => { return obj.data_penduduk ? obj.data_penduduk.nama : "-" },
				sort: true
			},
			{
				dataField: 'catatan',
				text: 'Catatan',
				sort: true
			},
			{
				dataField: 'penalti',
				text: 'Pembatasan Layanan',
				isDummyField: true,
				formatter: (cell, obj) => { return obj.penalti == 1 ? "Ya" : "Tidak" },
				sort: true
			},
			{
				dataField: 'status',
				text: 'Status',
				isDummyField: true,
				formatter: (cell, obj) => { return obj.status == 1 ? "Selesai" : "Belum Selesai" },
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
			},
		];

		const defaultSorted = [{
			dataField: 'nama',
			order: 'asc'
		}];

		const pageButtonRenderer = ({
			page,
			active,
			disable,
			title,
			onPageChange
		}) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>{page}</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing { from} to { to} of { total} Results
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				<Breadcrumb title="Data Catatan Penduduk" parent="Admin" />
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											<h5>Data Catatan Penduduk</h5>
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button size="sm" color="success" className="btn-square" onClick={() => {
												this.setState({ judul: "Tambah Catatan", dt: frmDef }); this.bukaForm();
											}}>Tambah Data</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<BootstrapTable
										keyField="uuid"
										data={data}
										columns={columns}
									/>
									<div className="pull-right text-white">
										{this.state.awal}{this.state.sebelum}{this.state.hal.map(dt => { return dt })}{this.state.setelah}{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<p>jika pada pilihan Pembatasan Layanan dipilih Ya, Catatan Penduduk ini akan mempengaruhi akses penduduk terhadap layanan. Akibatnya NIK ybs tidak bisa mengakses layanan - layanan tertentu.</p>
							<input className="form-control" id="uuid" type="hidden" placeholder="Catatan" defaultValue={this.state.dt.uuid} />
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={((e) => { this.getPenduduk(e, 'nik') })}
										defaultValue={this.state.nikStat}
										value={this.state.nikStat}
										name="NIK"
										options={this.state.frmNik}
										placeholder="Pilh NIK"
										onInputChange={(e) => this.handleInputChangeNIK(e, 'nik')}
										isClearable
									/>
								</Col>
							</FormGroup>
							<div style={{ display: this.state.status.dataPenduduk }}>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Pemohon</Label>
									<Col sm='9'>
										<input className="form-control" id="nama" type="text" placeholder="Nama Pemohon" value={this.state.dt.nama} readOnly />
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
									<Col sm='9'>
										<input className="form-control" id="tempat_lahir" type="text" placeholder="Tempat Lahir" value={this.state.dt.tempat_lahir} readOnly />
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
									<Col sm='9'>
										<input className="form-control" id="tanggal_lahir" type="text" placeholder="Tanggal Lahir" value={this.state.dt.tanggal_lahir} readOnly />
									</Col>
								</FormGroup>
							</div>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Catatan</Label>
								<Col sm='9'>
									<textarea className="form-control" id="catatan" placeholder="Catatan" defaultValue={this.state.dt.catatan} />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pembatasan Layanan</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={((e) => { this.changeSelectValue(e, 'penalti') })}
										defaultValue={this.state.pntStat}
										value={this.state.pntStat}
										name="PembatasanLayanan"
										options={this.state.frmPnt}
										placeholder="Pembatasan Layanan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status</Label>
								<Col sm='9'>
									<Select
										classNamePrefix="select"
										onChange={((e) => { this.changeSelectValue(e, 'status') })}
										defaultValue={this.state.sttStat}
										value={this.state.sttStat}
										name="Status"
										options={this.state.frmStt}
										placeholder="Status"
										isClearable
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
						</div>
					</Form>
				</Modal>

			</div>
		)
	}
}


export default BasicTable;
