import React, {Component} from 'react';
import {Post, Get, Delete, cekLogin} from '../function/Koneksi';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';

var images = require.context('../assets/images/', true);

class Samplepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',

        };
    }
       // ================== Sweet Alert ==================
       closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================
    handleSubmit = event => {
        event.preventDefault();
        let cek ={
            id : document.getElementById("nik").value,
            nama : document.getElementById("nama").value,
        }
        let daftar = {
            username : document.getElementById("username").value,
            password : document.getElementById("password").value,
            nik : document.getElementById("nik").value,
            id_role :"s",
            email : document.getElementById("email").value,
            no_hp : document.getElementById("no_hp").value,            
        };
        console.log(daftar);
        let resstat = 201;
        cekLogin('penduduk/verifikasi',cek, (resCek) => {    
            console.log("resCek");      
            console.log(resCek);      
            if(resCek.status==200){
                cekLogin('pengguna/create',daftar, (res) => {          
                    if (res.status === resstat)   
                       {
                           this.setState({ show: true, basicType:'success', basicTitle:'Daftar', pesanAlert:'Berhasil mendaftarkan akun!' });
                       }
                       else  if (res.status == 409)   
                       {
                           this.setState({ show: true, basicType:'danger', basicTitle:'Daftar', pesanAlert:res.data.message });
                       }
                       else {
                           this.setState({ show: true, basicType:'danger', basicTitle:'Daftar', pesanAlert:'Gagal mendaftarkan akun!!' });
                       }
                      
               })
            } else {
                console.log(resCek);
                this.setState({ show: true, basicType:'danger', basicTitle:'Daftar', pesanAlert:"Masukan NIK dan nama lengkap sesuai KTP!" });
            }
       
    })

      }
    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }

    render() {
        let style = this.state.style;
        const background = require('../assets/images/auth-layer.png')

        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={{ backgroundImage: "url("+background+")" }}>
                                        <div className="text-center">
                                            <img src={require('../assets/images/logo-auth.gif')} className="logo-login" alt=""/>
                                                <hr/>
                                                 <div className="social-media">
                                                     <ul className="list-inline">
                                                         <li className="list-inline-item"><i className="fa fa-facebook" aria-hidden="true"></i></li>
                                                         <li className="list-inline-item"><i className="fa fa-google-plus" aria-hidden="true"></i></li>
                                                         <li className="list-inline-item"><i className="fa fa-twitter" aria-hidden="true"></i></li>
                                                         <li className="list-inline-item"><i className="fa fa-instagram" aria-hidden="true"></i></li>
                                                         <li className="list-inline-item"><i className="fa fa-rss" aria-hidden="true"></i></li>
                                                     </ul>
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h3 className="text-center">Registrasi Akun Kependudukan (SuperOperator)</h3>
                                            <h6 className="text-center">Masukan dan lengkapi data kependudukan anda untuk menggunakan aplikasi</h6>
                                            <div className="card mt-4 p-4">
                                                <form onSubmit={this.handleSubmit} className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label">NIK</label>
                                                        <input type="number" min="0" required className="form-control" id="nik" placeholder="masukan sesuai  identitas kependudukan anda"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Nama Lengkap</label>
                                                        <input type="text" required  id="nama"  className="form-control" placeholder="masukan nama lengkap anda"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Username</label>
                                                        <input type="text" required  id="username"  className="form-control" placeholder="masukan username anda"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input type="password" required id="password" className="form-control" placeholder="masukan password anda"/>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label className="col-form-label">Email</label>
                                                        <input type="email" required className="form-control" id="email"  placeholder="masukan email anda"/>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label className="col-form-label">No. Handphone</label>
                                                        <input type="telp" required className="form-control" id="no_hp"  placeholder="masukan nomor handphone anda"/>
                                                    </div>
                                                   <div className="form-row">
                                                        <div className="col-sm-12 text-center">
                                                            <button type="submit" className="btn btn-success btn-block">Daftar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mt-4">
                                                        <div className="col-sm-12 text-center">
                                                            <Link to="/login"  className="btn btn-warning btn-block">Kembali
                                                            </Link>
                                                        </div>
                                                    </div>
                                                

                                                  
                                                </form>
                                            </div>
                                            <div className="text-center mt-4">
                                                <h6>Sudah punya akun? <Link to="/login" ><u className="text-success">Masuk disini</u></Link></h6>
                                            </div>  
                                        </div>
                                    </div>
                                </div>

                            </div>

                        {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default Samplepage;