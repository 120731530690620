import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';

function EditKuasa({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  let usaha = detailSuratProps.detailSurat.usaha.map((dt, idx) => (
    <div className="shareholder">
      <FormGroup className="row">
        <Col sm="3">
          <input
            className="form-control"
            id={"nama_rincian" + idx}
            type="text"
            required
            placeholder={"Nama Rincian " + (idx + 1)}
            value={dt.nama_rincian}
          />
        </Col>
        <Col sm="7">
          <input
            className="form-control"
            id={"isi_rincian" + idx}
            type="text"
            required
            placeholder={"Isi Rincian" + (idx + 1)}
            value={dt.isi_rincian}
          />
        </Col>
        <Col sm="2">
          <div className="float-right">
            <button
              type="button"
              className="btn btn-danger small"
              onClick={detailSuratProps.handleRemoveShareholder(idx)}
            >
              -
            </button>
          </div>
        </Col>
      </FormGroup>
    </div>
  ));

  return (
    <div>
      <Modal
        open={detailSuratProps.status.formUpdateSurat}
        onClose={detailSuratProps.tutupForm}
        styles={{ modal: { width: "80%" } }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Ubah {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <input
              className="form-control"
              id="uuid"
              type="hidden"
              placeholder=""
              defaultValue={detailSuratProps.detailSurat.uuid}
            />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Kuasa Dari{" "}
              </Label>
              <Col sm="9">
                <Select
                  // isDisabled={this.state.status.select}
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, "nik");
                  }}
                  defaultValue={detailSuratProps.detailSurat.pendStat}
                  value={detailSuratProps.detailSurat.pendStat}
                  name="penduduk"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilih NIK"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik")}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div
              style={{ display: detailSuratProps.status.showBio == false ? "none" : "" }}
            >
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="nik"
                type="hidden"
                readOnly
                placeholder="NIK"
                value={detailSuratProps.detailSurat.nik}
              />
              {/* </Col>
                </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_pemohon"
                    readOnly
                    type="text"
                    placeholder="nama_pemohon"
                    value={detailSuratProps.detailSurat.nama}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    readOnly
                    id="tempat_lahir"
                    type="text"
                    placeholder="Tempat lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir"
                    readOnly
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={detailSuratProps.detailSurat.tanggal_lahir}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                readOnly
                id="pekerjaan"
                type="hidden"
                placeholder="Pekerjaan"
                value={detailSuratProps.detailSurat.pekerjaan}
              />
              {/* </Col>
                </FormGroup> */}

              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="alamat"
                type="hidden"
                readOnly
                placeholder="Alamat"
                value={detailSuratProps.detailSurat.alamat}
              />
              {/* </Col>
                </FormGroup> */}
            </div>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Yang Diberi Kuasa{" "}
              </Label>
              <Col sm="9">
                <Select
                  // isDisabled={this.state.status.select}
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, "nik2");
                  }}
                  defaultValue={detailSuratProps.detailSurat.pendStat2}
                  value={detailSuratProps.detailSurat.pendStat2}
                  name="penduduk"
                  options={detailSuratProps.dataNik.frmNik2}
                  placeholder="Pilih NIK"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik2")}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div
              style={{ display: detailSuratProps.status.showBio2 == false ? "none" : "" }}
            >
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="nik2"
                readOnly
                type="hidden"
                required
                placeholder="NIK"
                defaultValue={detailSuratProps.detailSurat.nik2}
              />
              {/* </Col>
                </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama2"
                    required
                    type="text"
                    readOnly
                    placeholder="Nama"
                    value={detailSuratProps.detailSurat.nama2}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    required
                    id="tempat_lahir2"
                    readOnly
                    type="text"
                    placeholder="Tempat lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir2}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    readOnly
                    id="tanggal_lahir2"
                    readOnly
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={detailSuratProps.detailSurat.tanggal_lahir2}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                required
                id="pekerjaan2"
                readOnly
                type="hidden"
                placeholder="Pekerjaan"
                defaultValue={detailSuratProps.detailSurat.pekerjaan2}
              />
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="alamat2"
                readOnly
                type="hidden"
                required
                placeholder="Alamat"
                defaultValue={detailSuratProps.detailSurat.alamat2}
              />
              {/* </Col>
                </FormGroup> */}
            </div>
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Kuasa Untuk
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="kuasa_untuk"
                  type="text"
                  required
                  placeholder="Kuasa Untuk"
                  defaultValue={detailSuratProps.detailSurat.kuasa_untuk}
                />
              </Col>
            </FormGroup>{" "}
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Kuasa Atas
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="kuasa_atas"
                  type="text"
                  required
                  placeholder="Kuasa Atas"
                  defaultValue={detailSuratProps.detailSurat.kuasa_atas}
                />
              </Col>
            </FormGroup>{" "}
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Tujuan Kuasa
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="tujuan_kuasa"
                  type="text"
                  required
                  placeholder="Tujuan Atas"
                  defaultValue={detailSuratProps.detailSurat.tujuan_kuasa}
                />
              </Col>
            </FormGroup>{" "}
            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
              Rincian Data
              </Label>
            {usaha}
            <button
              type="button"
              onClick={detailSuratProps.handleAddShareholder}
              className="btn btn-success small"
            >
              Tambah rincian
              </button>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              // disabled={detailSuratProps.status.btnForm}
              onClick={detailSuratProps.tutupForm}
            >
              Tutup
              </button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >
              Simpan
              </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditKuasa
