import React, {Component} from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup,CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import {Post, Get, Delete, cekLogin} from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');

const frmDef = {
    uuid: '',
    nama  : '',
}
class BasicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form:false,
                btnForm:false,
                btnAksi:false
            },
            foto_desa:"",
            foto_info:"",
            input:"none",
            view:"inherit",
            judul: "Tambah Profile",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
        };
    }
    
    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = () => 
    {    
        Get('profile-desa', null, (dtkat) => {
            console.log(dtkat);
            this.setState({data:dtkat.results});
        });
    }

    // ========================================================================

    tutupForm = e => {
        this.setState({input:"none", view:"inherit"});
    }

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();
        this.state.status.btnForm = true;
        this.forceUpdate();
        let uuid = this.state.data.uuid;
        let addProfile = {
            foto_desa : this.state.foto_desa,
            nama_desa : document.getElementById("nama_desa").value,
            kode_desa : document.getElementById("kode_desa").value,
            alamat_desa : document.getElementById("alamat_desa").value,
            kode_pos : document.getElementById("kode_pos").value,
            notelp : document.getElementById("notelp").value,
            email : document.getElementById("email").value,
            fax : document.getElementById("fax").value,
            website : document.getElementById("website").value,
            batas_utara : document.getElementById("batas_utara").value,
            batas_timur : document.getElementById("batas_timur").value,
            batas_selatan : document.getElementById("batas_selatan").value,
            batas_barat : document.getElementById("batas_barat").value,
        };
        
        let psn = "Ubah"; 
        let resstat = 200;
        let metode = "update";
        Post('profile-desa/'+metode,uuid,addProfile, (res) => {
            if (res.status === resstat)
            {
                this.setState({ show: true, basicType:'success', basicTitle:'Data Profile', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Profile', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.setState({input:"none",view:"inherit"});
            this.fetch();
        });
    }
    // ========================================================================
    
    // ============================== Ubah Data ===============================
    
    ubahData = e => {
        e.preventDefault();
        this.setState({view:"none", input:"inherit"});
    }
    // ========================================================================

    componentDidMount() {
        this.fetch({page:null,where:[]});
    }

    processGbr = (d) => {
        // console.log(d[0].type);
        let type = d[0].type.split("/");
        if (type[1] == "jpg" || type[1] == "jpeg" || type[1] == "png" || type[1] == "bmp" || type[1] == "tiff" || type[1] == "webp")
        {
            Resizer.imageFileResizer(
                d[0],
                300,
                300,
                type[1],
                100,
                0,
                uri => {
                    this.setState({foto_desa:uri,foto_info:""});
                },
                'base64'
            );
        }
        else 
        {
            this.setState({foto_desa:"", foto_info:<div className='text-danger font-size-10'>Type file tidak valid</div>});
        }
    }

    render() {
        
        return (
            <div>
                <Breadcrumb title="Data Profile" parent="Admin"/>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5>Data Profile Desa</h5>
                                            {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <a href="" onClick={this.ubahData}><i className="fa fa-edit text-success" style={{fontSize:"24px"}}></i></a>                                                  
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2">
                                        &nbsp;
                                    </div>
                                    <div className="card-body col-lg-8 p-3" id="inputDesa" style={{display:this.state.input}}>
                                        <div className="row">
                                            <div className="col-lg-6 pr-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Nama Desa</Label>
                                                        <input className="form-control" id="nama_desa" type="text" placeholder="Nama Desa" defaultValue={this.state.data.nama_desa}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-6 pl-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Kode Desa</Label>
                                                        <input className="form-control" id="kode_desa" type="text" placeholder="Kode Desa" defaultValue={this.state.data.kode_desa}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Alamat</Label>
                                                    <textarea className="form-control" id="alamat_desa" placeholder="Alamat Desa" defaultValue={this.state.data.alamat_desa}></textarea>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 pr-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Kode Pos</Label>
                                                    <input className="form-control" id="kode_pos" type="text" placeholder="Kode Pos" defaultValue={this.state.data.kode_pos}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 pl-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Telp</Label>
                                                    <input className="form-control" id="notelp" type="text" placeholder="Telp Desa" defaultValue={this.state.data.notelp}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 pl-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Foto Desa</Label>
                                                    <Dropzone onDrop={acceptedFiles => this.processGbr(acceptedFiles)} maxFiles={1}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                        <div {...getRootProps()} style={{minHeight:"100px",paddingTop:"40px", padding:"5px 5px 5px 5px", border:"1px solid #d2d2d2"}}>
                                                            <input {...getInputProps()} />
                                                            <p>{this.state.foto_desa?<img src={this.state.foto_desa} style={{minHeight:"50px",minWidth:"50px"}}/>:"Drag 'n' drop some files here, or click to select files"}{this.state.foto_info?this.state.foto_info:""}</p>
                                                        </div>
                                                        </section>
                                                    )}
                                                    </Dropzone>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 pr-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Email</Label>
                                                    <input className="form-control" id="email" type="text" placeholder="Email" defaultValue={this.state.data.email}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 pl-3 pr-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Fax</Label>
                                                    <input className="form-control" id="fax" type="text" placeholder="Fax" defaultValue={this.state.data.fax}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 pl-3">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Website</Label>
                                                    <input className="form-control" id="website" type="text" placeholder="Website" defaultValue={this.state.data.website}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Batas Sebelah Utara</Label>
                                                    <textarea className="form-control" id="batas_utara" placeholder="Batas Sebelah Utara Desa" defaultValue={this.state.data.batas_utara}></textarea>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Batas Sebelah Timur</Label>
                                                    <textarea className="form-control" id="batas_timur" placeholder="Batas Sebelah Timur Desa" defaultValue={this.state.data.batas_timur}></textarea>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Batas Sebelah Selatan</Label>
                                                    <textarea className="form-control" id="batas_selatan" placeholder="Batas Sebelah Selatan Desa" defaultValue={this.state.data.batas_selatan}></textarea>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <FormGroup className="row">
                                                    <Label className="col-form-label" htmlFor="inputEmail3">Batas Sebelah Barat</Label>
                                                    <textarea className="form-control" id="batas_barat" placeholder="Batas Sebelah Barat Desa" defaultValue={this.state.data.batas_barat}></textarea>
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12 text-right">
                                                <button type="button" className="btn btn-warning mr-3" onClick={this.tutupForm}>Batal</button>
                                                <button type="submit" className="btn btn-success ml-3" onClick={this.simpan}>Simpan</button>             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        &nbsp;
                                    </div>
                                </div>
                                <div className="card-body" id="viewDesa" style={{display:this.state.view, fontSize:"20px"}}>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <img src={this.state.data.foto_desa} style={{width:"100%"}}/>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="row">
                                                <div className="col-lg-4">ID Desa</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.id_keldes}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Nama Desa</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.nama_desa}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Kode Desa</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.kode_desa}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Alamat</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.alamat_desa}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Kode Pos</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.kode_pos}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Telp</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.notelp}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Email</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.email}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Fax</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.fax}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Website</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.website}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">Batas desa</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7"></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"  style={{paddingLeft:"30px"}}>Sebelah Utara</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.batas_utara}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"  style={{paddingLeft:"30px"}}>Sebelah Timur</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.batas_timur}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"  style={{paddingLeft:"30px"}}>Sebelah Selatan</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.batas_selatan}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"  style={{paddingLeft:"30px"}}>Sebelah Barat</div>
                                                <div className="col-lg-1">:</div>
                                                <div className="col-lg-7">{this.state.data.batas_barat}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default BasicTable;
