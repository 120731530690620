import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';

function EditKelahiran({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  // console.log(detailSuratProps.dataNik);

  return (
    <div>
      <Modal open={detailSuratProps.status.formUpdateSurat} onClose={detailSuratProps.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>Data identitas akan terisi secara otomatis saat NIK dipilih. Jika ada ketidak sesuaian data, silakan ubah di data master penduduk.</p>
            <input className="form-control" id="uuid" type="hidden" placeholder="" defaultValue={detailSuratProps.detailSurat.uuid} />
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir Anak</Label>
              <Col sm='9'>
                <Datepicker
                  className="form-control"
                  selected={detailSuratProps.detailSurat.tanggal_lahir_anak}
                  onChange={(e) => detailSuratProps.gantiTgl(e, 'tanggal_lahir_anak')}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="tanggal"
                  dateFormat="dd/MM/yyyy HH:mm "
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir Anak</Label>
              <Col sm='9'>
                <input className="form-control" id="tempat_lahir_anak" type="text" placeholder="Tempat Lahir Anak" defaultValue={detailSuratProps.detailSurat.tempat_lahir_anak} required />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Anak</Label>
              <Col sm='9'>
                <input className="form-control" id="nama_anak" type="text" placeholder="Nama Anak" defaultValue={detailSuratProps.detailSurat.nama_anak} required />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Kelamin Anak</Label>
              <Col sm='9'>
                <select className="form-control" id="jk_anak" placeholder="jk_anak" defaultValue={detailSuratProps.detailSurat.jk_anak}>
                  <option value={'Laki-laki'}>Laki-Laki</option>
                  <option value={'Perempuan'}>Perempuan</option>
                </select>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK Ayah</Label>
              <Col sm='9'>
                <Select
                  classNamePrefix="select"
                  onChange={((e) => { detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'pemohon') })}
                  defaultValue={detailSuratProps.dataNikStat.nikStat}
                  value={detailSuratProps.dataNikStat.nikStat}
                  name="NIK"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilh NIK"
                  onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Ayah</Label>
                <Col sm='9'>
                  <input className="form-control" id="nama_ayah" type="text" placeholder="Nama Ayah" value={detailSuratProps.detailSurat.nama_ayah} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.nama_ayah}</span> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir Ayah</Label>
                <Col sm='9'>
                  <input className="form-control" id="tempat_lahir_ayah" type="text" placeholder="Tempat Lahir Ayah" value={detailSuratProps.detailSurat.tempat_lahir_ayah} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.tempat_lahir_ayah}</span> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir Ayah</Label>
                <Col sm='9'>
                  <input className="form-control" id="tanggal_lahir_ayah" type="text" placeholder="Tanggal Lahir" value={detailSuratProps.detailSurat.tanggal_lahir_ayah} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.tanggal_lahir_ayah.toLocaleDateString('id-ID')}</span> */}
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan Ayah</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="pekerjaan_ayah" type="text" placeholder="Pekerjaan Ayah" value={detailSurat.pekerjaan_ayah} readOnly /> */}
                  <span>{detailSuratProps.detailSurat.pekerjaan_ayah}</span>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat Ayah</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="alamat_ayah" type="text" placeholder="Alamat Ayah" value={detailSurat.alamat_ayah} readOnly /> */}
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK Ibu</Label>
              <Col sm='9'>
                <Select
                  classNamePrefix="select"
                  onChange={((e) => { detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'ibu') })}
                  defaultValue={detailSuratProps.dataNikStat.nik3Stat}
                  value={detailSuratProps.dataNikStat.nik3Stat}
                  name="NIK3"
                  options={detailSuratProps.dataNik.frmNik3}
                  placeholder="Pilh NIK IBU"
                  onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik3')}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk3 }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Ibu</Label>
                <Col sm='9'>
                  <input className="form-control" id="nama_ibu" type="text" placeholder="Nama Ibu" value={detailSuratProps.detailSurat.nama_ibu} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.nama_ibu}</span> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir Ibu</Label>
                <Col sm='9'>
                  <input className="form-control" id="tempat_lahir_ibu" type="text" placeholder="Tempat Lahir Ibu" value={detailSuratProps.detailSurat.tempat_lahir_ibu} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.tempat_lahir_ibu}</span> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir Ibu</Label>
                <Col sm='9'>
                  <input className="form-control" id="tanggal_lahir_ibu" type="text" placeholder="Tanggal Lahir" value={detailSuratProps.detailSurat.tanggal_lahir_ibu} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.tanggal_lahir_ibu.toLocaleDateString('id-ID')}</span> */}
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan Ibu</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="pekerjaan_ibu" type="text" placeholder="Pekerjaan Ibu" value={detailSurat.pekerjaan_ibu} readOnly /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat Ibu</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="alamat_ibu" type="text" placeholder="Alamat Ibu" value={detailSurat.alamat_ibu} readOnly /> */}
                </Col>
              </FormGroup>
            </div>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK Pelapor</Label>
              <Col sm='9'>
                <Select
                  classNamePrefix="select"
                  onChange={((e) => { detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'pelapor') })}
                  defaultValue={detailSuratProps.dataNikStat.nik2Stat}
                  value={detailSuratProps.dataNikStat.nik2Stat}
                  name="NIK2"
                  options={detailSuratProps.dataNik.frmNik2}
                  placeholder="Pilh NIK Pelapor"
                  onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik2')}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk2 }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Pelapor</Label>
                <Col sm='9'>
                  <input className="form-control" id="nama_pelapor" type="text" placeholder="Nama Pemohon" value={detailSuratProps.detailSurat.nama_pelapor} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.nama_pelapor}</span> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tempat_lahir_pelapor" type="text" placeholder="Tempat Lahir" value={detailSuratProps.detailSurat.tempat_lahir_pelapor} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.tempat_lahir_pelapor}</span> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tanggal_lahir_pelapor" type="text" placeholder="Tanggal Lahir" value={detailSuratProps.detailSurat.tanggal_lahir_pelapor} readOnly />
                  {/* <span>{detailSuratProps.detailSurat.tanggal_lahir_pelapor.toLocaleDateString('id-ID')}</span> */}
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="pekerjaan_pelapor" type="text" placeholder="Pekerjaan" value={detailSurat.pekerjaan_pelapor} readOnly /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Agama</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="agama_pelapor" type="text" placeholder="Agama" value={detailSurat.agama_pelapor} readOnly /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
                <Col sm='9'>
                  {/* <input className="form-control" id="alamat_pelapor" type="text" placeholder="Alamat" value={detailSurat.alamat_pelapor} readOnly /> */}
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Hubungan Pelapor</Label>
              <Col sm='9'>
                <input className="form-control" id="hubungan_pelapor" type="text" placeholder="Hubungan Pelapor" defaultValue={detailSuratProps.detailSurat.hubungan_pelapor} required />
              </Col>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-warning" onClick={detailSuratProps.tutupForm}>Tutup</button>
            <button type="submit" className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >Simpan</button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditKelahiran
